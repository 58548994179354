import { Alert, Button, ButtonGroup, Modal } from "react-bootstrap";

const ErrorsModal = (props:any) => {
  const errorsList:string[] = props.errorsList || [];
  const warningsList: string[] = props.warningsList || [];
  const miscMsgs: string[] = props.miscMsgs || [];
  const header = props.header;
  const onHide = props.onHide;
  const show = props.show;
  const animation = props.animation || true;
  const size = props.size || 'lg';
  const buttonText = props.buttonText;
  const buttonAction = props.buttonAction;

  return (
      <Modal
        show={show}
        onHide={onHide}
        animation={animation}
        size={size}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {header}
            {!header && errorsList.length > 0 && 'Error'}
            {!header && errorsList.length === 0 && warningsList.length > 0 && 'Warning'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorsList.map((errorMsg:string) => (
            <Alert key={errorMsg} variant='danger'>
              {errorMsg}
            </Alert>
          ))}
          {warningsList.map((msg:string) => (
            <Alert key={msg} variant='warning'>
              {msg}
            </Alert>
          ))}
          {miscMsgs.map((msg:string) => (
            <Alert key={msg}>
              {msg}
            </Alert>
          ))}
          {buttonText && buttonAction &&
            <div className='d-flex justify-content-center'>
              <ButtonGroup>
                <Button onClick={buttonAction}>{buttonText}</Button>{' '}
                <Button onClick={onHide} variant='outline-primary'>Dismiss</Button>
              </ButtonGroup>
            </div>
          }
        </Modal.Body>
      </Modal>
  );
};

ErrorsModal.displayName = 'ErrorsModal';

export default ErrorsModal;
