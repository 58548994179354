import { useEffect, useState } from "react";
import { Alert, Container, Nav, Tab } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { isArrayEqual } from "../../Helpers";
import MySpinner from "../../MySpinner";
import ProcessedDemandMatrix from "./ProcessedDemandMatrix";
import VariantResultsByBidder from "./VariantResultsByBidder";
import VariantResultsByProduct from "./VariantResultsByProduct";
import VariantResultsNav from "./VariantResultsNav";

const VariantResults = (props:any) => {
  const isAdmin = props.isAdmin || JSON.parse(window.localStorage.getItem('isAdmin') as string);
  const [parentAuction, setParentAuction] = useState<Auction|null>(null);
  const variationId = parseInt(useParams().id as string);
  const [variation, setVariation] = useState<Variation|null>(null);
  const [variationLoading, setVariationLoading] = useState(true);
  const [variationLoadError, setVariationLoadError] = useState('');
  const [variants, setVariants] = useState<Variant[]|null>(null);
  const [variantsLoading, setVariantsLoading] = useState(true);
  const [variantsLoadError, setVariantsLoadError] = useState('');
  const [results, setResults] = useState<VarRes|null>(null);
  const [resultsLoading, setResultsLoading] = useState(true);
  const [resultsLoadError, setResultsLoadError] = useState('');
  const [allProductsDict, setAllProductsDict] = useState<{ [key: string]: GeneralProduct }|null>(null);
  const [productsLoading, setProductsLoading] = useState(true);
  const [productsLoadError, setProductsLoadError] = useState('');
  const [biddersDict, setBiddersDict] = useState<{ [key: string]: Bidder }|null>(null);

  const createBiddersDict = (bidders: Bidder[]) => {
    let updatedBiddersDict: { [key: string]: Bidder } = {};

    bidders.forEach((bidder: Bidder)=>{
      updatedBiddersDict[bidder.abbr] = bidder;
    });

    setBiddersDict(updatedBiddersDict);
  }

  const fetchParentAuction = (auctionId: number) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/auctions/get/${auctionId}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`${res.status} error: ${res.statusText}`);
        }
        else {
          return res.json();
        }
      })
      .then((data)=>{
        setParentAuction(data);
        // setParentAuctionLoading(false);
      })
      .catch((err)=>{
        console.log(err);
        // setParentAuctionLoadError('Parent Auction Load Error: ' + err.message);
      });
  }
  const fetchVariation = (variationId: number) => {
    setVariationLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/variations/get/${variationId}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`${res.status} error: ${res.statusText}`);
        }
        else {
          return res.json();
        }
      })
      .then((data)=>{
        setVariation(data);
        setVariationLoading(false);
      })
      .catch((err)=>{
        console.log(err);
        setVariationLoadError('Variation Load Error: ' + err.message);
      });
  }
  const fetchVariants = (variationId: number) => {
    setVariantsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/variants/list?variation_id=${variationId}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`${res.status} error: ${res.statusText}`);
        }
        else {
          return res.json();
        }
      })
      .then((data)=>{
        setVariants(data);
        setVariantsLoading(false);
      })
      .catch((err)=>{
        console.log(err);
        setVariantsLoadError('Variants Load Error: ' + err.message);
      });
  }
  const fetchResults = (variationId: number) => {
    setResultsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/variations/results/${variationId}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`${res.status} error: ${res.statusText}`);
        }
        else {
          return res.json();
        }
      })
      .then((data)=>{
        setResults(data);
        setResultsLoading(false);
      })
      .catch((err)=>{
        console.log(err);
        setResultsLoadError('Variation Results Load Error: ' + err.message);
      });
  }
  const fetchProducts = () => {
    setProductsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/products/list`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`${res.status} error: ${res.statusText}`);
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setAllProductsDict(data);
        setProductsLoading(false)
      })
      .catch((err) => {
        setProductsLoadError('Product Data Load Error: ' + err.message);
        console.log(err);
      });
  }

  useEffect(()=>{
    if (variation) {
      // window.sessionStorage.setItem(`variation-${variationId}`, JSON.stringify(variation));
      fetchParentAuction(variation.auction_id);

      fetch(`${process.env.REACT_APP_API_URL}/api/bidders/list/${(variation as Variation).auction_id}`)
        .then((res) => {
          if(!res.ok) {
            throw new Error(`${res.status} error: ${res.statusText}`);
          }
          else {
            return res.json();
          }
        })
        .then((data) => {
          createBiddersDict(data);
          // console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [variation, variationId]);
  // useEffect(()=>{
  //   if (variants) {
  //     window.sessionStorage.setItem(`variants-${variationId}`, JSON.stringify(variants));
  //   }
  // }, [variants, variationId]);
  // useEffect(()=>{
  //   if (results) {
  //     window.sessionStorage.setItem(`results-${variationId}`, JSON.stringify(results));
  //   }
  // }, [results, variationId]);
  // useEffect(()=>{
  //   if (allProductsDict) {
  //     window.localStorage.setItem(`products-list`, JSON.stringify(allProductsDict));
  //   }
  // }, [allProductsDict, variationId]);

  useEffect(() => {
    fetchVariation(variationId);
    fetchVariants(variationId);
    fetchResults(variationId);
    // if (!JSON.parse(window.localStorage.getItem(`variation-${variationId}`) as string)) {
    //   fetchVariation(variationId);
    // }
    // else {
    //   setVariation(JSON.parse(window.localStorage.getItem(`variation-${variationId}`) as string));
    //   setVariationLoading(false);
    // }
    // if (!JSON.parse(window.localStorage.getItem(`variants-${variationId}`) as string)) {
    //   fetchVariants(variationId);
    // }
    // else {
    //   setVariants(JSON.parse(window.localStorage.getItem(`variants-${variationId}`) as string));
    //   setVariantsLoading(false);
    // }
    // if (!JSON.parse(window.localStorage.getItem(`results-${variationId}`) as string)) {
    //   fetchResults(variationId);
    // }
    // else {
    //   setResults(JSON.parse(window.localStorage.getItem(`results-${variationId}`) as string));
    //   setResultsLoading(false);
    // }
    if (!JSON.parse(window.localStorage.getItem('products-list') as string)) {
      // we'll keep caching this one because this is unlikely to change throughout the auction period
      fetchProducts();
    }
    else {
      setAllProductsDict(JSON.parse(window.localStorage.getItem('products-list') as string));
      setProductsLoading(false);
    }
  }, [variationId]);

  const bidderResultsByVariant = (abbr: string, variantId: number): [[string, boolean], number, number, number, number, number, number][] => {
    if (results) {
      return (results as VarRes).bidder_res[abbr].filter((x: [[string, boolean], number, number, number, number, number, number])=>x[1]===variantId)
    }
    else {
      return []
    }
  }

  const bidderResultsByProduct = (abbr: string, pr: [string, boolean]): [[string, boolean], number, number, number, number, number, number][] => {
    if (results && (results as VarRes).bidder_res[abbr]) {
      return (results as VarRes).bidder_res[abbr].filter((x: [[string, boolean], number, number, number, number, number, number])=>isArrayEqual(x[0],pr))
    }
    else {
      return []
    }
  }

  return (<>
    {variation && parentAuction &&
      <VariantResultsNav
        auction={parentAuction}
        variation={variation}
        isAdmin={isAdmin}
        handleLogin={props.handleLogin}
      />
    }
    {(!!variationLoadError || !!resultsLoadError || !!variantsLoadError || !!productsLoadError) &&
      <div>
        {[variationLoadError, variantsLoadError, resultsLoadError, productsLoadError].map((err: string, i: number)=>
        {
          if (err!=='') {
            return <Alert key={i} variant='danger' className='m-4'>
              {err}
            </Alert>
          }
          else { return <></> }
        })}
      </div>
    }
    {(variationLoading || resultsLoading) && !(variationLoadError || variantsLoadError || resultsLoadError) && <MySpinner />}
    {((!variationLoading && variationLoadError) || (!resultsLoading && resultsLoadError)) && <Alert variant='danger'>{variationLoadError}</Alert>}
    {parentAuction && !variationLoading && !resultsLoading && results && variation && variants && allProductsDict && biddersDict &&
    <>
    <Container className='justify-content-center h-100 overflow-hidden' fluid>
      <div className='d-flex flex-column' style={{ height: 'calc(100vh - 56px - 85px)' }}>
        <Tab.Container defaultActiveKey='product-results'>
          <Nav variant="tabs" className='tab-nav mt-4 mb-3'>
            <Nav.Item>
              <Nav.Link eventKey='product-results'>Results by Product</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='bidder-results'>Results by Bidder</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='demand-matrix'>Processed Demand Matrix</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="tab-content d-flex overflow-hidden flex-grow-1 h-100">
            <Tab.Pane eventKey='product-results' className="h-100 w-100">
              {(variantsLoading || productsLoading) && <MySpinner />}
              {!variantsLoading && !productsLoading &&
              <VariantResultsByProduct
                parentAuction={parentAuction}
                results={results}
                variation={variation}
                variants={variants}
                allProductsDict={allProductsDict}
                biddersDict={biddersDict}
                bidderResultsByVariant={bidderResultsByVariant}
                bidderResultsByProduct={bidderResultsByProduct}
              />}
            </Tab.Pane>
            <Tab.Pane eventKey='bidder-results' className="h-100 w-100">
              {(variantsLoading || productsLoading) && <MySpinner />}
              {!variantsLoading && !productsLoading &&
              <VariantResultsByBidder
                parentAuction={parentAuction}
                results={results}
                variation={variation}
                variants={variants}
                allProductsDict={allProductsDict}
                biddersDict={biddersDict}
                bidderResultsByVariant={bidderResultsByVariant}
                bidderResultsByProduct={bidderResultsByProduct}
              />
              }
            </Tab.Pane>
            <Tab.Pane eventKey='demand-matrix' className="h-100 w-100">
              {(variantsLoading || productsLoading) && <MySpinner />}
              {!variantsLoading && !productsLoading &&
              <ProcessedDemandMatrix
                parentAuction={parentAuction}
                results={results}
                variation={variation}
                variants={variants}
                allProductsDict={allProductsDict}
                biddersDict={biddersDict}
                bidderResultsByVariant={bidderResultsByVariant}
                bidderResultsByProduct={bidderResultsByProduct}
              />
              }
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </Container>
    </>
    }
  </>)
}

export default VariantResults;