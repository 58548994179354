import { useMemo, useState } from "react";
import { Button, ButtonGroup, ButtonToolbar, Col, Container, DropdownButton, Row, Table } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { formatNumber, range } from "../../Helpers";
import MySpinner from "../../MySpinner";
import ErrorsModal from "../global/ErrorsModal";
import IconWrapper from "../global/IconWrapper";

const AuctionBidderHistory = (props: any) => {
  const currentBidder: Bidder = props.currentBidder;
  const auction: Auction = props.auction;
  const productsDict = props.productsDict;
  // const filtered: boolean = props.bidderInfoOnly || false;

  const pricesFetch = props.pricesFetch;
  const pricesFetchLoading = props.pricesFetchLoading
  // const pricesFetchError = props.pricesFetchError;
  const eligibilityFetch = props.eligibilityFetch;
  const eligibilityFetchLoading = props.eligibilityFetchLoading;
  const pdemandFetch = props.pdemandFetch;
  // const pdemandFetchLoading = props.pdemandFetchLoading;
  // const pdemandFetchError = props.pdemandFetchError;
  const bidsFetch = props.bidsFetch;
  const bidsFetchLoading = props.bidsFetchLoading;
  // const bidsFetchError = props.bidsFetchError;

  const [selectedRound, setSelectedRound] = useState<number>(-1);
  const [selectedHistory, setSelectedHistory] = useState('Bids');

  const currentBids = useMemo(()=>{
    if (bidsFetch && selectedRound) {
      return bidsFetch
        .filter((b: Bid)=> selectedRound > 0 ? b.round_num===selectedRound : b.round_num <= auction.round_num)
    }
    else {
      return null
    }
  }, [bidsFetch, selectedRound]);

  const [currentlyDownloadingMyBids, setCurrentlyDownloadingMyBids] = useState(false);
  const [downloadingMyBidsError, setDownloadingMyBidsError] = useState<string|null>(null);

  // const pricesFetch = useFetch(`${process.env.REACT_APP_API_URL}/api/prices/get/${auction.id}`);
  // const eligibilityFetch = useFetch(`${process.env.REACT_APP_API_URL}/api/eligibility/get/${auction.id}/${currentBidder.id}`);
  // const pdemandFetch = useFetch(`${process.env.REACT_APP_API_URL}/api/pdemands/get/${auction.id}?bidder_id=${currentBidder.id}`);
  // const bidsFetch = useFetch(`${process.env.REACT_APP_API_URL}/api/bids/get/${auction.id}/${currentBidder.id}`);

  const handleClickRound = (r: number) => {
    setSelectedRound(r);
  }

  const handleDownloadMyBids = (downloadString: string, lang?: string) => {
    setCurrentlyDownloadingMyBids(true);
    window.location.assign(`${process.env.REACT_APP_API_URL}/api/downloads/${downloadString}/${auction.id}/${currentBidder.id}?lang=${lang}`);
    setTimeout(()=>{setCurrentlyDownloadingMyBids(false)}, 1200);
  }

    return (
        <Container className='mt-3'>
          {bidsFetch && !bidsFetchLoading && bidsFetch.length === 0 && <div className='text-center'>You have not submitted any bids in this auction yet.</div>}
          {bidsFetch && eligibilityFetch && pdemandFetch &&
            <>
              <ErrorsModal
                errorsList={[downloadingMyBidsError]}
                show={!!downloadingMyBidsError}
                onHide={() => setDownloadingMyBidsError(null)}
              />
              <Row>
                <Col>
                  <ButtonToolbar className='my-1 d-flex'>
                    <ButtonGroup className='mx-1'>
                      <Button variant='secondary' size='sm' disabled>View history:</Button>
                      <DropdownButton as={ButtonGroup} title={selectedHistory}>
                        <DropdownItem key='bids' onClick={() => setSelectedHistory('Bids')}>Bids</DropdownItem>
                        <DropdownItem key='activity' onClick={()=>setSelectedHistory('Activity')}>Activity</DropdownItem>
                      </DropdownButton>
                    </ButtonGroup>
                    <ButtonGroup className='mx-1'>
                      <Button variant='secondary' size='sm' disabled>{auction.ended ? 'Total rounds: ' : 'Current round: '}{auction.round_num}</Button>
                    </ButtonGroup>
                    <ButtonGroup className='mx-1'>
                      <Button variant='secondary' size='sm' disabled>Round:</Button>
                      <DropdownButton
                        as={ButtonGroup}
                        title={selectedRound > 0 ? selectedRound : 'All'}
                      >
                        <DropdownItem key='all' onClick={() => handleClickRound(-1)}>
                          All
                        </DropdownItem>
                        {range(auction.round_num).reverse().map((r: number) => <DropdownItem key={r+1} onClick={() => handleClickRound(r+1)}>{r+1}</DropdownItem>)}
                      </DropdownButton>
                    </ButtonGroup>
                    <ButtonGroup className='mx-1'>
                      <Button
                        onClick={selectedRound?()=>setSelectedRound(selectedRound-1):undefined}
                        disabled={!selectedRound||selectedRound<=1}
                        >
                        <IconWrapper>
                          <HiChevronLeft />
                        </IconWrapper>
                      </Button>
                      <Button
                        onClick={selectedRound?()=>setSelectedRound(selectedRound+1):undefined}
                        disabled={!selectedRound||selectedRound<0||selectedRound===auction.round_num}
                        >
                        <IconWrapper>
                          <HiChevronRight />
                        </IconWrapper>
                      </Button>
                    </ButtonGroup>
                    <ButtonGroup className='mx-1 ms-auto'>
                      <Button variant='secondary' size='sm' disabled>Download:</Button>
                      {currentlyDownloadingMyBids && <Button variant='outline-info' size='sm' disabled><MySpinner /></Button>}
                      {!currentlyDownloadingMyBids && <>
                      <DropdownButton
                        as={ButtonGroup}
                        title='My Bids'
                        variant='outline-info'
                        size='sm'
                        >
                        <DropdownItem size='sm' key='en' onClick={()=>handleDownloadMyBids('my-bids','en')}>English</DropdownItem>
                        <DropdownItem size='sm' key='fr' onClick={()=>handleDownloadMyBids('my-bids','fr')}>Français</DropdownItem>
                      </DropdownButton>
                      <DropdownButton
                        as={ButtonGroup}
                        title='My Status'
                        variant='outline-info'
                        size='sm'
                        >
                        <DropdownItem size='sm' key='en' onClick={()=>handleDownloadMyBids('my-status','en')}>English</DropdownItem>
                        <DropdownItem size='sm' key='fr' onClick={()=>handleDownloadMyBids('my-status','fr')}>Français</DropdownItem>
                      </DropdownButton>
                      <DropdownButton
                        as={ButtonGroup}
                        title='My Results'
                        variant='outline-info'
                        size='sm'
                        >
                        <DropdownItem size='sm' key='en' onClick={()=>handleDownloadMyBids('my-results','en')}>English</DropdownItem>
                        <DropdownItem size='sm' key='fr' onClick={()=>handleDownloadMyBids('my-results','fr')}>Français</DropdownItem>
                      </DropdownButton>
                      </>}
                    </ButtonGroup>
                  </ButtonToolbar>
                </Col>
              </Row>
              <Row>
                <Col>
                  {selectedHistory === 'Activity' &&
                    <>
                      {!eligibilityFetchLoading && eligibilityFetch &&
                      <Table>
                        <thead>
                          <tr>
                            <th>Round</th>
                            <th>Starting Eligibility (EP)</th>
                            <th>Submitted Activity (EP)</th>
                            <th>Processed Activity (EP)</th>
                            <th>Ending Eligibility (EP)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {eligibilityFetch && !eligibilityFetchLoading && eligibilityFetch &&
                            eligibilityFetch
                              .filter((e: Eligibility) => selectedRound > 0 ? e.round_num === selectedRound : e.round_num <= auction.round_num)
                              .sort((e1: Eligibility, e2: Eligibility) => e2.round_num - e1.round_num)
                              .map((eligObj: Eligibility, i: number) => {
                                return <tr key={i} className={eligObj.round_num % 2 === 0 ? 'bg-light' : 'bg-white'}>
                                  <td><strong>{eligObj.round_num}</strong></td>
                                  <td>{eligObj.start_elig}</td>
                                  <td>{eligObj.submitted_ap}</td>
                                  <td>{eligObj.processed_ap}</td>
                                  <td>{eligObj.end_elig}</td>
                                </tr>
                              })
                          }
                        </tbody>
                      </Table>
                      }
                    </>
                  }
                  {selectedHistory === 'Bids' &&
                    <>
                      {!bidsFetchLoading && bidsFetch.length > 0 && !pricesFetchLoading && pricesFetch.length > 0 &&
                      <Table>
                        <thead>
                          <tr>
                            <th>Round</th>
                            <th>Lot</th>
                            <th>Aggregate Demand</th>
                            <th>Excess Demand</th>
                            <th>Processed Demand</th>
                            <th>Posted Price</th>
                            <th>$/MHz/pop.</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentBids && currentBids.length>0 &&
                            currentBids
                              .sort((b1: Bid, b2: Bid) => b2.round_num - b1.round_num)
                              .map((bidObj: Bid, i: number) => {
                                const product: SpecificProduct = productsDict[bidObj.product_code][bidObj.set_aside ? 'sap' : 'open'];
                                const aggDemand = (pricesFetch.find((p: Price)=>p.product_code===bidObj.product_code&&p.round_num===bidObj.round_num)).agg_demand;
                                const excessDemand = aggDemand - product.max_supply;
                                const currentDmp = (bidObj.price / product.size / product.population).toFixed(4);
                                return <tr key={i} className={bidObj.round_num % 2 === 0 ? 'bg-light' : 'bg-white'}>
                                  <td>{bidObj.round_num}</td>
                                  <td>{product.name}</td>
                                  <td>{aggDemand}</td>
                                  <td>{excessDemand}</td>
                                  <td>{bidObj.num_blocks}</td>
                                  <td>${formatNumber(bidObj.price)}</td>
                                  <td>${currentDmp}</td>
                                </tr>
                              })
                          }
                          {
                            currentBids && currentBids.length === 0 &&
                            <div className='p-3'>No bids for this round</div>
                          }
                        </tbody>
                      </Table>
                      }
                    </>
                  }
                </Col>
              </Row>
            </>
          }
        </Container>
    );
};

AuctionBidderHistory.displayName = 'AuctionBidderHistory';


export default AuctionBidderHistory;
