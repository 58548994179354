import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

const PendingButton = (props: any) => {
  const size = props.size;
  const variant = props.variant;
  const disabled = props.disabled;
  const onClick = props.onClick;
  const pending = props.pending;
  const pendingMsg = props.pendingMsg;
  const error = props.error;
  const errorMsg = props.errorMsg;
  const type = props.type;
  const value = props.value;
  const inputType: boolean = props.inputType || false;

  const SPINNER = <><Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className='mx-1'
                      />
                      <span className="visually-hidden">Loading...</span>
                    </>;

  return (
    <>
    {inputType && 
      <Button 
        className={props.className}
        size={size}
        variant={variant}
        disabled={disabled || pending}
        onClick={onClick}
        type={type}
        value={pending ? 'Submitting...' : value}
        as='input'
      />
    }
    {!inputType &&
      <Button
        className={props.className}
        size={size}
        variant={variant}
        disabled={disabled || pending}
        onClick={onClick}
      >
        {pending && !error && <>{SPINNER}{pendingMsg ? ' ' + pendingMsg : ''}</>}
        {!pending && error && <>{errorMsg}</>}
        {!error && !pending && props.children}
      </Button> 
    }
    </>
  );
};

PendingButton.displayName = 'PendingButton';

export default PendingButton;
