import { memo, useEffect, useState } from "react";
import { ErrorBoundary } from 'react-error-boundary';
import { Container, Nav, Spinner, Tab } from "react-bootstrap";
import AuctionBids from "./AuctionBids";
import AuctionBidderHistory from "./AuctionBidderHistory";
import AuctionRegions from "./AuctionRegions";
import AuctionHistory from "./AuctionHistory";
import ErrorFallback from "./ErrorFallback";
import MySpinner from "../../MySpinner";
// import useFetch from "../../useFetch";

const AuctionCP = (props: any) => {
  const currentUser: [string, string] = props.currentUser;
  const auction: Auction = props.auction;
  // const auctionStatus = props.auctionStatus;

  const [allProductsDict, setAllProductsDict] = useState<GeneralProduct[]|null>(null);
  const currentBidder: Bidder = props.bidder;

  const [allMyBids, setAllMyBids] = useState<Bid[]>();
  const [allMyPDemands, setAllMyPDemands] = useState<PDemand[]>();
  const [allMyEligibilities, setAllMyEligibilities] = useState<Eligibility[]>();
  const [auctionAllPrices, setAuctionAllPrices] = useState<Price[]>();

  const [allMyBidsLoading, setAllMyBidsLoading] = useState(true);
  const [allMyPDemandsLoading, setAllMyPDemandsLoading] = useState(true);
  const [allMyEligibilitiesLoading, setAllMyEligibilitiesLoading] = useState(true);
  const [auctionAllPricesLoading, setAuctionAllPricesLoading] = useState(true);
  const [allMyBidsError, setAllMyBidsError] = useState<string|null>(null);
  const [allMyPDemandsError, setAllMyPDemandsError] = useState<string|null>(null);
  const [allMyEligibilitiesError, setAllMyEligibilitiesError] = useState<string|null>(null);
  const [auctionAllPricesError, setAuctionAllPricesError] = useState<string|null>(null);

  const [pricesComputationBegun, setPricesComputationBegun] = useState(false);
  const [loadingPricesTable, setLoadingPricesTable] = useState(false);
  const [pricesComputationComplete, setPricesComputationComplete] = useState(false);

  const fetchBids = () => {
    setAllMyBidsLoading(true);
    fetch(process.env.REACT_APP_API_URL + `/api/bids/get/${auction.id}/${currentBidder.id}?set_aside=false`)
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        const dataObj = process.env.REACT_APP_DEV_ENV === 'local' ? data[0] : data;
        setAllMyBids(dataObj);
        console.log('fetched bids for ' + currentBidder.name);
        setAllMyBidsLoading(false);
      })
      .catch(err => {
        setAllMyBidsLoading(false);
        setAllMyBidsError(err.message);
      });
  }

  const fetchPrices = () => {
    setAuctionAllPricesLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/prices/get/${auction.id}?set_aside=false`)
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        const dataObj = data;
        setAuctionAllPrices(dataObj);
        console.log('fetched prices for ' + auction.id);
        setAuctionAllPricesLoading(false);
      })
      .catch(err => {
        setAuctionAllPricesLoading(false);
        setAuctionAllPricesError(err.message);
      });
  }

  const fetchPDemands = () => {
    setAllMyPDemandsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/pdemands/get/${auction.id}?bidder_id=${currentBidder.id}&set_aside=false`)
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        const dataObj = process.env.REACT_APP_DEV_ENV === 'local' ? data[0] : data;
        setAllMyPDemands(dataObj);
        console.log('fetched prices for ' + auction.id);
        setAllMyPDemandsLoading(false);
      })
      .catch(err => {
        setAllMyPDemandsLoading(false);
        setAllMyPDemandsError(err.message);
      });
  }

  const fetchEligibilities = () => {
    setAllMyEligibilitiesLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/eligibility/get/${auction.id}/${currentBidder.id}`)
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        const dataObj = process.env.REACT_APP_DEV_ENV === 'local' ? data[0] : data;
        setAllMyEligibilities(dataObj);
        console.log('fetched prices for ' + auction.id);
        setAllMyEligibilitiesLoading(false);
      })
      .catch(err => {
        setAllMyEligibilitiesLoading(false);
        setAllMyEligibilitiesError(err.message);
      });
  }

  useEffect(()=>{
    console.log('updating pricesComputationBegun');
    if (pricesComputationBegun) {
      console.log('AuctionAdminCP: invoking begin computation');
    }
  }, [pricesComputationBegun]);

  useEffect(()=>{
    console.log('updating pricesComputationComplete');
    if (pricesComputationComplete) {
      setLoadingPricesTable(false);
      console.log('AuctionAdminCP: computation complete');
    }
  }, [pricesComputationComplete]);

  useEffect(() => {
    fetchBids();
    fetchPrices();
    fetchPDemands();
    fetchEligibilities();
    fetch(`${process.env.REACT_APP_API_URL}/api/products/list`)
      .then((res) => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setAllProductsDict(data);
        // console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

    return (
      <>
        <Container className='justify-content-center' fluid>
          {(!currentBidder || !allProductsDict) && <><Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Loading...</span>
                    </>}
          {currentBidder && allProductsDict && <Tab.Container defaultActiveKey={auction.ended ? 'bidder' : 'bids'}>
                      <Nav variant="tabs"  className='mt-4 mb-3'>
                        <Nav.Item>
                          <Nav.Link href='#' eventKey='bids' disabled={auction.ended||loadingPricesTable}>Place Bids</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link href='#' eventKey='regions' disabled={loadingPricesTable}>Select Regions</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            href='#'
                            eventKey="info"
                            onClick={
                              !pricesComputationComplete
                              ?
                              ()=>{
                                setLoadingPricesTable(true);
                                setTimeout(()=>{
                                  setPricesComputationBegun(true)
                                }, 1500);
                              }
                              :
                              undefined
                            }
                            disabled={loadingPricesTable}
                            >
                            Auction History
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link href='#' eventKey="bidder" disabled={loadingPricesTable}>Bidder History</Nav.Link>
                        </Nav.Item>
                        {/*<Nav.Item>
                                                  <Nav.Link eventKey='results' disabled={auctionStatus !== AUCTION_STATUS.ended}>Results</Nav.Link>
                                                </Nav.Item>*/}
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey='bids'>
                        {auctionAllPrices && allMyEligibilities && allMyPDemands && allMyBids &&
                          <ErrorBoundary
                            FallbackComponent={ErrorFallback}
                          >
                            <AuctionBids
                              className='mt-3'
                              currentBidder={currentBidder}
                              auction={auction}
                              allProductsDict={allProductsDict}
                              // auctionAllPrices={auctionAllPrices}
                              // allMyEligibilities={allMyEligibilities}
                              // allMyPDemands={allMyPDemands}
                              // allMyBids={allMyBids}
                              />
                          </ErrorBoundary>
                        }
                        </Tab.Pane>
                        <Tab.Pane eventKey='regions'>
                          <AuctionRegions
                            className='mt-3'
                            currentBidder={currentBidder}
                            auction={auction}
                            productsDict={allProductsDict}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey='info'>
                          {(!pricesComputationComplete || loadingPricesTable) && <div className="justify-content-center text-center"><div>Loading all prices for this auction.<br/>May take a while.</div><MySpinner /></div>}
                          <AuctionHistory
                            currentBidder={currentBidder}
                            auction={auction}
                            pricesFetch={auctionAllPrices}
                            pricesFetchLoading={auctionAllPricesLoading}
                            pricesFetchError={auctionAllPricesError}
                            eligibilityFetch={allMyEligibilities}
                            eligibilityFetchLoading={allMyEligibilitiesLoading}
                            eligibilityFetchError={allMyEligibilitiesError}
                            pdemandFetch={allMyPDemands}
                            pdemandFetchLoading={allMyPDemandsLoading}
                            pdemandFetchError={allMyPDemandsError}
                            bidsFetch={allMyBids}
                            bidsFetchLoading={allMyBidsLoading}
                            bidsFetchError={allMyBidsError}
                            productsDict={allProductsDict}
                            pricesComputationBegun={pricesComputationBegun}
                            pricesComputationComplete={pricesComputationComplete}
                            setPricesComputationComplete={setPricesComputationComplete}
                            loadingPricesTable={loadingPricesTable}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey='bidder'>
                        {auctionAllPrices && allMyEligibilities && allMyPDemands && allMyBids &&
                          <AuctionBidderHistory
                            currentBidder={currentBidder}
                            auction={auction}
                            productsDict={allProductsDict}
                            bidderInfoOnly={true}
                            pricesFetch={auctionAllPrices}
                            pricesFetchLoading={auctionAllPricesLoading}
                            pricesFetchError={auctionAllPricesError}
                            eligibilityFetch={allMyEligibilities}
                            eligibilityFetchLoading={allMyEligibilitiesLoading}
                            eligibilityFetchError={allMyEligibilitiesError}
                            pdemandFetch={allMyPDemands}
                            pdemandFetchLoading={allMyPDemandsLoading}
                            pdemandFetchError={allMyPDemandsError}
                            bidsFetch={allMyBids}
                            bidsFetchLoading={allMyBidsLoading}
                            bidsFetchError={allMyBidsError}
                          />
                        }
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>}
        </Container>
      </>
    );
};

AuctionCP.displayName = 'AuctionCP';


export default memo(AuctionCP);
