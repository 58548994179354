import { formatNumber } from "../../Helpers";
import { DynamicTable } from "../global/DynamicTable";

interface Fruit {
  name: string;
  color: string;
  desc: string;
  qty: number;
  cost: number;
}

const Sandbox = (props:any) => {
  const columns = [
    {
      key: 'name',
      title: 'Fruit',
      sort: (a:Fruit, b:Fruit) => a.name<b.name ? 1 : (a.name>b.name ? -1 : 0),
      searchable: true
    },
    {
      key: 'color',
      title: 'Colour',
      searchable: true
    },
    {
      key: 'desc',
      title: 'Description',
      searchable: true
    },
    {
      key: 'qty',
      title: 'Qty',
      sort: (a:Fruit, b:Fruit) => a.qty-b.qty
    },
    {
      key: 'cost',
      title: 'Cost',
      sort: (a:Fruit, b:Fruit) => a.cost-b.cost,
      render: (_: any, { cost }: any) => <span>{formatNumber(cost, true)}</span>
    }
  ];
  const data: Fruit[] = [
    {
      'name': 'Banana',
      'color': 'yellow',
      'desc': 'Long, moon-shaped fruit. Full of potassium.',
      'qty': 3,
      'cost': 0.99
    },
    {
      'name': 'Apple',
      'color': 'red',
      'desc': 'Round, crunchy fruit. Plentiful in the autumn.',
      'qty': 3,
      'cost': 1.29
    },
    {
      'name': 'Lime',
      'color': 'green',
      'desc': 'Tart citrus with a delicate sweetness. Smaller than a lemon.',
      'qty': 2,
      'cost': 1.35
    },
    {
      'name': 'Pineapple',
      'color': 'yellow',
      'desc': 'Large, thorny fruit with sweet, juicy flesh. Can double as a weapon.',
      'qty': 1,
      'cost': 3.49
    },
    {
      'name': 'Orange',
      'color': 'orange',
      'desc': 'Sweet and tart citrus, great for juicing.',
      'qty': 1,
      'cost': 1.29
    },
    {
      'name': 'Coconut',
      'color': 'brown',
      'desc': 'Large tropical fruit with a hard, hairy outer shell and milky white flesh. Full of juice.',
      'qty': 1,
      'cost': 4.99
    },
    {
      'name': 'Pear',
      'color': 'yellow',
      'desc': 'Sweet and juicy fruit with soft, white flesh.',
      'qty': 4,
      'cost': 2.00
    },
    {
      'name': 'Yuzu',
      'color': 'yellow',
      'desc': 'Small, yellow citrus with a fragrant aroma and slightly bittersweet note. Often used in seasoning.',
      'qty': 3,
      'cost': 2.49
    },
    {
      'name': 'Strawberry',
      'color': 'red',
      'desc': 'Tart, pointed berry full of seeds.',
      'qty': 8,
      'cost': 0.77
    },
    {
      'name': 'Blueberry',
      'color': 'blue',
      'desc': 'Small, round berry with a mushy texture. Grows well into the cooler season.',
      'qty': 14,
      'cost': 0.18
    },
    {
      'name': 'Guava',
      'color': 'green',
      'desc': 'Tropical fruit with a hardy green skin and pink or pale yellow flesh. Sweet and musky in taste.',
      'qty': 2,
      'cost': 1.29
    },
    {
      'name': 'Durian',
      'color': 'yellow',
      'desc': 'Large and spiky, sometimes called the "King of Fruits". Known for its pungent smell. Flesh is delicate and custard-like.',
      'qty': 2,
      'cost': 6.09
    }
  ];

  return (<>
    <DynamicTable
      data={data}
      columns={columns}
      pagination={4}
    />
  </>)
}

export default Sandbox;