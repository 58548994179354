import { useState, useEffect } from 'react';

const useFetch = (url: string) => {
  const [data, setData] = useState<any | null>(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    console.log(`Starting fetch request to: ${url}`);

    fetch(url)
      .then(res => {
        console.log(`Response status: ${res.status}, Content-Type: ${res.headers.get('Content-Type')}`);

        if (!res.ok) {
          if (res.status === 403 || res.status === 422) {
            console.log(`${res.status} error encountered`);
            return res.json().then(jsonData => {
              // Extract the custom message from the backend response
              const message = jsonData?.message || `${res.status}: ${res.statusText}`;
              throw new Error(message);
            });
          } else if (res.status !== 404) {
            console.log(`Handling non-404 error with status ${res.status}`);
            return res.text().then(text => { throw new Error(text || `${res.status}: ${res.statusText}`); });
          } else {
            console.log('404 error encountered');
            throw new Error(`${res.status}: ${res.statusText}`);
          }
        } else {
          return res.json();
        }
      })
      .then((data) => {
        console.log('Data received:', data);
        setData(data);
        setIsPending(false);
      })
      .catch(err => {
        console.error('Error occurred:', err.message);
        setIsPending(false);
        setError(err.message); // Set the error message from the backend or fallback
      });
  }, [url]);

  return { data, isPending, error };
};

export default useFetch;