import { Alert, Button, Card, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const NoPath = (props:any) => {
  const location = useLocation();
  const isUser = JSON.parse(window.localStorage.getItem('currentUser') as string);
  const isAdmin = JSON.parse(window.localStorage.getItem('isAdmin') as string);

  return (<>
    <Container className='d-flex justify-content-center'>
      <Card className='w-50 mt-4 text-center' body>
        <Card.Title>Page not found</Card.Title>
        <Card.Text>
          <span className='fw-bold'>{location.pathname}</span> is not a valid location.<br/>
          Please ensure it's spelled correctly.
        </Card.Text>
        {(isUser || isAdmin) &&
        <Link to='/web/auction-cp'>
          <Button>
            Return to Auctions List
          </Button>
        </Link>
        }
        {!isUser && !isAdmin &&
        <Link to='/web/auction-cp'>
          <Button>
            Return to Login
          </Button>
        </Link>
        }
      </Card>
    </Container>
  </>)
}

export default NoPath;