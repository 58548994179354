import React from 'react';
import { Alert, Button } from 'react-bootstrap';

const ErrorFallback = (props: any) => {
  const error: Error = props.error;
  const resetErrorBoundary = props.resetErrorBoundary;

    return (
    <Alert>
      <h3>Fatal error occurred.</h3>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <Button onClick={resetErrorBoundary}>Try again</Button>
    </Alert>
    );
};

ErrorFallback.displayName = 'ErrorFallback';

export default ErrorFallback;
