import { useState, useEffect, ChangeEvent, memo, useMemo } from "react";
import { Accordion, Button, ButtonGroup, ButtonToolbar, Card, Col, Container, DropdownButton, Form, InputGroup, Row } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { formatNumber, getTextStyle, productObjSort, range } from "../../Helpers";
import MySpinner from "../../MySpinner";
import { DynamicTable } from "../global/DynamicTable";
import IconWrapper from "../global/IconWrapper";

interface DataTableItem {
  round_num: number;
  bidder: string;
  proc_demand: [number, number];
}

const ProductResults = (props: any) => {
  const auction: Auction = props.auction;
  const productsDict = props.productsDict;

  const [searchTerm, setSearchTerm] = useState('');
  const [foundProducts, setFoundProducts] = useState<SpecificProduct[]>([]);
  const [isIncludingZeros, setIncludingZeros] = useState(false);
  // const allProductsFetch = useFetch(process.env.REACT_APP_API_URL + '/api/products/list');
  const [allProducts, setAllProducts] = useState<SpecificProduct[]>([]);
  const [nonZeroProducts, setNonZeroProducts] = useState<SpecificProduct[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<SpecificProduct|null>(null);

  const [biddersByAuction, setBiddersByAuction] = useState<null|Bidder[]>(null);
  const [biddersByAuctionLoading, setBiddersByAuctionLoading] = useState(true);
  const [biddersByAuctionError, setBiddersByAuctionError] = useState<null|string>(null);

  // const [selectedBidder, setSelectedBidder] = useState<Bidder|null>(null);
  const [selectedRound, setSelectedRound] = useState<number|null>(null);

  const [allPDemands, setAllPDemands] = useState<PDemand[]>([]);
  const [allPDemandsLoading, setAllPDemandsLoading] = useState(true);
  const [allPDemandsError, setAllPDemandsError] = useState<string|null>(null);
  const [currentPDemands, setCurrentPDemands] = useState<PDemand[]>([]);
  const [currentPDemandsLoading, setCurrentPDemandsLoading] = useState(false);
  const pricesFetch = props.pricesFetch;
  const pricesFetchLoading = props.pricesFetchLoading;

  const [productResultsArray, setProductResultsArray] = useState<DataTableItem[]>([]);
  const [productResultsDataTable, setProductResultsDataTable] = useState<any>({});

  const columns = [
    {
      title: 'Round',
      key: 'round_num',
    },
    {
      title: 'Bidder',
      key: 'bidder',
      sort: (a:DataTableItem, b:DataTableItem) => a.bidder<b.bidder?1:(a.bidder>b.bidder?-1:0),
      searchable: true
    },
    {
      title: 'Processed Demand',
      key: 'proc_demand',
      render: (_: any, item:DataTableItem) => <span className={item.proc_demand[1]>=0&&item.proc_demand[0]-item.proc_demand[1]>0?'text-success':(item.proc_demand[0]-item.proc_demand[1]<0?'text-danger':undefined)}>{item.proc_demand[0]<0?'N/A':`${item.proc_demand[0]}${item.proc_demand[1]>=0?` (${item.proc_demand[0]-item.proc_demand[1]})`:''}`}</span>
    }
    // {
    //   title: 'Start Price',
    //   key: 'start_price',
    //   render: (_: any, item:DataTableItem) => <span>{formatNumber(item.start_price[0], true)}</span>,
    //   sort: (a:DataTableItem, b:DataTableItem) => a.clock_price[0]-b.clock_price[0]
    // },
    // {
    //   title: 'Clock Price',
    //   key: 'clock_price',
    //   render: (_: any, item:DataTableItem) => <span>{formatNumber(item.clock_price[0], true)}</span>,
    //   sort: (a:DataTableItem, b:DataTableItem) => a.clock_price[0]-b.clock_price[0]
    // },
    // {
    //   title: 'Posted Price',
    //   key: 'posted_price',
    //   render: (_: any, item:DataTableItem) => <span>{formatNumber(item.posted_price[0], true)}</span>,
    //   sort: (a:DataTableItem, b:DataTableItem) => a.posted_price[0]-b.posted_price[0]
    // }
  ];

  const handleDownloadMyBids = (lang?: string) => {
    window.location.assign(`${process.env.REACT_APP_API_URL}/api/downloads/product-status/${auction.id}?lang=${lang}`);
  }

  const handleClickRound = (r: number) => {
    setSelectedRound(r);
  }

  const handleSelectProduct = (e: ChangeEvent<HTMLSelectElement>): void => {
    const p = productsDict[e.target.value];
    setSelectedProduct(p.open); // open only no set-aside

  }

  const fetchBiddersByAuction = (auctionId:number) => {
    fetch(process.env.REACT_APP_API_URL + '/api/bidders/list/' + auctionId)
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setBiddersByAuction(data);
        console.log('fetched bidders for auction ' + auctionId);
        setBiddersByAuctionLoading(false);
      })
      .catch(err => {
        setBiddersByAuctionLoading(false);
        setBiddersByAuctionError(err.message);
      });
  }

  const showProductPDemands = (productCode: string, round: number) => {
    setCurrentPDemands(allPDemands.filter((pd:PDemand)=>pd.product_code===productCode && pd.round_num===round));
    setCurrentPDemandsLoading(false);
  }

  const filterProducts: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const keyword: string = e.currentTarget.value;

    if (keyword !== '') {
        const results = allProducts.filter((p: SpecificProduct) => {
            const productStr = p.code;
            const productName = p.name;
            return productStr.startsWith(keyword.toLowerCase()) || productName.toLowerCase().includes(keyword.toLowerCase());
        });
        setFoundProducts(results);
    }
    else {
      if (isIncludingZeros) {
        setFoundProducts(allProducts);
      }
      else {
        setFoundProducts(nonZeroProducts);
      }
    }
    setSearchTerm(keyword);
  }

  const getPriceObj = (productCode: string, round: number): Price => {
    const foundPrice = pricesFetch.find((priceObj:Price)=>priceObj.product_code === productCode && priceObj.round_num === round);

    return foundPrice
  }
  const getAggDemand = (productCode: string, round: number) => {
    const foundPrice = pricesFetch.find((priceObj:Price)=>priceObj.product_code === productCode && priceObj.round_num === round);

    return foundPrice?.agg_demand
  }
  const getPopDollar = (productCode: string, round: number) => {
    const foundPrice = pricesFetch.find((priceObj:Price)=>priceObj.product_code === productCode && priceObj.round_num === round);
    const population = productsDict[productCode]['open'].population;
    const postedPrice = foundPrice?.posted_price as number;

    return (postedPrice / 10 / population).toFixed(2)
  }

  const lastRoundPriceObj = useMemo(()=>{
    if (selectedRound && selectedRound > 1 && selectedProduct) {
      return getPriceObj(selectedProduct.code, selectedRound-1)
    }
  }, [selectedRound, selectedProduct]);
  const currentRoundPriceObj = useMemo(()=>{
    if (selectedRound && selectedProduct) {
      return getPriceObj(selectedProduct.code, selectedRound)
    }
  }, [selectedRound, selectedProduct]);

  const handleToggleInclZeros = () => {
    const newStateInclZeros = !isIncludingZeros;
    setIncludingZeros(newStateInclZeros);
  }

  useEffect(()=> {
    console.log('rendering ProductResults');
    if (auction) {
      fetchBiddersByAuction(auction.id as number);
      fetch(process.env.REACT_APP_API_URL + `/api/pdemands/get/${auction.id}`)
        .then(res => {
          if(!res.ok) {
            return res.text().then(text => { throw new Error(text) })
          }
          else {
            return res.json();
          }
        })
        .then((data) => {
          setAllPDemands(data);
          setAllPDemandsLoading(false);
        })
        .catch(err => {
          setAllPDemandsError(err.message)
        });
    }
  }, []);

  useEffect(() => {
    if (productsDict) {
      let products: SpecificProduct[] = [];
      (Object.values(productsDict) as GeneralProduct[]).map((product:GeneralProduct) => {
        if (product.open) {
          products.push(product.open)
        }
        if (product.sap) {
          products.push(product.sap)
        }
      });
      setAllProducts(products);
      setSelectedRound(auction.round_num);
    }
  }, [productsDict]);

  useEffect(()=>{
    if (pricesFetch && allProducts) {
      const nonZeroProdsList = pricesFetch.filter((price: Price) => price.agg_demand > 0).map((p: Price)=>p.product_code);
      const products = allProducts.filter((p: SpecificProduct)=>nonZeroProdsList.includes(p.code));
      setNonZeroProducts(products);
    }
  }, [pricesFetch, allProducts]);

  useEffect(()=> {
    if (selectedRound && currentPDemands && allPDemands && biddersByAuction) {
      let productResultsTableValues:DataTableItem[] = [];
      currentPDemands.forEach((pd: PDemand) => {
        const product: GeneralProduct = productsDict[pd.product_code];
        // const category: 'sap'|'open' = pd.set_aside ? 'sap' : 'open';
        // const lot: SpecificProduct = product[category] as SpecificProduct;
        let productName;
        if (product.open) {
            productName = product.open.name;
        }
        else if (product.sap) {
            productName = product.sap.name;
        }
        const startPrice: [number, number] = selectedRound>1 ? [getPriceObj(pd.product_code, selectedRound).start_price, getPriceObj(pd.product_code, selectedRound-1).start_price] : [getPriceObj(pd.product_code, selectedRound).start_price, -1];
        const clockPrice: [number, number] = selectedRound>1 ? [getPriceObj(pd.product_code, selectedRound).clock_price, getPriceObj(pd.product_code, selectedRound-1).clock_price] : [getPriceObj(pd.product_code, selectedRound).clock_price, -1];
        const postedPrice: [number, number] = selectedRound>1 ? [getPriceObj(pd.product_code, selectedRound).posted_price, getPriceObj(pd.product_code, selectedRound-1).posted_price] : [getPriceObj(pd.product_code, selectedRound).posted_price, -1];

        const lastPDemand = allPDemands.find(x=>
                x.round_num===selectedRound-1
                &&x.bidder_id===pd.bidder_id
                &&x.product_code===pd.product_code
              );
        const lastPDemandValue = lastPDemand ? lastPDemand.value : 0;

        productResultsTableValues.push({
          round_num: pd.round_num,
          bidder: biddersByAuction.find((b:Bidder)=>b.id===pd.bidder_id)?.name as string,
          proc_demand: selectedRound>1
            ?
            [pd.value, lastPDemandValue]
            :
            [pd.value, -1]
          // start_price: startPrice,
          // clock_price: clockPrice,
          // posted_price: postedPrice
        });
      });
      setProductResultsArray(productResultsTableValues);
    }
  }, [currentPDemands]);

  useEffect(() => {
    setProductResultsDataTable({
      columns: columns,
      data: productResultsArray
    });
  }, [productResultsArray]);

  useEffect(() => {
    if (selectedProduct && selectedRound) {
      setCurrentPDemandsLoading(true);
      showProductPDemands(selectedProduct.code, selectedRound);
    }
  }, [selectedRound, selectedProduct]);

    return (
      <Container className='mt-3'>
        <Row>
          <Col>
            <ButtonToolbar className="my-1 justify-content-between">
              <InputGroup size="sm" className='flex-fill'>
                  <InputGroup.Text id="inputGroup-sizing-sm">Search Products</InputGroup.Text>
                  <Form.Control
                    aria-label="Search"
                    aria-describedby="inputGroup-sizing-sm"
                    value={searchTerm}
                    onChange={filterProducts}
                    type='search'
                  />
              </InputGroup>
              <ButtonGroup className='mx-1'>
                <Button
                  variant={isIncludingZeros ? 'primary' : 'outline-secondary'}
                  size='sm'
                  onClick={handleToggleInclZeros}
                >
                  Include zero agg. demand
                </Button>
              </ButtonGroup>
              <ButtonGroup className='mx-1'>
                <Button variant='secondary' disabled size='sm'>Round:</Button>
                <DropdownButton
                  size='sm'
                  as={ButtonGroup}
                  title={selectedRound ? selectedRound : auction.round_num}
                >
                  {range(auction.round_num).reverse().map((r: number) => <DropdownItem key={r+1} onClick={() => handleClickRound(r+1)}>{r+1}</DropdownItem>)}
                </DropdownButton>
              </ButtonGroup>
              <ButtonGroup className='mx-1 ms-auto'>
                <Button
                  onClick={selectedRound?()=>setSelectedRound(selectedRound-1):undefined}
                  disabled={!selectedRound||selectedRound===1}
                  >
                  <IconWrapper>
                    <HiChevronLeft />
                  </IconWrapper>
                </Button>
                <Button
                  onClick={selectedRound?()=>setSelectedRound(selectedRound+1):undefined}
                  disabled={!selectedRound||selectedRound===auction.round_num}
                  >
                  <IconWrapper>
                    <HiChevronRight />
                  </IconWrapper>
                </Button>
              </ButtonGroup>
              <ButtonGroup className='mx-1 ms-auto'>
                <DropdownButton
                  as={ButtonGroup}
                  title='Download Product Status File'
                  variant='outline-info'
                  size='sm'
                  >
                  <DropdownItem key='en' onClick={()=>handleDownloadMyBids('en')}>English</DropdownItem>
                  <DropdownItem key='fr' onClick={()=>handleDownloadMyBids('fr')}>Français</DropdownItem>
                </DropdownButton>
              </ButtonGroup>
            </ButtonToolbar>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='mb-3'>
              <Form.Select name='all_products' id='all_products' htmlSize={4} onChange={(e) => handleSelectProduct(e)}>
                <>
                  {!productsDict && <option disabled>(Loading products)</option>}
                  {searchTerm === '' && productsDict && isIncludingZeros && allProducts && allProducts.sort(productObjSort).map((product: SpecificProduct) => (
                    <option key={product.code} value={product.code}>{product.name}</option>
                  ))}
                  {searchTerm === '' && productsDict && !isIncludingZeros && nonZeroProducts && nonZeroProducts.sort(productObjSort).map((product: SpecificProduct) => (
                    <option key={product.code} value={product.code}>{product.name}</option>
                  ))}
                  {searchTerm !== '' && productsDict && foundProducts.sort(productObjSort).map((product: SpecificProduct) => (
                    <option key={product.code} value={product.code}>{product.name}</option>
                  ))}
                </>
              </Form.Select>
            </div>
          </Col>
        </Row>
        {selectedProduct && selectedRound && !getPriceObj(selectedProduct.code, selectedRound) && <div>Product not found in this auction</div>}
        {selectedProduct && selectedRound && currentRoundPriceObj &&
        <Row>
          <Col>
            <Accordion defaultActiveKey={['0','1']} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h5 className='text-center'>Product Info</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <Container fluid className='p-0'>
                    <Row>
                      <Col sm={6}>
                        <Row as='dl' className='my-1'>
                          <Col as='dt' sm={4}>
                            Product
                          </Col>
                          <Col as='dd' sm={8}>
                            {selectedProduct.name}
                          </Col>
                        </Row>
                         {/*<Row as='dl' className='my-1'>
                          <Col as='dt' sm={4}>
                            $/MHz/pop
                          </Col>
                          <Col as='dd' sm={8}>
                            ${selectedProduct.dmp}
                          </Col>
                        </Row>*/}
                        <Row as='dl' className='my-1'>
                          <Col as='dt' sm={4}>
                            Max Supply
                          </Col>
                          <Col as='dd' sm={8}>
                            {selectedProduct.max_supply}
                          </Col>
                        </Row>
                        <Row as='dl' className='my-1'>
                          <Col as='dt' sm={4}>
                            Population
                          </Col>
                          <Col as='dd' sm={8}>
                            {formatNumber(selectedProduct.population)}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={6}>
                        <Row as='dl' className='my-1'>
                          <Col as='dt' sm={4}>
                            Eligibility Points
                          </Col>
                          <Col as='dd' sm={8}>
                            {selectedProduct.ep} EP
                          </Col>
                        </Row>
                        <Row as='dl' className='my-1'>
                          <Col as='dt' sm={4}>
                            Initial Price
                          </Col>
                          <Col as='dd' sm={8}>
                            ${formatNumber(selectedProduct.initial_price)} ($/MHz/pop: ${selectedProduct.dmp})
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <h5 className='text-center'>Prices at Round {selectedRound}</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <Container fluid className='p-0'>
                    <Row>
                      <Col>
                        <strong>Start Price:</strong><br/>
                        <span className={getTextStyle(currentRoundPriceObj.start_price, lastRoundPriceObj ? lastRoundPriceObj.start_price : undefined)}>
                            {formatNumber(currentRoundPriceObj.start_price, true)} {lastRoundPriceObj && <>({formatNumber(currentRoundPriceObj.start_price-lastRoundPriceObj.start_price, true)})</>}
                        </span>
                      </Col>
                      <Col>
                        <strong>Clock Price:</strong><br/>
                        <span className={getTextStyle(currentRoundPriceObj.clock_price, lastRoundPriceObj ? lastRoundPriceObj.clock_price : undefined)}>
                            {formatNumber(currentRoundPriceObj.clock_price, true)} {lastRoundPriceObj && <>({formatNumber(currentRoundPriceObj.clock_price-lastRoundPriceObj.clock_price, true)})</>}
                        </span>
                      </Col>
                      <Col>
                        <strong>Posted Price:</strong><br/>
                        {currentRoundPriceObj.posted_price>=0 && <>
                        <span className={getTextStyle(currentRoundPriceObj.posted_price, lastRoundPriceObj ? lastRoundPriceObj.posted_price : undefined)}>
                            {formatNumber(currentRoundPriceObj.posted_price, true)} {lastRoundPriceObj && <>({formatNumber(currentRoundPriceObj.posted_price-lastRoundPriceObj.posted_price, true)})</>}
                        </span>
                        </>}
                        {(currentRoundPriceObj.posted_price<0) && <span>N/A</span>}
                      </Col>
                      <Col>
                        <strong>Aggregate Demand:</strong><br/>
                        {currentRoundPriceObj.agg_demand>=0 && <>
                        <span className={getTextStyle(currentRoundPriceObj.agg_demand, currentRoundPriceObj.agg_demand_prev)}>
                            {formatNumber(currentRoundPriceObj.agg_demand)} {selectedRound>1 && <>({formatNumber(currentRoundPriceObj.agg_demand-currentRoundPriceObj.agg_demand_prev)})</>}
                        </span>
                        </>}
                        {currentRoundPriceObj.agg_demand<0 && <span>N/A</span>}
                      </Col>
                      <Col>
                        <strong>$/MHz/pop</strong><br/>
                        {currentRoundPriceObj.posted_price>0 && <>
                        <span className={getTextStyle(currentRoundPriceObj.posted_price/selectedProduct.size/selectedProduct.population, lastRoundPriceObj ? lastRoundPriceObj.posted_price/selectedProduct.size/selectedProduct.population : -1)}>
                            {formatNumber(currentRoundPriceObj.posted_price/selectedProduct.size/selectedProduct.population, true)} {selectedRound>1 && lastRoundPriceObj && <>({formatNumber((currentRoundPriceObj.posted_price-lastRoundPriceObj.posted_price)/selectedProduct.size/selectedProduct.population, true)})</>}
                        </span>
                        </>}
                        {currentRoundPriceObj.posted_price<=0 && <span>N/A</span>}
                      </Col>
                    </Row>
                  </Container>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Card className='mt-2'>
              <Card.Body>
                <Card.Title className='text-center'>Processed Demands at Round {selectedRound}</Card.Title>
                <Container>
                  {currentPDemandsLoading && <MySpinner />}
                  {!currentPDemandsLoading && productResultsArray.length > 0 && <>
                    <div className='my-1 w-100 d-flex justify-content-between'>
                      <div>
                      </div>
                      <div>
                        <small>
                          Values in parentheses (...) indicate change from previous round
                        </small>
                      </div>
                    </div>
                    <DynamicTable
                      data={productResultsDataTable.data
                        .sort((a:DataTableItem, b:DataTableItem)=>{
                          if (a.bidder < b.bidder) {
                            return -1
                          }
                          else {
                            if (a.bidder > b.bidder) {
                              return 1
                            }
                            else {
                              return 0
                            }
                          }
                        })}
                      columns={productResultsDataTable.columns}
                    />
                  </>
                  }
                  {!currentPDemandsLoading && productResultsArray.length === 0 &&
                    <Card.Text className='text-center'>
                      <p>No demand activity found for this product in this round.</p>
                      {!auction.ended && auction.round_num === selectedRound && <p>Demands have not yet been processed for this round.</p>}
                    </Card.Text>
                  }
                </Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        }
      </Container>
    );
};

ProductResults.displayName = 'ProductResults';


export default memo(ProductResults);
