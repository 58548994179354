import { useEffect, useMemo, useState } from "react";
import { Badge, ListGroup } from "react-bootstrap";
import { formatNumber, PARAM_DICTIONARY } from "../../Helpers";
import MySpinner from "../../MySpinner";
import NamedBidder from "./NamedBidder";

type NamedBidderAbbr = 'BEL'|'BR'|'BP'|'COG'|'COM'|'ECO'|'IC'|'IR'|'RO'|'SSI'|'ST'|'SOG'|'SS'|'TB'|'TEL'|'TC'|'VF'|'VN'|'VID'|'WI'|'XP';

const VariationSummary = (props:any) => {
  const rps: RangeParam[] = props.rps;
  const vps: ValueParam[] = props.vps;

  const parentAuction: Auction = props.parentAuction;

  const [biddersList, setBiddersList] = useState<Bidder[]>([]);
  const [biddersListLoading, setBiddersListLoading] = useState(false);
  const [biddersListLoadError, setBiddersListLoadError] = useState('');
  const biddersAbbrDict = useMemo(()=>{
    let dict: { [key: string]: Bidder } = {};
    if (biddersList) {
      biddersList.forEach((b:Bidder)=>{
        dict[b.abbr] = b;
      });
    }
    return dict
  }, [biddersList]);
  const fetchBiddersList = () => {
    setBiddersListLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/bidders/list/${parentAuction.id}`)
      .then((res) => {
        if (res.status===400) {
          return res.text().then(text => { throw new Error(text) })
        }
        else if (!res.ok) {
          throw new Error(`${res.status} error: ${res.statusText}`);
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setBiddersList(data);
        setBiddersListLoading(false)
      })
      .catch((err) => {
        console.log('Bidders Data Load Error: ' + err.message);
        setBiddersListLoadError(err.message);
        setBiddersListLoading(false);
      });
  }

  const [allProductsDict, setAllProductsDict] = useState<{ [key: string]: GeneralProduct }|null>(null);
  const [productsLoading, setProductsLoading] = useState(true);
  const [productsLoadError, setProductsLoadError] = useState('');
  const fetchProducts = () => {
    setProductsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/products/list`)
      .then((res) => {
        if (!res.ok && res.status ===400) {
          return res.text().then(text => { throw new Error(text) })
        }
        else if (!res.ok) {
          throw new Error(`${res.status} error: ${res.statusText}`);
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setAllProductsDict(data);
        setProductsLoading(false)
      })
      .catch((err) => {
        console.log('Product Data Load Error: ' + err.message);
        setProductsLoading(false);
        // console.log(err);
      });
  }

  const [initPkgsDict, setInitPkgsDict] = useState<{ [key: string]: { [key: string]: ABConfig } }>({});
  const [initPkgsLoading, setInitPkgsLoading] = useState(true);
  const [initPkgsLoadError, setInitPkgsLoadError] = useState('');
  const fetchInitPkgs = () => {
    setInitPkgsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/abconfigs/get/${parentAuction.id}`)
      .then((res) => {
        if (res.status===400) {
          return res.text().then(text => { throw new Error(text) })
        }
        else if (!res.ok) {
          throw new Error(`${res.status} error: ${res.statusText}`);
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        const dict: { [key: string]: { [key: string]: ABConfig } } = {};
        data.forEach((abc: ABConfig)=>{
          if (dict[abc.bidder_abbr]) {
            dict[abc.bidder_abbr][abc.product_code] = abc;
          }
          else {
            const productObj: { [key: string]: ABConfig } = {};
            productObj[abc.product_code] = abc;
            dict[abc.bidder_abbr] = productObj;
          }
        });
        setInitPkgsDict(dict);
        setInitPkgsLoading(false)
      })
      .catch((err) => {
        console.log('Init Pkg Data Load Error: ' + err.message);
        setInitPkgsLoading(false);
        // console.log(err);
      });
  }

  useEffect(()=>{
    if (allProductsDict) {
      window.localStorage.setItem(`products-list`, JSON.stringify(allProductsDict));
    }
  }, [allProductsDict]);

  useEffect(()=>{
    if (!JSON.parse(window.localStorage.getItem('products-list') as string)) {
      fetchProducts();
    }
    else {
      setAllProductsDict(JSON.parse(window.localStorage.getItem('products-list') as string));
      setProductsLoading(false);
    }

    if (!JSON.parse(window.sessionStorage.getItem('bidders-list') as string)) {
      fetchBiddersList();
    }
    else {
      setBiddersList(JSON.parse(window.sessionStorage.getItem('bidders-list') as string));
      setBiddersListLoading(false);
    }
    fetchInitPkgs();
  }, []);

  return (<>
    <ListGroup className={props.className} horizontal>
      {rps && [0, 1].map((i: number)=>{
        if (rps[i]!=='NoParam') {
          return <ListGroup.Item key={i}>
            <div className='ms-2 me-auto'>
              <div className='fw-bold'>
                  {rps[i]!=='NoParam' &&
                    PARAM_DICTIONARY[(Object.keys(rps[i])[0])].name
                  }
              </div>
              {Object.keys(rps[i])[0]==='IncPct' &&
                <>{((rps[i] as IncPct)['IncPct'] as RangeAuc).values.length} variant{((rps[i] as IncPct)['IncPct'] as RangeAuc).values.length>1 ? 's' : ''} with varied increment percentage values of:
                  <ul>
                    {((rps[i] as IncPct)['IncPct'] as RangeAuc).values.map((val:number, j:number)=><li key={`val-${j}`} className='list-unstyled'><Badge className='m-1' bg='secondary'>{val}%</Badge></li>)}
                  </ul>
                </>
              }
              {Object.keys(rps[i])[0]==='SpectrumCap' &&
                <>{((rps[i] as SpectrumCap)['SpectrumCap'] as RangeAuc).values.length} variant{((rps[i] as SpectrumCap)['SpectrumCap'] as RangeAuc).values.length>1 ? 's' : ''} with varied spectrum cap values of:
                  <ul>
                    {((rps[i] as SpectrumCap)['SpectrumCap'] as RangeAuc).values.map((val:number, j:number)=><li key={`val-${j}`} className='list-unstyled'><Badge className='m-1' bg='secondary'>{val} blocks</Badge></li>)}
                  </ul>
                </>
              }
              {Object.keys(rps[i])[0]==='BidderBudget' && <>
                {((rps[i] as BidderBudget)['BidderBudget'] as RangeBdr).count} variants with varied values between {formatNumber(((rps[i] as BidderBudget)['BidderBudget'] as RangeBdr).start_pct)}% and {formatNumber(((rps[i] as BidderBudget)['BidderBudget'] as RangeBdr).end_pct)}% of original budget values for the following bidders:
                  <ul>
                    {((rps[i] as BidderBudget)['BidderBudget'] as RangeBdr).bidder_abbrs.map((abbr:string)=>{
                      return <li key={abbr}>
                        {biddersAbbrDict && biddersAbbrDict[abbr]
                        ?
                        (biddersAbbrDict[abbr].is_named ? <NamedBidder abbr={abbr as NamedBidderAbbr} name={biddersAbbrDict[abbr].name} /> : biddersAbbrDict[abbr].name)
                        :
                        abbr}
                      </li>
                    })}
                  </ul>
              </>}
              {Object.keys(rps[i])[0]==='BidderInitEP' && <>
                {((rps[i] as BidderInitEP)['BidderInitEP'] as RangeBdr).count} variants with varied values between {formatNumber(((rps[i] as BidderInitEP)['BidderInitEP'] as RangeBdr).start_pct)}% and {formatNumber(((rps[i] as BidderInitEP)['BidderInitEP'] as RangeBdr).end_pct)}% of original initial EP values for the following bidders:
                  <ul>
                    {((rps[i] as BidderInitEP)['BidderInitEP'] as RangeBdr).bidder_abbrs.map((abbr:string)=>{
                      return <li key={abbr}>
                        {biddersAbbrDict && biddersAbbrDict[abbr]
                        ?
                        (biddersAbbrDict[abbr].is_named ? <NamedBidder abbr={abbr as NamedBidderAbbr} name={biddersAbbrDict[abbr].name} /> : biddersAbbrDict[abbr].name)
                        :
                        abbr}
                      </li>
                    })}
                  </ul>
              </>}
              {Object.keys(rps[i])[0]==='Priority' && <>
                {((rps[i] as Priority)['Priority'] as RangeABC).count} variants with varied values between {formatNumber(((rps[i] as Priority)['Priority'] as RangeABC).start_pct)}% and {formatNumber(((rps[i] as Priority)['Priority'] as RangeABC).end_pct)}% of original priority values for the following initial package lots:
                  <ul>
                    {((rps[i] as Priority)['Priority'] as RangeABC).rows.map((r: [string, string, boolean], j: number)=>{
                      const abbr = r[0];
                      return <li key={`val-${j}`}>{(allProductsDict && !productsLoading && !productsLoadError) ? allProductsDict[r[1]].open?.name : r[1]} for {biddersAbbrDict && biddersAbbrDict[abbr]
                        ?
                        (biddersAbbrDict[abbr].is_named ? <NamedBidder abbr={abbr as NamedBidderAbbr} name={biddersAbbrDict[abbr].name} /> : biddersAbbrDict[abbr].name)
                        :
                        abbr}</li>
                    })}
                  </ul>
              </>}
              {Object.keys(rps[i])[0]==='Budget' && <>
                {((rps[i] as Budget)['Budget'] as RangeABC).count} variants with varied values between {formatNumber(((rps[i] as Budget)['Budget'] as RangeABC).start_pct)}% and {formatNumber(((rps[i] as Budget)['Budget'] as RangeABC).end_pct)}% of original budget values for the following initial package lots:
                  <ul>
                    {((rps[i] as Budget)['Budget'] as RangeABC).rows.map((r: [string, string, boolean], j: number)=>{
                      const abbr = r[0];
                      return <li key={`val-${j}`}>{(allProductsDict && !productsLoading && !productsLoadError) ? allProductsDict[r[1]].open?.name : r[1]} for {biddersAbbrDict && biddersAbbrDict[abbr]
                        ?
                        (biddersAbbrDict[abbr].is_named ? <NamedBidder abbr={abbr as NamedBidderAbbr} name={biddersAbbrDict[abbr].name} /> : biddersAbbrDict[abbr].name)
                        :
                        abbr}</li>
                    })}
                  </ul>
              </>}
              {Object.keys(rps[i])[0]==='Price' && <>
                {((rps[i] as any)['Price'] as RangeABC).count} variants with varied values between {formatNumber(((rps[i] as any)['Price'] as RangeABC).start_pct)}% and {formatNumber(((rps[i] as any)['Price'] as RangeABC).end_pct)}% of original maximum price per block values for the following initial package lots:
                  <ul>
                    {((rps[i] as any)['Price'] as RangeABC).rows.map((r: [string, string, boolean], j: number)=>{
                      const abbr = r[0];
                      return <li key={`val-${j}`}>{(allProductsDict && !productsLoading && !productsLoadError) ? allProductsDict[r[1]].open?.name : r[1]} for {biddersAbbrDict && biddersAbbrDict[abbr]
                        ?
                        (biddersAbbrDict[abbr].is_named ? <NamedBidder abbr={abbr as NamedBidderAbbr} name={biddersAbbrDict[abbr].name} /> : biddersAbbrDict[abbr].name)
                        :
                        abbr}</li>
                    })}
                  </ul>
              </>}
              {Object.keys(rps[i])[0]==='DMP' && <>
                {((rps[i] as DMP)['DMP'] as RangeABC).count} variants with varied values between {formatNumber(((rps[i] as DMP)['DMP'] as RangeABC).start_pct)}% and {formatNumber(((rps[i] as DMP)['DMP'] as RangeABC).end_pct)}% of original $/MHz./pop demand curve values for the following initial package lots:
                  <ul>
                    {((rps[i] as DMP)['DMP'] as RangeABC).rows.map((r: [string, string, boolean], j: number)=>{
                      const abbr = r[0];
                      return <li key={`val-${j}`}>{(allProductsDict && !productsLoading && !productsLoadError) ? allProductsDict[r[1]].open?.name : r[1]} for {biddersAbbrDict && biddersAbbrDict[abbr]
                        ?
                        (biddersAbbrDict[abbr].is_named ? <NamedBidder abbr={abbr as NamedBidderAbbr} name={biddersAbbrDict[abbr].name} /> : biddersAbbrDict[abbr].name)
                        :
                        abbr}</li>
                    })}
                  </ul>
              </>}
            </div>
          </ListGroup.Item>
        }
      })}
      {vps && vps[0] && vps[1] && [0, 1].map((i: number)=>{
        if (vps[i]!=='NoParam') {
          return <ListGroup.Item key={i}>
            <div className='ms-2 me-auto'>
              <div className='fw-bold'>
                  {vps[i]!=='NoParam' &&
                    PARAM_DICTIONARY[(Object.keys(vps[i])[0])].name
                  }
              </div>
              {Object.keys(vps[i])[0]==='IncPct' &&
                <>Increment % value: <Badge className='m-1' bg='secondary'>{((vps[i] as IncPct)['IncPct'] as ValueAuc).value}%</Badge></>
              }
              {Object.keys(vps[i])[0]==='SpectrumCap' &&
                <>Spectrum cap value: <Badge className='m-1' bg='secondary'>{((vps[i] as SpectrumCap)['SpectrumCap'] as ValueAuc).value} blocks</Badge></>
              }
              {Object.keys(vps[i])[0]==='BidderBudget' && <>
                Bidder budget set to <strong>{((vps[i] as BidderBudget)['BidderBudget'] as ValueBdr).value_pct}%</strong> for bidders:<br/>
                  <ul>
                    {((vps[i] as BidderBudget)['BidderBudget'] as ValueBdr).bidder_abbrs.map((bidder:string)=>{
                      return <li key={bidder}>
                        {biddersAbbrDict && biddersAbbrDict[bidder]
                        ?
                        (biddersAbbrDict[bidder].is_named ? <NamedBidder abbr={biddersAbbrDict[bidder].abbr as NamedBidderAbbr} name={biddersAbbrDict[bidder].name} /> : biddersAbbrDict[bidder].name)
                        :
                        bidder} (<strong>{biddersAbbrDict && biddersAbbrDict[bidder] && formatNumber(((vps[i] as BidderBudget)['BidderBudget'] as ValueBdr).value_pct * biddersAbbrDict[bidder].budget / 100, true)}</strong>)
                        </li>
                    })}
                  </ul>
              </>}
              {Object.keys(vps[i])[0]==='BidderInitEP' && <>
                Bidder initial EP set to <strong>{((vps[i] as BidderInitEP)['BidderInitEP'] as ValueBdr).value_pct}%</strong> for bidders:<br/>
                  <ul>
                    {((vps[i] as BidderInitEP)['BidderInitEP'] as ValueBdr).bidder_abbrs.map((bidder:string)=>{
                      return <li key={bidder}>
                        {biddersAbbrDict && biddersAbbrDict[bidder]
                        ?
                        (biddersAbbrDict[bidder].is_named ? <NamedBidder abbr={biddersAbbrDict[bidder].abbr as NamedBidderAbbr} name={biddersAbbrDict[bidder].name} /> : biddersAbbrDict[bidder].name)
                        :
                        bidder} (<strong>{biddersAbbrDict && biddersAbbrDict[bidder] && formatNumber(Math.round(((vps[i] as BidderInitEP)['BidderInitEP'] as ValueBdr).value_pct * biddersAbbrDict[bidder].init_ep / 100), false)} EP</strong>)
                        </li>
                    })}
                  </ul>
              </>}
              {Object.keys(vps[i])[0]==='Priority' && <>
                Priority values set to <strong>{formatNumber(((vps[i] as Priority)['Priority'] as ValueABC).value_pct)}%</strong> for initial packages:<br/>
                <ul>
                  {allProductsDict && !productsLoading && !productsLoadError && ((vps[i] as Priority)['Priority'] as ValueABC).rows
                    .map((r: [string, string, boolean], j: number)=>{
                      const abbr = r[0];
                      const product = allProductsDict[r[1]];
                      const productCode = (product.open?.code || product.sap?.code) as string;
                      const productName = (product.open?.name || product.sap?.name || r[1]) as string;
                      return <li key={`val-${j}`}>{productName} for {biddersAbbrDict && biddersAbbrDict[abbr]
                        ?
                        (biddersAbbrDict[abbr].is_named ? <NamedBidder abbr={abbr as NamedBidderAbbr} name={biddersAbbrDict[abbr].name} /> : biddersAbbrDict[abbr].name)
                        :
                        abbr}&nbsp;
                        (<strong>
                          {initPkgsLoading && <MySpinner />}
                          {!initPkgsLoading && initPkgsDict[abbr] && initPkgsDict[abbr][productCode] && ((vps[i] as Priority)['Priority'] as ValueABC).value_pct * (initPkgsDict[abbr][productCode].priority as number) / 100}
                        </strong>)
                      </li>
                  })}
                </ul>
              </>}
              {Object.keys(vps[i])[0]==='Budget' && <>
                Product budget values set to <strong>{formatNumber(((vps[i] as Budget)['Budget'] as ValueABC).value_pct)}%</strong> for initial packages:<br/>
                <ul>
                  {allProductsDict && !productsLoading && !productsLoadError && ((vps[i] as Budget)['Budget'] as ValueABC).rows
                    .map((r: [string, string, boolean], j:number)=>{
                      const abbr = r[0];
                      const product = allProductsDict[r[1]];
                      const productCode = (product.open?.code || product.sap?.code) as string;
                      const productName = (product.open?.name || product.sap?.name || r[1]) as string;
                      return <li key={`val-${j}`}>{productName} for {biddersAbbrDict && biddersAbbrDict[abbr]
                        ?
                        (biddersAbbrDict[abbr].is_named ? <NamedBidder abbr={abbr as NamedBidderAbbr} name={biddersAbbrDict[abbr].name} /> : biddersAbbrDict[abbr].name)
                        :
                        abbr}&nbsp;
                        (<strong>
                          {initPkgsLoading && <MySpinner />}
                          {!initPkgsLoading && initPkgsDict[abbr] && initPkgsDict[abbr][productCode] && formatNumber(((vps[i] as Budget)['Budget'] as ValueABC).value_pct * (initPkgsDict[abbr][productCode].budget as number) / 100, true)}
                        </strong>)
                      </li>
                  })}
                </ul>
              </>}
              {Object.keys(vps[i])[0]==='Price' && <>
                Product price values set to <strong>{formatNumber(((vps[i] as any)['Price'] as ValueABC).value_pct)}%</strong> for initial packages:<br/>
                <ul>
                  {allProductsDict && !productsLoading && !productsLoadError && ((vps[i] as any)['Price'] as ValueABC).rows
                    .map((r: [string, string, boolean], j: number)=>{
                      const abbr = r[0];
                      const product = allProductsDict[r[1]];
                      const productCode = (product.open?.code || product.sap?.code) as string;
                      const productName = (product.open?.name || product.sap?.name || r[1]) as string;
                      return <li key={`val-${j}`}>{productName} for {biddersAbbrDict && biddersAbbrDict[abbr]
                        ?
                        (biddersAbbrDict[abbr].is_named ? <NamedBidder abbr={abbr as NamedBidderAbbr} name={biddersAbbrDict[abbr].name} /> : biddersAbbrDict[abbr].name)
                        :
                        abbr}&nbsp;
                        (<strong>
                          {initPkgsLoading && <MySpinner />}
                          {!initPkgsLoading && initPkgsDict[abbr] && initPkgsDict[abbr][productCode] && formatNumber(((vps[i] as any)['Price'] as ValueABC).value_pct * (initPkgsDict[abbr][productCode].max_price as number) / 100, true)}
                        </strong>)
                      </li>
                  })}
                </ul>
              </>}
              {Object.keys(vps[i])[0]==='DMP' && <>
                $/MHz./pop. values set to <strong>{((vps[i] as DMP)['DMP'] as ValueABC).value_pct}%</strong> for initial packages:<br/>
                <ul>
                  {allProductsDict && !productsLoading && !productsLoadError && ((vps[i] as DMP)['DMP'] as ValueABC).rows
                    .map((r: [string, string, boolean], j: number)=>{
                      const abbr = r[0];
                      const product = allProductsDict[r[1]];
                      const productCode = (product.open?.code || product.sap?.code) as string;
                      return <li key={`val-${j}`}>{(allProductsDict && !productsLoading && !productsLoadError) ? allProductsDict[r[1]].open?.name : r[1]} for {biddersAbbrDict && biddersAbbrDict[abbr]
                        ?
                        (biddersAbbrDict[abbr].is_named ? <NamedBidder abbr={abbr as NamedBidderAbbr} name={biddersAbbrDict[abbr].name} /> : biddersAbbrDict[abbr].name)
                        :
                        abbr}&nbsp;
                          {initPkgsLoading && <MySpinner />}
                          {!initPkgsLoading && initPkgsDict[abbr] && initPkgsDict[abbr][productCode] &&
                            <><br/>(
                              {
                                [initPkgsDict[abbr][productCode].max_dmp_q1,
                                  initPkgsDict[abbr][productCode].max_dmp_q2,
                                  initPkgsDict[abbr][productCode].max_dmp_q3,
                                  initPkgsDict[abbr][productCode].max_dmp_q4,
                                  initPkgsDict[abbr][productCode].max_dmp_q5].map(
                                    (dmpVal: number|undefined, j: number)=>(
                                      <span key={`q${j+1}`} className='mx-1'>
                                        q{j+1}: <strong>{dmpVal ? ((vps[i] as DMP)['DMP'] as ValueABC).value_pct * dmpVal / 100 : 'None'}</strong>
                                      </span>
                                    ))
                              })
                            </>
                          }
                      </li>
                  })}
                </ul>
              </>}
            </div>
          </ListGroup.Item>
        }
      })}
    </ListGroup>
  </>)
}

export default VariationSummary;