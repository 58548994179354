import React, { useState } from 'react';
import { Alert, Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { formatNumber } from '../../Helpers';
import NamedBidder from '../global/NamedBidder';
import PendingButton from '../global/PendingButton';

const EditBidder = (props: any) => {
  const bidder: Bidder = props.bidder;
  const [newBudget, setNewBudget] = useState(formatNumber(bidder.budget));
  const [mainErrorAlert, setMainErrorAlert] = useState<string|null>(null);
  const [showMainAlert, setShowMainAlert] = useState(false);
  const [budgetError, setBudgetError] = useState<string|null>(null);

  const [submitBudgetPending, setSubmitBudgetPending] = useState(false);
  // const [submitBudgetError, setSubmitBudgetError] = useState<string|null>(null);

  const handleSubmitEditBidder = (e: React.FormEvent<HTMLFormElement>) => {
    const form = e.currentTarget;
    let isValid = true;
    if (form.checkValidity() === false) {
      isValid = false;
    }

    e.preventDefault();

    if (isNaN(newBudget.replaceAll(/[,\$\.]/g, '').trim() as unknown as number)) {
      isValid = false;
      setBudgetError('Budget must be a valid number');
    }

    if (isValid) {
      setBudgetError(null);
      const editBidderObj = {
        budget: parseInt(newBudget.replaceAll(/[,\$\.]/g, '').trim())
      };
      console.log('attempting to POST');
      setSubmitBudgetPending(true);
      fetch(`${process.env.REACT_APP_API_URL}/api/bidders/edit/${bidder.id}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(editBidderObj)
      })
      .then(res => {
        if (!res.ok) {
          if (res.status ===400) {
            return res.text().then(text => { throw new Error(text) })
          }
          else {
            throw new Error(`${res.status} error: ${res.statusText}`)
          }
        }
      })
      .then(data => {console.log('successfully sent as POST'); window.location.reload();})
      .catch(err => {
        console.log(err);
        setSubmitBudgetPending(false);
        setMainErrorAlert(err.message);
        setShowMainAlert(true);
      });
    }
  }

    return (
      <>
        {showMainAlert && <Alert
          variant='danger'
          onClose={() => setShowMainAlert(false)}
          dismissible
          >
          <Alert.Heading>Error</Alert.Heading>
          <p>
            {mainErrorAlert}
          </p>
        </Alert>
        }
        <Form onSubmit={e=>handleSubmitEditBidder(e)}>
          <Form.Group>
            <Container>
              <Row>
                <Form.Label className='text-center'>
                  Enter a new budget amount for
                  {bidder.is_named && <NamedBidder name={bidder.name} abbr={bidder.abbr} />}
                  {!bidder.is_named && <>{bidder.name}</>}
                  :
                </Form.Label>
              </Row>
              <Row className='justify-content-center'>
                <Col md={6}>
                  <InputGroup className='mb-3'>
                    <Button variant='secondary' className='pe-none' disabled>Current budget:</Button>
                    <InputGroup.Text>${formatNumber(bidder.budget)}</InputGroup.Text>
                  </InputGroup>
                </Col>
                <Col md={6}>
                  <InputGroup className='mb-3'>
                    <Button className='pe-none'>New budget:</Button>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      min={0}
                      step={1000}
                      aria-label='New budget'
                      aria-describedby='addon-new-budget'
                      value={newBudget}
                      onChange={e => setNewBudget(e.target.value)}
                      isValid={!budgetError && parseInt(newBudget.replace('$', '').trim()) > 0}
                      isInvalid={budgetError ? !!budgetError : parseInt(newBudget.replace('$', '').trim()) <= 0}
                      />
                    <Form.Control.Feedback type='invalid'>
                      {budgetError}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Row>
              <div className='d-flex justify-content-center'>
                <PendingButton
                  className='mx-1'
                  variant='primary'
                  type='submit'
                  value='Submit'
                  inputType={true}
                  pending={submitBudgetPending}
                  error={!!mainErrorAlert}
                />
              </div>
            </Container>

          </Form.Group>
        </Form>
      </>
    );
};

EditBidder.displayName = 'EditBidder';

export default EditBidder;
