import { ReactElement } from "react";
import bellLogo from "../../logos/bell.png";
import braggLogo from "../../logos/bragg.png";
import cogecoLogo from "../../logos/cogeco.png";
import ecotelLogo from "../../logos/ecotel.png";
import iristelLogo from "../../logos/iristel.png";
import sogetelLogo from "../../logos/sogetel.png";
import rogersLogo from "../../logos/rogers.png";
import sasktelLogo from "../../logos/sasktel.png";
import ssiLogo from "../../logos/ssi.png";
import starsolutionsLogo from "../../logos/starsolutions.png";
import tbaytelLogo from "../../logos/tbaytel.png";
import telusLogo from "../../logos/telus.png";
import valleyfiberLogo from "../../logos/valleyfiber.png";
import vianetLogo from "../../logos/vianet.png";
import videotronLogo from "../../logos/videotron.png";
import wireieLogo from "../../logos/wireie.png";
import xplornetLogo from "../../logos/xplornet.png";

interface Props {
  abbr: 'ABC'|'BEL'|'BIN'|'BR'|'BP'|'COG'|'COM'|'ECO'|'IC'|'ICE'|'IDL'|'IR'|'KAT'|'RO'|'SIG'|'SSI'|'ST'|'STO'|'SOG'|'SS'|'TB'|'TEL'|'TC'|'VF'|'VN'|'VID'|'WI'|'XP';
  name: string;
  size?: number;
}

const NamedBidder: (arg0: Props)=>ReactElement = ({ abbr, name, size=16 }) => {
  const imgDict = {
    'ABC': { logo: null, col: '#ffffff', text: '#000000' },
    'BEL': { logo: bellLogo, col: '#0000ff', text: '#ffffff' },
    'BIN': { logo: null, col: '#0000ff', text: '#ffffff' },
    'BR': { logo: braggLogo, col: '#7030a0', text: '#ffffff' },
    'BP': { logo: null, col: '#ffffff', text: '#000000' },
    'COG': { logo: cogecoLogo, col: '#4f81bd', text: '#000000' },
    'COM': { logo: null, col: '#dccdcf', text: '#000000' },
    'ECO': { logo: ecotelLogo, col: '#ccff99', text: '#000000' },
    'IC': { logo: null, col: '#a6a6a6', text: '#000000' },
    'ICE': { logo: null, col: '#c6c6c6', text: '#000000' },
    'IDL': { logo: null, col: '#e6e6e6', text: '#000000' },
    'IR': { logo: iristelLogo, col: '#00b0f0', text: '#000000' },
    'KAT': { logo: null, col: '#a9a9a9', text: '#000000' },
    'RO': { logo: rogersLogo, col: '#ff0000', text: '#ffffff' },
    'SIG': { logo: null, col: '#a9a9a9', text: '#000000' },
    'SSI': { logo: ssiLogo, col: '#cccc00', text: '#000000' },
    'ST': { logo: sasktelLogo, col: '#993366', text: '#ffffff' },
    'STO': { logo: null, col: '#a9a9a9', text: '#000000' },
    'SOG': { logo: sogetelLogo, col: '#3bb3e7', text: '#ffffff' },
    'SS': { logo: starsolutionsLogo, col: '#b5231d', text: '#ffffff' },
    'TB': { logo: tbaytelLogo, col: '#ffff99', text: '#000000' },
    'TEL': { logo: telusLogo, col: '#00b050', text: '#ffffff' },
    'TC': { logo: null, col: '#3bbac1', text: '#000000' },
    'VF': { logo: valleyfiberLogo, col: '#6b5e21', text: '#ffffff' },
    'VN': { logo: vianetLogo, col: '#e4eef2', text: '#000000' },
    'VID': { logo: videotronLogo, col: '#000000', text: '#ffffff' },
    'WI': { logo: wireieLogo, col: '#81be3e', text: '#000000' },
    'XP': { logo: xplornetLogo, col: '#008080', text: '#ffffff' }
  };
  const imgSrc = imgDict[abbr];
  return (
    <>
      {imgSrc && imgSrc.logo &&
      <img src={imgSrc.logo} style={{ width: size, filter: 'drop-shadow(0 0 3px rgb(255 255 255 / 0.8))' }} className='me-2' />
      }
      {imgSrc && !imgSrc.logo &&
      <div
        className="text-center d-inline-block position-relative me-2"
        style={{
          width: size + 'px',
          height: size + 'px',
          backgroundColor: imgSrc.col,
          fontSize: '9px',
          borderColor: imgSrc.text,
          border: '1px solid',
          top: '-4px',
      }}>
        &nbsp;
      </div>
      }
      <span>{name}</span>
    </>
  )
}

export default NamedBidder;