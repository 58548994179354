import * as math from "mathjs";
import { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { formatNumber, getTextStyle } from "../../Helpers";
import { DynamicTable } from "../global/DynamicTable";
import NamedBidder from "../global/NamedBidder";
import OffcanvasPane from "../global/OffcanvasPane";
import NewAuctionFromVariantLink from "./NewAuctionFromVariantLink";

interface DataTableItem {
  variant_num: number;
  proc_demand: [number, number];
  product_cost: [number, number];
}

const ProcessedDemandMatrix = (props:any) => {
  const results: VarRes = props.results;
  // const variation: Variation = props.variation;
  const variants: Variant[] = props.variants;
  const variantsDict = useMemo(()=>{
    let vDict: { [key: string]: Variant } = {};
    variants.forEach((v:Variant)=>{
      vDict[v.var_number.toString()] = v;
    });
    return vDict
  }, [variants]);

  const parentAuction: Auction = props.parentAuction;
  const allProductsDict: { [key: string]: GeneralProduct } = props.allProductsDict;
  const biddersDict: { [key: string]: Bidder } = props.biddersDict;

  const [relevantProducts, setRelevantProducts] = useState<[string, boolean][]|null>(null);

  // const bidderResultsByVariant = props.bidderResultsByVariant;
  const bidderResultsByProduct = props.bidderResultsByProduct;

  const [stdPDemandMatrix, setStdPDemandMatrix] = useState<{ [key: string]: { [key: string]: number } }|null>(null); // { abbr -> { PR as string -> pdemand_stdev } }
  const [minStd, setMinStd] = useState(100000);
  const [maxStd, setMaxStd] = useState(0);

  const [currentBidderPr, setCurrentBidderPr] = useState<[string, [string, boolean]]|null>(null);
  const currentProduct = useMemo(()=>{
    if (currentBidderPr) {
      const pr = currentBidderPr[1];
      const category: 'open'|'sap' = pr[1] ? 'sap' : 'open';
      const product = allProductsDict[pr[0]][category];
      return product
    }
  }, [currentBidderPr]);
  const [showDetails, setShowDetails] = useState(false);
  const [currentData, setCurrentData] = useState<DataTableItem[]>([]);
  const [foundData, setFoundData] = useState<DataTableItem[]>([]);
  const [includeZeros, setIncludeZeros] = useState(false);
  const columns = [
    {
      title: 'Variant',
      key: 'variant',
      sort: (a:DataTableItem, b:DataTableItem) => a.variant_num-b.variant_num,
      render: (_: any, item:DataTableItem) => <NewAuctionFromVariantLink variant={variantsDict[item.variant_num.toString()]} parentAuction={parentAuction} />
    },
    {
      title: 'Processed Demand',
      key: 'proc_demand',
      sort: (a:DataTableItem, b:DataTableItem) => a.proc_demand[0]-b.proc_demand[0],
      render: (_:any, item:DataTableItem) => <span className={getTextStyle(item.proc_demand[0], item.proc_demand[0]-item.proc_demand[1])}>{`${formatNumber(item.proc_demand[0])} (${formatNumber(item.proc_demand[1])})`}</span>
    },
    {
      title: 'Product Cost',
      key: 'product_cost',
      sort: (a:DataTableItem, b:DataTableItem) => a.product_cost[0]-b.product_cost[0],
      render: (_: any, item:DataTableItem) => <span className={getTextStyle(item.product_cost[0], item.product_cost[0]-item.product_cost[1])}>{`${formatNumber(item.product_cost[0], true)} (${formatNumber(item.product_cost[1], true)})`}</span>
    }
  ];

  const COLOUR_SCALE = [
    'rgb(197, 215, 231)',
    'rgb(154, 185, 214)',
    'rgb(110, 154, 196)',
    'rgb(65, 114, 159)',
    '#2d4f6f'
  ];

  const getStyle = (stdDev: number|null) => {
    let intensityPct: number;
    if (stdDev===0||!!stdDev) {
      intensityPct = Math.round((stdDev-minStd) / (maxStd-minStd)*100);
      if (Math.round(intensityPct / 20) > 0) {
        return {
          backgroundColor: COLOUR_SCALE[Math.round(intensityPct / 20)-1],
          color: Math.round(intensityPct / 20)-1===4 ? 'white' : undefined
        }
      }
      else {
        return { backgroundColor: COLOUR_SCALE[0] }
      }
    }
    else {
      return undefined
    }
  }

  const handleClickDetails = (abbr: string, pr: [string, boolean]) => {
    setCurrentBidderPr([abbr, pr]);
    setShowDetails(true);
  }

  useEffect(()=>{
    if (includeZeros) {
      setFoundData([...currentData]);
    }
    else {
      // setFoundData([...currentData]);
      setFoundData(currentData.filter((item:DataTableItem)=>item.product_cost[1]!==0));
    }
  }, [currentData, includeZeros]);
  useEffect(()=>{
    if (currentBidderPr) {
      let data: DataTableItem[] = [];
      bidderResultsByProduct(currentBidderPr[0], currentBidderPr[1])
        // [PR, variant_id, posted_price, pdemand, pdemand_delta, cost, cost_delta]
        .forEach((resultRow: [[string, boolean], number, number, number, number, number, number])=>{
          const foundVariant = variants.find((v:Variant)=>resultRow[1]===v.id);
          if (foundVariant) {
            data.push({
              variant_num: foundVariant.var_number,
              proc_demand: [resultRow[3], resultRow[4]],
              product_cost: [resultRow[5], resultRow[6]]
            });
          }
        });
      setCurrentData(data.sort((a:DataTableItem, b:DataTableItem) => a.variant_num-b.variant_num));
    }
  }, [currentBidderPr]);
  useEffect(()=>{
    let stdPDemand: { [key: string]: { [key: string]: number } } = {};
    let minStdPDemand = 100000;
    let maxStdPDemand = 0;

    // let updatedRelevantProducts: [string, boolean][] = [];

    let auctionProducts: [string, boolean][] = [];
    const auctionProductDict: { [key: string]: [string, boolean] } = {};

    (results.prices
      .map((price: [[string, boolean], number, number, number, number])=>price[0]))
      .forEach((p: [string, boolean])=>{
        const prCode: string = `${p[0]}-${JSON.stringify(p[1])}`;

        auctionProductDict[prCode] = p;
    });

    auctionProducts = Object.values(auctionProductDict);

    // const relevantProductsDict: { [key: string]: [string, boolean] } = {};

    Object.keys(biddersDict).forEach((abbr: string)=> {
      auctionProducts.forEach((pr: [string, boolean])=>{
        const prCode: string = `${pr[0]}-${JSON.stringify(pr[1])}`; // javascript can't use tuple as key. pr [string, boolean] stored as single string of <PRODUCT_CODE>-true or <PRODUCT_CODE>-false

        let bidderResPR = [];

        try {
          bidderResPR = bidderResultsByProduct(abbr, pr);
        }
        catch (err) {
          console.log(abbr);
          console.log(err);
          console.log(biddersDict);
        }

        // [[PR], variant_id, posted_price, pdemand, pdemand_delta, cost, cost_delta]
        const pdemandDeltas = bidderResPR.map((x:[[string, boolean], number, number, number, number, number, number])=>x[4]);

        if (pdemandDeltas.length > 0) {
          const val = math.std(pdemandDeltas);
          if (stdPDemand[abbr]) {
            stdPDemand[abbr][prCode] = val;
          }
          else {
            stdPDemand[abbr] = {};
            stdPDemand[abbr][prCode] = val;
          }

          if (val < minStdPDemand) {
            minStdPDemand = val;
          }
          if (val > maxStdPDemand) {
            maxStdPDemand = val;
          }
        }
      });
    });

    setMinStd(minStdPDemand);
    setMaxStd(maxStdPDemand);
    setStdPDemandMatrix(stdPDemand);
    setRelevantProducts(auctionProducts);
  }, [results]);

  return (<>
    <div className='d-flex flex-column w-100 h-100'>
      <div className='p-2 my-2'>
        Values in the matrix are standard deviations of processed demands across variants for a given product-bidder combination.<br/>
        Higher value indicates an increased change in processed demands across variants. Click on a cell to see details.
      </div>
      <div className='p-3'>
        {stdPDemandMatrix && relevantProducts && <>
          <div className='d-flex flex-column'>
            <div style={{ backgroundColor: 'rgb(248, 249, 250)' }}>
              <div style={{ gridAutoFlow: 'column', gridAutoColumns: '1fr' }} className='d-grid'>
                <div className='text-center px-2 py-1 hide-overflow-grid-cell'><strong><small>Product</small></strong></div>
                {Object.keys(biddersDict).sort().map((abbr: string)=>{
                  const bidder: Bidder = biddersDict[abbr];
                  return <div key={abbr} className='text-center px-2 py-1 hide-overflow-grid-cell'>
                    <strong><small>{bidder.is_named ? <NamedBidder name={bidder.name} abbr={bidder.abbr} /> : <>{biddersDict[abbr].name}</>}</small></strong>
                  </div>
                })}
              </div>
            </div>
            <div
              className='flex-grow overflow-scroll'
              style={{
                minHeight: '240px',
                maxHeight: '800px',
                height: '70vh'
              }}
            >
            {relevantProducts
              .sort((a: [string, boolean], b: [string,boolean])=>a[0]<b[0]?-1:(a[0]>b[0]?1:0))
              .map((pr: [string, boolean])=>{
                const prCode: string = `${pr[0]}-${JSON.stringify(pr[1])}`;
                const category: 'open'|'sap' = pr[1] ? 'sap' : 'open';
                return <div className='d-grid' style={{ gridAutoFlow: 'column', gridAutoColumns: '1fr' }} key={prCode}>
                  <div className='px-2 py-1 hide-overflow-grid-cell' style={{ backgroundColor: 'rgb(248, 249, 250)' }}>
                    <small>
                      <span className='fw-bold'>
                        {allProductsDict[pr[0]][category]?.name}
                      </span>
                      <br/>
                      (Pop. {formatNumber(allProductsDict[pr[0]][category]?.population as number)})
                    </small>
                  </div>
                  {Object.keys(biddersDict).sort().map((abbr: string, i: number)=>{
                    if (stdPDemandMatrix[abbr]) {
                      return <div key={abbr} className='overflow-hidden px-2 py-1 text-center align-items-center d-flex' style={getStyle(stdPDemandMatrix[abbr][prCode])}>
                          <div className='text-center w-100'>
                            <Button
                              variant='link'
                              className='text-decoration-none'
                              style={{ color: 'inherit' }}
                              onClick={()=>handleClickDetails(abbr, pr)}
                              disabled={!stdPDemandMatrix[abbr][prCode] && stdPDemandMatrix[abbr][prCode]!==0}
                              >
                              {!!stdPDemandMatrix[abbr][prCode] && formatNumber(stdPDemandMatrix[abbr][prCode])}
                              {!stdPDemandMatrix[abbr][prCode] && <>{stdPDemandMatrix[abbr][prCode]===0 ? '0' : 'N/A'}</>}
                            </Button>
                          </div>
                      </div>
                    }
                    else { return <div key={abbr} className='overflow-hidden px-2 py-1 text-center align-items-center d-flex'><div key={i} className='w-100 text-center'><em><small>No bids in this auction</small></em></div></div> }
                  })}
                </div>
              })}
            </div>
          </div>
        </>
        }
      </div>
    </div>
    {currentBidderPr && biddersDict[currentBidderPr[0]] && currentProduct &&
    <OffcanvasPane
      show={showDetails}
      onHide={()=>setShowDetails(false)}
      placement='bottom'
      title={`${biddersDict[currentBidderPr[0]].name} : ${currentProduct.name}`}
      className='h-50'
      >
      <div className='ms-auto float-end'>
        <small>Values in parentheses (...) indicate change from the parent auction</small>
        <Button
          variant={includeZeros ? 'primary' : 'outline-secondary'}
          onClick={()=>setIncludeZeros(!includeZeros)}
          className='ms-3'
          >
          Include zero-change
        </Button>
      </div>
      <DynamicTable
        data={foundData}
        columns={columns}
        searchable={false}
      />
    </OffcanvasPane>
    }
  </>)
}

export default ProcessedDemandMatrix;