import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Auction from "./Auction";
import { AUCTION_STATUS } from "../../Helpers";
import useFetch from "../../useFetch";
import useInterval from "../../useInterval";
import MySpinner from "../../MySpinner";

const AuctionContainer = (props: any) => {
  const currentUser: [string, string] = props.currentUser || JSON.parse(window.localStorage.getItem('currentUser') as string);
  const isAdmin = props.isAdmin || JSON.parse(window.localStorage.getItem('isAdmin') as string);
  const handleLogin = props.handleLogin;

  const [currentBidder, setCurrentBidder] = useState<Bidder|null>(null);

  const auctionId = useParams().id;

  useEffect(()=>{
    if (!isAdmin) {
      // fetch Bidder
      const fetchUrl = process.env.REACT_APP_DEV_ENV === 'local' ? `/api/bidders?id=104` : `/api/bidders/get_by_name/${currentUser[1]}/${auctionId}`;
      fetch(process.env.REACT_APP_API_URL + fetchUrl)
        .then((res) => {
          if(!res.ok) {
            return res.text().then(text => { throw new Error(text) })
          }
          else {
            return res.json();
          }
        })
        .then((data) => {
          const dataObj = process.env.REACT_APP_DEV_ENV === 'local' ? data[0] : data;
          setCurrentBidder(dataObj);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  // useInterval(() => {
  //   if (!updatedAuctionData || (!updatedAuctionData.ended && auctionStatus !== AUCTION_STATUS.paused) ||(!updatedAuctionData.ended && auctionStatus !== AUCTION_STATUS.ready)) {
  //     console.log('Checking auction status');
  //     setUpdateAuctionPending(true);
  //     fetch(`${process.env.REACT_APP_API_URL}/api/auctions/get/${auctionId}`)
  //       .then((res) => {
  //         if(!res.ok) {
  //           return res.text().then(text => { throw new Error(text) })
  //         }
  //         else {
  //           return res.json();
  //         }
  //       })
  //       .then((data) => {
  //         setTimeout(()=>{setUpdatedAuctionData(data); setUpdateAuctionPending(false);}, 1500);
  //       })
  //       .catch((err) => {
  //         setUpdateAuctionPending(false);
  //         console.log(err);
  //       });
  //   }
  // }, 15000, () => {return !!(updatedAuctionData && updatedAuctionData.ended)});

  // const reloadCurrentAuction = () => {
  //   setAuctionStatus('Reloading with updated auction data');
  //   setLoading(true);
  //   setTimeout(()=>{setUpdatedAuctionData(null); setCurrentAuction(updatedAuctionData); setLoading(false);}, 1400)
  // }

    return (
      <div className='auction'>
          <Auction
            currentUser={currentUser}
            isAdmin={isAdmin}
            currentBidder={currentBidder}
            auctionId={auctionId}
            handleLogin={handleLogin}
          />
        {/*{(initialAuctionFetch.isPending) && !initialAuctionFetch.error && <MySpinner />}
        {!initialAuctionFetch.isPending && !isAdmin && currentAuction && currentBidder &&
          <Auction
            currentUser={currentUser}
            isAdmin={false}
            bidder={currentBidder}
            auction={currentAuction}
            loading={loading}
            updatedAuction={updatedAuctionData}
            handleReload={reloadCurrentAuction}
            handleLogin={handleLogin}
            updatePending={updateAuctionPending}
            auctionStatus={auctionStatus}
          />
        }
        {!initialAuctionFetch.isPending && isAdmin && currentAuction &&
          <Auction
            currentUser={currentUser}
            isAdmin={true}
            auction={currentAuction}
            loading={loading}
            updatedAuction={updatedAuctionData}
            handleReload={reloadCurrentAuction}
            handleLogin={handleLogin}
            updatePending={updateAuctionPending}
            auctionStatus={auctionStatus}
          />
        }
        {/*{!isPending && !pendingBiddersFetch.isPending && currentBidder && auction && <Auction currentUser={currentUser} bidder={currentBidder} auction={auction} handleLogin={handleLogin} pendingBidders={pendingBiddersFetch.data} />}*/}
        {/*{!initialAuctionFetch.isPending && !loading && !updatedAuctionData && initialAuctionFetch.error && 'Auction not found.'}*/}
      </div>
    );
};

AuctionContainer.displayName = 'AuctionContainer';

export default AuctionContainer;
