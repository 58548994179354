import { Placement } from 'popper.js';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function TooltipIcon(props: any) {
  const placement = props.placement || 'top';

  return (
    <>
      <OverlayTrigger
        placement={placement as Placement}
        overlay={
          <Tooltip style={{ margin: 0 }} id={`tooltip-${props.id}`}>
            {props.text}
          </Tooltip>
        }
      >
        <div style={{ margin: 0 }}>
          {props.children}
        </div>
      </OverlayTrigger>
    </>
  );
}

export default TooltipIcon;