import { useEffect, useState } from "react";
import { Alert, Button, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import useInterval from "../../useInterval";

const AutoSubmitEmptyBidsModal = (props:any) => {
  const emptyBids: BidSubmission = props.emptyBids;

  const [submitting, setSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [allErrors, setAllErrors] = useState<string[]>([]);
  const [lastPostSuccess, setLastPostSuccess] = useState(true);
  const [currentRound, setCurrentRound] = useState(emptyBids.round_num);
  const [auctionEnded, setAuctionEnded] = useState(false);

  const fetchAuction = () => {
    setTimeout(()=>{
      fetch(`${process.env.REACT_APP_API_URL}/api/auctions/get/${emptyBids.auction_id}`)
        .then((res) => {
          if(res.status===400) {
            return res.text().then(text => { throw new Error(text) })
          }
          else if (!res.ok) {
            throw new Error(`${res.status}: ${res.statusText}`);
          }
          else {
            return res.json();
          }
        })
        .then((data: Auction) => {
          setAuctionEnded(data.ended);
        })
        .catch((err) => {
          setAllErrors([`${err.message}`]);
          setSubmitting(false);
          setLastPostSuccess(false);
        });
    }, 1500);
  }

  const checkSubmitted = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/bidders/has_submitted/${emptyBids.bidder_id}/${emptyBids.auction_id}`)
      .then((res) => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        const hasSubmitted: boolean = data;
        if (!hasSubmitted && !submitting && lastPostSuccess) {
          submitBids();
        }
      })
      .catch((err) => {
        setAllErrors([`${err.message}`]);
        console.log(err);
      });
  }

  const submitBids = () => {
    if (lastPostSuccess) {
      console.log('submitting empty bids...');
      setSubmitting(true);

      const bidObj: BidSubmission = {
        auction_id: emptyBids.auction_id,
        bidder_id: emptyBids.bidder_id,
        round_num: currentRound,
        new_bids: emptyBids.new_bids
      };

      setTimeout(()=>{
        fetch(`${process.env.REACT_APP_API_URL}/api/bids/submit`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(bidObj)
        })
        .then(res => {
            if(!res.ok) {
              if (res.status===400) {
                return res.text().then(text => { throw new Error(text) })
              }
              else {
                throw new Error(`${res.status}: ${res.statusText}`);
              }
            }
            else {
              return res.json();
            }
        })
        .then((data) => {
            console.log('successfully sent bids');
            setSubmitting(false);
            if (data.all_success) {
              setLastPostSuccess(true);
              setCurrentRound(currentRound+1);
              setAllErrors([]);
            }
            else {
              setLastPostSuccess(false);
              const errors: string[] = [];
              data.bid_results.forEach((result:BidResult)=>{
                if (!result.success) {
                  errors.push(`${result.product_code}: ${result.msg}`)
                }
              });
              setAllErrors(errors);
            }
        })
        .catch(err => {
          setSubmitting(false);
          setAllErrors([`${err.message}`]);
          setLastPostSuccess(false);
        });
      }, 300);
    }
  }

  useEffect(()=>{
    fetchAuction(); // checks if auction has ended
  }, [currentRound]);

  useInterval(()=>{
    if (allErrors.length===0 && !auctionEnded) {
      checkSubmitted();
    }
  }, 1000);

  return (
    <Modal
      show={true}
      size='lg'
      backdrop='static'
      keyboard={false}
      >
      <Modal.Header>
        <Modal.Title>
          Auto-submit 0 demand for all bids
          {(submitting && allErrors.length===0) && <div className='mx-3 float-end' style={{ marginTop: '-3px' }}>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              title="Submitting"
              >
              <span className="visually-hidden">(Submitting...)</span>
            </Spinner>
          </div>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Your eligibility is 0 and you can no longer submit any bids.</p>
        <p>
          This window will submit empty bids on your behalf until the auction ends. Please stay on this screen.
        </p>
        <h5 className='text-center'>
          Current Round: {currentRound}
        </h5>
        {!submitting && allErrors.length>0 && <div style={{ maxHeight: '280px', overflowY: 'auto' }}>
          {allErrors.map((e: string, i: number)=><Alert key={i} variant='danger'>{e}</Alert>)}
        </div>}
        {!submitting && auctionEnded && <Alert variant='primary'>Auction complete</Alert>}
      </Modal.Body>
      {!submitting && allErrors.length>0 && <Modal.Footer>
        <Link to='/web/auction-cp'>
          <Button variant="primary">
            Return to Auctions List
          </Button>
        </Link>
      </Modal.Footer>}
      {!submitting && auctionEnded && <Modal.Footer>
        <Button variant="primary" onClick={()=>window.location.reload()}>
          View Auction Results
        </Button>
      </Modal.Footer>}
    </Modal>
  )
}

export default AutoSubmitEmptyBidsModal;