import React, { useEffect, useState } from 'react';
import { Alert, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import IconWrapper from '../global/IconWrapper';
import { IoIosPlay } from 'react-icons/io';
import PendingButton from '../global/PendingButton';

const AuctionPauseCP = (props: any) => {
  const auction: Auction = props.auction;
  const currentPauses: number[] = props.auction.pauses.filter((r:number)=>r>auction.round_num);
  const fetchAuctions = props.fetchAuctions;

  const [newPauseRound, setNewPauseRound] = useState<number>(auction.round_num+1);
  const [resumeAuctionPending, setResumeAuctionPending] = useState(false);
  const [resumeAuctionError, setResumeAuctionError] = useState<string>();
  const [removePausePending, setRemovePausePending] = useState(false);
  const [removePauseError, setRemovePauseError] = useState<string>();
  const [showRemovePausePrompt, setShowRemovePausePrompt] = useState(false);
  const [roundToRemove, setRoundToRemove] = useState<number>();
  const [addPausePending, setAddPausePending] = useState(false);
  const [addPauseError, setAddPauseError] = useState<string>();

  const handleResumeAuction = () => {
    console.log('Resuming auction');

    if (process.env.REACT_APP_DEV_ENV !== 'local') {
      setResumeAuctionPending(true);
      fetch(`${process.env.REACT_APP_API_URL}/api/auctions/resume/${auction.id}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      })
        .then((res) => {
          if(!res.ok) {
            return res.text().then(text => { throw new Error(`Resume failed: ${text}`) })
          }
          // else {
          //   return res.json();
          // }
        })
        .then(data => {
          console.log('successfully sent as POST');
          setResumeAuctionPending(false);
          fetchAuctions();
        })
        .catch((err) => {
          setResumeAuctionPending(false);
          setResumeAuctionError(err.message);
        });
    }
  }

  const handleRemovePause = (round_num: number) => {
    console.log('Removing pause ' + round_num);

    const editAuctionObj: EditAuction = {
      pauses: auction.pauses.filter(r=>r!==round_num&&r>auction.round_num)
    }

    if (process.env.REACT_APP_DEV_ENV !== 'local') {
      console.log('submitting POST');
      setRemovePausePending(true);
      fetch(`${process.env.REACT_APP_API_URL}/api/auctions/edit/${auction.id}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(editAuctionObj)
      })
        .then(res => {
          if(!res.ok) {
            return res.text().then(text => { throw new Error(text) })
          }
          // else {
          //   return res.json();
          // }
        })
        .then(data => {
          console.log('successfully removed pause');
          setRemovePausePending(false);
          setShowRemovePausePrompt(false);
          fetchAuctions();
        })
        .catch(err => {
          console.log(err);
          setRemovePauseError(err.message);
          setRemovePausePending(false);
        });
    }
  }

  const handleAddPause = () => {
    if (newPauseRound && !auction.pauses.includes(newPauseRound)) {
      console.log('Adding pause at ' + newPauseRound);

      const newPauseList = [...auction.pauses];
      newPauseList.push(newPauseRound);

      const editAuctionObj: EditAuction = {
        pauses: newPauseList.sort().filter(p=>p>auction.round_num)
      }

      if (true) {
        console.log('submitting POST');
        setAddPausePending(true);
        fetch(`${process.env.REACT_APP_API_URL}/api/auctions/edit/${auction.id}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(editAuctionObj)
        })
          .then(res => {
            if (!res.ok && res.status ===400) {
              return res.text().then(text => { throw new Error(text) })
            }
            else if (!res.ok) {
              throw new Error(`${res.status} error: ${res.statusText}`);
            }
            // else {
            //   return res.json();
            // }
          })
          .then(data => {
            console.log('successfully added pause');
            setAddPausePending(false);
            fetchAuctions();
          })
          .catch(err => {
            console.log(err);
            setAddPauseError(err.message);
            setAddPausePending(false);
          });
      }
    }
  }

  const handleAddPauseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e) {
      setNewPauseRound(parseInt(e.target.value));
    }
  }

  const handleRemovePausePrompt = (round_num: number) => {
    // Show prompt for removing a pause
    setShowRemovePausePrompt(true);
    setRoundToRemove(round_num);
  }

  useEffect(()=>{
    setNewPauseRound(auction.round_num+1);
    console.log('re-rendering Auction Pause CP');
  }, []);

  return (
    <div className='d-flex w-100'>
      <div className='align-self-center me-3'>
        <h6 className='text-start m-0'>Round Pauses</h6>
      </div>
      <div className='flex-grow-1 d-flex align-self-center'>
        {auction.status !== 'Ended' &&
        <>
          <div className='align-self-center mx-1' style={{ maxWidth: '250px', width: '15%' }}>
            <span className='text-start fw-light'>Next pause:</span>
            <span className='ms-2 fw-bold'>
              <>
              {(auction.status === 'Ready' || auction.status === 'Paused') && currentPauses.length === 0 && 'None'}
              {(auction.status === 'Ready' || auction.status === 'Paused') && currentPauses.length > 0 && (auction.pauses.sort()[0] === 0 ? `Round ${auction.round_num+1}` : `Round ${currentPauses.sort()[0]}`)}
              </>
            </span>
          </div>
          {props.isAdmin &&
            <>
              <div className='mx-1' style={{ minWidth: '280px', width: '25%' }}>
                <InputGroup>
                  <PendingButton
                    size='sm'
                    onClick={handleAddPause}
                    disabled={addPausePending}
                    pending={addPausePending}
                  >
                    <IconWrapper>
                      <span>
                        Add pause at Round:
                      </span>
                    </IconWrapper>
                  </PendingButton>
                  <Form.Control
                    type='number'
                    min={auction.round_num+1}
                    value={newPauseRound}
                    onChange={handleAddPauseChange}
                  />
                </InputGroup>
              </div>
              {!auction.ended && currentPauses.length > 0 &&
              <div className='mx-1 flex-grow-1 align-self-center'>
                <span className='fw-light me-3'>
                  <abbr title='Click round number to remove pause'>
                    Upcoming pauses at Rounds:
                  </abbr>
                </span>
                {auction.pauses.filter(r=>r>auction.round_num).sort((a,b)=>a-b).map((r: number) => {
                  return <Button key={r} variant='outline-secondary' size='sm' className='mx-1' onClick={() => handleRemovePausePrompt(r)}>{r}</Button>
                })}
              </div>
              }
            </>
          }
        </>
        }
      </div>
      <div className='ms-auto align-self-center'>
        {auction.status === 'Paused' && props.isAdmin &&
          <PendingButton
            disabled={auction.status !== 'Paused'}
            variant={auction.status === 'Paused' ? 'primary' : 'secondary'}
            onClick={handleResumeAuction}
            pending={resumeAuctionPending}
            error={resumeAuctionError}
            errorMsg={resumeAuctionError}
            size='sm'
          >
            <IconWrapper>
              <IoIosPlay />
              {!resumeAuctionPending && !resumeAuctionError && auction.round_num > 1 && <span>Resume Auction</span>}
              {!resumeAuctionPending && !resumeAuctionError && auction.round_num === 1 && <span>Start Auction</span>}
            </IconWrapper>
          </PendingButton>
        }
      </div>
      {roundToRemove &&
      <Modal show={showRemovePausePrompt} onHide={() => setShowRemovePausePrompt(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Remove pause</Modal.Title>
        </Modal.Header>
        <Modal.Body>Remove the upcoming pause at Round {roundToRemove}?</Modal.Body>
        {!removePausePending && removePauseError &&
          <Alert variant='danger'>
          {removePauseError}
          </Alert>
        }
        <Modal.Footer>
          <PendingButton
            variant='primary'
            onClick={()=>handleRemovePause(roundToRemove)}
            disabled={removePausePending}
            pending={removePausePending}
            error={removePauseError}
          >
            Remove
          </PendingButton>
          <Button variant="secondary" onClick={()=>setShowRemovePausePrompt(false)} disabled={removePausePending}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      }
    </div>
  );
};

AuctionPauseCP.displayName = 'AuctionPauseCP';

export default AuctionPauseCP;
