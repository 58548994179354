import { memo, useEffect, useState } from "react";
import { Card, Container, ListGroup, Nav, Tab } from "react-bootstrap";
import AuctionBids from "./AuctionBids";
import AuctionBidderHistory from "./AuctionBidderHistory";
import AuctionRegions from "./AuctionRegions";
import useFetch from "../../useFetch";
import AuctionHistory from "./AuctionHistory";
// import PDemandsHistory from "./PDemandsHistory";
import BidderResults from "./BidderResults";
import ProductResults from "./ProductResults";
import MySpinner from "../../MySpinner";
// import useFetch from "../../useFetch";

const AuctionAdminCP = (props: any) => {
  const currentUser: string = props.currentUser;
  const auction: Auction = props.auction;

  const [allProductsDict, setAllProductsDict] = useState<GeneralProduct[]|null>(null);

  const [auctionAllPrices, setAuctionAllPrices] = useState<Price[]>([]);
  const [auctionAllPricesLoading, setAuctionAllPricesLoading] = useState(true);
  const [auctionAllPricesError, setAuctionAllPricesError] = useState<string|null>(null);
  const [pricesComputationBegun, setPricesComputationBegun] = useState(false);
  const [loadingPricesTable, setLoadingPricesTable] = useState(false);
  const [pricesComputationComplete, setPricesComputationComplete] = useState(false);

  // const pricesFetch = useFetch(`${process.env.REACT_APP_API_URL}/api/prices/get/${auction.id}?set_aside=false`);
  const eventsFetch = useFetch(`${process.env.REACT_APP_API_URL}/api/events/get/${auction.id}`);

  const fetchPrices = () => {
    console.log('fetching prices');
    setAuctionAllPricesLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/prices/get/${auction.id}?set_aside=false`)
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        const dataObj = data;
        setAuctionAllPrices(dataObj);
        console.log('fetched prices for ' + auction.id);
        setAuctionAllPricesLoading(false);
      })
      .catch(err => {
        setAuctionAllPricesLoading(false);
        setAuctionAllPricesError(err.message);
      });
  }

  useEffect(()=>{
    console.log('updating pricesComputationBegun');
    if (pricesComputationBegun) {
      console.log('AuctionAdminCP: invoking begin computation');
    }
  }, [pricesComputationBegun]);

  useEffect(()=>{
    console.log('updating pricesComputationComplete');
    if (pricesComputationComplete) {
      setLoadingPricesTable(false);
      console.log('AuctionAdminCP: computation complete');
    }
  }, [pricesComputationComplete]);

  useEffect(() => {
    console.log('fetching prices');
    fetchPrices();
    console.log('fetching products');
    fetch(`${process.env.REACT_APP_API_URL}/api/products/list`)
      .then((res) => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setAllProductsDict(data);
        // console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

    return (
      <>
        <Container className='justify-content-center' fluid>
          {!allProductsDict &&  <MySpinner />}
          {allProductsDict && <Tab.Container defaultActiveKey='bidder-results'>
            <Nav variant="tabs"  className='mt-4 mb-3'>
              <Nav.Item>
                <Nav.Link href='#' eventKey='bidder-results' disabled={loadingPricesTable}>Results by Bidder</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href='#' eventKey='product-results' disabled={loadingPricesTable}>Results by Product</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href='#'
                  eventKey="info"
                  onClick={
                    !pricesComputationComplete
                    ?
                    ()=>{
                      setLoadingPricesTable(true);
                      setTimeout(()=>{
                        setPricesComputationBegun(true)
                      }, 1500);
                    }
                    :
                    undefined
                  }
                  disabled={loadingPricesTable}
                >
                  Price History
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href='#' eventKey="events" disabled={loadingPricesTable}>Event Log</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey='events'>
                {eventsFetch.isPending && <MySpinner />}
                {!eventsFetch.isPending && eventsFetch.data &&
                  <Card>
                    <ListGroup>
                    {eventsFetch.data.map((event: string, i: number)=> {
                    return <ListGroup.Item key={i}>{event}</ListGroup.Item>
                    })}
                    </ListGroup>
                  </Card>
                }
                {!eventsFetch.isPending && eventsFetch.error && <p className='text-center'>Could not load events</p>}
              </Tab.Pane>
              <Tab.Pane eventKey='bidder-results'>
                <BidderResults
                  auction={auction}
                  pricesFetch={auctionAllPrices}
                  pricesFetchLoading={auctionAllPricesLoading}
                  productsDict={allProductsDict}
                />
              </Tab.Pane>
              <Tab.Pane eventKey='product-results'>
                <ProductResults
                  auction={auction}
                  pricesFetch={auctionAllPrices}
                  pricesFetchLoading={auctionAllPricesLoading}
                  productsDict={allProductsDict}
                />
              </Tab.Pane>
              <Tab.Pane eventKey='info'>
                {(!pricesComputationComplete || loadingPricesTable) && <div className="justify-content-center text-center"><div>Loading all prices for this auction.<br/>May take a while.</div><MySpinner /></div>}
                <AuctionHistory
                  auction={auction}
                  pricesFetch={auctionAllPrices}
                  pricesFetchLoading={auctionAllPricesLoading}
                  productsDict={allProductsDict}
                  pricesComputationBegun={pricesComputationBegun}
                  pricesComputationComplete={pricesComputationComplete}
                  setPricesComputationComplete={setPricesComputationComplete}
                  loadingPricesTable={loadingPricesTable}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>}
        </Container>
      </>
    );
};

AuctionAdminCP.displayName = 'AuctionAdminCP';


export default memo(AuctionAdminCP);
