import type { ChartOptions } from 'chart.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useMemo } from 'react';
import { Line } from "react-chartjs-2";
import { formatNumber } from '../../Helpers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const VariationGraph = (props:any) => {
  const title: string = props.title || props.label;
  const xAxis: number[] = props.xAxis; // these should already in order
  const yAxis: number[] = props.yAxis; // these should already be sorted
  const parentValue: number = props.parentValue;
  const label: string = props.label;
  const variantParamValues: [ValueParam, ValueParam][] = props.variantParamValues; // these are in corresponding order to the variants in xAxis

  const options: ChartOptions<'line'> = {
    scales: {
      x: {
        display: true,
        title: {
          text: 'Variant Number',
          display: true,
          padding: 6,
          font: {
            size: 18
          }
        },
        ticks: {
          callback: (value, index, ticks) => {
            return `Variant ${(value as number)+1}`
          }
        }
      },
      y: {
        display: true,
        title: {
          text: props.yAxisLabel,
          display: !!props.yAxisLabel,
          padding: 6,
          font: {
            size: 18
          }
        },
        ticks: {
          callback: (value, index, ticks) => { return formatNumber(value as number, true) }
        }
      }
    },
    plugins: {
      title: {
        display: !!title,
        text: `${title} for ${label}`,
        font: {
          size: 21
        }
      },
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
          font: {
            size: 14
          },
          padding: 8
        }
      }
    },
    animation: props.disableAnimation ? false : undefined
  };

  const variantDescriptions: string[] = useMemo(()=>{
    if (variantParamValues) {
      let allParamDescs: string[] = [];

      variantParamValues.forEach((pair: [ValueParam, ValueParam], i: number)=>{
        let paramDescs = ['', ''];
        paramDescs = paramDescs.map((desc: string, j: number)=>{
          const paramAbbr = pair[j]!=="NoParam" ? Object.keys(pair[j])[0] as ('IncPct'|'SpectrumCap'|'BidderBudget'|'BidderInitEP'|'Priority'|'Budget'|'DMP'|'Price') : null;
          if (paramAbbr==='IncPct') {
            return `Inc. % at ${formatNumber((pair[j] as any)[paramAbbr].value)}%`
          }
          else if (paramAbbr==='SpectrumCap') {
            return `Spec. cap at ${formatNumber((pair[j] as any)[paramAbbr].value)} blocks`
          }
          else if (paramAbbr==='BidderBudget') {
            return `Selected bidder budgets at ${formatNumber((pair[j] as any)[paramAbbr].value_pct)}%`
          }
          else if (paramAbbr==='BidderInitEP') {
            return `Selected bidder EPs at ${formatNumber((pair[j] as any)[paramAbbr].value_pct)}%`
          }
          else if (paramAbbr==='Priority') {
            return `Selected init pkg priorities at ${formatNumber((pair[j] as any)[paramAbbr].value_pct)}%`
          }
          else if (paramAbbr==='Price') {
            return `Selected init pkg prices at ${formatNumber((pair[j] as any)[paramAbbr].value_pct)}%`
          }
          else if (paramAbbr==='DMP') {
            return `Selected init pkg $/MHz./pop at ${formatNumber((pair[j] as any)[paramAbbr].value_pct)}%`
          }
          else if (paramAbbr==='Budget') {
            return `Selected init pkg budgets at ${formatNumber((pair[j] as any)[paramAbbr].value_pct)}%`
          }
          else {
            return ''
          }
        });
        allParamDescs.push(paramDescs.join('\n'));
      });

      return allParamDescs
    }
    else {
      return ['', '']
    }
  }, [variantParamValues]);

  return (<>{yAxis.length>0 && <Line
        data={{
          labels: xAxis.map((x: number, i: number)=>'Variant ' + x + '\n' + variantDescriptions[i]),
          datasets: [
            {
              label: 'Current Variation',
              data: yAxis,
              fill: false,
              borderColor: '#2d4f6f',
              borderDash: [6],
              pointRadius: 4,
              pointBorderWidth: 1,
              pointBackgroundColor: '#2d4f6f',
              tension: 0.1
            },
            {
              label: `Parent Value (${formatNumber(parentValue, true)})`,
              data: yAxis.map(y=>parentValue),
              fill: false,
              borderColor: '#8D2C2F',
              pointRadius: 0,
              tension: 0.1
            }
          ]
        }}
        options={options}
      />}</>)
}

export default VariationGraph;