import { memo, useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, ButtonToolbar, Card, Col, Container, DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { formatNumber, range } from "../../Helpers";
import MySpinner from "../../MySpinner";
import { DynamicTable } from "../global/DynamicTable";
import IconWrapper from "../global/IconWrapper";

interface DataTableItem {
  round_num: number;
  product_name: string;
  supply: number;
  agg_demand: [number, number];
  excess_demand: [number, number];
  start_price: [number, number];
  clock_price: [number, number];
  posted_price: [number, number];
  pop_dollar: [number, number];
}

const AuctionHistory = (props: any) => {
  const currentBidder: Bidder|null = props.currentBidder;
  const auction: Auction = props.auction;
  const productsDict = props.productsDict;

  const pricesFetch: Price[] = props.pricesFetch;
  const pricesFetchLoading = props.pricesFetchLoading;

  const [selectedRound, setSelectedRound] = useState<number>(0);

  const [priceHistoryDataTable, setPriceHistoryDataTable] = useState<any>({});
  const columns = [
    {
      title: 'Round',
      key: 'round_num',
      sort: (a:DataTableItem, b:DataTableItem)=>a.round_num-b.round_num
    },
    {
      title: 'Lot',
      key: 'product_name',
      sort: (a:DataTableItem, b:DataTableItem) => a.product_name<b.product_name?1:(a.product_name>b.product_name?-1:0),
      searchable: true
    },
    {
      title: 'Max Supply',
      key: 'supply',
      width: 40
    },
    {
      title: 'Agg. Demand',
      key: 'agg_demand',
      width: 40,
      render: (_: any, item:DataTableItem) => <span className={item.agg_demand[1]>=0&&item.agg_demand[0]-item.agg_demand[1]>0?'text-success':(item.agg_demand[0]-item.agg_demand[1]<0?'text-danger':undefined)}>{item.agg_demand[0]<0?'N/A':`${item.agg_demand[0]}${item.agg_demand[1]>=0?` (${item.agg_demand[0]-item.agg_demand[1]})`:''}`}</span>
    },
    {
      title: 'Start Price',
      key: 'start_price',
      width: 100,
      render: (_: any, item:DataTableItem) => <span>{formatNumber(item.start_price[0], true)}</span>,
      sort: (a:DataTableItem, b:DataTableItem) => a.clock_price[0]-b.clock_price[0]
    },
    {
      title: 'Clock Price',
      key: 'clock_price',
      width: 100,
      render: (_: any, item:DataTableItem) => <span>{formatNumber(item.clock_price[0], true)}</span>,
      sort: (a:DataTableItem, b:DataTableItem) => a.clock_price[0]-b.clock_price[0]
    },
    {
      title: 'Posted Price',
      key: 'posted_price',
      width: 100,
      render: (_: any, item:DataTableItem) => <span>{formatNumber(item.posted_price[0], true)}</span>,
      sort: (a:DataTableItem, b:DataTableItem) => a.posted_price[0]-b.posted_price[0]
    },
    {
      title: '$/MHz/pop',
      key: 'pop_dollar',
      width: 40,
      sort: (a:DataTableItem, b:DataTableItem) => a.pop_dollar[0]-b.pop_dollar[0],
      render: (_: any, { pop_dollar }: any) => <span className={pop_dollar[1]>=0&&pop_dollar[0]-pop_dollar[1]>0?'text-success':(pop_dollar[0]-pop_dollar[1]<0?'text-danger':undefined)}>{pop_dollar[0]<0?'N/A':`${formatNumber(pop_dollar[0], true)}${pop_dollar[1]>=0?` (${formatNumber(pop_dollar[0]-pop_dollar[1], true)})`:''}`}</span>
    }
  ];

  const handleClickRound = (r: number) => {
    setSelectedRound(r);
  }

  const getPriceObj = (productCode: string, round: number): Price|undefined => {
    const foundPrice = pricesFetch.find((priceObj:Price)=>priceObj.product_code === productCode && priceObj.round_num === round);
    return foundPrice
  }
  const getExcessDemand = (productCode: string, round: number) => {
    const foundPrice = getPriceObj(productCode, round);
    if (foundPrice) {
      const category: 'sap'|'open' = foundPrice.set_aside ? 'sap' : 'open';
      if (foundPrice.agg_demand > 0) {
        return foundPrice.agg_demand - productsDict[productCode][category].max_supply
      }
      else {
        return -1
      }
    }
    else {
      return -1
    }
  }
  const getPopDollar = (productCode: string, round: number) => {
    const foundPrice = getPriceObj(productCode, round);
    if (foundPrice) {
      const category: 'sap'|'open' = foundPrice.set_aside ? 'sap' : 'open';
      const population = productsDict[productCode][category].population;
      const postedPrice = foundPrice.posted_price as number;
      return (postedPrice / productsDict[productCode][category].size / population)
    }
    else {
      return -1 // Price does not exist
    }
  }

  const [isIncludingZeros, setIsIncludingZeros] = useState(false);

  const priceHistoryArray: DataTableItem[] = useMemo(()=>{
    if (pricesFetch && props.pricesComputationBegun) {
      let priceHistoryValues: DataTableItem[] = [];
      console.log('AuctionHistory: loading prices table');
      pricesFetch
        .sort((priceObjA: Price, priceObjB: Price) => {
            // show ascending from Round 1
            if (priceObjA.round_num < priceObjB.round_num) {
              return -1;
            }
            if (priceObjA.round_num > priceObjB.round_num) {
              return 1;
            }
            // a must be equal to b
            return priceObjA.product_code.localeCompare(priceObjB.product_code)
        })
        .forEach((priceObj: Price) => {
          const product: SpecificProduct = productsDict[priceObj.product_code][priceObj.set_aside ? 'sap' : 'open'];
          const currentRound = priceObj.round_num;

          const aggDemand: [number, number] = currentRound>1 ? [priceObj.agg_demand, priceObj.agg_demand_prev] : [priceObj.agg_demand, -1];
          const excessDemand: [number, number] = currentRound>1 ? [getExcessDemand(priceObj.product_code, currentRound), getExcessDemand(priceObj.product_code, currentRound-1)] : [getExcessDemand(priceObj.product_code, currentRound), -1];
          const startPrice: [number, number] = currentRound>1 ? [priceObj.start_price, (getPriceObj(priceObj.product_code, currentRound-1) as Price).start_price] : [priceObj.start_price, -1];
          const clockPrice: [number, number] = currentRound>1 ? [priceObj.clock_price, (getPriceObj(priceObj.product_code, currentRound-1) as Price).clock_price] : [priceObj.clock_price, -1];
          const postedPrice: [number, number] = currentRound>1 ? [priceObj.posted_price, (getPriceObj(priceObj.product_code, currentRound-1) as Price).posted_price] : [priceObj.posted_price, -1];
          const popDollar: [number, number] = currentRound>1 ? [getPopDollar(priceObj.product_code, priceObj.round_num), getPopDollar(priceObj.product_code, currentRound-1)] : [getPopDollar(priceObj.product_code, priceObj.round_num), -1];

          priceHistoryValues.push({
            round_num: priceObj.round_num,
            product_name: product.name,
            supply: product.max_supply,
            agg_demand: aggDemand,
            excess_demand: excessDemand,
            pop_dollar: popDollar,
            start_price: startPrice,
            clock_price: clockPrice,
            posted_price: postedPrice
          });
        })
      return priceHistoryValues
    }
    else {
      return []
    }
  }, [pricesFetch, props.pricesComputationBegun]);

  useEffect(()=>{
    if (priceHistoryArray.length > 0) {
      props.setPricesComputationComplete(true);
      console.log('AuctionHistory: completed loading prices table');
    }
    setPriceHistoryDataTable({
      columns: columns,
      data: priceHistoryArray
    });
  },[priceHistoryArray]);

  return (
      <Container className='mt-3'>
        {pricesFetchLoading && <MySpinner />}
        {!pricesFetchLoading && !props.loadingPricesTable && pricesFetch &&
          <>
            <ButtonToolbar className='my-1 justify-content-end'>
              <ButtonGroup className='mx-1'>
                <Button variant='secondary' size='sm' disabled>{auction.ended ? 'Total rounds: ' : 'Rounds available: '}{auction.round_num}</Button>
              </ButtonGroup>
              <ButtonGroup className='mx-1'>
                <Button variant='secondary' size='sm' disabled>Round:</Button>
                <DropdownButton
                  as={ButtonGroup}
                  title={selectedRound ? selectedRound : 'All'}
                >
                  <DropdownItem key={0} onClick={() => handleClickRound(0)}>All</DropdownItem>
                  {range(auction.round_num).reverse().map((r: number) => <DropdownItem key={r+1} onClick={() => handleClickRound(r+1)}>{r+1}</DropdownItem>)}
                </DropdownButton>
              </ButtonGroup>
              <ButtonGroup className='mx-1'>
                <Button
                  onClick={selectedRound?()=>setSelectedRound(selectedRound-1):undefined}
                  disabled={!selectedRound||selectedRound===1}
                  >
                  <IconWrapper>
                    <HiChevronLeft />
                  </IconWrapper>
                </Button>
                <Button
                  onClick={selectedRound?()=>setSelectedRound(selectedRound+1):undefined}
                  disabled={!selectedRound||selectedRound===auction.round_num}
                  >
                  <IconWrapper>
                    <HiChevronRight />
                  </IconWrapper>
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
            <Card className='prices-history-card'>
              <Card.Body>
                <Card.Title>
                  {!currentBidder &&
                  <>Prices for {selectedRound ? `Round ${selectedRound}` : 'All Rounds'}</>
                  }
                  {currentBidder &&
                    <>Prices for <strong>{currentBidder.name}</strong> Products: {selectedRound ? `Round ${selectedRound}` : 'All Rounds'}</>}
                </Card.Title>
                <Container>
                  {priceHistoryArray.length > 0 && !props.loadingPricesTable &&
                  <>
                    <div className='my-1 w-100 d-flex justify-content-end'>
                      <div className='mx-1 p-1'>
                        <small>
                          Values in parentheses (...) indicate change from previous round
                        </small>
                      </div>
                      <ButtonGroup className='mx-1'>
                        <Button
                          variant={isIncludingZeros ? 'primary' : 'outline-secondary'}
                          size='sm'
                          onClick={()=>setIsIncludingZeros(!isIncludingZeros)}
                        >
                          Include zero agg. demand
                        </Button>
                      </ButtonGroup>
                    </div>
                    <DynamicTable
                      data={
                        priceHistoryDataTable.data
                          .filter((item:DataTableItem)=>{
                            if (selectedRound===0) {
                              return isIncludingZeros?true:item.agg_demand[0]>0
                            }
                            else {
                              return item.round_num===selectedRound&&(isIncludingZeros?true:item.agg_demand[0]>0)
                            }
                          })
                        }
                      columns={priceHistoryDataTable.columns}
                      pagination={15}
                    />
                  </>}
                  {props.pricesComputationComplete &&  priceHistoryArray.length === 0 && <p className='text-center my-4'>No prices found.</p>}
                </Container>
              </Card.Body>
            </Card>
          </>
        }
      </Container>
  );
};

AuctionHistory.displayName = 'AuctionHistory';


export default memo(AuctionHistory);
