import { useEffect, useState } from "react";
import { Card, Container, Form, InputGroup, Nav } from "react-bootstrap";
import { formatDateCode, formatNumber } from "../../Helpers";
import ErrorsModal from "../global/ErrorsModal";
import PendingButton from "../global/PendingButton";
import VariationSummary from "../global/VariationSummary";
import ParameterSelection from "./ParameterSelection";

const NewVariantCP = (props:any) => {
  const auction: Auction = props.auction;
  // const [selectedParams, setSelectedParams] = useState<[string, string]>(['', '']);
  const [currentView, setCurrentView] = useState('0');
  const [isParamsReady, setIsParamsReady] = useState<[boolean, boolean]>([false, false]);
  const [currentParam, setCurrentParam] = useState<number>(0);
  // const [paramConfigs, setParamConfigs] = useState<[any, any]>([null, null]); // for front-end
  const [paramValues, setParamValues] = useState<[RangeParam, RangeParam]>(["NoParam", "NoParam"]); // for back-end
  const [numSimulations, setNumSimulations] = useState<[number, number]>([1,1]);
  const [currentlySubmitting, setCurrentlySubmitting] = useState(false);
  const [allErrors, setAllErrors] = useState<string[]>([]);
  const [showErrorsModal, setShowErrorsModal] = useState(false);

  const [variationName, setVariationName] = useState<string>(`${auction.name} - Variation ${formatDateCode(Date.now())}`);
  const [roundNum, setRoundNum] = useState(1);


  const PARAMETERS = [
    {
      'name': 'Auction: Increment Percentage',
      'abbr': 'IncPct',
      'explanation': 'Vary the increment percentage of the auction. Useful in determining how fast an auction concludes.'
    },
    {
      'name': 'Auction: Cross-band Spectrum Cap',
      'abbr': 'SpectrumCap',
      'explanation': 'Vary the spectrum cap of the auction. Useful in determining if pro-competitive measures are effective.'
    },
    {
      'name': 'Bidder Set: Budget',
      'abbr': 'BidderBudget',
      'explanation': "Vary the budget across a set of bidders (may also be a single bidder)."
    },
    {
      'name': 'Bidder Set: Initial EP',
      'abbr': 'BidderInitEP',
      'explanation': "Vary the initial EP across a set of bidders (may also be a single bidder)."
    },
    {
      'name': 'Product Set: Priority',
      'abbr': 'Priority',
      'explanation': "Vary the priority across a set of products in bidder initial packages."
    },
    {
      'name': 'Product Set: Budget',
      'abbr': 'Budget',
      'explanation': "Vary the budget across a set of product in bidder initial packages."
    },
    {
      'name': 'Product Set: Max Price per Block',
      'abbr': 'Price',
      'explanation': "Vary the maximum price per block across a set of products in bidder initial packages."
    },
    {
      'name': 'Product Set: $/MHz./pop. Demand Curve',
      'abbr': 'DMP',
      'explanation': "Vary the change in maximum $/MHz/pop. across a set of products in bidder initial packages."
    }
  ];

  const handleClickParamContinue = () => {
    setCurrentView((parseInt(currentView)+1).toString());
  }

  const handleSaveAndContinue = (isValid: boolean, paramValue: RangeParam, numSims: number, whichParam: number) => {
    const newParamsReady: [boolean, boolean] = [...isParamsReady];
    const newNumSimulations: [number, number] = [...numSimulations];
    const newParamValues: [any, any] = [...paramValues];

    newParamsReady[currentParam] = isValid;
    newNumSimulations[currentParam] = numSims;
    newParamValues[currentParam] = paramValue;

    setIsParamsReady(newParamsReady);
    setNumSimulations(newNumSimulations);
    setParamValues(newParamValues);

    // Proceed to next tab

    const intendedIndex = whichParam+1;

    if (intendedIndex!==3) {
      setCurrentView(`${whichParam+1}`);
    }
  }

  const handleSubmitNewVariation = () => {
    const newVariationObj: NewVariation = {
        auction_id: auction.id,
        name: variationName,
        start_round_num: roundNum,
        rp1: paramValues[0],
        rp2: paramValues[1],
    };

    // Validate input before making the request
    const errors: string[] = [];

    if (!variationName || variationName.length > 256) {
        errors.push('Variation name must be non-empty and within 256 characters.');
    }
    if (!roundNum || roundNum < 1 || roundNum > auction.round_num) {
        errors.push(`Round number must be between 1 and ${auction.round_num}, inclusive.`);
    }
    if (!paramValues[0] || paramValues[0] === "NoParam") {
        errors.push('Parameter 1 is required.');
    }
    if (!paramValues[1] || paramValues[1] === "NoParam") {
        errors.push('Parameter 2 is required.');
    }

    if (errors.length > 0) {
        setAllErrors(errors);
        setShowErrorsModal(true);
        return;
    }

    console.log('submitting POST', newVariationObj);

    setCurrentlySubmitting(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/variations/new`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newVariationObj),
    })
        .then((res) => {
            if (!res.ok) {
                return res.json().then((jsonData) => {
                    const errorMessage = jsonData.message || `${res.status}: ${res.statusText}`;
                    throw new Error(errorMessage);
                });
            }
        })
        .then(() => {
            console.log('Successfully sent as POST');
            window.location.replace('/web/variations/queue');
        })
        .catch((err) => {
            console.error('Error submitting new variation:', err.message);
            setAllErrors([`${err.message}`]);
            setShowErrorsModal(true);
            setCurrentlySubmitting(false);
        });
};

  useEffect(()=>{
    if (currentView!=='2') {
      setCurrentParam(parseInt(currentView));
    }
  }, [currentView]);

  useEffect(()=>{
    console.log('current params:');
    console.log(paramValues);
  }, [paramValues]);

  return (
    <Container fluid>
      <Card>
        <Card.Header>
          <Nav
            variant="tabs"
            activeKey={currentView}
            onSelect={(k)=>setCurrentView(k as string)}
            fill
            >
            {['Parameter 1', 'Parameter 2', 'Confirm'].map((b: string, i: number)=>
              <Nav.Item key={b}>
                <Nav.Link
                  eventKey={i}
                  disabled={((b==='Confirm' && !isParamsReady.every((b:boolean)=>!!b))||((b==='Parameter 2' && !isParamsReady[0])))}
                  >
                  {b}
                </Nav.Link>
              </Nav.Item>)}
          </Nav>
        </Card.Header>
        {(currentView==='0' || currentView==='1') &&
          <ParameterSelection
            auction={auction}
            parameters={PARAMETERS}
            paramValues={paramValues}
            isParamsReady={isParamsReady}
            whichParam={currentParam}
            // handleSelectParameter={handleSelectParameter}
            handleSaveAndContinue={handleSaveAndContinue}
            handleClickParamContinue={handleClickParamContinue}
          />

        }
        {currentView==='2' &&
        <Card.Body>
          <div className='mb-3'>
            <Form.Label htmlFor='variation-name'>Name this variation:</Form.Label>
            <InputGroup
              style={{ maxWidth: '420px' }}
              className='mb-3'
            >
              <Form.Control
                id='variation-name'
                value={variationName}
                aria-label="Variation Name"
                onChange={e=>setVariationName(e.target.value)}
              />
            </InputGroup>
            <Form.Label htmlFor='round-num'>Enter round from which variant auctions will begin (between <strong>1 and {auction.round_num}</strong> inclusive):</Form.Label>
            <InputGroup
              style={{ maxWidth: '240px' }}
            >
              <Form.Control
                id='round-num'
                value={roundNum}
                min={1}
                max={auction.round_num}
                type='number'
                step={1}
                isInvalid={!roundNum || roundNum < 1 || roundNum > auction.round_num}
                onChange={e=>setRoundNum(parseInt(e.target.value))}
                aria-label="Round Number"
              />
            </InputGroup>

          </div>

          <p>
            <span className='fw-bold'>{formatNumber(numSimulations[0]*numSimulations[1])} total variant simulations</span> expected with combinations of the following parameters:
          </p>
          <div className='justify-content-center d-flex'>
            <VariationSummary className='mb-3' rps={paramValues} parentAuction={auction} />
          </div>
          <div className='d-flex flex-column align-self-center justify-content-center'>
            <div className='d-flex justify-content-center'>
              <PendingButton
                disabled={isParamsReady.includes(false) || !roundNum || roundNum < 1 || roundNum > auction.round_num}
                onClick={()=>handleSubmitNewVariation()}
                pending={currentlySubmitting}
              >
                Confirm
              </PendingButton>
            </div>
            <div className='d-flex justify-content-center'>
              <div className='text-center p-2'>
                <small>The variation will be queued for simulation immediately after you click Confirm</small>
              </div>
            </div>
          </div>
        </Card.Body>
        }
        <Card.Footer>
          <div className='text-end'>
            {(isParamsReady[0] || isParamsReady[1]) &&
              <>
                Expected Number of Variants: <span className='fw-bold'>{formatNumber(numSimulations[0]*numSimulations[1])}</span>
              </>
            }
          </div>
        </Card.Footer>
      </Card>
      <ErrorsModal
        errorsList={allErrors}
        show={showErrorsModal}
        onHide={()=>setShowErrorsModal(false)}
      />
    </Container>
    )
}

export default NewVariantCP;