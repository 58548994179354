import { useEffect, useMemo, useState } from "react";
import { Alert, Button, ButtonGroup, Col, Form, InputGroup, ListGroup, Row } from "react-bootstrap";
import MySpinner from "../../MySpinner";
import IconWrapper from "../global/IconWrapper";
import { IoDuplicate, IoTrashBin } from 'react-icons/io5';
import { BsPencilSquare } from 'react-icons/bs';
import AuctionPauseCP from "./AuctionPauseCP";
import { Link } from "react-router-dom";
import PendingButton from "../global/PendingButton";
import OffcanvasPane from "../global/OffcanvasPane";
import FileEditor from "./FileEditor";
import CopyAuctionPane from "./CopyAuctionPane";
import useFetch from "../../useFetch";
import TooltipIcon from "../global/TooltipIcon";
import { HiChevronDoubleRight, HiDesktopComputer, HiOutlineSearch, HiOutlineStar } from "react-icons/hi";
import { DEFAULT_AB_PRICE_MULTIPLIER, formatNumber, initPkgSort } from "../../Helpers";
import DemandCurve from "../variantCP/DemandCurve";
import EditBidder from "./EditBidder";
import NamedBidder from "../global/NamedBidder";

const AuctionSummary = (props:any) => {
  const auction: Auction = props.auction;
  // const [findingAuction, setFindingAuction] = useState(true);

  const { data: bidders, isPending: biddersLoading, error: biddersError } = useFetch(process.env.REACT_APP_API_URL + '/api/bidders/list/' + auction.id);
  const { data: products, isPending: productsLoading, error: productsError } = useFetch(`${process.env.REACT_APP_API_URL}/api/products/list`);

  const [selectedBidder, setSelectedBidder] = useState<Bidder|null>(null);
  const [selectedBidderInitPkgSet, setSelectedBidderInitPkgSet] = useState<ABConfig[]|null>(null);
  const [selectedBidderInitPkgSetLoading, setSelectedBidderInitPkgSetLoading] = useState(false);
  const [selectedBidderInitPkgSetError, setSelectedBidderInitPkgSetError] = useState<string|null>(null);

  const [forceDelete, setForceDelete] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(true);
  const [showForceDeleteWarning, setShowForceDeleteWarning] = useState(true);

  const [selectedInitPkg, setSelectedInitPkg] = useState<ABConfig|null>(null);
  const [initPkgSearch, setInitPkgSearch] = useState('');
  const [foundInitPkgs, setFoundInitPkgs] = useState<ABConfig[]>([]);

  const numInitPkgValues = useMemo(()=>{
    if (selectedInitPkg) {
      return [selectedInitPkg.max_dmp_q1, selectedInitPkg.max_dmp_q2, selectedInitPkg.max_dmp_q3, selectedInitPkg.max_dmp_q4, selectedInitPkg.max_dmp_q5].filter(x=>!!x).length
    }
    else {
      return 0
    }
  }, [selectedInitPkg]);

  const fetchInitPkgsByBidder = (auctionId:number, bidderId:number) => {
    setSelectedBidderInitPkgSetLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/abconfigs/get/${auction.id}?bidder_id=${bidderId}`)
      .then(res => {
        if(!res.ok) {
          if (res.status ===400) {
            return res.text().then(text => { throw new Error(text) })
          }
          else {
            throw new Error(`${res.status} error: ${res.statusText}`);
          }
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setSelectedBidderInitPkgSet(data);
        console.log(`fetched init pkgs for Auction ${auctionId} Biddeer ${bidderId}`);
        setSelectedBidderInitPkgSetLoading(false);
      })
      .catch(err => {
        setSelectedBidderInitPkgSetLoading(true);
        setSelectedBidderInitPkgSetError(err.message);
      });
  }

  const filterInitPkgs: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const keyword: string = e.currentTarget.value;

    setInitPkgSearch(keyword);

    if (selectedBidderInitPkgSet) {
      let results: ABConfig[];
      if (keyword !== '') {
          results = selectedBidderInitPkgSet.filter((ip: ABConfig) => {
            const product: string = products[ip.product_code].open?.name || products[ip.product_code].sap?.name;
            return product.startsWith(keyword.toLowerCase()) || product.toLowerCase().includes(keyword.toLowerCase());
          });
      }
      else {
        results = selectedBidderInitPkgSet;
      }
      setFoundInitPkgs(results.sort(initPkgSort));
    }
  }

  useEffect(()=>{
    if (selectedBidderInitPkgSet) {
      setFoundInitPkgs(selectedBidderInitPkgSet.sort(initPkgSort));
    }
  }, [selectedBidderInitPkgSet]);

  useEffect(()=>{
    if (auction && selectedBidder) {
      fetchInitPkgsByBidder(auction.id, selectedBidder.id);
    }
  }, [selectedBidder]);

  const fetchAuctions = props.fetchAuctions;

  const [showEditAuctionPane, setShowEditAuctionPane] = useState(false);
  const [showDuplicateAuctionPane, setShowDuplicateAuctionPane] = useState(false);
  const [showDeleteAuctionPane, setShowDeleteAuctionPane] = useState(false);

  const [deleteAuctionPending, setDeleteAuctionPending] = useState(false);
  const [deleteAuctionError, setDeleteAuctionError] = useState<string|null>(null);

  const { data: variations, isPending: variationsLoading, error: variationsLoadError } = useFetch(`${process.env.REACT_APP_API_URL}/api/variations/list?auction_id=${auction.id}`);

  const isAllAutobidder = useMemo(()=>{
    if (auction) {
      return auction.autobidder_count===auction.bidder_count
    }
    else {
      return false
    }
  }, [auction]);

  const [bidderToEdit, setBidderToEdit] = useState<Bidder|null>(null);
  const [showEditBidderPane, setShowEditBidderPane] = useState(false);

  const handleClickEditBidder = (bidder: Bidder) => {
    setShowEditBidderPane(true);
    setBidderToEdit(bidder);
  }

  const handleSubmitDeleteAuction = () => {
    const deleteAuction: Auction = { ...auction };
    setDeleteAuctionPending(true);
    setDeleteAuctionError(null);

    if (deleteAuction) {
        fetch(
            `${process.env.REACT_APP_API_URL}/api/auctions/delete/${deleteAuction.id}?delete_variations=${JSON.stringify(forceDelete)}`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            }
        )
            .then(res => {
                if (!res.ok) {
                    return res.json().then(jsonData => {
                        const errorMessage = jsonData.message || `${res.status}: ${res.statusText}`;
                        throw new Error(errorMessage);
                    });
                }
            })
            .then(() => {
                console.log('Deleted auction ' + deleteAuction.id);
                setShowDeleteAuctionPane(false);
                setDeleteAuctionError(null);
                fetchAuctions();
                setDeleteAuctionPending(false);
            })
            .catch(err => {
                setDeleteAuctionError(err.message);
                setDeleteAuctionPending(false);
                console.error('Error deleting auction:', err.message);
            });
    }
  }

  useEffect(()=>{
    if (showDeleteAuctionPane) {
      setShowDeleteWarning(true);
      setShowForceDeleteWarning(true);
    }
  }, [showDeleteAuctionPane]);

  // useEffect(()=>{
  //   console.log('re-rendering AuctionSummary')
  // }, [auction]);

  return (
    <>
      {auction && <>
      <div className='d-flex mb-1 mb-lg-2'>
        <div className='flex-grow-1'>
          <h6 className='text-start my-2 me-2'>
            <span className='me-3'>Auction Info: <strong>{auction.name}</strong></span>
            {props.isAdmin && <a href='#' onClick={() => setShowEditAuctionPane(true)}>
              <BsPencilSquare size={20} className='ms-2' />
            </a>}
          </h6>
        </div>
        <div>
          <ListGroup horizontal>
            <ListGroup.Item className='px-2 py-0 my-0' style={{ border: '0' }}>
              <small>
                <span className='me-2 align-middle'>EP %:</span>
                <span className='fw-bold align-middle'>{auction.ep_pct}%</span>
                {props.isAdmin && auction.status!=='Ended' && <a href='#' onClick={() => setShowEditAuctionPane(true)}>
                  <BsPencilSquare className='ms-2' />
                </a>}
              </small>
            </ListGroup.Item>
            <ListGroup.Item className='px-2 py-0 my-0' style={{ border: '0' }}>
              <small>
                <span className='me-2 align-middle'>Incr. %:</span>
                <span className='fw-bold align-middle'>{auction.inc_pct}%</span>
                {props.isAdmin && auction.status!=='Ended' && <a href='#' onClick={() => setShowEditAuctionPane(true)}>
                  <BsPencilSquare className='ms-2' />
                </a>}
              </small>
            </ListGroup.Item>
            <ListGroup.Item className='px-2 py-0 my-0' style={{ border: '0' }}>
              <small>
                <span className='me-2 align-middle'>Spectrum Cap:</span>
                <span className='fw-bold align-middle'>{auction.spectrum_cap} blocks</span>
              </small>
            </ListGroup.Item>
            <ListGroup.Item className='px-2 py-0 my-0' style={{ border: '0' }}>
              <ButtonGroup>
                {props.isAdmin &&
                <>
                  <Button size='sm' variant='outline-primary' onClick={() => setShowDuplicateAuctionPane(true)}>
                    <IconWrapper>
                      <IoDuplicate />
                      <span>Duplicate</span>
                    </IconWrapper>
                  </Button>
                  <Button
                    size='sm'
                    variant='outline-danger'
                    onClick={() => setShowDeleteAuctionPane(true)}
                    disabled={variationsLoading}
                    >
                    <IconWrapper>
                      <IoTrashBin />
                      <span>Delete</span>
                    </IconWrapper>
                  </Button>
                </>
                }
              </ButtonGroup>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>
      <div className='d-flex align-self-stretch px-0 mt-2'>
        <div style={{ width: '15%' }} className='align-self-stretch me-3'>
          <h6 className='text-start'>Bidders</h6>
          <ListGroup className='biddersListGroup overflow-auto'>
            {biddersLoading && <MySpinner />}
            {!biddersLoading && !biddersError && bidders.map((b: Bidder)=>{
              return <ListGroup.Item key={b.id} action onClick={()=>{setSelectedBidder(b); setSelectedInitPkg(null);}} active={!!selectedBidder && selectedBidder.id===b.id}>
                <IconWrapper>
                  <span className='text-start fw-light fs-6'>
                    {b.is_named && <NamedBidder name={b.name} abbr={b.abbr} />}
                    {!b.is_named && <>{b.name}</>}
                  </span>
                  {b.autobidder && <TooltipIcon id={`${b.id}-autobidder`} text='Autobidder'><HiDesktopComputer /></TooltipIcon>}
                  {b.is_named && <TooltipIcon id={`${b.id}-named`} text='Bidder with 3500 MHz holdings'><HiOutlineStar /></TooltipIcon>}
                </IconWrapper>
              </ListGroup.Item>
            })}
          </ListGroup>
        </div>
        <div className='align-self-stretch me-3 flex-grow-1'>
          <Row className='row-cols-2 mb-0'>
            <Col sm={12} className='text-center'>
              <h6>
                <IconWrapper>
                  <span>
                    Bidder Info {selectedBidder && <> (<span className='fw-bolder'>{selectedBidder.is_named ? <NamedBidder name={selectedBidder.name} abbr={selectedBidder.abbr} /> : <>{selectedBidder.name}</>}</span>)</>}
                  </span>
                </IconWrapper>
              </h6>
            </Col>
          </Row>
          <Row className="selectedBidderHeaderInfo">
            {selectedBidder && (props.isAdmin || (props.user && props.user[1] === selectedBidder.name)) && <>
              <Col sm={12} lg={selectedBidder.autobidder ? 4 : 6} className='text-start'>
                <h6 className='fw-light'>
                  {!auction.ended && (props.isAdmin || (props.user && props.user[1] === selectedBidder.name)) &&
                  <IconWrapper className='justify-content-start'>
                    <small>
                      Total Budget:&nbsp;
                      <span className='fw-bold'>{formatNumber(selectedBidder.budget, true)}</span>
                    </small>
                    <Button size='sm' className='ms-1 p-0' variant='link' onClick={() => handleClickEditBidder(selectedBidder)} disabled={auction.status.startsWith('Transient')}><BsPencilSquare className='p-0' /></Button>
                  </IconWrapper>}
                  {(auction.ended || !props.isAdmin || (props.user && props.user[1] !== selectedBidder.name)) &&
                    <small>
                      Total Budget:&nbsp;
                      <span className='fw-bold'>{formatNumber(selectedBidder.budget, true)}</span>
                    </small>
                  }
                </h6>
              </Col>
              {selectedBidder.autobidder && <Col sm={12} lg={4} className='text-center'>
                <h6 className='fw-light'>
                  <small><abbr className='text-decoration-none' title={`This limits the maximum price a bidder will pay for a product to ${selectedBidder.ab_price_multiplier || DEFAULT_AB_PRICE_MULTIPLIER} x Initial Price of Product`}>Autobidder Price Multiplier</abbr>:&nbsp;
                    <span className='fw-bold'>{selectedBidder.ab_price_multiplier ? <span className='fw-bold'>{formatNumber(selectedBidder.ab_price_multiplier, false)}</span> : DEFAULT_AB_PRICE_MULTIPLIER}</span>
                  </small>
                </h6>
              </Col>}
              <Col sm={12} lg={selectedBidder.autobidder ? 4 : 6} className='text-end'>
                <h6 className='fw-light'><small>Initial EP:&nbsp;<span className='fw-bold'>{formatNumber(selectedBidder.init_ep)} EP</span></small></h6>
              </Col>
            </>}
          </Row>
          {selectedBidder && (props.isAdmin || (props.user && props.user[1] === selectedBidder.name)) &&
            <>
              {selectedBidder.autobidder && !selectedBidderInitPkgSetLoading && !selectedBidderInitPkgSetError && !productsLoading && !productsError && selectedBidderInitPkgSet && products &&
                <div className="mb-1">
                  <InputGroup>
                    <InputGroup.Text id="inputGroup-sizing-sm">
                      <IconWrapper>
                        <HiOutlineSearch />
                      </IconWrapper>
                    </InputGroup.Text>
                    <Form.Control
                      aria-label="Search"
                      aria-describedby="inputGroup-sizing-sm"
                      value={initPkgSearch}
                      onChange={filterInitPkgs}
                      type='search'
                    />
                  </InputGroup>
                </div>
              }
              {selectedBidder.autobidder && <ListGroup className='selectedAutoBidderInitPkgsListGroup overflow-auto'>
                {(selectedBidderInitPkgSetLoading || productsLoading) && <MySpinner />}
                {!selectedBidderInitPkgSetLoading && !selectedBidderInitPkgSetError && !productsLoading && !productsError && selectedBidderInitPkgSet && products && foundInitPkgs.map((ip: ABConfig)=>{
                    return <ListGroup.Item key={ip.product_code} action onClick={()=>setSelectedInitPkg(ip)} active={!!selectedInitPkg && selectedInitPkg===ip} className='fw-light text-start'><span className='me-3'>{products[ip.product_code].open?.name || products[ip.product_code].sap?.name}</span></ListGroup.Item>
                  })}
                </ListGroup>
              }
              {selectedBidder.autobidder && !selectedBidderInitPkgSetLoading && !selectedBidderInitPkgSetError && (!selectedBidderInitPkgSet || (selectedBidderInitPkgSet && selectedBidderInitPkgSet.length === 0)) && <Alert><small>No initial package lots available.</small></Alert>}
              {!selectedBidder.autobidder && <Alert><small>No initial package lots available: not an autobidder.</small></Alert>}
              {!productsLoading && productsError && selectedBidder.autobidder && !selectedBidderInitPkgSetLoading && !selectedBidderInitPkgSetError && selectedBidderInitPkgSet && selectedBidderInitPkgSet.length > 0 && <Alert variant='danger'>{productsError}</Alert>}
            </>
          }
          {!selectedBidder && <Alert><small>No bidder selected.<br/>Select bidder at left.</small></Alert>}
          {selectedBidder && !(props.isAdmin || (props.user && props.user[1] === selectedBidder.name)) && <Alert><small>Access to bidder info denied. Login as Admin to access.</small></Alert>}
        </div>
        <div style={{ width: '33%' }} className='align-self-stretch'>
          <h6 className='text-start'>Autobidder Initial Package {selectedInitPkg && products && <> (<span className='fw-bolder'>{products[selectedInitPkg.product_code].open?.name || products[selectedInitPkg.product_code].sap?.name}</span>)</>}</h6>
          {selectedInitPkg && selectedBidder && (props.isAdmin || (props.user && props.user[1] === selectedBidder.name)) &&
          <>
            <Row as='dl' className='row-cols-2 mb-0'>
              <Col as='dt' className='fw-light text-start'>
                <small>Quantity:</small>
              </Col>
              <Col as='dd' className='text-end mb-0'>
                {selectedInitPkg.quantity ?
                  <small><span className='fw-bold'>{selectedInitPkg.quantity}</span> blocks</small>
                  :
                  <small className='fw-light'>Unspecified</small>
                }
              </Col>
            </Row>
            <Row as='dl' className='row-cols-2 mb-0'>
              <Col as='dt' className='fw-light text-start'>
                <small>Priority:</small>
              </Col>
              <Col as='dd' className='text-end mb-0'>
                {selectedInitPkg.priority ?
                  <small className='fw-bold'>{selectedInitPkg.priority}</small>
                  :
                  <small className='fw-light'>Unspecified</small>
                }
              </Col>
            </Row>
            <Row as='dl' className='row-cols-2 mb-0'>
              <Col as='dt' className='fw-light text-start'>
                <small>Max Price per Block:</small>
              </Col>
              <Col as='dd' className='text-end mb-0'>
                {selectedInitPkg.max_price ?
                  <small className='fw-bold'>{formatNumber(selectedInitPkg.max_price, true)}</small>
                  :
                  <small className='fw-light'>Unspecified</small>
                }
              </Col>
            </Row>
            <Row as='dl' className='row-cols-2 mb-0'>
              <Col as='dt' className='fw-light text-start'>
                <small>Product Budget:</small>
              </Col>
              <Col as='dd' className='text-end mb-0'>
                {selectedInitPkg.budget ?
                  <small className='fw-bold'>{formatNumber(selectedInitPkg.budget, true)}</small>
                  :
                  <small className='fw-light'>Unspecified</small>
                }
              </Col>
            </Row>
            <Row className='mt-2 mb-0'>
              <Col>
                <small>Demand Curve</small>
              </Col>
            </Row>
            <Row>
              <Col>
                {numInitPkgValues>0 && numInitPkgValues<3 &&
                  <Alert>
                    <small>
                      Insufficient $/MHz/pop. values specified for graph.<br/>
                      {selectedInitPkg.max_dmp_q1 && <>q1: <strong>{selectedInitPkg.max_dmp_q1}</strong>&nbsp;</>}
                      {selectedInitPkg.max_dmp_q2 && <>q2: <strong>{selectedInitPkg.max_dmp_q2}</strong>&nbsp;</>}
                      {selectedInitPkg.max_dmp_q3 && <>q3: <strong>{selectedInitPkg.max_dmp_q3}</strong>&nbsp;</>}
                      {selectedInitPkg.max_dmp_q4 && <>q4: <strong>{selectedInitPkg.max_dmp_q4}</strong>&nbsp;</>}
                      {selectedInitPkg.max_dmp_q5 && <>q5: <strong>{selectedInitPkg.max_dmp_q5}</strong>&nbsp;</>}
                    </small>
                  </Alert>
                }
                {
                  numInitPkgValues>=3 && <>
                    <DemandCurve
                      demandCurveData={
                        [
                          {label: 'q1', value: selectedInitPkg.max_dmp_q1},
                          {label: 'q2', value: selectedInitPkg.max_dmp_q2},
                          {label: 'q3', value: selectedInitPkg.max_dmp_q3},
                          {label: 'q4', value: selectedInitPkg.max_dmp_q4},
                          {label: 'q5', value: selectedInitPkg.max_dmp_q5}
                        ]
                      }
                    />
                  </>
                }
                {numInitPkgValues===0 && <Alert variant='secondary'><small>No max $/MHz/pop. values specified for this product.</small></Alert>}
              </Col>
            </Row>
          </>
          }
          {selectedBidder && !selectedInitPkg && (props.isAdmin || (props.user && props.user[1] === selectedBidder.name)) && <Alert><small>No initial package selected.<br/>Select initial package at left (if available).</small></Alert>}
          {selectedBidder && !(props.isAdmin || (props.user && props.user[1] === selectedBidder.name)) && <Alert><small>Access to bidder initial package info denied. Login as Admin to access.</small></Alert>}
        </div>
      </div>
      <div
        className='d-flex w-100 py-1 mt-auto text-center justify-content-center'
        style={{
          borderTop: '1px rgba(213,209,213,1) solid'
        }}
        >
        {(auction.status==='Ready'&&auction.autobidder_count===auction.bidder_count) &&
        <div className='d-flex w-100'>
          <div className='align-self-center me-3'>
            <h6 className='text-start m-0'>Auction currently running</h6>
          </div>
        </div>
        }
        {auction.status!=='Ended' && !(auction.status==='Ready'&&auction.autobidder_count===auction.bidder_count) && <AuctionPauseCP auction={auction} fetchAuctions={fetchAuctions} isAdmin={props.isAdmin} />}
        {auction.status==='Ended' &&
        <div className='d-flex w-100 justify-content-end'>
            <div className='align-self-center me-3'>
              <h6 className='text-start m-0'>Variations</h6>
            </div>
            {isAllAutobidder && <>
            <div className='align-self-center me-3'>
              <span className='fw-light mx-3'>
                No. of Variations:
              </span>
              <strong>{!variationsLoading && !variationsLoadError && variations && variations.length}</strong>
              {variationsLoading && <MySpinner />}
              {!variationsLoading && variationsLoadError &&
                <Alert variant='danger'>
                  <small>Error fetching variation info.</small>
                </Alert>
              }
            </div>
            <div className='align-self-center mx-3'>
              <Link to={`/web/variations/${auction.id}`}>
                <Button variant="purple">Manage Variations</Button>
              </Link>
            </div>
            </>}
            {!isAllAutobidder &&
            <div className='align-self-center me-3'>
              <em className='fw-light'>Only all-autobidder auctions can have variations</em>
            </div>
            }
          </div>
        }
      </div>
      <OffcanvasPane
        title='Edit auction'
        onHide={() => setShowEditAuctionPane(false)}
        show={showEditAuctionPane}
        placement='top'
        className='h-50'
        >
        <div className='d-flex w-100 justify-content-center'>
          <div className='w-50' style={{ minWidth: '600px', maxWidth: '800px' }}>
            <FileEditor
              selectedAuction={auction}
              namedBidders={props.namedBidders}
            />
          </div>
        </div>
      </OffcanvasPane>
      <OffcanvasPane
        title='Duplicate auction'
        onHide={() => setShowDuplicateAuctionPane(false)}
        show={showDuplicateAuctionPane}
        placement='top'
        className='h-50'
        >
        <div className='d-flex w-100 justify-content-center'>
          <div className='w-50' style={{ minWidth: '600px', maxWidth: '800px' }}>
            <CopyAuctionPane
              selectedAuction={auction}
            />
          </div>
        </div>
      </OffcanvasPane>
      <OffcanvasPane
        title='Delete auction'
        onHide={() => {setDeleteAuctionError(null); setShowDeleteAuctionPane(false);}}
        show={showDeleteAuctionPane}
        placement='top'
        className='text-center h-50'
        >
        <div className='d-flex w-100 justify-content-center'>
          <div className='w-50' style={{ minWidth: '600px', maxWidth: '800px' }}>
            <p>Are you sure you want to delete <strong>{auction.name}</strong>?</p>
            <Alert show={showDeleteWarning} onClose={()=>setShowDeleteWarning(false)} variant='danger' dismissible><small><strong>Warning:</strong> This action cannot be undone! Please ensure you have copies of the auction info and the config file for this auction before going through with deletion.</small></Alert>
            {variations && variations.length>0 && <div className='text-start mb-3'>
              <Form.Check
                checked={forceDelete}
                onChange={(e)=>setForceDelete(e.target.checked)}
                type='checkbox'
                id='force-delete'
                label={`Click to force delete this auction's ${variations.length} linked variation${variations.length>1?'s':''}`}
                />
            </div>}
            {forceDelete && <Alert show={showForceDeleteWarning} onClose={()=>setShowForceDeleteWarning(false)} variant='danger' dismissible><small><strong>Warning:</strong> This will forcibly delete all variations linked to this auction. Deleted variations cannot be recovered.</small></Alert>}
            <ButtonGroup>
              <PendingButton variant='primary' onClick={handleSubmitDeleteAuction} pending={deleteAuctionPending}>Delete</PendingButton>
              <Button variant='outline-secondary' onClick={() => {setDeleteAuctionError(null); setShowDeleteAuctionPane(false);}}>Cancel</Button>
            </ButtonGroup>
            {deleteAuctionError && <Alert variant='danger' className='mt-3'><Alert.Heading>Error</Alert.Heading>{deleteAuctionError}</Alert>}
          </div>
        </div>
      </OffcanvasPane>
      {showEditBidderPane && bidderToEdit &&
      <OffcanvasPane
        title={`Edit bidder: ${bidderToEdit.name}`}
        onHide={() => setShowEditBidderPane(false)}
        show={showEditBidderPane}
        placement='top'
        className='h-50'
        >
        <div className='d-flex w-100 justify-content-center'>
          <div className='w-50' style={{ minWidth: '600px', maxWidth: '800px' }}>
            <EditBidder
              bidder={bidderToEdit}
            />
          </div>
        </div>
      </OffcanvasPane>
      }
      </>}
    </>
  );
}

export default AuctionSummary;