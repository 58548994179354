import { ChangeEvent, useEffect, useState } from "react";
import { Alert, Button, ButtonGroup, Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import { regionObjSort } from "../../Helpers";
import OffcanvasPane from "../global/OffcanvasPane";
import useFetch from "../../useFetch";

const AuctionRegions = (props: any) => {
  const currentBidder: Bidder = props.currentBidder;
  const [currentDisplayRegions, setCurrentDisplayRegions] = useState<string[]>([]);
  const [currentT2Toggles, setCurrentT2Toggles] = useState<string[]>([]);

  const [showDisplayRegionsPane, setShowSaveDisplayRegionsPane] = useState(false);

  const [submitErrorAlert, setSubmitErrorAlert] = useState('');
  const [showSubmitErrorAlert, setShowSubmitErrorAlert] = useState(false);

  const { data: regionsT2, isPending: regionsT2Loading, error: regionsT2Error }  = useFetch(`${process.env.REACT_APP_API_URL}/api/regionst2/list`);
  const { data: regionsT4, isPending: regionsT4Loading, error: regionsT4Error }  = useFetch(`${process.env.REACT_APP_API_URL}/api/regionst4/list`);

  const NON_DIVIDED_T2S = ['2-018', '2-017'];

  const updateT2Toggles = () => {
    let updatedT2Regions: string[] = currentT2Toggles.filter((r: string) => r.startsWith(NON_DIVIDED_T2S[0]) || r.startsWith(NON_DIVIDED_T2S[1])); // empty except for T2s; any remaining T2s should be empty T2s (with zero T4s)
    // check aginst currentDisplayRegions and rebuild T2 toggles array accordingly
    (Object.values(regionsT2) as SATier2[]).forEach((t2Region: SATier2) => {
      const t4Regions: string[] = t2Region.tier4;
      let numT4sToggled = 0;
      const totalNumT4s = t4Regions.length;

      if (totalNumT4s > 0) { // ignore T2s with zero T4s
        t4Regions.forEach((t4Region: string) => {
          if (currentDisplayRegions.includes(t4Region)) {
            numT4sToggled++;
          }
        });
        if (numT4sToggled == totalNumT4s) {
          updatedT2Regions.push(t2Region.sa);
        }
      }
    });
    setCurrentT2Toggles(updatedT2Regions);
    console.log('toggling T2');
  }

  // const _handleToggleT2 = (regionCode: string, isOn: boolean, toggleAll: boolean) => {
  //   const region: SATier2 = regionsT2[regionCode];
  //   let updatedRegions: string[] = [];

  //   if (toggleAll) {
  //     if (!isOn) {
  //       // toggling T4 regions OFF
  //       // rebuild copy of currentDisplayRegions excluding newly toggled T4 regions
  //       currentDisplayRegions.forEach((code: string) => {
  //         if (!region.tier4.includes(code)) {
  //           updatedRegions.push(code);
  //         }
  //       });
  //     }
  //     else {
  //       // toggling T4 regions ON
  //       updatedRegions = [...currentDisplayRegions];
  //       region.tier4.forEach((t4Code: string) => {
  //         updatedRegions.push(t4Code);
  //       });
  //     }
  //     setCurrentDisplayRegions(updatedRegions);
  //   }
  // }

  const handleToggleT2 = (e: ChangeEvent, regionCode: string) => {
    const isOn = (e.currentTarget as HTMLInputElement).checked;
    const region: SATier2 = regionsT2[regionCode];
    let updatedRegions: string[] = [];

    if (!isOn) { // turn OFF
      if (region.tier4.length == 0) {
        // T2 with no T4s
        setCurrentT2Toggles(currentT2Toggles.filter((r: string) => r !== region.sa));
      }
      else {
        // switch ON all related T4s
        currentDisplayRegions.forEach((code: string) => {
          if (!region.tier4.includes(code)) {
            updatedRegions.push(code);
          }
        });
      }

    }
    else { // turn ON
      if (region.tier4.length===0) {
        // T2 with no T4s
        setCurrentT2Toggles([...currentT2Toggles, region.sa]);
      }
      else {
        // switch OFF all related T4s
        updatedRegions = [...currentDisplayRegions];
        region.tier4.forEach((t4Code: string) => {
          updatedRegions.push(t4Code);
        });
      }
    }
    if (region.tier4.length > 0) {
      setCurrentDisplayRegions(updatedRegions);
    }
  }

  const handleToggleT4 = (e: ChangeEvent, regionCode: string) => {
    const isOn = (e.currentTarget as HTMLInputElement).checked;
    // const region: SATier4 = regionsT4[regionCode];
    let updatedRegions: string[] = [];

    if (!isOn) { // OFF to ON
      currentDisplayRegions.forEach((code: string) => {
        if (code !== regionCode) {
          updatedRegions.push(code);
        }
      });
    }
    else { // ON to OFF
      updatedRegions = [...currentDisplayRegions];
      updatedRegions.push(regionCode);
    }
    setCurrentDisplayRegions(updatedRegions);
  }

  // set both T2s and T4s using mixed T2 and T4 array input allCodes
  const handleSetAll = (allCodes: string[]) => {
    let t4Regions: string[] = [];
    let t2Regions: string[] = [];

    allCodes.forEach((r: string) => {
      if (r.startsWith('2')) {
        t2Regions.push(r);
      }
      else if (r.startsWith('4')) {
        const regionCleaned = r.trim().match(/4-\d+/g);
        const region = regionCleaned ? regionCleaned[0] : null;
        if (region && !t4Regions.includes(region)) {
          t4Regions.push(region);
        }
      }
    })
    setCurrentT2Toggles(t2Regions);
    setCurrentDisplayRegions(t4Regions);
  }

  const submitUpdatedDisplayRegions = () => {
    const editBidderObj: EditBidder = {
      display_regions: currentDisplayRegions,
      budget: currentBidder.budget
    };

    console.log('submitting POST');
    fetch(`${process.env.REACT_APP_API_URL}/api/bidders/edit/${currentBidder.id}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(editBidderObj)
    })
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        // else {
        //   return res.json();
        // }
      })
      .then(data => {console.log('successfully sent as POST'); window.location.reload();})
      .catch(err => {
        console.log(err);
        setSubmitErrorAlert(err.message);
        setShowSubmitErrorAlert(true);
      });
  }

  // useEffect(() => {
  //   console.log('updated currentDisplayRegions');
  //   console.log(currentDisplayRegions);
  // }, [currentDisplayRegions]);

  useEffect(() => {
    // console.log('updated currentDisplayRegions:');
    // console.log(currentDisplayRegions);
    if (regionsT2) {
      updateT2Toggles();
    }
  }, [regionsT2, currentDisplayRegions]);

  useEffect(() => {
    handleSetAll(currentBidder.display_regions);
  }, []);

    return (
      <Container>
        <Container>
          <Form>
            <Row>
              <Col sm={8}>
                <h6 className='mb-2'>Select regions to display in the bidding interface:</h6>
              </Col>
              <Col sm={4}>
                <div className='float-end'>
                  <Button variant='primary' className='my-1' onClick={() => {setShowSaveDisplayRegionsPane(true); setShowSubmitErrorAlert(false);}}>Save</Button>{' '}
                  <Button variant='outline-secondary' className='my-1' onClick={() => handleSetAll(currentBidder.display_regions)}>Reset</Button>{' '}
                  <Button variant='outline-danger' className='my-1' onClick={() => handleSetAll([])}>Deselect all</Button>
                </div>
              </Col>
            </Row>
            <Row className='regions-picker'>
              <Col>
                <div className='mb-2'>Tier 2 <small>(will automatically select or deselect included Tier 4 regions)</small></div>
                {!regionsT2Loading && (Object.values(regionsT2) as SATier2[]).sort(regionObjSort).filter((regionT2: SATier2) => regionT2.tier4.length > 0).map((regionT2: SATier2) =>
                  <Form.Switch
                    id={regionT2.sa}
                    key={regionT2.sa}
                    label={regionT2.name}
                    checked={currentT2Toggles.includes(regionT2.sa)}
                    onChange={(e) => handleToggleT2(e, regionT2.sa)}
                    // onClick={(e) => handleToggleT2(e.currentTarget, regionT2.sa, true)}
                    />
                )}
              </Col>
              <Col>
                <div className='mb-2'>Tier 4</div>
                {!regionsT4Loading && (Object.values(regionsT4) as SATier4[]).sort(regionObjSort).map((regionT4: SATier4) =>
                  <Form.Switch
                    id={regionT4.sa}
                    key={regionT4.sa}
                    label={regionT4.name}
                    checked={currentDisplayRegions.includes(regionT4.sa)}
                    onChange={(e) => handleToggleT4(e, regionT4.sa)}
                    />
                )}
              </Col>
            </Row>
          </Form>
        </Container>
        <OffcanvasPane
          title='Save updated display regions'
          onHide={() => setShowSaveDisplayRegionsPane(false)}
          show={showDisplayRegionsPane}
          placement='top'
          className='h-auto'
          >
            {showSubmitErrorAlert &&
            <Alert variant='danger'>
              {submitErrorAlert}
            </Alert>
            }
            <ButtonGroup className='float-end'>
              <Button variant='primary' onClick={() => submitUpdatedDisplayRegions()}>Confirm</Button>
              <Button variant='outline-secondary' onClick={() => setShowSaveDisplayRegionsPane(false)}>Cancel</Button>
            </ButtonGroup>
            <ul>
              {currentDisplayRegions && regionsT4 && currentDisplayRegions.map((region: string) => {
                const trimmed = region.match(/4\-\d+/g) || [];
                return <li key={region}>{regionsT4[trimmed[0]]?.name}</li>
                // return <li key={region}>{region}</li>
              })}
            </ul>
        </OffcanvasPane>
      </Container>
    );
};

AuctionRegions.displayName = 'AuctionRegions';

export default AuctionRegions;