export const DEFAULT_AB_PRICE_MULTIPLIER = 50;

export const AUCTION_STATUS = {
  'ended': 'COMPLETE',
  'waiting_general': 'Waiting on bidder(s)',
  'waiting': 'Waiting on other bidder(s)',
  'ready': 'Ready for bids',
  'error': 'Error checking auction',
  'paused': 'PAUSED'
}

export const getTextStyle = (currentVal: number, lastRoundVal?: number) => {
    if (!lastRoundVal) {
      return undefined
    }
    else {
      if ((currentVal-lastRoundVal)>0) {
        return 'text-success'
      }
      else if ((currentVal-lastRoundVal)<0) {
        return 'text-danger'
      }
      else {
        return undefined
      }
    }
  }

export const PARAM_DICTIONARY: { [key: string]: Parameter } = {
    'IncPct': {
        'name': 'Auction: Increment Percentage',
        'type': 'Auction',
        'short_name': 'Incr. %',
        'explanation': 'Vary the increment percentage of the auction. Useful in determining how fast an auction concludes.'
    },
    'SpectrumCap': {
        'name': 'Auction: Cross-band Spectrum Cap',
        'type': 'Auction',
        'short_name': 'Spectrum Cap',
        'explanation': 'Vary the spectrum cap of the auction. Useful in determining if pro-competitive measures are effective.'
    },
    'BidderBudget': {
        'name': 'Bidder Set: Budget',
        'type': 'Bidder Set',
        'short_name': 'Budget',
        'explanation': 'Vary the budget across a set of bidders (may also be a single bidder).'
    },
    'BidderInitEP': {
        'name': 'Bidder Set: Initial EP',
        'type': 'Bidder Set',
        'short_name': 'Init. EP',
        'explanation': 'Vary the initial EP across a set of bidders (may also be a single bidder).'
    },
    'Priority': {
        'name': 'Product Set: Priority',
        'type': 'Product Set',
        'short_name': 'Priority',
        'explanation': 'Vary the priority across a set of products in bidder initial packages.'
    },
    'Budget': {
        'name': 'Product Set: Budget',
        'type': 'Product Set',
        'short_name': 'Budget',
        'explanation': 'Vary the budget across a set of product in bidder initial packages.'
    },
    'Price': {
        'name': 'Product Set: Max Price per Block',
        'type': 'Product Set',
        'short_name': 'Price',
        'explanation': 'Vary the maximum price per block across a set of products in bidder initial packages.'
    },
    'DMP': {
        'name': 'Product Set: $/MHz./pop. Demand Curve',
        'type': 'Product Set',
        'short_name': '$/MHz./pop.',
        'explanation': 'Vary the change in maximum $/MHz/pop. across a set of products in bidder initial packages.'
    }
};

export const getParamLabel = (variation: Variation, whichParam: number, biddersDict: { [key: string]: Bidder }) => {
    if (whichParam===0||whichParam===1) {
      let rp: RangeParam = 'NoParam';
      let bidders: string[] = []; // list of bidders varied
      let label: string = '';

      if (whichParam===0 && JSON.parse(variation.rp1)!=='NoParam') {
        rp = JSON.parse(variation.rp1);
      }
      else if (whichParam===1 && JSON.parse(variation.rp2)!=='NoParam') {
        rp = JSON.parse(variation.rp2);
      }

      if (rp!=='NoParam') {
        switch(Object.keys(rp)[0]) {
          case 'BidderBudget':
            bidders = ((rp as BidderBudget)['BidderBudget'] as RangeBdr).bidder_abbrs.map((b:string)=>biddersDict[b].name||b);
            break;
          case 'BidderInitEP':
            bidders = ((rp as BidderInitEP)['BidderInitEP'] as RangeBdr).bidder_abbrs.map((b:string)=>biddersDict[b].name||b);
            break;
          default:
            label = `${PARAM_DICTIONARY[Object.keys(rp)[0]].type} ${whichParam+1}: ${PARAM_DICTIONARY[Object.keys(rp)[0]].short_name}`;
            break;
        }
      }
      if (bidders.length===1) {
        label = `${bidders[0]} ${PARAM_DICTIONARY[Object.keys(rp)[0]].short_name}`;
      }
      else {
        label = `${PARAM_DICTIONARY[Object.keys(rp)[0]].type} ${whichParam+1}: ${PARAM_DICTIONARY[Object.keys(rp)[0]].short_name}`;
      }
      return label
    }
  }

export const getParamSetItems = (variation: Variation, whichParam: number, biddersDict: { [key: string]: Bidder; }, allProductsDict: { [key: string]: GeneralProduct; }) => {
    if (whichParam===0||whichParam===1) {
      let rp: RangeParam = 'NoParam';
      let items: string[] = []; // list of bidders or products varied

      if (whichParam===0 && JSON.parse(variation.rp1)!=='NoParam') {
        rp = JSON.parse(variation.rp1);
      }
      else if (whichParam===1 && JSON.parse(variation.rp2)!=='NoParam') {
        rp = JSON.parse(variation.rp2);
      }

      if (rp!=='NoParam') {
        let firstProduct, displayProduct;
        switch(Object.keys(rp)[0]) {
          case 'BidderBudget':
            items = ((rp as BidderBudget)['BidderBudget'] as RangeBdr).bidder_abbrs.map((b:string)=>biddersDict[b].name||b);
            break;
          case 'BidderInitEP':
            items = ((rp as BidderInitEP)['BidderInitEP'] as RangeBdr).bidder_abbrs.map((b:string)=>biddersDict[b].name||b);
            break;
          case 'Priority':
            firstProduct = ((rp as Priority)['Priority'] as RangeABC).rows[0];
            displayProduct = allProductsDict[firstProduct[1]][firstProduct[2] ? 'sap': 'open'];
            if (displayProduct && ((rp as Priority)['Priority'] as RangeABC).rows.length>1) {
              items = [displayProduct.name, `... and ${((rp as Priority)['Priority'] as RangeABC).rows.length-1} other products`]
            }
            else if (displayProduct && ((rp as Priority)['Priority'] as RangeABC).rows.length===1) {
              items = [displayProduct.name];
            }
            else {
              items = [`${((rp as Priority)['Priority'] as RangeABC).rows.length} products`];
            }
            break;
          case 'Budget':
            firstProduct = ((rp as Budget)['Budget'] as RangeABC).rows[0];
            displayProduct = allProductsDict[firstProduct[1]][firstProduct[2] ? 'sap': 'open'];
            if (displayProduct && ((rp as Budget)['Budget'] as RangeABC).rows.length>1) {
              items = [displayProduct.name, `... and ${((rp as Budget)['Budget'] as RangeABC).rows.length-1} other products`]
            }
            else if (displayProduct && ((rp as Budget)['Budget'] as RangeABC).rows.length===1) {
              items = [displayProduct.name];
            }
            else {
              items = [`${((rp as Budget)['Budget'] as RangeABC).rows.length} products`];
            }
            break;
          case 'Price':
            firstProduct = ((rp as any)['Price'] as RangeABC).rows[0];
            displayProduct = allProductsDict[firstProduct[1]][firstProduct[2] ? 'sap': 'open'];
            if (displayProduct && ((rp as any)['Price'] as RangeABC).rows.length>1) {
              items = [displayProduct.name, `... and ${((rp as any)['Price'] as RangeABC).rows.length-1} other products`]
            }
            else if (displayProduct && ((rp as any)['Price'] as RangeABC).rows.length===1) {
              items = [displayProduct.name];
            }
            else {
              items = [`${((rp as any)['Price'] as RangeABC).rows.length} products`];
            }
            break;
          case 'DMP':
            firstProduct = ((rp as DMP)['DMP'] as RangeABC).rows[0];
            displayProduct = allProductsDict[firstProduct[1]][firstProduct[2] ? 'sap': 'open'];
            if (displayProduct && ((rp as DMP)['DMP'] as RangeABC).rows.length>1) {
              items = [displayProduct.name, `... and ${((rp as DMP)['DMP'] as RangeABC).rows.length-1} other products`]
            }
            else if (displayProduct && ((rp as DMP)['DMP'] as RangeABC).rows.length===1) {
              items = [displayProduct.name];
            }
            else {
              items = [`${((rp as DMP)['DMP'] as RangeABC).rows.length} products`];
            }
            break;
          default:
            items = [];
            break;
        }
      }
      return items
    }
  }

export function getParamUnit(paramAbbr: string) {
  if (paramAbbr==='SpectrumCap') {
    return ' blocks'
  }
  else if (paramAbbr==='IncPct') {
    return '%'
  }
  else {
    return '% of orig.'
  }
}

export function formatDateCode(timestamp: number) {
  const a = Math.floor(timestamp/1000).toString();
  return `${a.slice(2,6)}-${a.slice(6)}`
}

export function parseValueParam(vp: ValueParam) {
  if (vp!=="NoParam") {
    if (Object.keys(vp)[0]==='IncPct') {
      return ((vp as IncPct)['IncPct'] as ValueAuc).value
    }
    else if (Object.keys(vp)[0]==='SpectrumCap') {
      return ((vp as SpectrumCap)['SpectrumCap'] as ValueAuc).value
    }
    else if (Object.keys(vp)[0]==='BidderBudget') {
      return ((vp as BidderBudget)['BidderBudget'] as ValueBdr).value_pct
    }
    else if (Object.keys(vp)[0]==='BidderInitEP') {
      return ((vp as BidderInitEP)['BidderInitEP'] as ValueBdr).value_pct
    }
    else if (Object.keys(vp)[0]==='Priority') {
      return ((vp as Priority)['Priority'] as ValueABC).value_pct
    }
    else if (Object.keys(vp)[0]==='Budget') {
      return ((vp as Budget)['Budget'] as ValueABC).value_pct
    }
    else if (Object.keys(vp)[0]==='DMP') {
      return ((vp as DMP)['DMP'] as ValueABC).value_pct
    }
    else if (Object.keys(vp)[0]==='Price') {
      return ((vp as any)['Price'] as ValueABC).value_pct
    }
  }
}

export function getParentValue(auction: Auction, rp: RangeParam) {
  if (rp!=="NoParam") {
    if (Object.keys(rp)[0]==='IncPct') {
      return auction.inc_pct
    }
    else if (Object.keys(rp)[0]==='SpectrumCap') {
      return auction.spectrum_cap
    }
    else {
      return 100
    }
  }
}

export function simpleCleanDecode(s: string) {
  return s.trim().replaceAll('Ã©', '\u00E9').replaceAll('Ã®','\u00EE').replaceAll('Ã¯','\u00EF').replaceAll('Ã§', '\u00E7').replaceAll('Ãª','\u00EA').replaceAll('Ã¨','\u00E8').replaceAll('ÃŽ','\u00CE').replaceAll('Ãˆ','\u00C8').replaceAll('Ã‰','\u00C9').replaceAll('ÃŠ','\u00CA').replaceAll('Ã¦','\u00E6').replaceAll('Ã\u008e','\u00CE')
}

export function doNotExceed(n: number, max: number) {
  if (n < max) {
    return n
  }
  else {
    return max
  }
}

function min(x: number,y: number,z:number)
{
    if (x <= y && x <= z)
            return x;
        if (y <= x && y <= z)
            return y;
        else
            return z;
}

function getEditDist(str1: string, str2: string, m: number, n: number)
{
    // Create a table to store results of subproblems
        let dp = new Array(m + 1);
        for(let i=0;i<m+1;i++)
        {
            dp[i]=new Array(n+1);
            for(let j=0;j<n+1;j++)
            {
                dp[i][j]=0;
            }
        }

        // Fill d[][] in bottom up manner
        for (let i = 0; i <= m; i++) {
            for (let j = 0; j <= n; j++) {
                // If first string is empty, only option is
                // to insert all characters of second string
                if (i === 0)
                    dp[i][j] = j; // Min. operations = j

                // If second string is empty, only option is
                // to remove all characters of second string
                else if (j === 0)
                    dp[i][j] = i; // Min. operations = i

                // If last characters are same, ignore last
                // char and recur for remaining string
                else if (str1[i - 1]
                         === str2[j - 1])
                    dp[i][j] = dp[i - 1][j - 1];

                // If the last character is different,
                // consider all possibilities and find the
                // minimum
                else
                    dp[i][j] = 1
                               + min(dp[i][j - 1], // Insert
                                     dp[i - 1][j], // Remove
                                     dp[i - 1]
                                       [j - 1]); // Replace
            }
        }

        return dp[m][n];
}

function getSameCount(str1: string, str2: string) {
  let count = 0;
  const obj = str2.split("");
  str1.split('').forEach((str: string) => {
    let idx = obj.findIndex(s => s === str);
    if(idx >= 0){
      count++;
      obj.splice(idx, 1);
    }
  });
  return count;
}

export function getSensibleDuration(s: number, ms?: boolean) {
  if (s > 60) {
    return Math.floor(s / 60) + 'm ' + Math.round(s) % 60 + 's'
  }
  else if (ms && s < 1) {
    return s * 1000 + ' m'
  }
  else {
    return Math.round(s*1000) / 1000 + 's'
  }
}

function isObject(object: any) {
  return object != null && typeof object === 'object';
}

export function isDeepEqual(object1: any, object2: any) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !isDeepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

export function isArrayEqual(a: any[], b: any[]) {
  if (a.length===b.length) {
    if (a.every((val: any, index: number)=>{
      if (Array.isArray(val) && Array.isArray(b[index])) {
        return isArrayEqual(val, b[index])
      }
      else if (!Array.isArray(val) && !Array.isArray(b[index])) {
        return val===b[index]
      }
      else {
        return false
      }
    })) {
      return true
    }
  }
}

// Improve getEditDist by varying required edit distance threshold to count as match
// depending on string lengths. I.e. Shorter strings have stricter comparison
// conditions than longer ones.
export function isLikelyTypo(str1: string, str2: string, m: number, n: number) {
  const editDist = getEditDist(str1, str2, m, n);
  if (editDist === 0) {
    return true
  }
  else {
    if (m < 4 || n < 4) {
      // must require some letter overlap in addition to small edit distance
      return getSameCount(str1, str2) > 2 && editDist < 2
    }
    else if (m < 8 || n < 8) {
      return getSameCount(str1, str2) > 3 && editDist < 3
    }
    else {
      return getSameCount(str1, str2) > 4 && editDist < 4
    }
  }
}

export function range(n: number): number[] {
  return Array.from(Array(n).keys())
}

export function arrayBufferToBase64(buffer: ArrayBuffer) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}

// a bid is [price, num_blocks]
export function maxSpent(bids: [number, number][]): number {
  if (bids.length > 0) {
    return Math.max(...bids.map((bid: [number, number]) => bid[0] * bid[1]))
  }
  else {
    return 0
  }
}

// a bid is [price, num_blocks]
export function maxEp(bids: [number, number][], epCost: number): number {
  if (bids.length > 0) {
    // sort asc by price
    bids.sort((a:[number,number], b:[number,number])=>{
      if (a[0]<b[0]) { return -1 }
      if (a[0]>b[0]) { return 1 }
      else { return 0 }
    }).reverse();
    return bids[0][1] * epCost
  }
  else {
    return 0
  }
}

export function formatNumber(num: number, isDollar?: boolean): string {
  let numString: string;

  if (Math.abs(num % 1) > 0 ) { // don't add .00 for already integer numbers
    const decimalNumberSplit = num.toFixed(2).split('.');
    numString = decimalNumberSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.' + decimalNumberSplit[1];
  }
  else {
    numString = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  if (isDollar) {
    if (num < 0) {
      return `-$${numString.slice(1)}`
    }
    else {
      return `$${numString}`
    }
  }
  else {
    return numString
  }
}

export function cleanNumString(numStr: string): string {
  return numStr.trim().replace(' ', '').replace(',', '').replace('$', '');
}

export function formatPrice(num: string): string {
  return `$${num}`
}

export function auctionSortByName(a: Auction, b: Auction): number {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  // a must be equal to b
  return 0;
}

export function auctionSortByDate(a: Auction, b: Auction): number {
  if (a.created_on < b.created_on) {
    return -1;
  }
  if (a.created_on > b.created_on) {
    return 1;
  }
  // a must be equal to b
  return 0;
}

export function auctionSortByRound(a: Auction, b: Auction): number {
  if (a.round_num < b.round_num) {
    return -1;
  }
  if (a.round_num > b.round_num) {
    return 1;
  }
  // a must be equal to b
  return 0;
}

export function auctionSortByStatus(a: Auction, b: Auction): number {
  if (a.status < b.status) {
    return -1;
  }
  if (a.status > b.status) {
    return 1;
  }
  // a must be equal to b
  return 0;
}

export function namedBidderSort(a: NamedBidder, b: NamedBidder): number {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  // a must be equal to b
  return 0;
}

export function regionObjSort(a: SATier2|SATier4, b: SATier2|SATier4): number {
  if (a.sa < b.sa) {
    return -1;
  }
  if (a.sa > b.sa) {
    return 1;
  }
  // a must be equal to b
  return 0;
}

export function productObjSort(a: SpecificProduct, b: SpecificProduct): number {
  if (a.code < b.code) {
    return -1;
  }
  if (a.code > b.code) {
    return 1;
  }
  // a must be equal to b
  return 0;
}

export function initPkgSort(a: ABConfig, b:ABConfig): number {
  if (a.product_code < b.product_code) {
    return -1;
  }
  if (a.product_code > b.product_code) {
    return 1;
  }
  // a must be equal to b
  return 0;
}

export function bidderObjSort(a: Bidder, b: Bidder): number {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  // a must be equal to b
  return 0;
}

export function userStringTupleSort(a: [string, string], b: [string, string]): number {
  if (a[0] < b[0]) {
    return -1;
  }
  if (a[0] > b[0]) {
    return 1;
  }
  // a must be equal to b
  return 0;
}