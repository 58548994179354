import { useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { HiExternalLink } from "react-icons/hi";
import { Link } from "react-router-dom";
import { formatDateCode } from "../../Helpers";
import MySpinner from "../../MySpinner";
import IconWrapper from "../global/IconWrapper";
import PendingButton from "../global/PendingButton";
import VariationSummary from "../global/VariationSummary";

const NewAuctionFromVariantLink = (props: any) => {
  const v: Variant = props.variant;
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmPending, setConfirmPending] = useState(false);
  const [confirmError, setConfirmError] = useState<string|null>(null);
  const [success, setSuccess] = useState(false);
  const parentAuction: Auction = props.parentAuction;
  const [newAuctionName, setNewAuctionName] = useState(`${parentAuction.name} - ${Object.keys(JSON.parse(v.vp1))[0]} ${JSON.parse(v.vp2)!=='NoParam' ? Object.keys(JSON.parse(v.vp2))[0]  : ''} ${formatDateCode(Date.now())}`);

  const handleConfirm = () => {
    console.log('submitting POST');
    setConfirmPending(true);
    setConfirmError(null);

    // Validate input before submitting
    const errors: string[] = [];

    if (!newAuctionName || newAuctionName.trim() === "") {
        errors.push('Auction name cannot be empty.');
    }
    if (newAuctionName.length > 256) {
        errors.push('Auction name must be within 256 characters.');
    }

    if (errors.length > 0) {
        setConfirmError(errors.join(" "));
        setConfirmPending(false);
        return;
    }

    if (v && newAuctionName) {
        fetch(`${process.env.REACT_APP_API_URL}/api/variants/create_auction/${v.id}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                new_name: newAuctionName.trim(), // Trim whitespace from name
            }),
        })
            .then((res) => {
                if (!res.ok) {
                    return res.json().then((jsonData) => {
                        const errorMessage = jsonData.message || `${res.status}: ${res.statusText}`;
                        throw new Error(errorMessage);
                    });
                }
            })
            .then(() => {
                console.log('created new auction from variant ' + v.var_number);
                setConfirmPending(false);
                setConfirmError(null);
                setSuccess(true);
            })
            .catch((err) => {
                console.error('Error creating new auction:', err.message);
                setConfirmError(err.message);
                setConfirmPending(false);
                setSuccess(false);
            });
    }
};

  return (<>
    <IconWrapper>
      <span>{v.var_number}</span>
      <Button variant='link' className='text-decoration-none p-0 link-info' onClick={()=>setShowConfirm(true)}>
        <HiExternalLink />
      </Button>
    </IconWrapper>
    <Modal size='lg' show={showConfirm} onHide={()=>setShowConfirm(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Create new auction from variant</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-3'>
        {confirmPending && <MySpinner />}
        {!confirmPending && !success && <>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Enter a new name for this auction:</Form.Label>
            <Form.Control
              type="text"
              value={newAuctionName}
              onChange={e=>setNewAuctionName(e.target.value)}
              isInvalid={newAuctionName.length>256} />
          </Form.Group>
          <div className='my-2'>A new all-autobidder auction will be created based on this variant with the following parameters:</div>
          <div className='justify-content-center p-2'>
            <VariationSummary parentAuction={parentAuction} vps={[JSON.parse(v.vp1), JSON.parse(v.vp2)]} />
          </div>
        </>}
        {!confirmPending && !success && !!confirmError && <>
          <Alert variant='danger'>
            <h6>Error</h6>
            <p>{confirmError}</p>
          </Alert>
        </>}
        {!confirmPending && success && <>
          <Alert>
            <h6>Success</h6>
            <p>New auction created.</p>
          </Alert>
        </>}
      </Modal.Body>
      <Modal.Footer>
        {!confirmPending && success && <>
          <Button variant="outline-secondary" onClick={()=>setShowConfirm(false)}>
            Return to Variation Results
          </Button>
          <Link to='/web/auction-cp'>
            <Button variant="secondary" onClick={()=>setShowConfirm(false)}>
              Go to Auctions List
            </Button>
          </Link>
        </>}
        {(confirmPending || !success) && <>
          <Button variant="secondary" onClick={()=>setShowConfirm(false)}>
            Close
          </Button>
          <PendingButton variant="primary" onClick={handleConfirm} pending={confirmPending}>
            Confirm
          </PendingButton>
        </>}
      </Modal.Footer>
    </Modal>
  </>)
}

export default NewAuctionFromVariantLink;