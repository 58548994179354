import { Breadcrumb, Button, Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import ParamBadge from "../global/ParamBadge";
import nesglobe from "../../nesglobe.png";

const VariantResultsNav = (props:any) => {
  const auction: Auction = props.auction;
  const variation: Variation = props.variation;

  return (
    <Navbar expand='lg' bg='light'>
      <Container fluid>
        <Navbar.Brand className='fs-6'>
            <img className='logo' src={nesglobe} alt="NE&S 3800 MHz Clock Auction Simulation Tool" />
        </Navbar.Brand>
        <Navbar.Text>
          <Breadcrumb>
            <Breadcrumb.Item href={`/web/auction-cp`}>
              Auctions List
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/web/variations/${variation.auction_id}`}>
              Variation Manager
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/web/variation-results/${variation.id}`}>
              Variation Results
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <span>Parent Auction: <span className='fw-bold'>{auction.name}</span></span> |&nbsp;
              <span>Variation: <span className='fw-bold'>{variation.name}</span></span>&nbsp;
              <ParamBadge parentAuction={auction} className='mx-4 px-1' title={variation.name} rpSet={[JSON.parse(variation.rp1), JSON.parse(variation.rp2)]} rp={JSON.parse(variation.rp1)} />
              <ParamBadge parentAuction={auction} className='me-2 px-1' title={variation.name} rpSet={[JSON.parse(variation.rp1), JSON.parse(variation.rp2)]} rp={JSON.parse(variation.rp2)} />
            </Breadcrumb.Item>
          </Breadcrumb>
        </Navbar.Text>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          {props.isAdmin &&
          <Navbar.Text className="ms-1">
            <Link to='/web/variations/queue'>
              <Button variant="purple" size="sm">Variations Queue</Button>
            </Link>
          </Navbar.Text>
          }
          <Navbar.Text className="ms-1">
            <Link to={`/web/variations/${variation.auction_id}`}>
              <Button variant="secondary" size="sm">Back</Button>
            </Link>
          </Navbar.Text>
          <Navbar.Text className='ms-1'>
            <Link to='/web' onClick={() => props.handleLogin(null, false)}>
              <Button variant='danger' size='sm'>Logout</Button>
            </Link>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    );
}

export default VariantResultsNav;