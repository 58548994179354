import { useEffect, useState } from "react";
import { Alert, Button, ButtonGroup, Card, Col, Container, Form, InputGroup, ListGroup, Row } from "react-bootstrap";
import { BsPencilSquare } from "react-icons/bs";
import { FaSort } from "react-icons/fa";
import { HiOutlineSearch } from "react-icons/hi";
import { IoTrashBin } from "react-icons/io5";
import { SiAddthis } from "react-icons/si";
import { Link } from "react-router-dom";
import IconWrapper from "../global/IconWrapper";
import OffcanvasPane from "../global/OffcanvasPane";
import ParamBadge from "../global/ParamBadge";
import PendingButton from "../global/PendingButton";
import ErrorsModal from "../global/ErrorsModal";
import NewVariantCP from "./NewVariantCP";
import VariationEditor from "./VariationEditor";

const VariantsList = (props: any) => {
  const auction: Auction = props.auction;
  // const handleSelectVariant = props.handleSelectVariant;
  const variants: Variation[] = props.variants;
  const selectedVariation: Variation = props.selectedVariation;

  const [showNewVariationPane, setShowNewVariationPane] = useState(false);
  const [showEditVariationPane, setShowEditVariationPane] = useState(false);

  const [showDeleteVariationPane, setShowDeleteVariationPane] = useState(false);
  const [deleteVariationPending, setDeleteVariationPending] = useState(false);
  const [deleteVariationError, setDeleteVariationError] = useState<string|null>(null);

  const [currentSort, setCurrentSort] = useState<[string, boolean]>(['created', true]); // [sortType, isAscending]
  const [sortedVariations, setSortedVariations] = useState<Variation[]>(variants);
  const [foundVariations, setFoundVariations] = useState<Variation[]>(variants);
  const [searchTerm, setSearchTerm] = useState('');

  const [errorMessages, setErrorMessages] = useState<string[]>([]); // For ErrorsModal
  const [showErrorsModal, setShowErrorsModal] = useState(false); // ErrorsModal visibility

  const handleCloseErrorsModal = () => setShowErrorsModal(false);

  const handleChangeSort = (sortBy: string) => {
    if (currentSort[0] === sortBy) {
      setCurrentSort([sortBy, !currentSort[1]]);
    }
    else {
      setCurrentSort([sortBy, true]);
    }
  }

  const sortVariations = (sortBy: string, isReverse: boolean) => {
    let allSorted = [...variants];
    let foundSorted = [...foundVariations];

    if (sortBy === 'name') {
      allSorted.sort((v1:Variation, v2:Variation)=>v1.name<v2.name?1:(v1.name>v2.name?-1:0));
      foundSorted.sort((v1:Variation, v2:Variation)=>v1.name<v2.name?1:(v1.name>v2.name?-1:0));
    }
    else if (sortBy === 'created') {
      allSorted.sort((v1:Variation, v2:Variation)=>v1.created_on-v2.created_on);
      foundSorted.sort((v1:Variation, v2:Variation)=>v1.created_on-v2.created_on);
    }

    if (!!isReverse) {
      allSorted.reverse();
      foundSorted.reverse();
    }

    setSortedVariations(allSorted);
    setFoundVariations(foundSorted);
  }

  const filterVariations = (keyword: string) => {
    if (keyword !== '') {
        const results = variants.filter((v: Variation) => {
            return v.name.startsWith(keyword.toLowerCase()) || v.name.toLowerCase().includes(keyword.toLowerCase());
        });
        setFoundVariations(results);
    }
    else {
        setFoundVariations(sortedVariations);
    }
    setSearchTerm(keyword);
  }

  const handleSearchVariations: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const keyword: string = e.currentTarget.value;
    filterVariations(keyword);
  }

  useEffect(()=>{
    // sets foundAuctions
    filterVariations(searchTerm)
  }, [sortedVariations]);
  useEffect(()=>{
    if (variants) {
      sortVariations(currentSort[0], currentSort[1]);
    }
  }, [variants, currentSort]);

  const handleSubmitDeleteVariation = (variationId: number) => {
    setDeleteVariationPending(true);
    setDeleteVariationError(null);
    if (variationId) {
        fetch(`${process.env.REACT_APP_API_URL}/api/variations/delete/${variationId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        })
            .then((res) => {
                if (!res.ok) {
                    return res.json().then((jsonData) => {
                        const errorMessage = jsonData.message || `${res.status}: ${res.statusText}`;
                        throw new Error(errorMessage);
                    });
                }
            })
            .then(() => {
                console.log('Deleted variation');
                setShowDeleteVariationPane(false);
                setDeleteVariationError(null);
                setDeleteVariationPending(false);
                window.location.reload();
            })
            .catch((err) => {
                setDeleteVariationError(err.message);
                setErrorMessages([err.message]); // Set error for ErrorsModal
                setShowErrorsModal(true); // Show ErrorsModal
                setDeleteVariationPending(false);
                console.error("Error deleting variation:", err.message);
            });
    }
};

  return (<>
    <Container className='variantsList text-center h-100' fluid>
      <div className='mb-2 d-flex'>
        <div className='me-1 flex-grow-1'>
          <InputGroup>
            <InputGroup.Text id="inputGroup-sizing-sm">
              <IconWrapper>
                <HiOutlineSearch />
                Search
              </IconWrapper>
            </InputGroup.Text>
            <Form.Control
              aria-label="Search"
              aria-describedby="inputGroup-sizing-sm"
              value={searchTerm}
              onChange={handleSearchVariations}
              type='search'
            />
          </InputGroup>
        </div>
        <div className='ms-1'>
          <PendingButton
            onClick={() => setShowNewVariationPane(true)}
            >
            <IconWrapper>
              <SiAddthis size={12} />
              <span>New Variation</span>
            </IconWrapper>
          </PendingButton>
        </div>
      </div>
      <Card style={{ height: 'calc(100vh - 56px - 480px)', overflowY: 'auto' }}>
        <Card.Header>
          <Row>
            <Col
              className='d-flex text-left'
              sm={3}
              onClick={()=>handleChangeSort('name')}
            >
              <Button variant='link' className='text-decoration-none p-0' style={{ color: 'inherit' }}>
                <IconWrapper>
                  <span className='fw-bold'>Variation Name</span>
                  <FaSort />
                </IconWrapper>
              </Button>
            </Col>
            <Col
              className='d-flex text-left'
              sm={3}
            >
              <span className='fw-bold'>Modified Parameters</span>
            </Col>
            <Col
              className='d-flex justify-content-center'
              sm={2}
              onClick={()=>handleChangeSort('created')}
            >
              {/*<span className='fw-bold'>Created</span>*/}
              <Button variant='link' className='text-decoration-none p-0' style={{ color: 'inherit' }}>
                <IconWrapper>
                  <span className='fw-bold'>Created</span>
                  <FaSort />
                </IconWrapper>
              </Button>
            </Col>
            <Col
              className='d-flex justify-content-center'
              sm={1}
            >
              <span className='fw-bold'>From Round</span>
            </Col>
            <Col
              className='d-flex justify-content-center'
              sm={2}
            >
              <span className='fw-bold'>No. of Variants</span>
            </Col>
            <Col
              className='d-flex justify-content-center text-center'
              sm={1}
            >
            </Col>
            <Col
              className='d-flex justify-content-center flex-shrink'
            >
            </Col>
          </Row>
        </Card.Header>
        {variants &&
        <ListGroup variant='flush' className='overflow-scroll'>
          {variants.length === 0 && <div className='d-flex flex-column p-3'>
              <div>No variations available.</div>
              <div>
                <Button
                  size='sm'
                  onClick={()=>setShowNewVariationPane(true)}
                  >
                  <IconWrapper>
                    <SiAddthis size={12} />
                    <span>New Variation</span>
                  </IconWrapper>
                </Button>
              </div>
            </div>}
          {variants && foundVariations && variants.length > 0 && foundVariations.map((v: Variation)=>{
            const copy: Variation = {...v};
            const parsedVariation: ParsedVariation = Object.assign(copy, { rp1: JSON.parse(copy.rp1), rp2: JSON.parse(copy.rp2) });

            return <ListGroup.Item key={v.id} className={selectedVariation===v?'variantListItem selected':'variantListItem'} onClick={()=>props.setSelectedVariation(v)}>
              <Row>
                <Col className='text-start' sm={3}>
                  {v.name}
                  <Button size="sm" variant="outline-primary" className="ms-2 p-1" onClick={() => setShowEditVariationPane(true)}>
                    <IconWrapper>
                      <BsPencilSquare />
                    </IconWrapper>
                  </Button>
                  <Button size="sm" variant="outline-danger" className="ms-2 p-1" onClick={() => setShowDeleteVariationPane(true)}>
                    <IconWrapper>
                      <IoTrashBin />
                    </IconWrapper>
                  </Button>
                </Col>
                <Col sm={3} className='text-start'>
                  {parsedVariation.rp1 && Object.keys(parsedVariation.rp1) && <ParamBadge parentAuction={auction} rpSet={[JSON.parse(v.rp1), JSON.parse(v.rp2)]} rp={JSON.parse(v.rp1)} title={v.name} />}
                  {parsedVariation.rp2 && Object.keys(parsedVariation.rp2) && <ParamBadge parentAuction={auction} rpSet={[JSON.parse(v.rp1), JSON.parse(v.rp2)]} rp={JSON.parse(v.rp2)} title={v.name} />}
                </Col>
                <Col sm={2}>
                  {new Date(v.created_on).toLocaleDateString('en-CA')}&nbsp;
                  {new Date(v.created_on).toLocaleTimeString('en-CA')}
                </Col>
                <Col sm={1}>
                  {v.start_round_num}
                </Col>
                <Col sm={2}>
                  <span className='fw-bold'>{v.num_variants}</span> variants
                </Col>
                <Col sm={1}>
                {v.status==='Completed' &&
                  <Link to={`/web/variation-results/${v.id}`}>
                    <Button
                      variant='outline-primary'
                      size='sm'
                    >
                      View Results
                    </Button>
                  </Link>
                }
                {v.status!=='Completed' &&
                  <Link to={`/web/variations/queue`}>
                    <Button
                      variant='outline-secondary'
                      size='sm'
                    >
                      {v.status}
                    </Button>
                  </Link>
                }
                </Col>
              </Row>
            </ListGroup.Item>
          })}
        </ListGroup>
        }
      </Card>
    </Container>
    <OffcanvasPane
      show={showNewVariationPane}
      onHide={()=>setShowNewVariationPane(false)}
      title={<>Auction Variation Creator (for <span className='fw-bold'>{auction.name}</span>)</>}
      placement='top'
      scroll={true}
      className='h-100'
      >
      <NewVariantCP
        auction={auction}
      />
    </OffcanvasPane>
    <OffcanvasPane
      title='Edit variation'
      onHide={() => setShowEditVariationPane(false)}
      show={showEditVariationPane}
      placement='top'
      className='h-50'
      >
      <div className='d-flex w-100 justify-content-center'>
        <div className='w-50' style={{ minWidth: '600px', maxWidth: '800px' }}>
          <VariationEditor
            variation={selectedVariation}
          />
        </div>
      </div>
    </OffcanvasPane>
    {selectedVariation &&
      <OffcanvasPane
        title='Delete variation'
        onHide={() => {setDeleteVariationError(null); setShowDeleteVariationPane(false);}}
        show={showDeleteVariationPane}
        placement='top'
        className='text-center h-50'
        >
        <div className='d-flex w-100 justify-content-center'>
          <div className='w-50' style={{ minWidth: '600px', maxWidth: '800px' }}>
            <p>Are you sure you want to delete <strong>{selectedVariation.name}</strong>?</p>
            <Alert variant='danger'><small><strong>Warning:</strong> This action cannot be undone!</small></Alert>
            <ButtonGroup>
              <PendingButton variant='primary' onClick={()=>handleSubmitDeleteVariation(selectedVariation.id)} pending={deleteVariationPending}>Yes</PendingButton>
              <Button variant='outline-secondary' onClick={() => {setDeleteVariationError(null); setShowDeleteVariationPane(false);}}>Cancel</Button>
            </ButtonGroup>
            {deleteVariationError && <Alert variant='danger' className='mt-3'><Alert.Heading>Error</Alert.Heading>{deleteVariationError}</Alert>}
          </div>
        </div>
      </OffcanvasPane>
    }
  </>)
}

export default VariantsList;