import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import MySpinner from "../../MySpinner";
import useFetch from "../../useFetch";
import useInterval from "../../useInterval";
import ErrorsModal from "../global/ErrorsModal";
import ParentAuctionSummary from "./ParentAuctionSummary";
import VariantCPNav from "./VariantCPNav";
import VariantsList from "./VariantsList";

const VariantCP = (props: any) => {
  const isAdmin = props.isAdmin || JSON.parse(window.localStorage.getItem('isAdmin') as string);
  const handleLogin = props.handleLogin;
  const auctionId = useParams().id;

  const initialAuctionFetch = useFetch(process.env.REACT_APP_API_URL + '/api/auctions/get/' + auctionId);
  const [currentAuction, setCurrentAuction] = useState<null|Auction>(initialAuctionFetch.data);

  const [allVariations, setAllVariations] = useState<Variation[]|null>(null);
  const [updatedVariations, setUpdatedVariations] = useState<Variation[]|null>(null);

  const [selectedVariation, setSelectedVariation] = useState<Variation|null>(null);
  const [variationsFetching, setVariationsFetching] = useState(false);
  const [variationsFetchError, setVariationsFetchError] = useState('');
  const [showErrorsModal, setShowErrorsModal] = useState(false);

  const fetchVariations = () => {
    setVariationsFetching(true);
    // setTimeout(()=>{console.log('holding')}, 2000);

    fetch(`${process.env.REACT_APP_API_URL}/api/variations/list?auction_id=${auctionId}`)
      .then(res => {
        if(!res.ok) {
          if (res.status!==404 && res.status!==502) {
            return res.text().then(text => { throw new Error(text) })
          }
          else {
            throw new Error(`${res.status} error: ${res.statusText}`);
          }
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setAllVariations(data);
        setVariationsFetching(false);
        console.log('fetched variations');
      })
      .catch(err => {
        setVariationsFetching(false);
        setVariationsFetchError(err.message);
      });
  }

  useEffect(()=>{
    setShowErrorsModal(!!variationsFetchError);
  }, [variationsFetchError]);
  useEffect(()=>{
    // load initial fetch of variations into updatedVariations (previously empty)
    if (allVariations && !updatedVariations) {
      setUpdatedVariations(allVariations);
      console.log('initial load');
    }
    // subsequent fetches:
    // only load newly fetched allVariations into updatedVariations
    // if a change in auction data is detected
    else if (allVariations && updatedVariations) {
      if (allVariations.length!==updatedVariations.length || JSON.stringify(allVariations)!==JSON.stringify(updatedVariations)) {
        setUpdatedVariations(allVariations);
        console.log('updating variations');
      }
    }
  }, [allVariations]);
  useEffect(()=>{
    // redirect if page is reached without being logged in as admin
    if (!isAdmin) {
      window.location.replace('/web/');
    }
    else {
      // Fetch auctions list on initial load
      // Populate VariantsList
      fetchVariations();
    }
  }, []);
  useEffect(()=>{
    if (!initialAuctionFetch.error) {
      setCurrentAuction(initialAuctionFetch.data);
    }
  }, [initialAuctionFetch.data]);

  useInterval(()=>{
    // poll every 4 secs
    fetchVariations();
  }, 4000);

  return (
    <>
    {!initialAuctionFetch.isPending && !initialAuctionFetch.error && currentAuction &&
      <>
        <VariantCPNav
          isAdmin={isAdmin}
          handleLogin={handleLogin}
          auction={currentAuction}
        />
        <div className='d-flex flex-column'>
          <div className='w-100'>
            <ParentAuctionSummary
              auction={currentAuction}
            />
          </div>
          <div className='flex-grow pb-2 overflow-hidden'>
            {variationsFetching && !updatedVariations && <MySpinner />}
            {!variationsFetching && !updatedVariations && !!variationsFetchError &&
            <Alert variant='danger'>
              <h6>Could not fetch variations for this auction</h6>
              {variationsFetchError}
            </Alert>
            }
            {updatedVariations &&
            <VariantsList
              auction={currentAuction}
              setSelectedVariation={setSelectedVariation}
              variants={updatedVariations}
              selectedVariation={selectedVariation}
            />
            }
          </div>
        </div>
        <ErrorsModal
          show={showErrorsModal}
          buttonAction={()=>window.location.reload()}
          onHide={()=>setShowErrorsModal(false)}
          buttonText='Reload'
          errorsList={[variationsFetchError]}
          header='Variations update failed (Server error)'
        />
      </>
    }
    </>
  )
}

export default VariantCP;