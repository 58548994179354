import { useMemo, useRef, useState } from "react";
import { Badge, Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { SiAddthis } from "react-icons/si";
import IconWrapper from "../global/IconWrapper";

const VarySpectrumCap = (props:any) => {
  const auction: Auction = props.auction;
  const alreadyConfirmedValues = props.paramValue && (props.paramValue as RangeAuc).values.length > 0 ? (props.paramValue as RangeAuc).values : [];
  const [values, setValues] = useState<number[]>(alreadyConfirmedValues);
  const newValRef = useRef<HTMLInputElement>(null);
  const isValid = useMemo(()=>{
    return values.length > 0
  }, [values]);

  const handleSaveValue = () => {
    props.onValidate(values.length > 0, { 'SpectrumCap': {'values': values } }, values.length, props.currentParam);
  }

  const handleRemoveValue = (val: number) => {
    setValues(values.filter((v: number)=>v!==val));
  }

  const handleAddValue = () => {
    if (newValRef.current) {
      const newVal: number = parseInt(newValRef.current.value);
      if (!!newVal && !values.includes(newVal)) {
        const newValues: number[] = [...values];
        newValues.push(newVal);
        setValues(newValues.sort((a,b)=>a-b));
      }
    }
  }

  return (
    <>
      <Row className='mb-3'>
        <Col sm={4}>
          <Card className='h-100'>
            <Card.Header className='text-center'>
              <span className="fw-bold">Parent Auction Values</span>
            </Card.Header>
            <Card.Body className='text-center'>
              <div className='mb-3'>
                <h6>Parent Auction</h6>
                Spectrum Cap: <span className='fw-bold'>{auction.spectrum_cap}</span> blocks
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header className='text-center'>
              <span className="fw-bold">Variant Simulations</span>
            </Card.Header>
            <Card.Body>
              <h6>
               Simulate variant auctions with the following spectrum cap values. <small><em>(Click to remove.)</em></small>
              </h6>
              <div className='d-flex justify-content-center flex-wrap mb-3'>
                {values.map((val: number)=>{
                  return <div className='m-1' key={val}><Badge bg='secondary' onClick={()=>handleRemoveValue(val)}>{val} blocks</Badge></div>
                })}
              </div>
              <div className='d-flex justify-content-center'>
                  <InputGroup className='mb-3' style={{ maxWidth: '360px' }}>
                    <Button
                      onClick={handleAddValue}
                    >
                      <IconWrapper>
                        <SiAddthis size={12} />
                        <span>Add value:</span>
                      </IconWrapper>
                    </Button>
                    <Form.Control
                      placeholder=""
                      area-label='Enter new Spectrum Cap value'
                      area-describedby='blocks-unit'
                      type='number'
                      min={0}
                      ref={newValRef}
                    />
                    <InputGroup.Text id='blocks-unit'>blocks</InputGroup.Text>
                  </InputGroup>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            variant="primary"
            className='float-end my-3'
            disabled={!isValid}
            onClick={()=>handleSaveValue()}
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
    )
}

export default VarySpectrumCap;