import { SyntheticEvent, useEffect, useState } from "react";
import { Buffer } from "buffer";
import { Alert, Form, ProgressBar, Spinner } from "react-bootstrap";
// import { createTupleTypeNode } from "typescript";
import * as xlsx from 'xlsx';
import { ConfigFileError } from "./ConfigFileError";
import useFetch from "../../useFetch";
import { arrayBufferToBase64, formatDateCode, isLikelyTypo } from "../../Helpers";
import PendingButton from "../global/PendingButton";

const FileUploader = (props:any) => {
  const allAuctions:Auction[] = props.allAuctions;
  const namedBiddersArray:NamedBidder[] = Object.values(props.namedBidders);
  const validFileTypes = props.validFileTypes;
  const [mainErrorMsg, setMainErrorMsg] = useState<null|string>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [warnings, setWarnings] = useState<string[]>([]);
  const [validatedFileB64, setValidatedFileB64] = useState<string|null>(null);
  const [validatedNewAuctionData, setValidatedNewAuctionData] = useState<NewAuction|null>(null);
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [validationProgressVal, setValidationProgressVal] = useState(0);
  const [currentlySubmitting, setCurrentlySubmitting] = useState(false);
  const regionsFetch = useFetch(`${process.env.REACT_APP_API_URL}/api/regionst4/list`);
  const productsFetch = useFetch(`${process.env.REACT_APP_API_URL}/api/products/list`);

  const findNamedBidder = (checkName: string, namedBidders: NamedBidder[]) => {
    const matchedNamedBidder = namedBiddersArray.find((namedBidder:NamedBidder) => {
      const lowered = checkName.toLowerCase();
      let allNames = [namedBidder.abbr, namedBidder.name, namedBidder.display_name];
      let matched: boolean = false;
      let matchedName: string|null = null;

      if (namedBidder.alt_names) {
        allNames = allNames.concat(namedBidder.alt_names);
      }

      allNames.forEach((name: string)=>{
        if (!matched && name!=='') {
          matched = isLikelyTypo(lowered, name.toLowerCase(), checkName.length, name.length);
          matchedName = name;
        }
      });

      if (matched && matchedName) {
        console.log(`${checkName} identified as likely typo of ${matchedName}`);
      }

      return matched
    });
    return matchedNamedBidder
  }

  const handleFileUpload = (e:SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    const file = target.files ? target.files[0] : null;
    const filename = file?.name;
    const filetype = filename?.split('.').at(-1);

    let newAuctionObj:NewAuction;
    let gatheredErrors:string[] = [];
    let gatheredWarnings:string[] = [];

    try {
      if (file) {
        console.log('validating file ' + filename);
        if (validFileTypes.includes(filetype)) {
          const reader = new FileReader();
          reader.onload = (e) => {
            try {
              if (e.target) {
                setValidationInProgress(true);
                setValidationProgressVal(0);
                const data = e.target.result;
                if (data) {
                  setValidatedFileB64(arrayBufferToBase64(data as ArrayBuffer));
                }
                const workbook = xlsx.read(data, { type: 'array' });
                // const sheetNames = workbook.SheetNames;
                // const sheets = workbook.Sheets;

                let missingSheets:string[] = []; // missing sheets
                let missingColumns:string[] = [];
                const REQUIRED_SHEETS = ['auction', 'bidders', 'initial packages'];
                const REQUIRED_COLUMNS_auction = ['Auction Name', 'Cross-band Spectrum Cap Blocks', 'EP Percentage', 'Increment Percentage'];
                const REQUIRED_COLUMNS_bidders = ['Bidder Name', 'Total Budget', 'Initial EP', 'Autobidder'];
                // const REQUIRED_COLUMNS_initpkgs = [
                //   'Bidder Name',
                //   'Product Code',
                //   'Product Budget', // values can be empty
                //   'Quantity',
                //   'Maximum Price per Block', // values can be empty
                //   'Minimum Nonzero Quantity', // values can be empty
                //   'Priority', // values can be empty
                //   'Product Code',
                //   'Quantity',
                //   'max $/MHz/pop q1', // values can be empty
                //   'max $/MHz/pop q2', // values can be empty
                //   'max $/MHz/pop q3', // values can be empty
                //   'max $/MHz/pop q4', // values can be empty
                //   'max $/MHz/pop q5' // values can be empty
                // ];

                // Ensure all necessary sheets exist
                REQUIRED_SHEETS.forEach((sheet) => {
                  if (!workbook.SheetNames.includes(sheet)) {
                    missingSheets.push(sheet);
                  }
                });
                if (missingSheets.length > 0) {
                  throw Error('Missing required sheet(s) in config file: ' + missingSheets.join(', '));
                }

                setValidationProgressVal(5);

                const auctionSheetRows:ExcelAuction[] = xlsx.utils.sheet_to_json(workbook.Sheets['auction']);
                const biddersSheetRows:ExcelBidder[] = xlsx.utils.sheet_to_json(workbook.Sheets['bidders']);
                const initPkgsSheetRows:ExcelInitPkg[] = xlsx.utils.sheet_to_json(workbook.Sheets['initial packages']);

                // create dict of NamedBidder.abbr -> [initPkgsRows]
                const initPkgsDict: { [key: string]: ExcelInitPkg[] } = {};
                initPkgsSheetRows.forEach((initPkgRow: ExcelInitPkg)=>{
                  const namedBidder = findNamedBidder(initPkgRow['Bidder Name'], namedBiddersArray);
                  if (namedBidder) {
                    if (initPkgsDict[namedBidder.abbr]) {
                      const newRow = [...initPkgsDict[namedBidder.abbr]];
                      newRow.push(initPkgRow);
                      initPkgsDict[namedBidder.abbr] = newRow;
                    }
                    else {
                      initPkgsDict[namedBidder.abbr] = [initPkgRow];
                    }
                  }
                });

                // Check auction sheet has values
                if (auctionSheetRows.length === 0) {
                  throw Error('No auction fields found');
                }
                const auctionSheetRow = auctionSheetRows[0];
                // Check bidders sheet has values
                if (biddersSheetRows.length === 0) {
                  throw Error('No bidders found');
                }

                // Check required columns for auction sheet
                REQUIRED_COLUMNS_auction.forEach((column) => {
                  if (!Object.keys(auctionSheetRows[0]).includes(column)) {
                    missingColumns.push(column + ' (auction sheet)');
                  }
                });
                workbook.Sheets['auction']["E1"].h.toLowerCase() === 'Round Pauses'.toLowerCase() || missingColumns.push('Round Pauses (auction sheet)');
                // Check required columns for bidder sheet
                REQUIRED_COLUMNS_bidders.forEach((column) => {
                  if (!Object.keys(biddersSheetRows[0]).includes(column)) {
                    missingColumns.push(column + ' (bidder sheet)');
                  }
                });

                // columns should only go up to L
                // otherwise there are superfluous columns
                if (workbook.Sheets['initial packages']['!ref'] && 'ABCDEFGHIJK'.includes(workbook.Sheets['initial packages']['!ref'][3])) {
                  gatheredWarnings.push('Initial packages sheet appears to contain extra columns. Only columns A to L will be parsed.');
                }

                // Init pkgs sheet needs to be checked differently
                // because while columns must exist, they may have
                // empty values, and empty value columns do not show up
                // in the converted JSON object.
                workbook.Sheets['initial packages']['A1'].h === 'Bidder Name' || missingColumns.push('Bidder Name (initial packages sheet)');
                workbook.Sheets['initial packages']['B1'].h === 'Product Code' || missingColumns.push('Product Code (initial packages sheet)');
                workbook.Sheets['initial packages']['C1'].h === 'Quantity' || missingColumns.push('Quantity (initial packages sheet)');
                workbook.Sheets['initial packages']['D1'].h === 'Priority' || missingColumns.push('Priority (initial packages sheet)');
                workbook.Sheets['initial packages']['E1'].h === 'Minimum Nonzero Quantity' || missingColumns.push('Minimum Nonzero Quantity (initial packages sheet)');
                workbook.Sheets['initial packages']['F1'].h === 'Maximum Price per Block' || missingColumns.push('Maximum Price per Block (initial packages sheet)');
                workbook.Sheets['initial packages']['G1'].h === 'Product Budget' || missingColumns.push('Product Budget (initial packages sheet)');
                workbook.Sheets['initial packages']['H1'].h === 'max $/MHz/pop q1' || missingColumns.push('max $/MHz/pop q1 (initial packages sheet)');
                workbook.Sheets['initial packages']['I1'].h === 'max $/MHz/pop q2' || missingColumns.push('max $/MHz/pop q2 (initial packages sheet)');
                workbook.Sheets['initial packages']['J1'].h === 'max $/MHz/pop q3' || missingColumns.push('max $/MHz/pop q3 (initial packages sheet)');
                workbook.Sheets['initial packages']['K1'].h === 'max $/MHz/pop q4' || missingColumns.push('max $/MHz/pop q4 (initial packages sheet)');
                workbook.Sheets['initial packages']['L1'].h === 'max $/MHz/pop q5' || missingColumns.push('max $/MHz/pop q5 (initial packages sheet)');

                if (missingColumns.length > 0) {
                  throw Error('Missing required column(s) in config file (ensure spelling is correct): ' + missingColumns.join(', '));
                }

                // const setAsideEligibilityRows:ExcelSetAsideEligibility[] = xlsx.utils.sheet_to_json(workbook.Sheets['set-aside eligibility']);

                let auctionName = auctionSheetRow['Auction Name'];
                const epPercentage = auctionSheetRow['EP Percentage'];
                const incrPercentage = auctionSheetRow['Increment Percentage'];
                const crossbandSpectrumCap = auctionSheetRow['Cross-band Spectrum Cap Blocks'];
                let roundPauses = auctionSheetRow['Round Pauses'];

                // Check auction name exists
                if (!auctionName || auctionName === '') {
                  gatheredErrors.push('Auction sheet error: Auction name missing');
                }
                setValidationProgressVal(10);
                // Check auction name is unique
                if ((allAuctions.filter((auction:Auction) => auction.name === auctionName)).length > 0) {
                  const newName = auctionSheetRow['Auction Name'] + ": " + formatDateCode(Date.now());
                  gatheredWarnings.push('An auction of this name already exists. If you click on Submit, the new auction will be renamed to ' + newName + '. This can be changed by editing the auction later.');
                  auctionName = newName;
                }
                // check EP and Incr. percentages
                if (epPercentage > 100 || epPercentage < 90) {
                  gatheredErrors.push('Auction sheet error: EP Percentage must be a value between 90 and 100 (inclusive)');
                }
                setValidationProgressVal(15);
                if (incrPercentage > 100 || incrPercentage < 0) {
                  gatheredErrors.push('Auction sheet error: Increment Percentage must be a value between 0 and 100 (inclusive)');
                }
                // check crossbandSpectrumCap is boolean
                if (typeof crossbandSpectrumCap !== 'number') {
                  gatheredErrors.push('Auction sheet error: 100 MHz Cross-band Spectrum Cap must have a value of TRUE or FALSE');
                }
                setValidationProgressVal(20);
                // process round pauses which should be comma-separated list of round numbers
                if (typeof roundPauses === 'number') { // if just one pause
                  roundPauses = roundPauses.toString();
                }
                const roundPausesArray = roundPauses ? roundPauses.split(',').map(s=>parseInt(s.trim())) : [];
                if (!roundPausesArray.every(p=>p)) {
                  gatheredErrors.push('Auction sheet error: Round pauses must be a list of numbers separated by commas');
                }
                setValidationProgressVal(25);
                // validate Bidders
                let problemBidders:string[] = [];

                const uniqueBidders:string[] = biddersSheetRows.map((bidder:ExcelBidder)=>bidder['Bidder Name']).filter((v,i,a)=>a.indexOf(v)===i);
                if (uniqueBidders.length !== biddersSheetRows.length) {
                  gatheredErrors.push('Bidders sheet error: Missing or repeat bidder name found');
                }

                setValidationProgressVal(50);

                let bidderNameLengthErrorFound = false;
                let bidderSpecificErrorFound = false; // errors for everything else; specifies which bidder
                let newBiddersList:NewBidder[] = [];
                let autobiddersList:string[] = [];
                let isAllAutobidders = false;

                biddersSheetRows.forEach((bidder:ExcelBidder) => {
                  const currentBidder = bidder['Bidder Name'];
                  let currentNewBidder:NewBidder;

                  // account for named bidder typos
                  let bidderAbbr = Buffer.from(bidder['Bidder Name'], 'utf-8').toString().trim();
                  let correctedBidderAbbr = bidderAbbr;

                  const matchedNamedBidder = findNamedBidder(bidderAbbr, namedBiddersArray);

                  let correspondingAutobidderRows: ExcelInitPkg[] = [];

                  if (matchedNamedBidder) {
                    gatheredWarnings.push(`${bidderAbbr} identified as bidder with 3500 MHz holdings ${matchedNamedBidder.display_name}.`);
                    correctedBidderAbbr = matchedNamedBidder.abbr;

                    // check if init pkg bidder name and current bidder name refer to same named bidder
                    correspondingAutobidderRows = initPkgsDict[matchedNamedBidder.abbr] || [];
                  }
                  else {
                    // otherwise compare directly with strict comparison and only allow for differences in case
                    correspondingAutobidderRows = initPkgsSheetRows.filter((pkg:ExcelInitPkg)=>pkg['Bidder Name'].toLowerCase() === bidderAbbr.toLowerCase());
                  }

                  if (!bidderNameLengthErrorFound && currentBidder.length >= 256) {
                    bidderNameLengthErrorFound = true;
                    problemBidders.push(currentBidder);
                  }
                  else {
                    if (!bidder['Total Budget']) {
                      bidderSpecificErrorFound = true;
                      problemBidders.push(currentBidder);
                      gatheredErrors.push(`Bidder error: ${currentBidder} does not have a valid budget defined`);
                    }

                    if (!bidder['Initial EP']) {
                      bidderSpecificErrorFound = true;
                      problemBidders.push(currentBidder)
                      gatheredErrors.push(`Bidder error: ${currentBidder} does not have a valid initial EP defined`);
                    }

                    if (typeof bidder['Autobidder'] !== 'boolean') {
                      bidderSpecificErrorFound = true;
                      problemBidders.push(currentBidder)
                      gatheredErrors.push(`Bidder error: ${currentBidder} type is not defined (Autobidder column must be TRUE or FALSE)`);
                    }
                    else if (bidder['Autobidder'] === true) {
                      autobiddersList.push(currentBidder);
                    }

                    // look for it in Set-Aside Eligibility sheet
                    // const currentSetAsideBidderRows: ExcelSetAsideEligibility[] = setAsideEligibilityRows.filter((bidder:ExcelSetAsideEligibility) => bidder['Bidder Name'] === bidderAbbr);
                    // const currentSetAsideRegions: string = (currentSetAsideBidderRows.map((setAsideEligibilityRow:ExcelSetAsideEligibility) => setAsideEligibilityRow['Tier 2 Region'].match(/\d-\d+/g))).join(', ');
                    // let currentSetAsideEligibilities: Record<string, boolean> = {};
                    // currentSetAsideBidderRows.map((setAsideEligibilityRow:ExcelSetAsideEligibility) => {
                    //   let tierCodeFound: string[]|null = setAsideEligibilityRow['Tier 2 Region'].match(/\d-\d+/g);
                    //   if (tierCodeFound) {
                    //    const tierCode: string = tierCodeFound.join('');
                    //    currentSetAsideEligibilities[tierCode] = setAsideEligibilityRow['Set-Aside Eligible'];
                    //   }
                    //   else {
                    //     gatheredErrors.push(`Set-Aside Eligibility sheet error: Invalid tier defined for set-aside eligibility for ${bidderAbbr}: ${setAsideEligibilityRow['Tier 2 Region']}`);
                    //   }
                    // });

                    // check for autobidder price multiplier
                    let autobidderPriceMultiplier = undefined;
                    if (bidder['Autobidder Price Multiplier'] && typeof bidder['Autobidder Price Multiplier']==='number') {
                      if (bidder['Autobidder Price Multiplier']<=0) {
                        bidderSpecificErrorFound = true;
                        problemBidders.push(currentBidder);
                        gatheredErrors.push(`Bidder error: ${currentBidder} must have a positive integer value for Autobidder Price Multiplier`);
                      }
                      else {
                        autobidderPriceMultiplier = bidder['Autobidder Price Multiplier'];
                      }
                    }

                    // get display regions
                    let displayRegions: string[] = [];
                    if (bidder['Display Regions'] && typeof bidder['Display Regions'] === 'string') {
                      displayRegions = bidder['Display Regions'].split(',').map(x=>x.trim().slice(0,5));
                      const allRegions: string[] = (Object.values(regionsFetch.data) as SATier4[]).map((regionObj: SATier4) => regionObj.sa);

                      let problemDisplayRegions: string[] = [];
                      // check that all display regions are valid region codes
                      displayRegions.forEach((region: string) => {
                        if (!allRegions.includes(region)) {
                          problemDisplayRegions.push(region);
                        }
                      });

                      if (problemDisplayRegions.length > 0) {
                        gatheredErrors.push(`Bidder error: ${currentBidder} contains invalid display regions (${problemDisplayRegions.join(', ')})`);
                        bidderSpecificErrorFound = true;
                      }
                    }

                    // validate initial packages for autobidders
                    let autoBidderConfigs: NewABConfig[] = [];
                    if (bidder['Autobidder']) {
                      if (correspondingAutobidderRows.length === 0) {
                        gatheredErrors.push(`Bidder error: ${currentBidder} is an autobidder but has no initial packages`);
                      }
                      else {
                        correspondingAutobidderRows.forEach((initPkg: ExcelInitPkg) => {
                          let isOK = true;
                          let productCode = initPkg['Product Code'];
                          const qty = initPkg['Quantity'];
                          const priority = initPkg['Priority'];
                          const minNzq = initPkg['Minimum Nonzero Quantity'];
                          const maxPrice = initPkg['Maximum Price per Block'];
                          const budget = initPkg['Product Budget'];
                          const q1 = initPkg['max $/MHz/pop q1'];
                          const q2 = initPkg['max $/MHz/pop q2'];
                          const q3 = initPkg['max $/MHz/pop q3'];
                          const q4 = initPkg['max $/MHz/pop q4'];
                          const q5 = initPkg['max $/MHz/pop q5'];
                          let idProductError = '';

                          if (!productCode) {
                            gatheredErrors.push(`Initial pkg error for ${currentBidder}: Missing product code`);
                            isOK = false;
                          }
                          else {
                            productCode = productCode.split(' ')[0]; // ignore anything after product code
                          }
                          if (productCode && !productsFetch.data[productCode]) {
                            gatheredErrors.push(`Initial pkg error for ${currentBidder}: ${productCode} not found amongst valid products for this auction`);
                            isOK = false;
                          }
                          else {
                            idProductError = ` (${productCode})`;
                          }
                          if (typeof qty !== 'number' || qty < 0) {
                            gatheredErrors.push(`Initial pkg error for ${currentBidder}${idProductError}: Contains invalid quantity`);
                            isOK = false;
                          }
                          if (priority && typeof priority !== 'number' && priority < 0) {
                            gatheredErrors.push(`Initial pkg error for ${currentBidder}${idProductError}: Contains invalid priority`);
                            isOK = false;
                          }
                          if (minNzq && typeof minNzq !== 'number' && minNzq < 1) {
                            gatheredErrors.push(`Initial pkg error for ${currentBidder}${idProductError}: Contains invalid minimum nonzero quantity`);
                            isOK = false;
                          }
                          if (maxPrice && maxPrice && typeof maxPrice !== 'number' && maxPrice < 0) {
                            gatheredErrors.push(`Initial pkg error for ${currentBidder}${idProductError}: Contains invalid Maximum Price per Block`);
                            isOK = false;
                          }
                          if (budget && typeof budget !== 'number' && budget < 0) {
                            gatheredErrors.push(`Initial pkg error for ${currentBidder}${idProductError}: Contains invalid budget`);
                            isOK = false;
                          }
                          if ((q1 && typeof q1 !== 'number') ||
                              (q2 && typeof q2 !== 'number') ||
                              (q3 && typeof q3 !== 'number') ||
                              (q4 && typeof q4 !== 'number') ||
                              (q5 && typeof q5 !== 'number')
                            ) {
                            gatheredErrors.push(`Initial pkg error for ${currentBidder}${idProductError}: Contains invalid max $/MHz/pop value`);
                            isOK = false;
                          }

                          if (isOK) {
                            autoBidderConfigs.push({
                              product_code: productCode,
                              set_aside: false, // no set-aside for now
                              quantity: qty,
                              priority: priority,
                              min_nzq: minNzq,
                              max_price: maxPrice,
                              budget: budget,
                              max_dmp_q1: q1,
                              max_dmp_q2: q2,
                              max_dmp_q3: q3,
                              max_dmp_q4: q4,
                              max_dmp_q5: q5
                            });
                          }
                          else {
                            bidderSpecificErrorFound = true;
                          }
                        });
                      }
                    }

                    // create NewBidder object

                    if (!bidderSpecificErrorFound) {
                      currentNewBidder = {
                        abbr: correctedBidderAbbr,
                        is_named: !!matchedNamedBidder,
                        budget: bidder['Total Budget'],
                        init_ep: bidder['Initial EP'],
                        ab_price_multiplier: autobidderPriceMultiplier,
                        autobidder: bidder['Autobidder'],
                        display_regions: displayRegions,
                        default_set_aside_elig: false,
                        set_aside_elig: {},
                        autobidder_config: autoBidderConfigs
                      }
                      newBiddersList.push(currentNewBidder);
                    }
                  }
                });

                if (autobiddersList.length === newBiddersList.length) {
                  isAllAutobidders = true;
                }

                if (isAllAutobidders && !roundPausesArray.includes(1)) {
                  roundPausesArray.push(1); // force pause before Round 1 for all autobidders
                }

                setValidationProgressVal(80);

                if (bidderNameLengthErrorFound) {
                  gatheredErrors.push('Bidders sheet error: All bidder names should be within 256 characters.');
                }

                setWarnings(gatheredWarnings);

                if (gatheredErrors.length > 0) {
                  setErrors(gatheredErrors);
                  throw Error('Errors found within file: See below');
                }

                newAuctionObj = {
                  name: auctionName,
                  ep_pct: epPercentage,
                  inc_pct: incrPercentage,
                  spectrum_cap: crossbandSpectrumCap,
                  pauses: roundPausesArray.sort(),
                  bidders: newBiddersList
                }

                clearErrors();

                console.log('Valid config file for POST.');
                setValidationInProgress(false);
                setValidatedNewAuctionData(newAuctionObj);
                setValidationProgressVal(100);
                console.log(newAuctionObj);
              }
            }
            catch (err) {
              setValidatedNewAuctionData(null);
              if (err instanceof ConfigFileError) {
                // catch config file format errors
                setMainErrorMsg(`Config File Error: ${err.message}`);
              }
              else if (err instanceof Error) {
                setMainErrorMsg(`${err.message}`);
                console.log(err);
              }
            }
          };
          reader.readAsArrayBuffer(file);
        }
        else {
          setValidatedNewAuctionData(null);
          setValidationInProgress(false);
          setMainErrorMsg('Invalid file type: .' + filetype);
        }
      }
    }
    catch (err) {
      setValidatedNewAuctionData(null);
      setValidationInProgress(false);
      if (err instanceof Error) {
        // any other errors
        setMainErrorMsg(`${err.message}`);
        console.log(err);
        // handleError(err);
      }
    }
  }

  const handleSubmitFile = (e:SyntheticEvent) => {
    e.preventDefault();
    console.log('submitting POST');
    setCurrentlySubmitting(true);
    clearErrors();
    setValidationInProgress(false);
    setValidationProgressVal(0);
    const postData = {...validatedNewAuctionData};
    postData['config_file'] = validatedFileB64 || '';
    fetch(process.env.REACT_APP_API_URL + '/api/auctions/new', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData)
    })
      .then(res => {
        if (!res.ok) {
          if (res.status===400) {
            setWarnings([]);
            return res.text().then(text => { throw new Error(text) })
          }
          else {
            throw new Error(`${res.status}: ${res.statusText}`)
          }
        }
      })
      .then(data => {console.log('successfully sent as POST'); window.location.reload();})
      .catch(err => {
        setCurrentlySubmitting(false);
        setMainErrorMsg(err.message.split('\n')[0]);
        setErrors(err.message.split('\n').slice(1));
      });
  }

  const clearErrors = () => {
    setErrors([]);
    setMainErrorMsg(null);
  }

  useEffect(() => {
    clearErrors();
  }, []);

  return (
    <>
    {(regionsFetch.isPending || productsFetch.isPending) && <><Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Loading...</span>
                  </>}
    {!regionsFetch.isPending && !productsFetch.isPending && regionsFetch.data &&
      <>
      <Form.Group controlId="formFileLg" className="mb-3">
        <Form.Label>Upload a new auction config file (.xls or .xlsx)</Form.Label>
        <Form.Control
          type="file"
          size="lg"
          onChange={ (e) => handleFileUpload(e) }
          onClick={ (e) => handleFileUpload(e) }
          isValid={!mainErrorMsg}
          isInvalid={mainErrorMsg ? !!mainErrorMsg : undefined}
          disabled={currentlySubmitting}
          />
        <Form.Control.Feedback type='invalid'>
          {mainErrorMsg}
        </Form.Control.Feedback>
      </Form.Group>
      {validationInProgress && <ProgressBar className='my-3' animated now={validationProgressVal} />}
      {warnings.map((warningMsg:string, i:number) => (
        <Alert key={i} variant='warning'>
          {warningMsg}
        </Alert>
      ))}
      {errors && errors.map((errorMsg:string) => (
        <Alert key={errorMsg} variant='danger'>
          {errorMsg}
        </Alert>
      ))}
      <div className='d-flex justify-content-center'>
        <PendingButton
          variant='primary'
          disabled={(validatedNewAuctionData ? undefined : !validatedNewAuctionData) || currentlySubmitting}
          onClick={(e: SyntheticEvent<Element, Event>)=>handleSubmitFile(e)}
          type='submit'
          value='Submit'
          inputType={true}
          pending={currentlySubmitting}
          />
      </div>
      </>
      }
    </>
  );
};

FileUploader.displayName = 'FileUploader';

export default FileUploader;

