import { Badge, Button, Card, Col, Container, Form, InputGroup, ListGroup, Row } from "react-bootstrap";
import { SiAddthis } from "react-icons/si";
import { FaSort } from "react-icons/fa";
import MySpinner from "../../MySpinner";
import IconWrapper from "../global/IconWrapper";
import PendingButton from "../global/PendingButton";
import { useEffect, useState } from "react";
import { auctionSortByDate, auctionSortByName, auctionSortByRound, auctionSortByStatus } from "../../Helpers";
import OffcanvasPane from "../global/OffcanvasPane";
import FileUploader from "./FileUploader";
import { HiDesktopComputer, HiOutlineSearch } from "react-icons/hi";
import { IoIosPlay } from "react-icons/io";
import { Link } from "react-router-dom";
import TooltipIcon from "../global/TooltipIcon";

const AuctionsList = (props:any) => {
  const auctions: Auction[] = props.auctions;
  const selectedAuction: Auction = props.selectedAuction || -1;

  const [sortedAuctions, setSortedAuctions] = useState<Auction[]>(props.auctions);
  const [currentSort, setCurrentSort] = useState<[string, boolean]>(['created', true]); // [sortType, isAscending]

  const [showNewAuctionPane, setShowNewAuctionPane] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [foundAuctions, setFoundAuctions] = useState<Auction[]>(props.auctions);

  const [auctionStatusDict, setAuctionStatusDict] = useState<{ [key: string]: { pending: boolean, errors: string[] } }>({});

  const filterAuctions = (keyword: string) => {
    if (keyword !== '') {
        const results = sortedAuctions.filter((a: Auction) => {
            return a.name.startsWith(keyword.toLowerCase()) || a.name.toLowerCase().includes(keyword.toLowerCase());
        });
        setFoundAuctions([...results]);
    }
    else {
        setFoundAuctions([...sortedAuctions]);
    }
    setSearchTerm(keyword);
  }

  const handleSearchAuctions: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const keyword: string = e.currentTarget.value;
    filterAuctions(keyword);
  }

  const handleResumeAuction = (auction: Auction) => {
    console.log('Resuming auction');

    const newAuctionStatusDict = {...auctionStatusDict};

    if (process.env.REACT_APP_DEV_ENV !== 'local') {
      if (newAuctionStatusDict[(auction.id).toString()]) {
        newAuctionStatusDict[(auction.id).toString()] = { pending: true, errors: [] };
      }
      setAuctionStatusDict(newAuctionStatusDict);
      fetch(`${process.env.REACT_APP_API_URL}/api/auctions/resume/${auction.id}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      })
        .then((res) => {
          if(!res.ok) {
            return res.text().then(text => { throw new Error(`Resume failed: ${text}`) })
          }
          // else {
          //   return res.json();
          // }
        })
        .then(data => {
          console.log('successfully sent as POST');
          if (newAuctionStatusDict[(auction.id).toString()]) {
            newAuctionStatusDict[(auction.id).toString()] = { pending: false, errors: [] };
          }
          setAuctionStatusDict(newAuctionStatusDict);
          props.fetchAuctions();
        })
        .catch((err) => {
          if (newAuctionStatusDict[(auction.id).toString()]) {
            newAuctionStatusDict[(auction.id).toString()] = { pending: true, errors: [err.message] };
          }
          setAuctionStatusDict(newAuctionStatusDict);
        });
    }
  }

  const handleChangeSort = (sortBy: string) => {
    if (currentSort[0] === sortBy) {
      setCurrentSort([sortBy, !currentSort[1]]);
    }
    else {
      setCurrentSort([sortBy, true]);
    }
  }

  const sortAuctions = (sortBy: string, isReverse: boolean) => {
    let allSorted = [...auctions];
    let foundSorted = [...foundAuctions];

    if (sortBy === 'name') {
      allSorted.sort(auctionSortByName);
      foundSorted.sort(auctionSortByName);
    }
    else if (sortBy === 'created') {
      allSorted.sort(auctionSortByDate);
      foundSorted.sort(auctionSortByDate);
    }
    else if (sortBy === 'round') {
      allSorted.sort(auctionSortByRound);
      foundSorted.sort(auctionSortByRound);
    }
    else if (sortBy === 'status') {
      allSorted.sort(auctionSortByStatus);
      foundSorted.sort(auctionSortByStatus);
    }

    if (!!isReverse) {
      allSorted.reverse();
      foundSorted.reverse()
    }

    setSortedAuctions(allSorted);
    setFoundAuctions(foundSorted);
  }

  useEffect(()=>{
    // sets foundAuctions
    filterAuctions(searchTerm)
  }, [sortedAuctions]);
  useEffect(()=>{;
    // sets sortedAuctions and foundAuctions
    sortAuctions(currentSort[0], currentSort[1]);
  }, [auctions, currentSort]);
  useEffect(()=>{
    // console.log('updating found auctions');
    // console.log(foundAuctions);
  }, [foundAuctions]);

  return (
    <>
      <div className='mb-2 d-flex'>
        <div className='me-1 flex-grow-1'>
          <InputGroup>
            <InputGroup.Text id="inputGroup-sizing-sm">
              <IconWrapper>
                <HiOutlineSearch />
                Search
              </IconWrapper>
            </InputGroup.Text>
            <Form.Control
              aria-label="Search"
              aria-describedby="inputGroup-sizing-sm"
              value={searchTerm}
              onChange={handleSearchAuctions}
              type='search'
            />
          </InputGroup>
        </div>
        {props.isAdmin &&
          <div className='ms-1'>
            <PendingButton
              onClick={() => setShowNewAuctionPane(true)}
              disabled={true} // Change this to false to re-enable the button later
            >
              <IconWrapper>
                <SiAddthis size={12} />
                <span>New Auction</span>
              </IconWrapper>
            </PendingButton>
            <OffcanvasPane
              title='Create new auction'
              onHide={() => setShowNewAuctionPane(false)}
              show={showNewAuctionPane}
              placement='top'
              className='h-50'
            >
              <div className='d-flex w-100 justify-content-center'>
                <div className='w-50' style={{ minWidth: '600px', maxWidth: '800px' }}>
                  <FileUploader
                    validFileTypes={['xls', 'xlsx']}
                    allAuctions={auctions}
                    namedBidders={props.namedBidders}
                  />
                </div>
              </div>
            </OffcanvasPane>
          </div>
        }        
      </div>
      {auctions &&
        <Card style={{ height: 'calc(100vh - 56px - 515px)', overflowY: 'auto' }}>
          <Card.Header>
            <Row>
              <Col
                className='d-flex'
                onClick={()=>handleChangeSort('name')}
              >
                <span className='fw-bold'>Auction Name</span>
                <IconWrapper>
                  <FaSort />
                </IconWrapper>
              </Col>
              <Col
                className='d-flex justify-content-center'
                sm={3}
                onClick={()=>handleChangeSort('created')}
              >
                <IconWrapper>
                  <span className='fw-bold'>Created</span>
                  <FaSort />
                </IconWrapper>
              </Col>
              <Col
                className='d-flex justify-content-center'
                sm={1}
                onClick={()=>handleChangeSort('round')}
              >
                <IconWrapper>
                  <span className='fw-bold'>Round</span>
                  <FaSort />
                </IconWrapper>
              </Col>
              <Col
                className='d-flex justify-content-center'
                sm={1}
                onClick={()=>handleChangeSort('status')}
              >
                <IconWrapper>
                  <span className='fw-bold'>Status</span>
                  <FaSort />
                </IconWrapper>
              </Col>
              <Col
                className='d-flex justify-content-center'
                sm={2}
              >
                <IconWrapper>
                  <span className='fw-bold'>Action</span>
                </IconWrapper>
              </Col>
            </Row>
          </Card.Header>
          <ListGroup variant='flush'>
            {foundAuctions.length === 0 && <div className='d-flex flex-column p-3'>
              <div>No auctions available</div>
              <div>
                {props.isAdmin &&
                  <PendingButton
                    onClick={() => setShowNewAuctionPane(true)}
                    disabled={!auctions}
                    >
                    <IconWrapper>
                      <SiAddthis size={12} />
                      <span>New Auction</span>
                    </IconWrapper>
                  </PendingButton>
                }
              </div>
            </div>}
            {foundAuctions.length > 0 &&
            foundAuctions
              .map((a: Auction) => {
              return <ListGroup.Item key={a.id} className={a.id === selectedAuction.id ? 'auctionListItem selected' : 'auctionListItem'} onClick={() => props.setSelectedAuction(a)}>
                <Row>
                  <Col className='text-start'>
                    <IconWrapper>
                      <span>{a.name}</span>
                      {a.bidder_count===a.autobidder_count && <TooltipIcon id={`${a.id}-all-autobidder`} text='All Autobidder Auction'><HiDesktopComputer /></TooltipIcon>}
                    </IconWrapper>
                  </Col>
                  <Col sm={3}>
                    {new Date(a.created_on).toLocaleDateString('en-CA')}&nbsp;
                    {new Date(a.created_on).toLocaleTimeString('en-CA')}
                  </Col>
                  <Col sm={1}>{a.round_num}</Col>
                  <Col sm={1}>
                    {a.status === 'Ready' && <Badge bg='primary' pill>{a.status}</Badge>}
                    {a.status === 'Paused' && <Badge bg='warning' pill>{a.status}</Badge>}
                    {a.status === 'Ended' && <Badge bg='success' pill>{a.status}</Badge>}
                    {a.status === 'Transient' && <Badge bg='danger' pill>{a.status}</Badge>}
                  </Col>
                  <Col sm={2}>
                    {a.status === 'Ready' &&
                      <>
                        {props.isAdmin && a.bidder_count===a.autobidder_count &&
                        <small><MySpinner /></small>
                        }
                        {props.isAdmin && a.bidder_count!==a.autobidder_count &&
                        <Link to={'/web/auction/' + a.id}>
                          <Button size='sm' className='mb-2' variant='outline-primary' disabled={a.status.startsWith('Transient')}>
                            {props.isAdmin ? 'View Data' : 'Enter Auction'}
                          </Button>
                        </Link>
                        }
                        {!props.isAdmin &&
                          <Link to={'/web/auction/' + a.id}>
                            <Button size='sm' className='mb-2' variant='outline-primary' disabled={a.status.startsWith('Transient')}>
                              Enter Auction
                            </Button>
                          </Link>
                        }
                      </>
                    }
                    {a.status === 'Paused' && props.isAdmin &&
                      <Link to={'/web/auction/' + a.id}>
                        <Button size='sm' className='mb-2' variant='outline-primary' disabled={a.status.startsWith('Transient')}>
                          {props.isAdmin ? 'View Data' : 'Enter Auction'}
                        </Button>
                      </Link>
                    }
                    {a.status==='Paused' && !props.isAdmin && <small>Waiting for admin</small>}
                    {a.status === 'Ended' &&
                      <>
                      <Link to={'/web/auction/' + a.id}>
                        <Button size='sm' className='mb-2' variant='outline-primary' disabled={a.status.startsWith('Transient')}>
                          {props.isAdmin ? 'View Data' : 'Enter Auction'}
                        </Button>
                      </Link>
                      </>
                    }
                    {a.status === 'Transient' && <small>Waiting for server</small>}
                  </Col>
                </Row>
              </ListGroup.Item>
            })}
          </ListGroup>
        </Card>
      }
  </>
  );
}

export default AuctionsList;