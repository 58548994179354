import { useEffect, useState } from "react";
import { Alert, Button, ButtonGroup, ButtonToolbar, Form, InputGroup, Table } from "react-bootstrap";
import { FaSort } from "react-icons/fa";
import { HiOutlineSearch } from "react-icons/hi";
import { formatNumber, initPkgSort, isArrayEqual } from "../../Helpers";
import MySpinner from "../../MySpinner";
import IconWrapper from "../global/IconWrapper";
import NamedBidder from "../global/NamedBidder";
import DemandCurve from "./DemandCurve";

const InitPkgInfoTable = (props: any) => {
  const auction: Auction = props.auction;
  const bidders: Bidder[] = props.bidders;
  const productsDict: {[key: string]: GeneralProduct} = props.products;
  const variantRows: [string, string, boolean][] = props.variantRows;
  const minPct: number = props.minPct;
  const maxPct: number = props.maxPct;
  const paramName: string = props.paramName; // 'Priority', 'Budget', 'Price', 'DMP'
  const handleToggleSelectInitPkg = props.handleToggleSelectInitPkg;

  const [auctionInitPkgs, setAuctionInitPkgs] = useState<ABConfig[]>([]);
  const [auctionInitPkgsLoading, setAuctionInitPkgsLoading] = useState(false);
  const [auctionInitPkgsError, setAuctionInitPkgsError] = useState<string|null>(null);

  const [foundInitPkgs, setFoundInitPkgs] = useState<ABConfig[]>([]);
  const [initPkgSearch, setInitPkgSearch] = useState('');
  const [isIncludingNones, setIsIncludingNones] = useState(false);

  const [biddersDict, setBiddersDict] = useState<{ [key: string]: Bidder }>({});

  const isContainedInRows = (rows: [string, string, boolean][], checkRow: [string, string, boolean]): boolean => {
    return !!rows.find((r: [string, string, boolean])=>{
          return isArrayEqual(r, checkRow)
        })
  }

  const [currentSort, setCurrentSort] = useState<['bidder'|'lot'|'paramVal', boolean]>(['lot', true]);

  const fetchInitPkgs = (auctionId:number) => {
    setAuctionInitPkgsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/abconfigs/get/${auction.id}`)
      .then(res => {
        if(!res.ok) {
          if (res.status ===400) {
            return res.text().then(text => { throw new Error(text) })
          }
          else {
            throw new Error(`${res.status} error: ${res.statusText}`);
          }
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setAuctionInitPkgs(data.sort(initPkgSort));
        setFoundInitPkgs(filterNones(data).sort(initPkgSort));
        console.log(`fetched init pkgs for Auction ${auctionId}`);
        setAuctionInitPkgsLoading(false);
      })
      .catch(err => {
        setAuctionInitPkgsLoading(false);
        setAuctionInitPkgsError(err.message);
      });
  }
  const fetchBidders = () => {
    fetch(process.env.REACT_APP_API_URL + '/api/bidders/list/' + auction.id)
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        const dict: { [key: string]: Bidder } = {};
        data.forEach((b:Bidder)=>{
          dict[b.abbr] = b;
        });
        console.log('fetched bidders for auction ' + auction.id);
        setBiddersDict(dict);
      })
      .catch(err => {
        console.log(err.message);
      });
  }

  const filterInitPkgs: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const keyword: string = e.currentTarget.value;

    setInitPkgSearch(keyword);

    if (auctionInitPkgs && productsDict) {
      let results: ABConfig[];
      if (keyword !== '') {
          results = auctionInitPkgs.filter((ip: ABConfig) => {
            const product = productsDict[ip.product_code].open?.name || productsDict[ip.product_code].sap?.name;
            if (product) {
              return product.startsWith(keyword.toLowerCase()) || product.toLowerCase().includes(keyword.toLowerCase()) || (ip.bidder_abbr).toLowerCase().startsWith(keyword.toLowerCase()) || (ip.bidder_abbr).toLowerCase().includes(keyword.toLowerCase());
            }
            else {
              results = [];
            }
          });
      }
      else {
        results = auctionInitPkgs;
      }
      let finalArray = [...results];
      setFoundInitPkgs(finalArray.sort(initPkgSort));
    }
  }

  const filterNones = (ips: ABConfig[]) => {
    return ips.filter((ip: ABConfig)=>{
      if (paramName==='Priority') {
        return isIncludingNones || !!ip.priority
      }
      else if (paramName==='Budget') {
        return isIncludingNones || !!ip.budget
      }
      else if (paramName==='Price') {
        return isIncludingNones || !!ip.max_price
      }
      else if (paramName==='DMP') {
        return isIncludingNones || (ip.max_dmp_q1||ip.max_dmp_q2||ip.max_dmp_q3||ip.max_dmp_q4||ip.max_dmp_q5)
      }
    })
  }

  const performSort = (ips: ABConfig[]) => {
    let sortedResults: ABConfig[] = [...ips];
    if (currentSort[0]==='bidder') {
      sortedResults.sort((a:ABConfig, b:ABConfig)=>{
        return a.bidder_abbr<b.bidder_abbr?-1:(a.bidder_abbr>b.bidder_abbr?1:0)
      });
    }
    else if (currentSort[0]==='lot') {
      sortedResults.sort(initPkgSort);
    }
    else if (currentSort[0]==='paramVal') {
      if (paramName==='Priority') {
        sortedResults.sort((a:ABConfig, b:ABConfig)=>{
          let ap = a.priority || -1000000000;
          let bp = b.priority || -1000000000;
          return ap-bp
        });
      }
      else if (paramName==='Budget') {
        sortedResults.sort((a:ABConfig, b:ABConfig)=>{
          let ap = a.budget || -1000000000;
          let bp = b.budget || -1000000000;
          return ap-bp
        });
      }
      else if (paramName==='Price') {
        sortedResults.sort((a:ABConfig, b:ABConfig)=>{
          let ap = a.max_price || -100000000000000;
          let bp = b.max_price || -100000000000000;
          return ap-bp
        });
      }
    }
    if (!currentSort[1]) {
      sortedResults.reverse();
    }
    return sortedResults
  }

  useEffect(()=>{
    setFoundInitPkgs(performSort(foundInitPkgs));
  }, [currentSort]);

  useEffect(()=>{
    setFoundInitPkgs(
      performSort(filterNones(auctionInitPkgs))
    );
  }, [isIncludingNones]);

  useEffect(()=>{
    fetchInitPkgs(auction.id);
    fetchBidders();
  }, []);

  return <>
    {auctionInitPkgsLoading && <MySpinner />}
    {bidders && !auctionInitPkgsLoading && !auctionInitPkgsError && auctionInitPkgs.length===0 && <Alert>No autobidders with initial package values found for this auction.</Alert>}
    {!auctionInitPkgsLoading && !!auctionInitPkgsError && <Alert variant='danger'>{auctionInitPkgsError}</Alert>}
    {bidders && !auctionInitPkgsLoading && !auctionInitPkgsError && auctionInitPkgs.length>0 && <>
    <ButtonToolbar className="mb-1 justify-content-between">
      <InputGroup>
        <InputGroup.Text id="inputGroup-sizing-sm">
          <IconWrapper>
            <HiOutlineSearch />
          </IconWrapper>
        </InputGroup.Text>
        <Form.Control
          aria-label="Search"
          aria-describedby="inputGroup-sizing-sm"
          value={initPkgSearch}
          onChange={filterInitPkgs}
          type='search'
        />
      </InputGroup>
      <ButtonGroup className='mx-1'>
        <Button
          variant={isIncludingNones ? 'primary' : 'outline-secondary'}
          size='sm'
          onClick={()=>{setIsIncludingNones(!isIncludingNones)}}
        >
          Show None values
        </Button>
      </ButtonGroup>
    </ButtonToolbar>
    <div style={{ overflowY: 'scroll' }}>
      <Table responsive>
        {(paramName==='Priority'||paramName==='Budget'||paramName==='Price') && <>
          <thead>
            <tr>
              <th style={{ maxWidth: '80px' }}><small>Select for variation?</small></th>
              <th>
                <Button variant="link" className="p-0 text-body text-decoration-none" onClick={()=>setCurrentSort(['bidder', !currentSort[1]])}>
                  <IconWrapper>
                    <strong>Bidder</strong>
                    <FaSort />
                  </IconWrapper>
                </Button>
              </th>
              <th>
                <Button variant="link" className="p-0 text-body text-decoration-none" onClick={()=>setCurrentSort(['lot', !currentSort[1]])}>
                  <IconWrapper>
                    <strong>Lot</strong>
                    <FaSort />
                  </IconWrapper>
                </Button>
              </th>
              <th>
                <Button variant="link" className="p-0 text-body text-decoration-none" onClick={()=>setCurrentSort(['paramVal', !currentSort[1]])}>
                  <IconWrapper>
                    <strong>{paramName}</strong>
                    <FaSort />
                  </IconWrapper>
                </Button>
              </th>
              <th>Variant Min.</th>
              <th>Variant Max.</th>
            </tr>
          </thead>
          <tbody>
            {foundInitPkgs.length===0 && <tr>
              <td colSpan={6} className='text-center'>No initial package product lots found</td>
            </tr>}
            {foundInitPkgs.length>0 && foundInitPkgs.map((ip: ABConfig)=>{
                const bidder = biddersDict[ip.bidder_abbr];
                const isSa = productsDict[ip.product_code].open?.set_aside;
                if (paramName==='Priority') {
                  return <tr key={`${ip.bidder_id}-${ip.product_code}`}>
                    <td>
                      <Form.Check
                        id={`ip-${ip.bidder_id}-${ip.product_code}`}
                        checked={isContainedInRows(variantRows, [ip.bidder_abbr, ip.product_code, isSa])}
                        onChange={(e)=>handleToggleSelectInitPkg(e, ip)}
                        disabled={!ip.priority}
                      />
                    </td>
                    <td>{bidder ? (bidder.is_named ? <NamedBidder abbr={bidder.abbr} name={bidder.name} /> : bidder.name) : ip.bidder_abbr}</td>
                    <td>{productsDict[ip.product_code].open?.name || productsDict[ip.product_code].sap?.name}</td>
                    <td>{ip.priority || 'None'}</td>
                    <td>{minPct && ip.priority && variantRows.length > 0 && isContainedInRows(variantRows, [ip.bidder_abbr, ip.product_code, isSa]) ? minPct * ip.priority/100 : <em>--</em>}</td>
                    <td>{maxPct && ip.priority && variantRows.length > 0 && isContainedInRows(variantRows, [ip.bidder_abbr, ip.product_code, isSa]) ? maxPct * ip.priority/100 : <em>--</em>}</td>
                  </tr>
                }
                else if (paramName==='Budget') {
                  return <tr key={`${ip.bidder_abbr}-${ip.product_code}`}>
                    <td>
                      <Form.Check
                        id={`ip-${ip.bidder_abbr}-${ip.product_code}`}
                        checked={isContainedInRows(variantRows, [ip.bidder_abbr, ip.product_code, isSa])}
                        onChange={(e)=>handleToggleSelectInitPkg(e, ip)}
                        disabled={!ip.budget}
                      />
                    </td>
                    <td>{bidder ? (bidder.is_named ? <NamedBidder abbr={bidder.abbr} name={bidder.name} /> : bidder.name) : ip.bidder_abbr}</td>
                    <td>{productsDict[ip.product_code].open?.name || productsDict[ip.product_code].sap?.name}</td>
                    <td>{ip.budget ? formatNumber(ip.budget, true) : 'None'}</td>
                    <td>{minPct && ip.budget && variantRows.length > 0 && isContainedInRows(variantRows, [ip.bidder_abbr, ip.product_code, isSa]) ? formatNumber(minPct * ip.budget/100, true) : <em>--</em>}</td>
                    <td>{maxPct && ip.budget && variantRows.length > 0 && isContainedInRows(variantRows, [ip.bidder_abbr, ip.product_code, isSa]) ? formatNumber(maxPct * ip.budget/100) : <em>--</em>}</td>
                  </tr>
                }
                else if (paramName==='Price') {
                  return <tr key={`${ip.bidder_abbr}-${ip.product_code}`}>
                    <td>
                      <Form.Check
                        id={`ip-${ip.bidder_abbr}-${ip.product_code}`}
                        checked={isContainedInRows(variantRows, [ip.bidder_abbr, ip.product_code, isSa])}
                        onChange={(e)=>handleToggleSelectInitPkg(e, ip)}
                        disabled={!ip.max_price}
                      />
                    </td>
                    <td>{bidder ? (bidder.is_named ? <NamedBidder abbr={bidder.abbr} name={bidder.name} /> : bidder.name) : ip.bidder_abbr}</td>
                    <td>{productsDict[ip.product_code].open?.name || productsDict[ip.product_code].sap?.name}</td>
                    <td>{ip.max_price ? formatNumber(ip.max_price, true) : 'None'}</td>
                    <td>{minPct && ip.max_price && variantRows.length > 0 && isContainedInRows(variantRows, [ip.bidder_abbr, ip.product_code, isSa]) ? formatNumber(minPct * ip.max_price/100, true) : <em>--</em>}</td>
                    <td>{maxPct && ip.max_price && variantRows.length > 0  && isContainedInRows(variantRows, [ip.bidder_abbr, ip.product_code, isSa]) ? formatNumber(maxPct * ip.max_price/100, true) : <em>--</em>}</td>
                  </tr>
                }
              })
            }
          </tbody>
        </>}
        {(paramName==='DMP') && <>
          <thead>
            <tr>
              <th style={{ maxWidth: '80px' }}><small>Select for variation?</small></th>
              <th>
                <Button variant="link" className="p-0 text-body text-decoration-none" onClick={()=>setCurrentSort(['bidder', !currentSort[1]])}>
                  <IconWrapper>
                    <strong>Bidder</strong>
                    <FaSort />
                  </IconWrapper>
                </Button>
              </th>
              <th>
                <Button variant="link" className="p-0 text-body text-decoration-none" onClick={()=>setCurrentSort(['lot', !currentSort[1]])}>
                  <IconWrapper>
                    <strong>Lot</strong>
                    <FaSort />
                  </IconWrapper>
                </Button>
              </th>
              <th>$/MHz/pop. Demand Curve</th>
            </tr>
          </thead>
          <tbody>
            {foundInitPkgs.length===0 && <tr>
              <td colSpan={6} className='text-center'>No initial package product lots found</td>
            </tr>}
            {foundInitPkgs.length>0 && foundInitPkgs.map((ip: ABConfig)=>{
              const isSa = productsDict[ip.product_code].open?.set_aside;
              return <tr key={`${ip.bidder_abbr}-${ip.product_code}`}>
                <td>
                  <Form.Check
                    id={`ip-${ip.bidder_abbr}-${ip.product_code}`}
                    checked={isContainedInRows(variantRows, [ip.bidder_abbr, ip.product_code, isSa])}
                    onChange={(e)=>handleToggleSelectInitPkg(e, ip)}
                    disabled={!(ip.max_dmp_q1||ip.max_dmp_q2||ip.max_dmp_q3||ip.max_dmp_q4||ip.max_dmp_q5)}
                  />
                </td>
                <td>{ip.bidder_abbr}</td>
                <td>{productsDict[ip.product_code].open?.name || productsDict[ip.product_code].sap?.name}</td>
                <td style={{ maxWidth: '130px' }}>{(ip.max_dmp_q1||ip.max_dmp_q2||ip.max_dmp_q3||ip.max_dmp_q4||ip.max_dmp_q5) ?
                  <DemandCurve
                      demandCurveData={
                        [
                          {label: 'q1', value: ip.max_dmp_q1},
                          {label: 'q2', value: ip.max_dmp_q2},
                          {label: 'q3', value: ip.max_dmp_q3},
                          {label: 'q4', value: ip.max_dmp_q4},
                          {label: 'q5', value: ip.max_dmp_q5}
                        ]
                      }
                      disableAnimation={true}
                      minConstant={isContainedInRows(variantRows, [ip.bidder_abbr, ip.product_code, isSa]) ? minPct/100 : undefined}
                      maxConstant={isContainedInRows(variantRows, [ip.bidder_abbr, ip.product_code, isSa]) ? maxPct/100 : undefined}
                    />
                 : 'None'}</td>
              </tr>
              })
            }
          </tbody>
        </>}
      </Table>
    </div>
    </>
    }
  </>
}

export default InitPkgInfoTable;