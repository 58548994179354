import { Data, Layout } from 'plotly.js';
import { useMemo } from 'react';
import Plot from 'react-plotly.js';
import { range } from '../../Helpers';

interface Annotation {
  type: string;
  items: string[];
}

const Param3DGraph = (props:any) => {
  const title = props.title;
  const width = props.width || 900;
  const height = props.height || 800;
  const xData: number[] = props.xData;
  const yData: number[] = props.yData || undefined;
  const zData: number[] = props.zData;
  const parentData: [number, number, number] = props.parentData;

  const annotationsData: { [key: string]: Annotation } = props.annotations;
  const annotations = useMemo(()=>{
    let annotationText = '';
    if (annotationsData) {
      annotationText += '<b>' + annotationsData['A']['type'] + ' 1</b><br>';
      annotationText += annotationsData['A']['items'].join('<br>');
      if (annotationsData['B']) {
        annotationText += '<br><br><b>' + annotationsData['B']['type'] + ' 2</b><br>';
        annotationText += annotationsData['B']['items'].join('<br>');
      }
    }
    return annotationText
  }, [annotationsData]);

  const parentX: number[] = useMemo(()=>{
    // return xData.map((x:number)=>parentData[0])
    return [parentData[0]]
  }, [xData, parentData]);
  const parentY: number[] = useMemo(()=>{
    // return yData ? yData.map((y:number)=>parentData[1]) : zData.map((z:number)=>parentData[2])
    return yData ? [parentData[1]] : [parentData[2]]
  }, [yData, parentData]);
  const parentZ: number[]|undefined = useMemo(()=>{
    return yData ? [parentData[2]] : undefined
  }, [zData, parentData]);

  const surfaceZData = useMemo(()=>{
    if (yData) {
      const numX = new Set(xData).size;
      const numY = new Set(yData).size;

      // surfaceData is a matrix of dimensions numX x numY
      const surfaceData = range(numY).map((rowNum: number)=>{
        // row contains every numY-th value of zData
        return zData.filter((zVal: number, i: number)=>i%numY===rowNum) // length of this array should be numX
      });

      // console.log([...Array.from(new Set(xData))]);
      // console.log([...Array.from(new Set(yData))]);
      // console.log(surfaceData);
      return surfaceData
    }
    else {
      return null
    }
  }, [xData, yData, zData]);

  const paramValueUnits: [string, string] = props.paramValueUnits;

  const hoverTemplate = useMemo(()=>{
    if (yData) {
      // 2 params
      return `<b><i>Variant %{text}</i></b><br><b>${props.xLabel}:</b> %{x:.2f}${paramValueUnits[0]}<br><b>${props.yLabel}:</b> %{y:.2f}${paramValueUnits[1]}<br>$%{z:,.2f}`
    }
    else {
      // 1 param
      return `<b><i>Variant %{text}</i></b><br><b>${props.xLabel}:</b> %{x:.2f}${paramValueUnits[0]}`
    }
  }, [yData]);

  const layoutConfig: Partial<Layout> = useMemo(()=>{
    if (yData) { // 3D layout
      return {
        title: title,
        autosize: false,
        width: width,
        height: height,
        margin: {
          l: 80,
          b: 80,
          t: 80,
          r: 80,
          pad: 30
        },
        annotations: annotationsData ? [{
          visible: true,
          align: 'left',
          valign: 'bottom',
          xref: 'paper',
          yref: 'paper',
          xanchor: 'right',
          yanchor: 'bottom',
          showarrow: false,
          x: 1.2,
          y: 0,
          text: annotations
        }] : undefined,
        scene: {
          aspectmode: 'auto',
          aspectratio: {
            x: 0,
            y: 5,
            z: 0
          },
          camera: {
            eye: {
              x: 1.2,
              y: 2.1,
              z: 0
            }
          },
          bgcolor: 'rgba(214,214,214)',
          xaxis: {
            showaxeslabels: true,
            title: `${props.xLabel} (${paramValueUnits[0]})`,
            exponentformat: 'none'
          },
          yaxis: {
            showaxeslabels: true,
            title: yData ? `${props.yLabel} (${paramValueUnits[1]})` : props.zLabel,
            exponentformat: 'none'
          },
          zaxis: yData ? {
            showaxeslabels: true,
            title: props.zLabel,
            exponentformat: 'none'
          } : undefined
        }
      }
    }
    else { // 2D layout
      return {
        title: title,
        autosize: false,
        width: width,
        height: height,
        margin: {
          l: 100,
          r: 50,
          pad: 2
        },
        xaxis: yData ? undefined : {
          title: `${props.xLabel} (${paramValueUnits[0]})`,
          exponentformat: 'none'
        },
        yaxis: yData ? undefined : {
          title: props.zLabel,
          exponentformat: 'none'
        }
      }
    }
  }, [yData]);

  const dataObj: Data[] = useMemo(()=>{
    if (yData && surfaceZData) {
      // 3D data
      return [
          {
            x: xData as number[],
            y: yData,
            z: zData,
            type: 'scatter3d',
            mode: 'markers',
            hovertemplate: hoverTemplate,
            text: props.variantNums,
            marker: {
              color: '#172b6f',
              opacity: 0.8,
              size: 8
            },
            name: 'Current Variation'
          },
          // {
          //   x: xData as number[],
          //   y: yData,
          //   z: zData,
          //   type: 'mesh3d',
          //   opacity: 0.1,
          //   alphahull: 3
          // },
          {
            x: [...Array.from(new Set(xData))],
            y: [...Array.from(new Set(yData))],
            z: surfaceZData,
            type: 'surface',
            displayModeBar: false,
            hoverinfo: 'none',
            showscale: false,
            showlegend: false,
            colorscale: [[0, 'rgba(45, 79, 111,0.2)'], [1, 'rgba(45, 79, 111,1)']],
            opacity: 0.8
          },
          {
            x: parentX,
            y: parentY,
            z: parentZ,
            type: 'scatter3d',
            showscale: false,
            mode: 'markers',
            hovertemplate: `$%{z:,}`,
            colorscale: [[0, '#8D2C2F)'], [1, '#8D2C2F']],
            marker: {
              color: '#8D2C2F',
              opacity: 0.8,
              size: 16
            },
            name: 'Parent Auction'
          }
        ]
    }
    else {
      // 2D data
      return [
          {
            x: xData as number[],
            y: zData,
            z: undefined,
            type: 'scatter',
            mode: 'markers',
            hovertemplate: hoverTemplate,
            text: props.variantNums,
            marker: {
              color: '#172b6f',
              opacity: 0.9,
              size: 8
            },
            name: 'Variants'
          },
          {
            x: parentX,
            y: parentY,
            z: parentZ,
            type: 'scatter',
            showscale: yData ? false : true,
            mode: 'markers',
            hovertemplate: `$%{y:,}`,
            colorscale: [[0, '#8D2C2F)'], [1, '#8D2C2F']],
            marker: {
              color: '#8D2C2F',
              opacity: 0.9,
              size: 16
            },
            name: 'Parent Auction'
          }
        ]
    }
  }, [layoutConfig, xData, yData, zData, title]);

  return (<>
    <Plot
      debug={true}
      data={dataObj}
      layout={layoutConfig}
    />
  </>)
}

export default Param3DGraph;