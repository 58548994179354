import React, { useEffect, useState } from 'react';
import * as xlsx from 'xlsx';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';
import PendingButton from '../global/PendingButton';
import FileSaver from 'file-saver';

const DownloadExcelTemplate = (props: any) => {
  const auction: Auction = props.auction;
  const bidder: Bidder = props.bidder;
  const products: SpecificProduct[] = props.allProducts;
  const prices: Price[] = props.allPrices;
  const allMyPDemands: PDemand[] = props.allMyPDemands;

  const [downloadPending, setDownloadPending] = useState(false);

  const downloadExcelTemplate = () => {
    setDownloadPending(true);
    const filename = `My-Clock-Bids_${auction.name.replaceAll(' ', '-').slice(0, 8)}_${bidder.abbr.replaceAll(' ', '-')}`.slice(0,25) + '.xlsx';
    createTemplate(filename);

    console.log('downloading bids Excel');
  }

  const createTemplate = (filename: string) => {

    const bidsObjectArray = products
      .sort((a:SpecificProduct,b:SpecificProduct)=>{
        if (a.code<b.code) { return -1 }
        else if (a.code>b.code) { return 1 }
        else { return 0 }
      })
      .map((p: SpecificProduct) => {
        return {
          'Round': auction.round_num,
          'Company ID': bidder.id,
          'Company Name': bidder.name,
          'Product Number': p.code,
          'Category': p.set_aside ? '' : 'Open',
          'Service Area Name': p.name.slice(8),
          'Eligibility Points': p.ep,
          'Round Start Price': auction.round_num>1 ? prices.find((price: Price)=>price.product_code===p.code&&price.round_num===auction.round_num)?.start_price : p.initial_price,
          'Clock Price': auction.round_num>1 ? prices.find((price: Price)=>price.product_code===p.code&&price.round_num===auction.round_num)?.clock_price : p.initial_price,
          'Bid Quantity': auction.round_num>1 ? allMyPDemands.find((pd: PDemand)=>pd.auction_id===auction.id&&pd.bidder_id===bidder.id&&pd.product_code===p.code&&pd.round_num===auction.round_num-1)?.value : '',
          'Bid Price ($)': auction.round_num>1 ? prices.find((price: Price)=>price.product_code===p.code&&price.round_num===auction.round_num)?.clock_price : ''
        }
      });
    const bidsSheet = xlsx.utils.json_to_sheet(bidsObjectArray);
    let workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, bidsSheet, filename);
    xlsx.writeFile(workbook, filename);
    setDownloadPending(false);
  }


    return (
      <Modal
        show={props.show}
        onHide={props.onHide}
        animation={true}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Download Bids Excel Template
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex justify-content-center'>
            Click to download ISED Excel template for bids:
          </div>
          <div className='d-flex justify-content-center mt-3'>
            <ButtonGroup>
              <PendingButton onClick={downloadExcelTemplate} pending={downloadPending}>Download</PendingButton>{' '}
              <Button onClick={props.onHide} variant='outline-primary'>Cancel</Button>
            </ButtonGroup>
          </div>
        </Modal.Body>
      </Modal>
    );
};

DownloadExcelTemplate.displayName = 'DownloadExcelTemplate';

export default DownloadExcelTemplate;
