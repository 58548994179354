import { Breadcrumb, Button, Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import nesglobe from "../../nesglobe.png";

const QueueNav = (props:any) => {
  // const [showSimulationsQueue, setShowSimulationsQueue] = useState(false);

  return (
    <Navbar expand='lg' bg='light'>
      <Container fluid>
        <Navbar.Brand className='fs-6'>
            <img className='logo' src={nesglobe} alt="NE&S 3800 MHz Clock Auction Simulation Tool" />
        </Navbar.Brand>
        <Navbar.Text>
          <Breadcrumb>
            <Breadcrumb.Item href='/web/variations/queue'>
              Variations Queue
            </Breadcrumb.Item>
          </Breadcrumb>
        </Navbar.Text>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text className="ms-1">
            <Link to='/web/auction-cp'>
              <Button variant="secondary" size="sm">Auction CP</Button>
            </Link>
          </Navbar.Text>
          <Navbar.Text className='ms-1'>
            <Link to='/web' onClick={() => props.handleLogin(null, false)}>
              <Button variant='danger' size='sm'>Logout</Button>
            </Link>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    );
}

export default QueueNav;