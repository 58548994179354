import { ChangeEvent, useMemo, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

const VaryIncPercent = (props:any) => {
  const auction: Auction = props.auction;
  const alreadyConfirmedValues = props.paramValue && (props.paramValue as RangeAuc).values.length > 0 ? (props.paramValue as RangeAuc).values : [];
  const [values, setValues] = useState<number[]>(alreadyConfirmedValues);
  const isValid = useMemo(()=>{
    return values.length > 0
  }, [values]);

  const handleSaveValue = () => {
    props.onValidate(values.length > 0, { 'IncPct': {'values': values } }, values.length, props.currentParam);
  }

  const handleToggleSelectValue = (e: ChangeEvent<HTMLInputElement>, v: number): void => {
    const isOn = (e.currentTarget as HTMLInputElement).checked;

    let updatedValues: number[] = [];

    if (!isOn) { // turn OFF
      values.forEach((val: number) => {
        if (val!==v) {
          updatedValues.push(val);
        }
      });
    }
    else { // turn ON
      updatedValues = [...values];
      updatedValues.push(v);
    }

    setValues(updatedValues);
  }

  return (
    <>
      <Row className='mb-3'>
        <Col sm={4}>
          <Card className='h-100'>
            <Card.Header className='text-center'>
              <span className="fw-bold">Parent Auction Values</span>
            </Card.Header>
            <Card.Body className='text-center'>
              <div className='mb-3'>
                <h6>Parent Auction</h6>
                Increment Percentage: <span className='fw-bold'>{auction.inc_pct}</span> %
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header className='text-center'>
              <span className="fw-bold">Variant Simulations</span>
            </Card.Header>
            <Card.Body>
              <h6>
                Create simulation auctions with the following increment percentage values:
              </h6>
              <div>
                <Form>
                  {[5, 10, 15, 20, 25, 30].map((val: number)=>{
                    return <div key={val} className='mb-3'>
                      <Form.Check
                        type='checkbox'
                        id={`val-${val}`}
                        label={`${val}%`}
                        checked={values.includes(val)}
                        onChange={(e)=>handleToggleSelectValue(e, val)}
                      />
                    </div>
                  })}
                </Form>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            variant="primary"
            className='float-end my-3'
            disabled={!isValid}
            onClick={()=>handleSaveValue()}
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
    )
}

export default VaryIncPercent;