import { useState } from "react";
import { Badge, Button, OverlayTrigger } from "react-bootstrap";
import { PARAM_DICTIONARY } from "../../Helpers";
import OffcanvasPane from "./OffcanvasPane";
import ParamSummaryWindow from "./ParamSummaryWindow";
import VariationSummary from "./VariationSummary";

const ParamBadge = (props:any) => {
  const rpSet: RangeParam[] = props.rpSet;
  const vpSet: ValueParam[] = props.vpSet;
  const rp: RangeParam = props.rp;
  const vp: ValueParam = props.vp;
  const position = props.position || 'bottom';
  const parentAuction: Auction = props.parentAuction;

  const title = props.title;

  const [showVariationSummary, setShowVariationSummary] = useState(false);
  const [showVariantSummary, setShowVariantSummary] = useState(false);

  return (<>
    {rp && rp!=='NoParam' &&
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement={position}
        delay={{ show: 250, hide: 150 }}
        overlay={<ParamSummaryWindow rp={rp} />}
      >
        <Button
          variant='link'
          className='text-decoration-none m-0 p-0'
          onClick={()=>setShowVariationSummary(true)}
        >
          <Badge
            className={props.className + ' badge-purple'}
          >
            {rp && !vp && <>{PARAM_DICTIONARY[Object.keys(rp)[0]].name}</>}
          </Badge>
        </Button>
      </OverlayTrigger>
    }
    {vp && vp!=='NoParam' &&
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement={position}
        delay={{ show: 250, hide: 150 }}
        overlay={<ParamSummaryWindow vp={vp} />}
      >
        <Button
          variant='link'
          className='text-decoration-none m-0 p-0'
          onClick={()=>setShowVariantSummary(true)}
        >
          <Badge
            bg='light'
            text='dark'
            className={props.className + ' badge-light-purple'}
          >
            {!rp && vp && <>{PARAM_DICTIONARY[Object.keys(vp)[0]].name}</>}
          </Badge>
        </Button>
      </OverlayTrigger>
    }
    {
      rpSet && <OffcanvasPane style={{ height: '360px' }} show={showVariationSummary} onHide={()=>setShowVariationSummary(false)} placement="bottom" title={`Varied Parameters for ${title}`}>
        <div className='d-flex justify-content-center'>
          <VariationSummary rps={rpSet} parentAuction={parentAuction} />
        </div>
      </OffcanvasPane>
    }
    {
      vpSet && <OffcanvasPane style={{ height: '360px' }} show={showVariantSummary} onHide={()=>setShowVariantSummary(false)} placement="bottom" title={`Varied Parameters for ${title}`}>
        <div className='d-flex justify-content-center'>
          <VariationSummary vps={vpSet} parentAuction={parentAuction} />
        </div>
      </OffcanvasPane>
    }
  </>)
}

export default ParamBadge;