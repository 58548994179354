import { useEffect, useMemo, useState } from "react";
import { IoIosDownload } from "react-icons/io";
import { Badge, Breadcrumb, Button, Container, Navbar, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AUCTION_STATUS, isDeepEqual } from "../../Helpers";
import nesglobe from "../../nesglobe.png";

const AuctionNavBar = (props:any) => {
  const auction: Auction = props.auction;
  const updatedAuction: Auction = props.updatedAuction;
  const handleReload = props.handleReload;
  const currentUser: [string, string] = props.currentUser;
  const bidder: Bidder|null = props.bidder;
  const isAdmin: boolean = props.isAdmin;
  const auctionStatus: string = props.auctionStatus;
  const handleLogin = props.handleLogin;

  const auctionChanged = useMemo(() => {
    if (updatedAuction) {
      return !isDeepEqual(auction, updatedAuction)
    }
    else {
      return false
    }
  }, [auction, updatedAuction]);

  // const pendingBidders = props.pendingBidders; // null for non-Admin

  const updatePending = props.updatePending; // is an auction update fetch currently pending?
  // const [statusStyle, setStatusStyle] = useState('');

  // useEffect(()=> {
  //   console.log('rendering AuctionNavBar');
  // }, [auction]);

    return (
        <Navbar expand='lg' bg='light'>
          <Container fluid>
            <Navbar.Brand className='fs-6'>
                <img className='logo' src={nesglobe} alt="NE&S 3800 MHz Clock Auction Simulation Tool" />
            </Navbar.Brand>
            <Navbar.Text>
              <Breadcrumb>
                <Breadcrumb.Item href='/web/auction-cp'>
                  Auctions List
                </Breadcrumb.Item>
                <Breadcrumb.Item href={`/web/auction/${auction.id}`}>
                  {auction.status==='Ended' ? 'Auction Data' : 'Bidding'}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  User: <strong>{isAdmin ? 'Admin' : currentUser[1]}</strong> | Auction: <strong>{auction.name}</strong> - Round {auction.round_num} <Badge pill bg={auctionStatus === AUCTION_STATUS.paused ? 'warning' : undefined}>{auctionStatus}</Badge>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Navbar.Text>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text className='px-3'>
                <Button
                  onClick={handleReload}
                  size='sm'
                  variant={auctionChanged ? 'outline-primary' : 'outline-secondary' }
                  disabled={!auctionChanged}
                >
                  {!updatePending && !auctionChanged && 'Up to date'}
                  {!updatePending && auctionChanged &&
                    <>
                      Updates available <IoIosDownload />
                    </>
                  }
                  {updatePending && <>Checking for auction updates <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    /></>}
                </Button>
              </Navbar.Text>
              <Navbar.Text className='px-1'>
                <Link to='/web/auction-cp'>
                  <Button variant='secondary' size='sm'>Back</Button>
                </Link>
              </Navbar.Text>
              <Navbar.Text className='px-1'>
                <Link to='/web' onClick={() => handleLogin(null, false)}>
                  <Button variant='danger' size='sm'>Logout</Button>
                </Link>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
    );
};

AuctionNavBar.displayName = 'AuctionNavBar';

export default AuctionNavBar;
