import { useEffect, useState } from "react";
import { Nav, Spinner, Tab, Table } from "react-bootstrap";
import { MDBDataTable } from 'mdbreact';
import { formatNumber } from "../../Helpers";
import { DynamicTable } from "../global/DynamicTable";

interface T2DataTableItem {
  service_area: string;
  name: string;
  population: number;
  subtiers: string[];
}
interface T4DataTableItem {
  service_area: string;
  name: string;
  population: number;
  tier2: string;
  tier3: string;
}

const RegionsView = () => {
  const columnsT2 = [
    {
      title: 'Service Area',
      key: 'service_area',
      sort: (a:T2DataTableItem, b:T2DataTableItem) => a.service_area<b.service_area?1:(a.service_area>b.service_area?-1:0),
      width: 30
    },
    {
      title: 'Name',
      key: 'name',
      sort: (a:T2DataTableItem, b:T2DataTableItem) => a.name<b.name?1:(a.name>b.name?-1:0),
      width: 130,
      searchable: true
    },
    {
      title: 'Population',
      key: 'population',
      sort: (a:T2DataTableItem, b:T2DataTableItem) => a.population-b.population,
      render: (_: any, item:T2DataTableItem)=>formatNumber(item.population),
      width: 60
    },
    {
      title: 'Sub-tiers',
      key: 'subtiers',
      width: 180,
      render: (_: any, item:T2DataTableItem) => item.subtiers.join(', ')
    }
  ];
  const columnsT4 = [
    {
      title: 'Service Area',
      key: 'service_area',
      sort: (a:T4DataTableItem, b:T4DataTableItem) => a.service_area<b.service_area?1:(a.service_area>b.service_area?-1:0),
      width: 50,
      searchable: true
    },
    {
      title: 'Name',
      key: 'name',
      sort: (a:T4DataTableItem, b:T4DataTableItem) => a.name<b.name?1:(a.name>b.name?-1:0),
      width: 150,
      searchable: true
    },
    {
      title: 'Population',
      key: 'population',
      sort: (a:T4DataTableItem, b:T4DataTableItem) => a.population-b.population,
      render: (_: any, item:T4DataTableItem)=>formatNumber(item.population),
      width: 80
    },
    {
      title: 'Tier 2',
      key: 'tier2',
      width: 50,
      searchable: true
    },
    {
      title: 'Tier 3',
      key: 'tier3',
      width: 50,
      searchable: true
    }
  ];

  const [regionsT2Data, setRegionsT2Data] = useState<any[]>([]);
  const [loadingT2Data, setLoadingT2Data] = useState(true);
  const [regionsT2ErrorMsg, setRegionsT2ErrorMsg] = useState<null|string>(null);

  const [regionst2Array, setRegionst2Array] = useState<T2DataTableItem[]>([]);
  const [regionst4Array, setRegionst4Array] = useState<T4DataTableItem[]>([]);

  const [regionsT4Data, setRegionsT4Data] = useState<any[]>([]);
  const [loadingT4Data, setLoadingT4Data] = useState(true);
  const [regionsT4ErrorMsg, setRegionsT4ErrorMsg] = useState<null|string>(null);

  const fetchRegionsT2Data = () => {
    fetch(process.env.REACT_APP_API_URL + '/api/regionst2/list')
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setRegionsT2Data(data);
        setLoadingT2Data(false);
        console.log('fetched T2 region data');
      })
      .catch(err => {
        setLoadingT2Data(false);
        setRegionsT2ErrorMsg(err.message);
      });
  }
  const fetchRegionsT4Data = () => {
    fetch(process.env.REACT_APP_API_URL + '/api/regionst4/list')
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setRegionsT4Data(data);
        setLoadingT4Data(false);
        console.log('fetched T4 region data');
      })
      .catch(err => {
        setLoadingT4Data(false);
        setRegionsT4ErrorMsg(err.message);
      });
  }

  useEffect(() => {
    fetchRegionsT2Data();
    fetchRegionsT4Data();
  }, []);

  useEffect(() => {
    let regionst2Values:T2DataTableItem[] = [];
    (Object.values(regionsT2Data) as ExcelRegionT2[]).map((region: ExcelRegionT2) => {
      regionst2Values.push({
        service_area: region.sa,
        name: region.san,
        population: region.population,
        subtiers: region.tier3.concat(region.tier4)
      });
    });
    setRegionst2Array(regionst2Values);
  }, [regionsT2Data]);

  useEffect(() => {
    let regionst4Values:T4DataTableItem[] = [];
    (Object.values(regionsT4Data) as ExcelRegionT4[]).map((region: ExcelRegionT4) => {
      regionst4Values.push({
        service_area: region.sa,
        name: region.san,
        population: region.population,
        tier2: region.tier2,
        tier3: region.tier3
      });
    });
    setRegionst4Array(regionst4Values);
  }, [regionsT4Data]);

    return (
      <Tab.Container defaultActiveKey='tier-2'>
        <Nav className='mt-3 justify-content-center' variant="pills" defaultActiveKey="tier-2">
          <Nav.Item>
            <Nav.Link eventKey="tier-2">Tier 2</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="tier-4">Tier 4</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey='tier-2'>
            <div className='mt-3'>
            {!loadingT2Data && regionst2Array &&
            <DynamicTable
              data={regionst2Array}
              columns={columnsT2}
            />
            }
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey='tier-4'>
            <div className='mt-3'>
            {!loadingT4Data && regionst4Array &&
            <DynamicTable
              data={regionst4Array}
              columns={columnsT4}
            />
            }
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    );
};

RegionsView.displayName = 'RegionsView';

export default RegionsView;
