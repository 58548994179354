import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Alert, Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { isArrayEqual } from "../../Helpers";
import MySpinner from "../../MySpinner";
import useFetch from "../../useFetch";
import InitPkgInfoTable from "./InitPkgInfoTable";

const VaryABC = (props:any) => {
  const paramName: string = props.paramName;
  const PARAMETERS = props.parameters;

  const auction: Auction = props.auction;
  const { data: bidders, isPending: biddersLoading, error: biddersError} = useFetch(`${process.env.REACT_APP_API_URL}/api/bidders/list/${auction.id}`);
  const { data: products, isPending: productsLoading, error: productsError } = useFetch(`${process.env.REACT_APP_API_URL}/api/products/list`);

  const confirmedConfig: RangeABC = props.paramValue;
  const confirmedRows: [string, string, boolean][] = confirmedConfig ? confirmedConfig.rows : [];
  const confirmedMin: number = confirmedConfig ? Math.floor(confirmedConfig.start_pct) : 50;
  const confirmedMax: number = confirmedConfig ? Math.floor(confirmedConfig.end_pct) : 150;
  const confirmedCount: number = confirmedConfig ? confirmedConfig.count : 5;

  const [currentBidder, setCurrentBidder] = useState<Bidder|null>(null); // for browsing parent auction only

  const [rows, setRows] = useState(confirmedRows);
  const [minPct, setMinPct] = useState(confirmedMin);
  const [maxPct, setMaxPct] = useState(confirmedMax);
  const [count, setCount] = useState(confirmedCount);

  const isValid = useMemo(()=>{
    return rows.length > 0 && minPct < maxPct
  }, [rows, minPct, maxPct]);

  const handleToggleSelectInitPkg = (e: ChangeEvent<HTMLInputElement>, ip: ABConfig): void => {
    const isOn = (e.currentTarget as HTMLInputElement).checked;

    // let updatedABConfigs: ABConfig[] = [];
    let updatedRows: [string, string, boolean][] = [];

    if (!isOn) { // turn OFF
      rows.forEach((r: [string, string, boolean])=> {
        if (!isArrayEqual(r, [ip.bidder_abbr, ip.product_code, products[ip.product_code].open?.set_aside])) {
          updatedRows.push(r);
        }
      });
    }
    else { // turn ON
      updatedRows = [...rows];
      // updatedABConfigs.push(ip);
      updatedRows.push([ip.bidder_abbr, ip.product_code, products[ip.product_code].open?.set_aside]);
    }

    setRows(updatedRows);
  }

  const handleSaveValue = () => {
    let paramConfig: RangeABC;
    paramConfig = {
      'rows': rows,
      'start_pct': minPct,
      'end_pct': maxPct,
      'count': count
    };

    props.onValidate(rows.length > 0 && minPct < maxPct, {
      [paramName]: paramConfig
    }, count, props.currentParam);
  }

  useEffect(()=>{
    console.log(rows);
  }, [rows]);

  useEffect(()=>{
    if (confirmedRows.length > 0 && bidders && products) {
      setRows(confirmedRows);
    }
  }, [bidders, products]);

  // useEffect(()=>{
  //   const updatedRows: [string, string, boolean][] = [];
  //   if (bidders && selectedABConfigs && products && confirmedRows.length > 0) {
  //     selectedABConfigs.forEach((ip: ABConfig)=>{
  //       const isSa: boolean = !!products[ip.product_code].open?.set_aside;
  //       updatedRows.push([bidders.find((b:Bidder)=>b.id===confirmedRows[0][0]).id, ip.product_code, isSa]);
  //     });
  //     setRows(updatedRows);
  //   }
  // }, [selectedABConfigs, confirmedRows]);

  return <>
    <div className='d-flex flex-column h-100'>
      {/*<Row className='mb-3' style={{ maxHeight: '320px', height: '30vh', minHeight: '200px' }}>*/}
      <Row className='mb-3'>
        <Col md={8} className="align-self-stretch">
          <Card className='h-100'>
            <Card.Header className='text-center'>
              <span className="fw-bold">
                Initial Package Values ({PARAMETERS.find((p: any)=>p['abbr']===paramName)['name']})
              </span>
            </Card.Header>
            <Card.Body className='text-center' style={{ maxHeight: '600px', overflowY: 'scroll' }}>
              <div className='d-flex'>
                <div className='px-2 flex-fill'>
                  {productsLoading && <MySpinner />}
                  {!biddersLoading && !biddersError && bidders && !productsLoading && !productsError && products &&
                    <InitPkgInfoTable
                      auction={auction}
                      bidders={bidders}
                      products={products}
                      variantRows={rows}
                      minPct={minPct}
                      maxPct={maxPct}
                      paramName={paramName}
                      handleToggleSelectInitPkg={handleToggleSelectInitPkg}
                    />
                  }
                  {currentBidder && !currentBidder.autobidder && <Alert className='p-2'><small>No initial packages available: not an autobidder.</small></Alert>}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      {/*</Row>
            <Row className='mb-3 flex-grow-1'>*/}
        <Col>
          <Card>
            <Card.Header className='text-center'>
              <span className="fw-bold">Parameter Configuration</span>
            </Card.Header>
            <Card.Body>
              {!!products &&
              <div className='d-flex flex-column mb-4'>
                <p>Selected lots will vary parameter values with the following configuration:</p>
                {rows.length===0 && <Alert>
                  No initial package lots have been selected.
                </Alert>}
                {rows.length > 0 &&
                <Form>
                  <div className='mb-2'>For the bidders, simulation auctions should vary <span className='fw-bold'>{paramName==='DMP' ? '$/MHz./pop' : paramName}</span> values between (% amount of of original values):</div>
                  <div className='d-flex align-items-center justify-content-center my-4'>
                    <InputGroup style={{ width: '180px' }}>
                      <Form.Control
                        value={minPct}
                        type='number'
                        step={1}
                        min={1}
                        isValid={minPct < maxPct}
                        isInvalid={minPct >= maxPct}
                        onChange={(e)=>setMinPct(parseInt(e.target.value))}
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                    <div className='p-2'>
                      and
                    </div>
                    <InputGroup style={{ width: '180px' }}>
                      <Form.Control
                        value={maxPct}
                        type='number'
                        step={1}
                        min={2}
                        isValid={minPct < maxPct}
                        isInvalid={minPct >= maxPct}
                        onChange={(e)=>setMaxPct(parseInt(e.target.value))}
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                  </div>
                  <div className='my-2'>Enter a desired number of variant simulated auctions based on varying this parameter:</div>
                  <div className='d-flex align-items-center justify-content-center my-1'>
                    <InputGroup style={{ width: '180px' }}>
                      <Form.Control
                          value={count}
                          min={1}
                          step={1}
                          type='number'
                          isValid={count<maxPct-minPct&&count>=1}
                          isInvalid={count>=maxPct-minPct||count<1}
                          onChange={(e)=>setCount(parseInt(e.target.value))}
                        />
                      <InputGroup.Text>variants</InputGroup.Text>
                    </InputGroup>
                  </div>
                </Form>
                }
              </div>
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            variant="primary"
            className='float-end my-3'
            disabled={!isValid}
            onClick={()=>handleSaveValue()}
          >
            Save
          </Button>
        </Col>
      </Row>
    </div>
  </>
}

export default VaryABC;