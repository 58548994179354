import { useState, useEffect, memo } from "react";
import { Button, ButtonGroup, ButtonToolbar, Card, Col, Container, DropdownButton, Row, Tab} from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { bidderObjSort, formatNumber, range } from "../../Helpers";
import MySpinner from "../../MySpinner";
import { DynamicTable } from "../global/DynamicTable";
import IconWrapper from "../global/IconWrapper";
import NamedBidder from "../global/NamedBidder";
import PendingButton from "../global/PendingButton";

interface DataTableItem {
  round_num: number;
  lot: string;
  supply: number;
  quantity: [number, number];
  agg_demand: [number, number];
  excess_demand: [number, number];
  start_price: [number, number];
  clock_price: [number, number];
  posted_price: [number, number];
  total_cost: [number, number];
  pop_dollar: [number, number];
}

const BidderResults = (props: any) => {
  const auction: Auction = props.auction;
  const productsDict = props.productsDict;

  const [biddersByAuction, setBiddersByAuction] = useState<null|Bidder[]>(null);
  const [biddersByAuctionLoading, setBiddersByAuctionLoading] = useState(true);
  const [biddersByAuctionError, setBiddersByAuctionError] = useState<null|string>(null);

  const [selectedBidder, setSelectedBidder] = useState<Bidder|null>(null);
  const [selectedRound, setSelectedRound] = useState<number|null>(null);

  const [allPDemands, setAllPDemands] = useState<PDemand[]>([]);
  const [allPDemandsLoading, setAllPDemandsLoading] = useState(false);
  const pricesFetch = props.pricesFetch;
  const pricesFetchLoading = props.pricesFetchLoading;
  // const [allPrices, setAllPrices] = useState<Price[]>([]);
  // const [allPricesLoading, setAllPricesLoading] = useState(false);

  const [currentlyDownloadingMyBids, setCurrentlyDownloadingMyBids] = useState(false);

  const [bidderResultsArray, setBidderResultsArray] = useState<DataTableItem[]>([]);
  const [bidderResultsDataTable, setBidderResultsDataTable] = useState<any>({});
  const columns = [
    {
      title: 'Round',
      key: 'round_num',
      // sort: (a:DataTableItem, b:DataTableItem) => a.round_num-b.round_num,
      width: 20
    },
    {
      title: 'Lot',
      key: 'lot',
      sort: (a:DataTableItem, b:DataTableItem) => a.lot<b.lot?1:(a.lot>b.lot?-1:0),
      width: 100,
      searchable: true
    },
    {
      title: 'Product Supply',
      key: 'supply',
      width: 40
    },
    {
      title: 'Proc. Demand',
      key: 'quantity',
      width: 40,
      render: (_: any, item:DataTableItem) => <span className={item.quantity[1]>=0&&item.quantity[0]-item.quantity[1]>0?'text-success':(item.quantity[0]-item.quantity[1]<0?'text-danger':undefined)}>{item.quantity[0]<0?'N/A':`${item.quantity[0]}${item.quantity[1]>=0?` (${item.quantity[0]-item.quantity[1]})`:''}`}</span>
    },
    {
      title: 'Agg. Demand',
      key: 'agg_demand',
      width: 40,
      render: (_: any, item:DataTableItem) => <span className={item.agg_demand[1]>=0&&item.agg_demand[0]-item.agg_demand[1]>0?'text-success':(item.agg_demand[0]-item.agg_demand[1]<0?'text-danger':undefined)}>{item.agg_demand[0]<0?'N/A':`${item.agg_demand[0]}${item.agg_demand[1]>=0?` (${item.agg_demand[0]-item.agg_demand[1]})`:''}`}</span>
    },
    {
      title: 'Excess Demand',
      key: 'excess_demand',
      width: 40,
      // // show N/A
      // render: (_: any, item:DataTableItem) => <span className={item.excess_demand[1]>=0&&item.excess_demand[0]-item.excess_demand[1]>0?'text-success':(item.excess_demand[0]-item.excess_demand[1]<0?'text-danger':undefined)}>{item.excess_demand[0]<0?'N/A':`${item.excess_demand[0]}${item.excess_demand[1]>=0?` (${item.excess_demand[0]-item.excess_demand[1]})`:''}`}</span>
      // // show actual negative value
      render: (_: any, item:DataTableItem) => <span className={item.excess_demand[1]>=0&&item.excess_demand[0]-item.excess_demand[1]>0?'text-success':(item.excess_demand[0]-item.excess_demand[1]<0?'text-danger':undefined)}>{`${item.excess_demand[0]}${item.excess_demand[1]>=0?` (${item.excess_demand[0]-item.excess_demand[1]})`:''}`}</span>
    },
    {
      title: 'Start Price',
      key: 'start_price',
      width: 100,
      render: (_: any, item:DataTableItem) => <span>{formatNumber(item.start_price[0], true)}</span>,
      sort: (a:DataTableItem, b:DataTableItem) => a.clock_price[0]-b.clock_price[0]
    },
    {
      title: 'Clock Price',
      key: 'clock_price',
      width: 100,
      render: (_: any, item:DataTableItem) => <span>{formatNumber(item.clock_price[0], true)}</span>,
      sort: (a:DataTableItem, b:DataTableItem) => a.clock_price[0]-b.clock_price[0]
    },
    {
      title: 'Posted Price',
      key: 'posted_price',
      width: 100,
      render: (_: any, item:DataTableItem) => <span>{formatNumber(item.posted_price[0], true)}</span>,
      sort: (a:DataTableItem, b:DataTableItem) => a.posted_price[0]-b.posted_price[0]
    },
    {
      title: '$/MHz/pop',
      key: 'pop_dollar',
      width: 40,
      sort: (a:DataTableItem, b:DataTableItem) => a.pop_dollar[0]-b.pop_dollar[0],
      render: (_: any, { pop_dollar }: any) => <span className={pop_dollar[1]>=0&&pop_dollar[0]-pop_dollar[1]>0?'text-success':(pop_dollar[0]-pop_dollar[1]<0?'text-danger':undefined)}>{pop_dollar[0]<0?'N/A':`${formatNumber(pop_dollar[0], true)}${pop_dollar[1]>=0?` (${formatNumber(pop_dollar[0]-pop_dollar[1], true)})`:''}`}</span>
    }
  ];

  const fetchBiddersByAuction = (auctionId:number) => {
    fetch(process.env.REACT_APP_API_URL + '/api/bidders/list/' + auctionId)
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setBiddersByAuction(data);
        console.log('fetched bidders for auction ' + auctionId);
        setBiddersByAuctionLoading(false);
      })
      .catch(err => {
        setBiddersByAuctionLoading(false);
        setBiddersByAuctionError(err.message);
      });
  }

  const handleClickBidder = (bidder: Bidder) => {
    setSelectedBidder(bidder);
    if (!selectedRound) {
      setSelectedRound(auction.round_num);
    }
  }

  const handleClickRound = (r: number) => {
    setSelectedRound(r);
  }

  const showBidderPDemands = (bidderId: number) => {
    setAllPDemandsLoading(true);
    fetch(process.env.REACT_APP_API_URL + `/api/pdemands/get/${auction.id}?bidder_id=${bidderId}`)
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setAllPDemands(data);
        setAllPDemandsLoading(false);
      })
      .catch(err => {
        console.log(err)
      });
  }

  const getPrice = (productCode: string, round: number): Price => {
    const foundPrice: Price = pricesFetch.find((priceObj:Price)=>priceObj.product_code === productCode && priceObj.round_num === round);
    return foundPrice
  }
  const getAggDemand = (productCode: string, round: number) => {
    const foundPrice: Price = pricesFetch.find((priceObj:Price)=>priceObj.product_code === productCode && priceObj.round_num === round);

    return foundPrice?.agg_demand
  }
  const getExcessDemand = (productCode: string, round: number) => {
    const aggDemand = getAggDemand(productCode, round);

    if (aggDemand > -1) {
      return aggDemand - productsDict[productCode]['open'].max_supply
    }
    else {
      return -1
    }
  }
  const getPopDollar = (productCode: string, round: number) => {
    const foundPrice = pricesFetch.find((priceObj:Price)=>priceObj.product_code === productCode && priceObj.round_num === round);
    const population = productsDict[productCode]['open'].population;
    const postedPrice = foundPrice?.posted_price as number;

    return (postedPrice / productsDict[productCode]['open'].size / population)
  }

  const handleDownloadMyBids = (downloadString: string, lang?: string) => {
    if (selectedBidder) {
      window.location.assign(`${process.env.REACT_APP_API_URL}/api/downloads/${downloadString}/${auction.id}/${selectedBidder.id}?lang=${lang}`);
      setTimeout(()=>{setCurrentlyDownloadingMyBids(false)}, 1200);
    }
  }

  useEffect(()=> {
    if (auction) {
      fetchBiddersByAuction(auction.id as number);
      // fetchBidderPrices();
    }
  }, []);

  useEffect(()=> {
    if (selectedRound && allPDemands) {
      let bidderResultsTableValues:DataTableItem[] = [];
      allPDemands
        .filter((pd:PDemand)=>pd.round_num===selectedRound)
        .forEach((pd: PDemand) => {
          const product: GeneralProduct = productsDict[pd.product_code];
          const category: 'sap'|'open' = pd.set_aside ? 'sap' : 'open';
          const lot: SpecificProduct = product[category] as SpecificProduct;

          const lastPDemand = allPDemands.find(pd=>pd.round_num===selectedRound-1&&pd.product_code===lot.code);
          const lastPDemandValue = lastPDemand ? lastPDemand.value : 0;

          const quantity: [number, number] = selectedRound>1 ? [pd.value, lastPDemandValue] : [pd.value, -1];
          const aggDemand: [number, number] = selectedRound>1 ? [getAggDemand(pd.product_code, selectedRound), getAggDemand(pd.product_code, selectedRound-1)] : [getAggDemand(pd.product_code, selectedRound), -1];
          const excessDemand: [number, number] = selectedRound>1 ? [getExcessDemand(pd.product_code, selectedRound), getExcessDemand(pd.product_code, selectedRound-1)] : [getExcessDemand(pd.product_code, selectedRound), -1];
          const startPrice: [number, number] = selectedRound>1 ? [getPrice(pd.product_code, selectedRound).start_price, getPrice(pd.product_code, selectedRound-1).start_price] : [getPrice(pd.product_code, selectedRound).start_price, -1];
          const clockPrice: [number, number] = selectedRound>1 ? [getPrice(pd.product_code, selectedRound).clock_price, getPrice(pd.product_code, selectedRound-1).clock_price] : [getPrice(pd.product_code, selectedRound).clock_price, -1];
          const postedPrice: [number, number] = selectedRound>1 ? [getPrice(pd.product_code, selectedRound).posted_price, getPrice(pd.product_code, selectedRound-1).posted_price] : [getPrice(pd.product_code, selectedRound).posted_price, -1];
          const popDollar: [number, number] = selectedRound>1 ? [getPopDollar(pd.product_code, selectedRound), getPopDollar(pd.product_code, selectedRound-1)] : [getPopDollar(pd.product_code, selectedRound), -1];
          const totalCost: [number, number] = selectedRound>1 ? [pd.value * getPrice(pd.product_code, selectedRound).posted_price, pd.value * getPrice(pd.product_code, selectedRound-1).posted_price] : [pd.value * getPrice(pd.product_code, selectedRound).posted_price, -1];

          bidderResultsTableValues.push({
            round_num: pd.round_num,
            lot: lot.name,
            supply: lot.max_supply,
            quantity: quantity,
            agg_demand: aggDemand,
            excess_demand: excessDemand,
            start_price: startPrice,
            clock_price: clockPrice,
            posted_price: postedPrice,
            total_cost: totalCost,
            pop_dollar: popDollar
          });
      });
      setBidderResultsArray(bidderResultsTableValues);
    }
  }, [allPDemands, selectedRound]);

  useEffect(() => {
    setBidderResultsDataTable({
      columns: columns,
      rows: bidderResultsArray
    });
  }, [bidderResultsArray]);

  useEffect(() => {
    if (selectedBidder) {
      showBidderPDemands(selectedBidder.id);
    }
  }, [selectedBidder]);

    return (
        <Container className='mt-3'>
          <Tab.Container id="pdemands-history-tab-panes" defaultActiveKey="1">
            <Row>
              <Col>
                <div className='d-inline-block justify-content-between'>
                  <ButtonToolbar className='my-1'>
                    <ButtonGroup className='mx-1 ms-auto'>
                      <Button variant='secondary' size='sm' disabled>{auction.ended ? 'Total rounds: ' : 'Current round: '}{auction.round_num}</Button>
                    </ButtonGroup>
                    <ButtonGroup className='mx-1 ms-auto'>
                      <Button variant='secondary' size='sm' disabled>Viewing processed demands for:</Button>
                      <DropdownButton
                        as={ButtonGroup}
                        title={selectedBidder ? selectedBidder.name : 'Select bidder'}
                      >
                        {biddersByAuctionLoading && !biddersByAuctionError && <MySpinner />}
                        {biddersByAuction && !biddersByAuctionLoading && !biddersByAuctionError &&
                          biddersByAuction.sort(bidderObjSort).map((bidder:Bidder) => {
                            return <DropdownItem key={bidder.id} onClick={()=>handleClickBidder(bidder)}>
                              {bidder.is_named &&
                              <NamedBidder name={bidder.name} abbr={bidder.abbr} />
                              }
                              {!bidder.is_named && <>{bidder.name}</>}
                            </DropdownItem>
                          })
                        }
                      </DropdownButton>
                    </ButtonGroup>
                    {selectedBidder &&
                    <>
                      <ButtonGroup className='mx-1 ms-auto'>
                        <Button variant='secondary' size='sm' disabled>Round:</Button>
                        <DropdownButton
                          as={ButtonGroup}
                          title={selectedRound ? selectedRound : auction.round_num}
                        >
                          {range(auction.round_num).reverse().map((r: number) => <DropdownItem key={r+1} onClick={() => handleClickRound(r+1)}>{r+1}</DropdownItem>)}
                        </DropdownButton>
                      </ButtonGroup>
                      <ButtonGroup className='mx-1 ms-auto'>
                        <Button
                          onClick={selectedRound?()=>setSelectedRound(selectedRound-1):undefined}
                          disabled={!selectedRound||selectedRound===1}
                          >
                          <IconWrapper>
                            <HiChevronLeft />
                          </IconWrapper>
                        </Button>
                        <Button
                          onClick={selectedRound?()=>setSelectedRound(selectedRound+1):undefined}
                          disabled={!selectedRound||selectedRound===auction.round_num}
                          >
                          <IconWrapper>
                            <HiChevronRight />
                          </IconWrapper>
                        </Button>
                      </ButtonGroup>
                      <ButtonGroup className='ms-4 mx-1 me-auto'>
                        <Button variant='secondary' size='sm' disabled>Download:</Button>
                        {currentlyDownloadingMyBids && <Button variant='outline-info' size='sm' disabled><MySpinner /></Button>}
                        {!currentlyDownloadingMyBids && <>
                        <DropdownButton
                          as={ButtonGroup}
                          title='My Bids'
                          variant='outline-info'
                          size='sm'
                          >
                          <DropdownItem size='sm' key='en' onClick={()=>handleDownloadMyBids('my-bids','en')}>English</DropdownItem>
                          <DropdownItem size='sm' key='fr' onClick={()=>handleDownloadMyBids('my-bids','fr')}>Français</DropdownItem>
                        </DropdownButton>
                        <DropdownButton
                          as={ButtonGroup}
                          title='My Status'
                          variant='outline-info'
                          size='sm'
                          >
                          <DropdownItem size='sm' key='en' onClick={()=>handleDownloadMyBids('my-status','en')}>English</DropdownItem>
                          <DropdownItem size='sm' key='fr' onClick={()=>handleDownloadMyBids('my-status','fr')}>Français</DropdownItem>
                        </DropdownButton>
                        <DropdownButton
                          as={ButtonGroup}
                          title='My Results'
                          variant='outline-info'
                          size='sm'
                          >
                          <DropdownItem size='sm' key='en' onClick={()=>handleDownloadMyBids('my-results','en')}>English</DropdownItem>
                          <DropdownItem size='sm' key='fr' onClick={()=>handleDownloadMyBids('my-results','fr')}>Français</DropdownItem>
                        </DropdownButton>
                        </>}
                      </ButtonGroup>
                    </>
                    }
                  </ButtonToolbar>
                </div>
              </Col>
            </Row>
            {selectedBidder && selectedRound &&
            <Row>
              <Col>
                <Card className='my-1'>
                  <Card.Body>
                    <Container fluid className='p-0'>
                      <Row>
                        <Col sm={4}>
                          <Row as='dl' className='my-1'>
                            <Col as='dt' sm={6}>
                              Bidder Name
                            </Col>
                            <Col as='dd' sm={6}>
                              {selectedBidder.is_named ? <NamedBidder name={selectedBidder.name} abbr={selectedBidder.abbr} /> : <>{selectedBidder.name}</>}
                            </Col>
                          </Row>
                          <Row as='dl' className='my-1'>
                            <Col as='dt' sm={6}>
                              Total Budget
                            </Col>
                            <Col as='dd' sm={6}>
                              ${formatNumber(selectedBidder.budget)}
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={4}>
                          <Row as='dl' className='my-1'>
                            <Col as='dt' sm={6}>
                              Bidder Type
                            </Col>
                            <Col as='dd' sm={6}>
                              {selectedBidder.autobidder ? 'Autobidder' : 'Manual Bidder'}
                              {selectedBidder.is_named ? ', Bidder with 3500 MHz holdings' : ''}
                            </Col>
                          </Row>
                          <Row as='dl' className='my-1'>
                            <Col as='dt' sm={6}>
                              Total Budget Used
                            </Col>
                            <Col as='dd' sm={6}>
                              {selectedBidder.budget_used >=0 &&
                              <>{formatNumber(selectedBidder.budget_used, true)}</>
                              }
                              {selectedBidder.budget_used < 0 && 'N/A'}
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={4}>
                          <Row as='dl' className='my-1'>
                            <Col as='dt' sm={6}>
                              Initial EP
                            </Col>
                            <Col as='dd' sm={6}>
                              {formatNumber(selectedBidder.init_ep)} EP
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            }
            <Row>
              <Col>
                {selectedBidder && selectedRound && <Card className='my-1'>
                  <Card.Body className='bidder-results-card'>
                    <Card.Title className='text-center'>
                      Processed Demands at Round {selectedRound}
                    </Card.Title>
                    <Container>
                    {allPDemandsLoading &&
                      <MySpinner />
                    }
                    {!auction.ended && selectedRound === auction.round_num && <Card.Text className='text-center'>Demands have not yet been processed for this bidder for this round.</Card.Text>}
                    {!allPDemandsLoading && (auction.ended || auction.round_num > selectedRound) &&
                      <>
                        <div className='my-1 w-100 d-flex justify-content-between'>
                          <div>
                            <strong className='me-3'>Budget Used This Round:</strong>
                            {selectedBidder.budget_used >=0 &&
                            <>{formatNumber(
                                allPDemands.filter((pd:PDemand)=>pd.round_num===selectedRound).map(
                                  (pd: PDemand)=>pd.value*getPrice(pd.product_code, pd.round_num).posted_price
                                )
                                .reduce((x:number, a:number)=>x+a, 0), true)}
                            </>
                            }
                            {selectedBidder.budget_used < 0 && 'N/A'}
                          </div>
                          <div>
                            <small>
                              Values in parentheses (...) indicate change from previous round
                            </small>
                          </div>
                        </div>
                        <DynamicTable
                          columns={bidderResultsDataTable.columns}
                          data={bidderResultsDataTable.rows}
                        />
                      </>
                    }
                    </Container>
                  </Card.Body>
                </Card>}
              </Col>
            </Row>
          </Tab.Container>
        </Container>
    );
};

BidderResults.displayName = 'BidderResults';


export default memo(BidderResults);
