import { Accordion, Alert, Button, ButtonGroup, Col, Container, Form, InputGroup, OverlayTrigger, Row, Spinner, Stack, Tooltip } from "react-bootstrap";
import { AUCTION_STATUS, formatNumber, maxEp, maxSpent, productObjSort } from "../../Helpers";
import BidProduct from "./BidProduct";
import { useEffect, useMemo, useState } from "react";
import ErrorsModal from "../global/ErrorsModal";
// import useFetch from "../../useFetch";
import PendingButton from "../global/PendingButton";
import AutoSubmitModal from "./AutoSubmitModal";
import MySpinner from "../../MySpinner";
import DownloadExcelTemplate from "./DownloadExcelTemplate";
import UploadExcelBids from "./UploadExcelBids";
import IconWrapper from "../global/IconWrapper";
import { HiCheckCircle } from "react-icons/hi";
import useInterval from "../../useInterval";
import AutoSubmitEmptyBidsModal from "./AutoSubmitEmptyBidsModal";

const AuctionBids = (props: any) => {
  const currentBidder: Bidder = props.currentBidder;
  const auction: Auction = props.auction;
  const allProductsDict = props.allProductsDict;

  const [auctionStatus, setAuctionStatus] = useState<string|null>(null); // NOT auctionStatus passed from Auction.tsx

  const [currentBids, setCurrentBids] = useState<{ [key: string]: { [key: string]: [number, number][] } }>({}); // a bid dictionary by product code
  // const [defaultBids, setDefaultBids] = useState<any>({}); // same as preloaded currentBids for round_num > 1
  const [isValidBids, setIsValidBids] = useState(false);
  const [backendValidated, setBackendValidated] = useState(false);

  const [allErrors, setAllErrors] = useState<string[]>([]);
  const [allWarnings, setAllWarnings] = useState<string[]>([]);
  const [miscMsgs, setMiscMsgs] = useState<string[]>([]);
  const [showWarningsModal, setShowWarningsModal] = useState(false);
  const [epWarningStyle, setEpWarningStyle] = useState('');
  const [budgetWarningStyle, setBudgetWarningStyle] = useState('');
  const [submittedActivity, setSubmittedActivity] = useState(0);
  const [requestedCommitment, setRequestedCommitment] = useState(0);
  const [prevProcessedBidAmt, setPrevProcessedBidAmt] = useState<number|null>(null);

  const [showAutoSubmitModal, setShowAutoSubmitModal] = useState(false);
  const [showAutoSubmitWarningsModal, setShowAutoSubmitWarningsModal] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [foundProducts, setFoundProducts] = useState<SpecificProduct[]>([]); // for Search filter
  const [allDisplayProducts, setAllDisplayProducts] = useState<SpecificProduct[]>([]);

  const [validityCheck, setValidityCheck] = useState<BidValidityStatus>({ ok: true, message: 'No warnings/errors found', style: 'outline-success' });

  // const [lastBids, setLastBids] = useState<Bid[]|null>();

  const [allMyBids, setAllMyBids] = useState<Bid[]>();
  const [allMyPDemands, setAllMyPDemands] = useState<PDemand[]>();
  const [allMyEligibilities, setAllMyEligibilities] = useState<Eligibility[]>();
  const [auctionAllPrices, setAuctionAllPrices] = useState<Price[]>();

  const [allMyBidsLoading, setAllMyBidsLoading] = useState(true);
  const [allMyPDemandsLoading, setAllMyPDemandsLoading] = useState(true);
  const [allMyEligibilitiesLoading, setAllMyEligibilitiesLoading] = useState(true);
  const [auctionAllPricesLoading, setAuctionAllPricesLoading] = useState(true);
  const [allMyBidsError, setAllMyBidsError] = useState<string|null>(null);
  const [allMyPDemandsError, setAllMyPDemandsError] = useState<string|null>(null);
  const [allMyEligibilitiesError, setAllMyEligibilitiesError] = useState<string|null>(null);
  const [auctionAllPricesError, setAuctionAllPricesError] = useState<string|null>(null);

  const [currentlySubmitting, setCurrentlySubmitting] = useState(false);
  const [currentlyValidating, setCurrentlyValidating] = useState(false);

  const currentEp = useMemo(()=>{
    if (allMyEligibilities && auction.round_num > 1) {
      setSubmittedActivity((allMyEligibilities as Eligibility[]).find((e: Eligibility)=>e.round_num===auction.round_num-1)?.processed_ap as number);
      return (allMyEligibilities.find((x: Eligibility)=>x.round_num===(auction.round_num-1))?.end_elig as number);
    }
    else {
      return currentBidder.init_ep
    }
  }, [allMyEligibilities]);

  const [showDownloadExcel, setShowDownloadExcel]= useState(false);
  const [showUploadExcel, setShowUploadExcel] = useState(false);

  const [showReduceAllModal, setShowReduceAllModal] = useState(false);

  const allReduced = useMemo(()=>{
    return Object.keys(currentBids).length>0 && submittedActivity===0
  }, [currentBids]);
  const emptyBidsDict = useMemo(()=>{
    let newBids: { [key: string]: { [key: string]: [number, number][] } } = {};
    (Object.values(allProductsDict) as GeneralProduct[]).forEach((p:GeneralProduct)=>{
      if (p.open) {
        newBids[p.open.code] = { 'open': [] }
      }
      else if (p.sap) {
        newBids[p.sap.code] = { 'sap': [] }
      }
    });
    return newBids
  }, [allProductsDict]);

  const dtStyles = 'text-start fw-light fs-6';
  const ddStyles = 'text-end fs-6';

  const getPrices = (productCode: string, steps_back?: number) => {
    let r: number = steps_back || auction.round_num;
    const allPrices = auctionAllPrices as Price[];

    const foundPrices = allPrices.filter((priceObj: Price) => {
      return priceObj.product_code === productCode && priceObj.round_num > auction.round_num-r
    });

    return foundPrices
  }

  const getPrevPdemands = (productCode: string, steps_back?: number): PDemand[] => {
    const steps = steps_back || auction.round_num;
    const allPDemands = allMyPDemands as PDemand[];

    const foundPDemands = allPDemands.filter((pdemandObj: PDemand) => {
      return pdemandObj.product_code === productCode && pdemandObj.round_num && pdemandObj.round_num > auction.round_num - steps
    });

    return foundPDemands
  }

  const filterProducts: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const keyword: string = e.currentTarget.value;

    if (keyword !== '') {
        const results = allDisplayProducts.filter((p: SpecificProduct) => {
            const productStr = p.code;
            const productName = p.name;
            return productStr.startsWith(keyword.toLowerCase()) || productName.toLowerCase().includes(keyword.toLowerCase());
        });
        setFoundProducts(results);
    }
    else {
        setFoundProducts(allDisplayProducts);
    }
    setSearchTerm(keyword);
  }

  const handleRemoveBid = (productCode: string, ep: number, setAside: boolean, bidPrice: number, bidBlocks: number) => {
    let newBids = {...currentBids};
    let newBidArray: [number, number][];
    const category = setAside ? 'sap' : 'open';
    const oldBidArray = currentBids[productCode][category];

    newBidArray = newBids[productCode][category].filter((bid: [number, number]) => {
      return bid[0] !== bidPrice || bid[1] !== bidBlocks;
    });
    if (newBidArray.length > 0) {
        newBids[productCode][category] = newBidArray;
    }
    else {
        newBids[productCode][category] = [];
    }

    // calculate new submittedActivity
    setSubmittedActivity(submittedActivity - maxEp(oldBidArray, ep) + maxEp(newBidArray, ep));

    // calculate new requestedCommitment
    setRequestedCommitment(requestedCommitment - maxSpent(oldBidArray) + maxSpent(newBidArray));
    setCurrentBids(newBids);
  }

  const clearCurrentBids = () => {
    let newBids = {...currentBids};
    if (Object.keys(newBids).length>0) {
      Object.keys(newBids).forEach((key: string)=>{
        if (newBids[key].open) {
          newBids[key].open = [];
        }
        else if (newBids[key].sap) {
          newBids[key].sap = [];
        }
      });
    }
    else {
      newBids = emptyBidsDict;
    }
    setCurrentBids(newBids);
    setSubmittedActivity(0);
    setRequestedCommitment(0);
  }

  const formatBidObject = (bidsDict: any) => {
    let bidsSubmitObj: BidSubmission;
    let newBidsObj: NewBid[] = [];

    // format front-end currentBids object to BidSubmission object
    const relevantProducts = Object.keys(currentBids);
    relevantProducts.forEach((productCode: string) => {
        if (!Object.keys(bidsDict[productCode]).includes('sap')) { // Open products
            let currentNewBid: NewBid = {
                product_code: productCode,
                set_aside: false,
                bids: bidsDict[productCode]['open'].map(([bidPrice, bidNum]: [string, string]) => [parseInt(bidPrice), parseInt(bidNum)])
            };
            newBidsObj.push(currentNewBid);
        }
        else {
            let currentNewBid: NewBid = {
                product_code: productCode,
                set_aside: true,
                bids: bidsDict[productCode]['sap'].map(([bidPrice, bidNum]: [string, string]) => [parseInt(bidPrice), parseInt(bidNum)])
            };
            newBidsObj.push(currentNewBid);
        }
    });

    bidsSubmitObj = {
        auction_id: auction.id as number,
        bidder_id: currentBidder.id as number,
        round_num: auction.round_num,
        new_bids: newBidsObj
    };

    return bidsSubmitObj
  }

  const handleSubmitBids = () => {
    if (allReduced) {
      setShowReduceAllModal(true);
    }
    else {
      submitBidsPost(true);
    }
  }
  const submitBidsPost = (reload?:boolean) => {
    const bidsSubmitObj: BidSubmission = formatBidObject(currentBids);
    console.log('submitting POST');
    setCurrentlySubmitting(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/bids/submit`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bidsSubmitObj)
    })
    .then(res => {
        if(!res.ok) {
          if (res.status===400) {
            return res.text().then(text => { throw new Error(text) })
          }
          else {
            throw new Error(`${res.status}: ${res.statusText}`);
          }
        }
        else {
          return res.json();
        }
    })
    .then(data => {
        console.log('successfully sent as POST');
        if (reload) { window.location.reload(); }
    })
    .catch(err => {
        setCurrentlySubmitting(false);
        setAllErrors([`${err.message}`]);
        setValidityCheck({ ok: false, message: 'Failed to submit', style: 'outline-danger' });
    });
  }
  const handleValidateBids = () => {
    if (process.env.REACT_APP_DEV_ENV !== 'local') {
      const bidsSubmitObj = formatBidObject(currentBids);
      setCurrentlyValidating(true);

      console.log('submitting POST');
      fetch(`${process.env.REACT_APP_API_URL}/api/bids/validate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bidsSubmitObj)
      })
      .then(res => {
        if(!res.ok) {
          if (res.status ===400) {
            return res.text().then(text => { throw new Error(text) })
          }
          else {
            throw new Error(`${res.status}: ${res.statusText}`);
          }
        }
        else {
          return res.json();
        }
      })
      .then(data => {
          console.log('successfully sent as POST');
          if (data.all_success) {
              setBackendValidated(true);
          }
          else {
            const errors: string[] = [];
            const msgs: string[] = [];
              setValidityCheck({ ok: false, message: 'Errors found by server', style: 'outline-danger' });
              data.bid_results.forEach((result: BidResult)=>{
                if (result.success) {
                  msgs.push(`${result.product_code}: ${result.msg}`);
                }
                else {
                  errors.push(`${result.product_code}: ${result.msg}`);
                }
              });
              setAllErrors(errors);
              setMiscMsgs(msgs);
          }
          setCurrentlyValidating(false);
      })
      .catch(err => {
          setValidityCheck({ ok: false, message: 'Fatal error', style: 'outline-danger' });
          setAllErrors(['Validation submission error. See console log and contact administrator.']);
          setCurrentlyValidating(false);
          console.log(err.message);
      });
    }
    else {
      setBackendValidated(true);
    }
  }
  const handlePlaceBid = (e: React.FormEvent<HTMLFormElement>) => {
    console.log('handlePlaceBid');
    e.preventDefault();
    const bidFormElements: ProductBidHTMLFormElement = e.currentTarget.elements as ProductBidHTMLFormElement;
    const setAside: boolean = !!bidFormElements.setAside || false;
    const productCode: string = bidFormElements.code.value;
    const ep: number = bidFormElements.ep.value;
    const bidPrice: number = bidFormElements.price.value;
    const bidBlocks: number = bidFormElements.blocks.value;

    let newBids = {...currentBids};
    const category = setAside? 'sap' : 'open';

    if (!newBids[productCode]) {
        newBids[productCode] = {};
    }

    if (!Object.keys(newBids[productCode]).includes(category)) {
        newBids[productCode][category] = [[bidPrice, bidBlocks]];
    }
    else {
        newBids[productCode][category].push([bidPrice, bidBlocks]);
    }

    const oldBidArray = newBids[productCode][category].filter((bid: [number, number]) => {
      return bid[0] !== bidPrice || bid[1] !== bidBlocks;
    });

    // calculate new submittedActivity
    if (oldBidArray.length == 0) {
      // no other bids for this product yet
      // simply add ep for this bid
      setSubmittedActivity(submittedActivity + bidBlocks*ep);
      // same with requested commitment
      setRequestedCommitment(requestedCommitment + bidPrice*bidBlocks);
    }
    else {
      if (bidBlocks*ep === maxEp(newBids[productCode][category], ep)) {
        // other bids already exist for this product
        // iff this bid is the new max ep,
        // take away old max ep and add this one as new max ep
        setSubmittedActivity(submittedActivity - maxEp(oldBidArray, ep) + bidBlocks*ep);
      }
      if (bidPrice*bidBlocks === maxSpent(newBids[productCode][category])) {
        // same with requested commitment
        setRequestedCommitment(requestedCommitment - maxSpent(oldBidArray) + maxSpent(newBids[productCode][category]));
      }
    }
    // else this bid is not the new max ep, not the new max budget
    // do nothing -- no need to update current submitted activity nor requested commitment

    setCurrentBids(newBids);
  }

  const validatePreCheck = () => { // front-end validation
    const errMessages = [];
    const warnings = [];

    if (allReduced) {
      setEpWarningStyle('text-warning');
      warnings.push('Empty bids: If your bid to reduce demand to 0 is accepted for all products, you will be dropped out of the auction and will not be able to place any more bids.');
    }

    if (currentEp) {
      // validate EP
      if (submittedActivity < currentEp * auction.ep_pct / 100) {
          setEpWarningStyle('text-warning');
          warnings.push('Activity: Submitted activity does not meet required minimum. You may proceed but your eligibility for the next round may decrease.');
      }
      else if (submittedActivity > currentEp) {
          setEpWarningStyle('text-danger');
          errMessages.push('Activity: Submitted activity exceeds available eligibility points');
      }
    }

    // validate bid amount against budget
    if (requestedCommitment > currentBidder.budget) {
        setBudgetWarningStyle('text-warning');
        errMessages.push('Budget: Requested commitment exceeds available budget');
    }

    if (errMessages.length === 0) {
        setIsValidBids(true);

        if (warnings.length === 0) {
          setEpWarningStyle('text-success');
          setBudgetWarningStyle('text-success');
          setValidityCheck({ ok: true, message: 'No warnings/errors found', style: 'outline-success' });
        }
        else {
          setValidityCheck({ ok: true, message: 'View input warnings', style: 'outline-warning' });
        }
    }
    else {
        setIsValidBids(false);
        setValidityCheck({ ok: false, message: 'View input errors', style: 'outline-danger' })
    }
    setAllErrors(errMessages);
    setAllWarnings(warnings);
  }

  const findDisplayProducts = (regionCodes: string[]) => {
    const displayProducts: SpecificProduct[] = [];

    regionCodes.forEach((r: string) => {
      const regionCleaned = r.trim().match(/4-\d+/g);
      const region = regionCleaned? regionCleaned[0] : '';
      let currentProduct: GeneralProduct;

      ["-0", "-1"].forEach((suffix: string) => {
        if (!(region + suffix in allProductsDict)) {
          return
        }
        currentProduct = allProductsDict[region + suffix];
        if (currentProduct.open) {
          displayProducts.push(currentProduct.open)
        }
        if (currentProduct.sap) {
          displayProducts.push(currentProduct.sap)
        }
      })

    });

    return displayProducts
  }

  const fetchBids = () => {
    setAllMyBidsLoading(true);
    fetch(process.env.REACT_APP_API_URL + `/api/bids/get/${auction.id}/${currentBidder.id}?set_aside=false`)
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        const dataObj = process.env.REACT_APP_DEV_ENV === 'local' ? data[0] : data;
        setAllMyBids(dataObj);
        console.log('fetched bids for ' + currentBidder.name);
        setAllMyBidsLoading(false);
      })
      .catch(err => {
        setAllMyBidsLoading(false);
        setAllMyBidsError(err.message);
      });
  }
  const fetchPrices = () => {
    setAuctionAllPricesLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/prices/get/${auction.id}?set_aside=false`)
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        const dataObj = process.env.REACT_APP_DEV_ENV === 'local' ? data[0] : data;
        setAuctionAllPrices(dataObj);
        console.log('fetched prices for ' + auction.id);
        setAuctionAllPricesLoading(false);
      })
      .catch(err => {
        setAuctionAllPricesLoading(false);
        setAuctionAllPricesError(err.message);
      });
  }
  const fetchPDemands = () => {
    setAllMyPDemandsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/pdemands/get/${auction.id}?bidder_id=${currentBidder.id}&set_aside=false`)
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        const dataObj = process.env.REACT_APP_DEV_ENV === 'local' ? data[0] : data;
        setAllMyPDemands(dataObj);
        console.log('fetched prices for ' + auction.id);
        setAllMyPDemandsLoading(false);
      })
      .catch(err => {
        setAllMyPDemandsLoading(false);
        setAllMyPDemandsError(err.message);
      });
  }
  const fetchEligibilities = () => {
    setAllMyEligibilitiesLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/eligibility/get/${auction.id}/${currentBidder.id}`)
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        const dataObj = process.env.REACT_APP_DEV_ENV === 'local' ? data[0] : data;
        setAllMyEligibilities(dataObj);
        console.log('fetched prices for ' + auction.id);
        setAllMyEligibilitiesLoading(false);
      })
      .catch(err => {
        setAllMyEligibilitiesLoading(false);
        setAllMyEligibilitiesError(err.message);
      });
  }

  useEffect(()=>{
    console.log('rendering AuctionBids');
    validatePreCheck();

    fetchBids();
    fetchPrices();
    fetchPDemands();
    fetchEligibilities();

    // determine products to display based on display regions
    if (currentBidder.display_regions.length > 0) {
      // just in case: ensure display_regions has no duplicates
      let uniqDisplayRegions = [...new Set(currentBidder.display_regions)];
      setFoundProducts(findDisplayProducts(uniqDisplayRegions));
      setAllDisplayProducts(findDisplayProducts(uniqDisplayRegions));
    }
    else {
      setFoundProducts((Object.values(allProductsDict) as GeneralProduct[]).map((p:GeneralProduct)=>(p.open ? p.open : p.sap as SpecificProduct)).sort());
      setAllDisplayProducts((Object.values(allProductsDict) as GeneralProduct[]).map((p:GeneralProduct)=>(p.open ? p.open : p.sap as SpecificProduct)));
    }
  },[]);
  useEffect(()=> {
    // initialise currentBids to empty bids for every product
    const newBids: { [key: string]: { [key: string]: [number, number][] } } = {};
    (Object.values(allProductsDict) as GeneralProduct[]).forEach((p:GeneralProduct)=>{
      if (p.open) {
        newBids[p.open.code] = { 'open': [] }
      }
      else if (p.sap) {
        newBids[p.sap.code] = { 'sap': [] }
      }
    });
    setCurrentBids(newBids);
  }, [allProductsDict]);
  useEffect(()=>{
    if (allMyBids && allMyPDemands && auctionAllPrices && auction.round_num > 1 && allProductsDict) {
      // if not Round 1, initialise current bids using last round's pdemands with this round's clock prices
      const newBidDict: { [key: string]: { [key: string]: [number, number][] } } = {};
      (Object.values(allProductsDict) as GeneralProduct[]).forEach((p:GeneralProduct)=>{
        if (p.open) {
          newBidDict[p.open.code] = { 'open': [] }
        }
        else if (p.sap) {
          newBidDict[p.sap.code] = { 'sap': [] }
        }
      });
      allMyPDemands
        .filter((pd: PDemand) => pd.round_num===auction.round_num-1)
        .forEach((pd:PDemand) => {
          if (!newBidDict[pd.product_code]) {
            newBidDict[pd.product_code] = {}
          }
          const clockPrice = auctionAllPrices.find((price:Price)=>pd.product_code===price.product_code&&price.round_num===auction.round_num)?.clock_price;
          if (clockPrice) {
            if (Object.keys(newBidDict[pd.product_code]).includes(pd.set_aside ? 'sap' : 'open')) {
              newBidDict[pd.product_code][pd.set_aside ? 'sap' : 'open'].push([clockPrice, pd.value]);
            }
            else {
              newBidDict[pd.product_code][pd.set_aside ? 'sap' : 'open'] = [[clockPrice, pd.value]];
            }
          }
        })
      const lastRoundPDemands = allMyPDemands
        .filter((p:PDemand)=>p.round_num===auction.round_num-1);
      if (lastRoundPDemands.length > 0) {
        setPrevProcessedBidAmt(
          lastRoundPDemands
            .map((p:PDemand)=>{
              const foundPrice = auctionAllPrices.find((price:Price)=>price.product_code===p.product_code&&price.round_num===auction.round_num-1);
              if (foundPrice) {
                return p.value*foundPrice.posted_price
              }
              else {
                // should not occur?
                return 0
              }
            })
            .reduce((a,b)=>a+b)
        );
        setRequestedCommitment(
          lastRoundPDemands
            .map((p:PDemand)=>{
              const foundPrice = auctionAllPrices.find((price:Price)=>price.product_code===p.product_code&&price.round_num===auction.round_num);
              if (foundPrice) {
                return p.value*foundPrice.clock_price
              }
              else {
                // should not occur?
                return 0
              }
            })
            .reduce((a,b)=>a+b)
        );
      }
      else {
        setPrevProcessedBidAmt(0);
      }
      setCurrentBids(newBidDict);
    }
    else {
      setRequestedCommitment(0);
      setCurrentBids({});
    }
  }, [allMyBids, allMyPDemands, auctionAllPrices]);

  useEffect(()=>{
    setBackendValidated(false);
    setMiscMsgs([]); // clear backend success msgs
    validatePreCheck(); // clear other errors and warnings occurs in validatePreCheck()
  }, [currentBids]);

  useEffect(()=> {
    if (auction) {
      console.log('updating status');
      if (auction.ended) {
        setAuctionStatus(AUCTION_STATUS.ended);
      }
      else if (auction.status === 'Paused') {
        setAuctionStatus(AUCTION_STATUS.paused);
      }
      else {
        if (auction.pending_bidder_count > 0) {
          if (currentBidder) {
            if (process.env.REACT_APP_DEV_ENV === 'local') {
              setAuctionStatus(AUCTION_STATUS.ready);
            }
            else {
              // check if current bidder is a pending bidder
              fetch(`${process.env.REACT_APP_API_URL}/api/bidders/has_submitted/${currentBidder.id}/${auction.id}`)
                .then((res) => {
                  if(!res.ok) {
                    return res.text().then(text => { throw new Error(text) })
                  }
                  else {
                    return res.json();
                  }
                })
                .then((data) => {
                  const hasSubmitted: boolean = data;
                  if (hasSubmitted) {
                    setAuctionStatus(AUCTION_STATUS.waiting);
                  }
                  else {
                    setAuctionStatus(AUCTION_STATUS.ready);
                  }
                })
                .catch((err) => {
                  setAuctionStatus(AUCTION_STATUS.error);
                  console.log(err);
                });
            }
          }
        }
        else {
          // All bidders may have submitted their bids (pending bidder count is 0), but the round is currently being processed.
          // In such cases, pending_bidder_count is 0, but auction status is Ready. Just keep waiting.
          setAuctionStatus(AUCTION_STATUS.waiting);
          //throw new Error('Error: Should not have 0 pending bidder count at Ready status');
        }
      }
    }
  }, [auction]);

  return (
    <>
        {auctionStatus===AUCTION_STATUS.ready && currentEp>0 &&
        <>
        <Container className="bidsCP sticky-top w-100">
            <Alert variant='light'>
                <Row>
                    <Col sm={8}>
                        <Row>
                            <Col sm={6}>
                                <Row as='dl' className='row-cols-2'>
                                    <Col as='dt' sm={6} className={dtStyles}>
                                        Eligibility:
                                    </Col>
                                    <Col as='dd' sm={6} className={ddStyles}>
                                        {
                                          auction.round_num > 1 &&
                                          currentEp &&
                                          <><strong>{formatNumber(currentEp)}</strong> pts</>
                                        }
                                        {
                                          currentEp &&
                                          auction.round_num === 1 &&
                                          <><strong>{formatNumber(currentEp)}</strong> pts</>
                                        }
                                    </Col>
                                </Row>
                                <Row as='dl' className='row-cols-2'>
                                    <Col as='dt' sm={6} className={dtStyles}>
                                        Required Activity ({auction.ep_pct}%):
                                    </Col>
                                    <Col as='dd' sm={6} className={ddStyles}>
                                      {currentEp &&
                                        <><strong>{formatNumber(Math.floor(currentEp * auction.ep_pct / 100))}</strong> pts</>
                                      }
                                    </Col>
                                </Row>
                                <Row as='dl' className='row-cols-2'>
                                    <Col as='dt' sm={6} className={dtStyles}>
                                        Submitted Activity:
                                    </Col>
                                    <Col as='dd' sm={6} className={ddStyles}>
                                        <strong className={epWarningStyle}>
                                            {formatNumber(submittedActivity)}
                                        </strong> pts
                                    </Col>
                                </Row>
                                <Row as='dl' className='row-cols-2'>
                                    <Col as='dt' sm={6} className={dtStyles}>
                                        Previously Processed Activity:
                                    </Col>
                                    <Col as='dd' sm={6} className={ddStyles}>
                                        <strong>
                                            {
                                                allMyEligibilities &&
                                                allMyEligibilities.length > 0 &&
                                                auction.round_num > 1 &&
                                                formatNumber(allMyEligibilities.find((x: Eligibility)=>x.round_num===(auction.round_num-1))?.processed_ap as number)
                                            }
                                            {
                                                auction.round_num === 1 && '-'
                                            }
                                        </strong> pts
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={6}>
                                <Row as='dl' className='row-cols-2'>
                                    <Col as='dt' sm={6} className={dtStyles}>
                                        Budget:
                                    </Col>
                                    <Col as='dd' sm={6} className={ddStyles}>
                                        <strong>${formatNumber(currentBidder.budget)}</strong>
                                    </Col>
                                </Row>
                                <Row as='dl' className='row-cols-2'>
                                    <Col as='dt' sm={6} className={dtStyles}>
                                        Requested Commitment:
                                    </Col>
                                    <Col as='dd' sm={6} className={ddStyles}>
                                        <strong className={budgetWarningStyle}>${formatNumber(requestedCommitment)}</strong>
                                    </Col>
                                </Row>
                                <Row as='dl' className='row-cols-2'>
                                    <Col as='dt' sm={6} className={dtStyles}>
                                        Previously Processed Bid Amount:
                                    </Col>
                                    <Col as='dd' sm={6} className={ddStyles}>
                                        <strong>
                                          $
                                          {
                                            prevProcessedBidAmt ? formatNumber(prevProcessedBidAmt) : ' -'
                                          }
                                        </strong>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                              <InputGroup size="sm" className="my-3">
                                  <InputGroup.Text id="inputGroup-sizing-sm">Search Products</InputGroup.Text>
                                  <Form.Control
                                    aria-label="Search"
                                    aria-describedby="inputGroup-sizing-sm"
                                    value={searchTerm}
                                    onChange={filterProducts}
                                    type='search'
                                  />
                                <Button
                                  onClick={()=>clearCurrentBids()}
                                  variant='outline-primary'
                                  size='sm'
                                  className='mx-2'
                                  >
                                  Clear All Bids
                                </Button>
                              </InputGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={4}>
                        <Stack gap={2}>
                            <ButtonGroup>
                              <PendingButton
                                variant={backendValidated ? 'outline-success' : 'outline-primary'}
                                onClick={handleValidateBids}
                                // disabled={!isValidBids}
                                pending={currentlyValidating}
                              >
                                {backendValidated && <>
                                  <IconWrapper className='justify-content-center'>
                                    <span>Validate</span>
                                    <HiCheckCircle />
                                  </IconWrapper>
                                </>}
                                {!backendValidated && <>Validate</>}
                              </PendingButton>
                              <PendingButton
                                // disabled={!backendValidated}
                                onClick={()=>handleSubmitBids()}
                                pending={currentlySubmitting}
                                disabled={Object.keys(currentBids).length < 1}
                              >
                                Validate & Submit
                              </PendingButton>
                            </ButtonGroup>
                            <Button
                              onClick={() => setShowAutoSubmitWarningsModal(true)}
                              disabled={auction.round_num===1||auction.bidder_count-auction.autobidder_count>1}
                            >
                              Auto-submit Bids to Maintain Demand
                            </Button>
                            <Button variant='outline-secondary' onClick={() => setShowUploadExcel(true)}><small>Load Bids from File</small></Button>
                            <Button variant='outline-info' onClick={() => setShowDownloadExcel(true)} disabled={currentBidder.display_regions.length > 0 ? allDisplayProducts.length === 0 : undefined}><small>Download Bid File</small></Button>
                            <Button variant={validityCheck.style} onClick={() => (allErrors.length > 0 || allWarnings.length > 0 || miscMsgs.length > 0) && setShowWarningsModal(true)}><small>{validityCheck.message}</small></Button>
                        </Stack>
                    </Col>
                </Row>
            </Alert>
        </Container>
        <ErrorsModal
          errorsList={allErrors}
          warningsList={allWarnings}
          miscMsgs={miscMsgs}
          header='Validation Results'
          onHide={() => setShowWarningsModal(false)}
          show={showWarningsModal}
        />
        <ErrorsModal
          warningsList={['If your bid to reduce demand to 0 is accepted for all products, you will be dropped out of the auction and will not be able to place any more bids.']}
          show={showReduceAllModal}
          onHide={()=>setShowReduceAllModal(false)}
          buttonText='Proceed'
          buttonAction={()=>submitBidsPost(true)}
        />
        <ErrorsModal
          warningsList={['Auto-submitting bids will place bids at Clock Price up to a specified round (inclusive) to maintain demand. Any bids currently placed on the bidding screen will be ignored.']}
          show={showAutoSubmitWarningsModal}
          onHide={()=>setShowAutoSubmitWarningsModal(false)}
          buttonText='Proceed'
          buttonAction={()=>{setShowAutoSubmitModal(true); setShowAutoSubmitWarningsModal(false);}}
        />
        <AutoSubmitModal
          auction={auction}
          bidder={currentBidder}
          show={showAutoSubmitModal}
          onHide={() => setShowAutoSubmitModal(false)}
          // currentBids={currentBids}
          // formatBidObj={formatBidObject}
        />
        {(auctionAllPricesLoading || allMyPDemandsLoading || allMyBidsLoading) && <MySpinner />}
        {auctionAllPrices && !auctionAllPricesLoading && !allMyBidsLoading && !allMyPDemandsLoading &&
            <DownloadExcelTemplate
              auction={auction}
              bidder={currentBidder}
              allPrices={auctionAllPrices}
              allMyPDemands={allMyPDemands}
              allProducts={(Object.values(allProductsDict) as GeneralProduct[]).map((p:GeneralProduct)=>p.open)}
              show={showDownloadExcel}
              onHide={() => setShowDownloadExcel(false)}
            />
        }
        <UploadExcelBids
          auction={auction}
          bidder={currentBidder}
          show={showUploadExcel}
          onHide={() => setShowUploadExcel(false)}
          productsDict={allProductsDict}
          allPrices={auctionAllPrices}
        />
        <Container className="bidPlacer">
          {(auctionAllPricesLoading || allMyPDemandsLoading || allMyBidsLoading) && <MySpinner />}
          {allProductsDict && !auctionAllPricesLoading && !allMyPDemandsLoading && !allMyBidsLoading &&
          <Accordion
            defaultActiveKey={allDisplayProducts.map((p: SpecificProduct)=>p.code.replaceAll('-', ''))}
            alwaysOpen
            flush
            >
            {currentBidder && auctionAllPrices && foundProducts.sort(productObjSort).map((product: SpecificProduct) => {
                return <BidProduct
                  key={product.code.replaceAll('-', '')}
                  eventKey={product.code.replaceAll('-', '')}
                  product={product}
                  handlePlaceBid={handlePlaceBid}
                  handleRemoveBid={handleRemoveBid}
                  // price={getPrices(product.code)}
                  prices={getPrices(product.code)}
                  prevPdemands={getPrevPdemands(product.code)}
                  auction={auction}
                  // lastBids={lastBids}
                  currentBids={currentBids}
                  />
            })}
          </Accordion>
          }
        </Container>
        </>
        }
        {auctionStatus===AUCTION_STATUS.ready && currentEp===0 && Object.keys(currentBids).length>0 && currentBidder && <>
        <AutoSubmitEmptyBidsModal emptyBids={formatBidObject(emptyBidsDict)} />
        </>}
        {auctionStatus===AUCTION_STATUS.waiting &&
        <Container className='text-center'>
            <h3>Your bids have already been submitted for this round.</h3>
            <h4>
                Currently waiting on other bidder(s) to place their bids.
            </h4>
            <Spinner animation="grow" role="status" className='mt-4'>
              <span className="visually-hidden">Waiting...</span>
            </Spinner>
        </Container>
        }
        {auctionStatus===AUCTION_STATUS.paused &&
        <Container className='text-center'>
            <h3>This auction is currently paused.</h3>
            <h4>
                You will be able to resume bidding once the Admin has resumed the auction.
            </h4>
            <Spinner animation="grow" role="status" className='mt-4'>
              <span className="visually-hidden">Waiting...</span>
            </Spinner>
        </Container>
        }
        {!auctionStatus && <MySpinner/>}
    </>
  );
};

AuctionBids.displayName = 'AuctionBids';

export default AuctionBids;