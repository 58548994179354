import * as math from "mathjs";
import { useEffect, useMemo, useState } from "react";
import { Alert, Button, ButtonGroup, Form, InputGroup, ListGroup, Nav, Tab, Table } from "react-bootstrap";
import { FaSort } from "react-icons/fa";
import { ImTable } from "react-icons/im";
import { AiOutlineLineChart } from "react-icons/ai";
import { HiOutlineCube, HiOutlineSearch } from "react-icons/hi";
import { formatNumber, getParamLabel, getParamSetItems, getParamUnit, getParentValue, isArrayEqual, PARAM_DICTIONARY, parseValueParam } from "../../Helpers";
import MySpinner from "../../MySpinner";
import IconWrapper from "../global/IconWrapper";
import OffcanvasPane from "../global/OffcanvasPane";
import ParamBadge from "../global/ParamBadge";
import VariationResultsGraph from "./VariationResultsGraph";
import Param3DGraph from "./Param3DGraph";
import NewAuctionFromVariantLink from "./NewAuctionFromVariantLink";
import NamedBidder from "../global/NamedBidder";

const VariantResultsByBidder = (props:any) => {
  const parentAuction: Auction = props.parentAuction;
  const results: VarRes = props.results;
  const variation: Variation = props.variation;
  const variants: Variant[] = props.variants;
  const allProductsDict: { [key: string]: GeneralProduct } = props.allProductsDict;
  const biddersDict: { [key: string]: Bidder } = props.biddersDict;

  const bidderResultsByVariant = props.bidderResultsByVariant;
  // const bidderResultsByProduct = props.bidderResultsByProduct;

  const [stdCosts, setStdCosts] = useState<[string,number][]>([]);

  const [currentBidder, setCurrentBidder] = useState<Bidder|null>(null);
  const [currentVariant, setCurrentVariant] = useState<Variant|null>(null);
  const currentParentCost: number|null = useMemo(()=>{
    if (currentBidder) {
      return results.bidder_total_cost[currentBidder.abbr][0][1] - results.bidder_total_cost[currentBidder.abbr][0][2]
    }
    else {
      return null
    }
  }, [currentBidder, results.bidder_total_cost]);
  const [showProductsList, setShowProductsList] = useState(false);
  const [productSearchTerm, setProductSearchTerm] = useState('');
  const [foundFilteredProducts, setFoundFilteredProducts] = useState<[[string, boolean], number, number, number, number, number, number][]>([]);

  const filterProducts: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const keyword: string = e.currentTarget.value;

    setProductSearchTerm(keyword);

    if (foundProductsData && allProductsDict) {
      let results: [[string, boolean], number, number, number, number, number, number][];
      if (keyword !== '') {
          results = foundProductsData.filter((p: [[string, boolean], number, number, number, number, number, number]) => {
            const category: 'sap'|'open' = p[0][1] ? 'sap' : 'open';
            const product = allProductsDict[p[0][0]][category];
            if (product) {
              return product.name.startsWith(keyword.toLowerCase()) || product.name.toLowerCase().includes(keyword.toLowerCase());
            }
            else {
              return false
            }
          });
      }
      else {
        results = foundProductsData;
      }
      setFoundFilteredProducts(results);
    }
  }
  const [isIncludingZeros, setIsIncludingZeros] = useState(false);

  const [calculationsPending, setCalculationsPending] = useState(true);

  const [currentSortVariants, setCurrentSortVariants] = useState<[string, boolean]>(['variantNum', true]); // boolean represents sort is ascending
  const [currentSortProducts, setCurrentSortProducts] = useState<[string, boolean]>(['costChange', true]);

  const [foundVariantsData, setFoundVariantsData] = useState<[number, number, number][]>([]);
  const [selectedBidderSortedByVariant, setSelectedBidderSortedByVariant] = useState<[number, number, number][]>([]);
  const [foundProductsData, setFoundProductsData] = useState<[[string, boolean], number, number, number, number, number, number][]>([]);

  useEffect(()=>{
    setFoundFilteredProducts(foundProductsData);
  }, [foundProductsData]);
  useEffect(()=>{
    if (currentBidder) {
      const variantsByBidder = [...results.bidder_total_cost[currentBidder.abbr]];
      if (currentSortVariants[0]==='totalCostChange') {
        if (currentSortVariants[1]) {
          setFoundVariantsData(
            variantsByBidder
            .sort((a: [number, number, number], b: [number, number, number])=>a[2]-b[2])
          );
        }
        else {
          setFoundVariantsData(
            variantsByBidder
            .sort((a: [number, number, number], b: [number, number, number])=>b[2]-a[2])
          );
        }
      }
      else if (currentSortVariants[0]==='totalCost') {
        if (currentSortVariants[1]) {
          setFoundVariantsData(
            variantsByBidder
            .sort((a: [number, number, number], b: [number, number, number])=>a[1]-b[1])
          );
        }
        else {
          setFoundVariantsData(
            variantsByBidder
            .sort((a: [number, number, number], b: [number, number, number])=>b[1]-a[1])
          );
        }
      }
      else if (currentSortVariants[0]==='variantNum') {
        let sorted = variantsByBidder
            .sort((a: [number, number, number], b: [number, number, number])=>a[0]-b[0]);
        if (currentSortVariants[1]) {
          setFoundVariantsData(sorted);
        }
        else {
          setFoundVariantsData(sorted.reverse());
        }
      }
      else {
        setFoundVariantsData(
            variantsByBidder
        );
      }
    }
  }, [currentSortVariants, currentBidder]);
  useEffect(()=>{
    if (currentBidder) {
      const sorted = [...results.bidder_total_cost[currentBidder.abbr]];
      sorted.sort((a: [number, number, number], b: [number, number, number])=>a[0]-b[0]);
      setSelectedBidderSortedByVariant(sorted);
    }
  }, [currentBidder]);
  useEffect(()=>{
    if (currentBidder && currentVariant) {
      const bidderResults = [...bidderResultsByVariant(currentBidder.abbr, currentVariant.id)];
      if (currentSortProducts[0]==='costChange') {
        if (currentSortProducts[1]) {
          setFoundProductsData(
            bidderResults
              .sort((x:[[string, boolean], number, number, number, number, number, number],y:[[string, boolean], number, number, number, number, number, number])=>x[6]-y[6])
          );
        }
        else {
          setFoundProductsData(
            bidderResults
              .sort((x:[[string, boolean], number, number, number, number, number, number],y:[[string, boolean], number, number, number, number, number, number])=>y[6]-x[6])
          );
        }
      }
      else if (currentSortProducts[0]==='cost') {
        if (currentSortProducts[1]) {
          setFoundProductsData(
            bidderResults
              .sort((x:[[string, boolean], number, number, number, number, number, number],y:[[string, boolean], number, number, number, number, number, number])=>x[5]-y[5])
          );
        }
        else {
          setFoundProductsData(
            bidderResults
              .sort((x:[[string, boolean], number, number, number, number, number, number],y:[[string, boolean], number, number, number, number, number, number])=>y[5]-x[5])
          );
        }
      }
      else if (currentSortProducts[0]==='procDemandChange') {
        if (currentSortProducts[1]) {
          setFoundProductsData(
            bidderResults
              .sort((x:[[string, boolean], number, number, number, number, number, number],y:[[string, boolean], number, number, number, number, number, number])=>x[4]-y[4]||x[3]-y[3])
          );
        }
        else {
          setFoundProductsData(
            bidderResults
              .sort((x:[[string, boolean], number, number, number, number, number, number],y:[[string, boolean], number, number, number, number, number, number])=>y[4]-x[4]||y[3]-x[3])
          );
        }
      }
      else if (currentSortProducts[0]==='parentPrice') {
        if (currentSortProducts[1]) {
          setFoundProductsData(
            bidderResults
              .sort((x:[[string, boolean], number, number, number, number, number, number],y:[[string, boolean], number, number, number, number, number, number])=>{
                return (results.parent_prices.find((price: [[string, boolean], number])=>isArrayEqual(price[0],x[0]))?.at(1) as number) - (results.parent_prices.find((price: [[string, boolean], number])=>isArrayEqual(price[0],y[0]))?.at(1) as number)
              })
          );
        }
        else {
          setFoundProductsData(
            bidderResults
              .sort((x:[[string, boolean], number, number, number, number, number, number],y:[[string, boolean], number, number, number, number, number, number])=>{
                return (results.parent_prices.find((price: [[string, boolean], number])=>isArrayEqual(price[0],y[0]))?.at(1) as number) - (results.parent_prices.find((price: [[string, boolean], number])=>isArrayEqual(price[0],x[0]))?.at(1) as number)
              })
          );
        }
      }
      else if (currentSortProducts[0]==='price') {
        if (currentSortProducts[1]) {
          setFoundProductsData(
            bidderResults
              .sort((x:[[string, boolean], number, number, number, number, number, number],y:[[string, boolean], number, number, number, number, number, number])=>x[2]-y[2])
          );
        }
        else {
          setFoundProductsData(
            bidderResults
              .sort((x:[[string, boolean], number, number, number, number, number, number],y:[[string, boolean], number, number, number, number, number, number])=>y[2]-x[2])
          );
        }
      }
      else {
        setFoundProductsData(bidderResultsByVariant(currentBidder.abbr, currentVariant.id));
      }
    }
  }, [currentBidder, currentVariant, currentSortProducts])
  useEffect(()=>{
    let stdCost: [string, any][] = []; // should be number but typescript thinks math.std is returning number[] for some reason

    Object.keys(results.bidder_total_cost).forEach((abbr: string)=>{
      const btc = results.bidder_total_cost[abbr];
      const btcDelta = btc.map(x=>x[2]);

      stdCost.push([abbr, math.std(btcDelta)]);
    });
    stdCost.sort((x,y)=>x[1]-y[1]).reverse();
    setStdCosts(stdCost);
    setCalculationsPending(false);
  }, [results]);

  return (<>
    <div className='d-flex overflow-hidden flex-grow-1 h-100 w-100' style={{ height: 'calc(-180px + 100vh)' }}>
      <div className='left-menu d-flex flex-column overflow-auto py-3' style={{ width: '320px' }}>
        <h6 className="text-center">
          Bidder (Std. Dev.)
        </h6>
        {calculationsPending && <MySpinner />}
        {!calculationsPending && <ListGroup style={{ overflowY: 'scroll' }}>
                    {stdCosts.map((stdC: [string, number])=>{
                      const b: Bidder = biddersDict[stdC[0]];
                      return <ListGroup.Item key={stdC[0]} onClick={()=>{setCurrentBidder(biddersDict[stdC[0]]);setCurrentVariant(null);}} active={currentBidder ? stdC[0]===currentBidder.abbr : undefined}>
                        <a href="#" className="stretched-link text-reset text-decoration-none"><span className='fw-light'>
                            {b.is_named ? <NamedBidder name={biddersDict[stdC[0]].name} abbr={b.abbr} /> : <>{b.name}</>} ({formatNumber(stdC[1])})
                          </span></a>
                      </ListGroup.Item>
                    })}
                  </ListGroup>}
      </div>
      <div className='right-content d-flex flex-column w-100 h-100 p-3'>
        <div style={{ overflowY: 'scroll' }}>
          {!currentBidder && <Alert>Select a bidder to view cost amounts for each variant.</Alert>}
          {currentBidder && <>
            <Tab.Container defaultActiveKey="results-list">
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link disabled>
                    View:
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="results-list">
                    <IconWrapper>
                      <ImTable />
                      <span>List of Variants</span>
                    </IconWrapper>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="results-2d">
                    <IconWrapper>
                      <AiOutlineLineChart />
                      <span>Bidder Cost by Variant</span>
                    </IconWrapper>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="results-3d">
                    <IconWrapper>
                      <HiOutlineCube />
                      <span>Bidder Cost by Varied Parameter</span>
                    </IconWrapper>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="results-list">
                  <Table hover>
                    <thead>
                      <tr>
                        <th>
                          <Button variant="link" className="p-0 text-body text-decoration-none" onClick={()=>setCurrentSortVariants(['variantNum', !currentSortVariants[1]])}>
                            <strong>Variant</strong>
                            <FaSort />
                          </Button>
                        </th>
                        <th>
                          Varied Parameters
                        </th>
                        <th>
                          Parent Total Cost
                        </th>
                        <th>
                          <Button variant="link" className="p-0 text-body text-decoration-none" onClick={()=>setCurrentSortVariants(['totalCost', !currentSortVariants[1]])}>
                            <IconWrapper>
                              <strong>Variant Total Cost</strong>
                              <FaSort />
                            </IconWrapper>
                          </Button>
                        </th>
                        <th>
                          <Button variant="link" className="p-0 text-body text-decoration-none" onClick={()=>setCurrentSortVariants(['totalCostChange', !currentSortVariants[1]])}>
                            <IconWrapper>
                              <strong>± Total Cost Change (% Change)</strong>
                              <FaSort />
                            </IconWrapper>
                          </Button>
                        </th>
                        <th>
                          &nbsp;
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {foundVariantsData.map((row: [number, number, number], i: number)=>{
                        const v: Variant|undefined = variants.find((v:Variant)=>v.id===row[0]);
                        if (v) {
                          return <tr key={i} style={ currentVariant && currentVariant.id===row[0] ? { backgroundColor:'rgb(248, 249, 250)' } : undefined }>
                            <td>
                              {v && <NewAuctionFromVariantLink variant={v} parentAuction={parentAuction} /> /* Variant Number */}
                            </td>
                            <td>
                              {/* Param 1 */ v && v.vp1 && Object.keys(v.vp1) && <ParamBadge parentAuction={parentAuction} title={`Variant ${v.var_number}`} vpSet={[JSON.parse(v.vp1), JSON.parse(v.vp2)]} vp={JSON.parse(v.vp1)} />}
                              {/* Param 2 */ v && v.vp2 && Object.keys(v.vp2) && <ParamBadge parentAuction={parentAuction} className='ms-1' title={`Variant ${v.var_number}`} vpSet={[JSON.parse(v.vp1), JSON.parse(v.vp2)]} vp={JSON.parse(v.vp2)} />}
                            </td>
                            <td>
                              {/* Parent Total Cost */ formatNumber(row[1]-row[2], true)}
                            </td>
                            <td>
                              ${/* Variant Total Cost */ formatNumber(row[1])}
                            </td>
                            <td>
                              <span className={row[2] >= 0 ? 'text-success' : 'text-danger'}>{formatNumber(row[2], true)} {row[1]!==0 && <>({formatNumber(row[2]*100/(row[1]-row[2]))}%)</>}</span>
                            </td>
                            <td>
                              <Button size="sm" variant="link" onClick={()=>{setCurrentVariant(variants.find((v:Variant)=>v.id===row[0]) as Variant); setShowProductsList(true);}}>
                                <small>View products</small>
                              </Button>
                            </td>
                          </tr>
                        }
                        else { return <></> }
                      })}
                    </tbody>
                  </Table>
                </Tab.Pane>
                <Tab.Pane eventKey="results-2d">
                  {selectedBidderSortedByVariant &&
                    <>
                    <VariationResultsGraph
                      title='Bidder Cost across Variants'
                      xAxis={
                        selectedBidderSortedByVariant
                          // .sort((a,b)=>a[1]-b[1])
                          .map((pp: [number, number, number])=>{
                              return variants.find((v:Variant)=>v.id===pp[0])?.var_number
                            }
                          )
                        }
                      yAxis={
                        selectedBidderSortedByVariant
                          // .filter((pp: [number, number, number])=>pp[1]!==0)
                          // .sort((a,b)=>a[1]-b[1])
                          .map(pp=>pp[1])
                        }
                      variantParamValues={
                        selectedBidderSortedByVariant
                          .filter((pp: [number, number, number])=>pp[1]!==0)
                          // .sort((a,b)=>a[1]-b[1])
                          .map((pp: [number, number, number])=>{
                            return [JSON.parse(variants.find((v:Variant)=>v.id===pp[0])?.vp1 as string), JSON.parse(variants.find((v:Variant)=>v.id===pp[0])?.vp2 as string)]
                          })
                      }
                      parentValue={currentParentCost}
                      label={currentBidder.name}
                      yAxisLabel='Bidder Cost'
                    />
                    </>
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="results-3d">
                  {currentBidder && selectedBidderSortedByVariant &&
                    <Param3DGraph
                      title={`Bidder Cost by Varied Parameters for ${currentBidder.name}`}
                      paramValueUnits={[getParamUnit(PARAM_DICTIONARY[Object.keys(JSON.parse(variation.rp1))[0]].name), JSON.parse(variation.rp2)!=='NoParam' ? getParamUnit(PARAM_DICTIONARY[Object.keys(JSON.parse(variation.rp2))[0]].name) : undefined]}
                      xLabel={getParamLabel(variation, 0, biddersDict)}
                      // yLabel={PARAM_DICTIONARY[Object.keys(JSON.parse(variation.rp1))[0]].name}
                      yLabel={JSON.parse(variation.rp2)!=='NoParam' ? getParamLabel(variation, 1, biddersDict) : undefined}
                      zLabel={`${currentBidder.name} Cost ($)`}
                      annotations={
                        {
                          'A': {
                            'type': PARAM_DICTIONARY[Object.keys(JSON.parse(variation.rp1))[0]].type,
                            'items': getParamSetItems(variation, 0, biddersDict, allProductsDict)
                          },
                          'B': JSON.parse(variation.rp2)!=='NoParam' ? {
                            'type': PARAM_DICTIONARY[Object.keys(JSON.parse(variation.rp2))[0]].type,
                            'items': getParamSetItems(variation, 1, biddersDict, allProductsDict)
                          } : undefined
                        }
                      }
                      variantNums={
                        selectedBidderSortedByVariant
                          .map((row: [number, number, number])=>{
                            const v = variants.find((v:Variant)=>v.id===row[0]);
                            return (v as Variant).var_number
                          })
                      }
                      xData={
                        selectedBidderSortedByVariant
                          .map((row: [number, number, number])=>{
                            const v = variants.find((v:Variant)=>v.id===row[0]);
                            return parseValueParam(JSON.parse((v as Variant).vp1))
                          })
                      }
                      yData={
                        JSON.parse(variation.rp2)!=='NoParam' ?
                        selectedBidderSortedByVariant
                          .map((row: [number, number, number])=>{
                            const v = variants.find((v:Variant)=>v.id===row[0]);
                            return parseValueParam(JSON.parse((v as Variant).vp2))
                          })
                        :
                        undefined
                      }
                      zData={
                        selectedBidderSortedByVariant
                          .map((row: [number, number, number])=>{
                            return row[1]
                          })
                      }
                      parentData={[getParentValue(parentAuction, JSON.parse(variation.rp1)), JSON.parse(variation.rp2)!=='NoParam' ? getParentValue(parentAuction,  JSON.parse(variation.rp2)) : undefined, currentParentCost]}
                    />
                  }
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </>}
        </div>
      </div>
    </div>
    {currentBidder && currentVariant &&
    <OffcanvasPane
      title={`${currentBidder.name} - Variant ${currentVariant.var_number}`}
      show={showProductsList}
      placement='bottom'
      onHide={()=>setShowProductsList(false)}
      className='h-50'
      >
      <div className='pt-2 overflow-auto'>
        {!(currentBidder && currentVariant) && <Alert className='m-4'>
          Select a bidder and a variant above to view product results.
        </Alert>}
        {currentVariant && <>
        <InputGroup>
          <InputGroup.Text id="inputGroup-sizing-sm">
            <IconWrapper>
              <HiOutlineSearch />
              <span>Search Products</span>
            </IconWrapper>
          </InputGroup.Text>
          <Form.Control
            aria-label="Search"
            aria-describedby="inputGroup-sizing-sm"
            value={productSearchTerm}
            onChange={filterProducts}
            type='search'
          />
          <ButtonGroup className='mx-1'>
            <Button
              variant={isIncludingZeros ? 'primary' : 'outline-secondary'}
              size='sm'
              onClick={()=>setIsIncludingZeros(!isIncludingZeros)}
            >
              Include zero cost change
            </Button>
          </ButtonGroup>
        </InputGroup>
        <Table hover>
          <thead>
            <tr>
              <th>Product</th>
              <th>
                <Button variant="link" className="p-0 text-body text-decoration-none" onClick={()=>setCurrentSortProducts(['parentPrice', !currentSortProducts[1]])}>
                  <IconWrapper>
                    <strong>Parent Posted Price</strong>
                    <FaSort />
                  </IconWrapper>
                </Button>
              </th>
              <th>
                <Button variant="link" className="p-0 text-body text-decoration-none" onClick={()=>setCurrentSortProducts(['price', !currentSortProducts[1]])}>
                  <IconWrapper>
                    <strong>Variant Posted Price</strong>
                    <FaSort />
                  </IconWrapper>
                </Button>
              </th>
              <th>
                <Button variant="link" className="p-0 text-body text-decoration-none" onClick={()=>setCurrentSortProducts(['procDemandChange', !currentSortProducts[1]])}>
                  <IconWrapper>
                    <strong>Processed Demand<br/><small>Parent (± Change) = Variant</small></strong>
                    <FaSort />
                  </IconWrapper>
                </Button>
              </th>
              <th>
                <Button variant="link" className="p-0 text-body text-decoration-none" onClick={()=>setCurrentSortProducts(['cost', !currentSortProducts[1]])}>
                  <IconWrapper>
                    <strong>Variant Cost for Product</strong>
                    <FaSort />
                  </IconWrapper>
                </Button>
              </th>
              <th>
                <Button variant="link" className="p-0 text-body text-decoration-none" onClick={()=>setCurrentSortProducts(['costChange', !currentSortProducts[1]])}>
                  <IconWrapper>
                    <strong>± Cost Change (%)</strong>
                    <FaSort />
                  </IconWrapper>
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>
            {foundFilteredProducts
              .filter((row: [[string, boolean], number, number, number, number, number, number])=>isIncludingZeros?true:row[6]!==0)
              .map((row: [[string, boolean], number, number, number, number, number, number])=>{
              const category: 'sap'|'open' = row[0][1] ? 'sap' : 'open';
              return <tr key={`p-${row[0][0]}`}>
                <td>
                  {allProductsDict[row[0][0]][category]?.name}
                </td>
                <td>
                  ${formatNumber(results.parent_prices.find((price: [[string, boolean], number])=>isArrayEqual(price[0],row[0]))?.at(1) as number)}
                </td>
                <td>
                  {formatNumber(row[2], true)}
                </td>
                <td>
                  <span className={row[4]>0 ? 'text-success' : (row[4]<0 ? 'text-danger' : undefined)}>
                    {row[3]-row[4]} ({row[4]>=0 && '+'}{row[4]}) = {formatNumber(row[3])}
                  </span>
                </td>
                <td>
                  {formatNumber(row[5], true)}
                </td>
                <td>
                  <span className={row[6]>0 ? 'text-success' : (row[6]<0 ? 'text-danger' : undefined)}>
                    {formatNumber(row[6], true)} {row[5]-row[6]!==0 && <>({formatNumber((row[5]-(row[5]-row[6]))/(row[5]-row[6])*100)}%</>})
                  </span>
                </td>
              </tr>
            })}
          </tbody>
        </Table>
        </>
        }
      </div>
    </OffcanvasPane>
    }
  </>)
}

export default VariantResultsByBidder;