import { ChangeEvent, useMemo, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { formatNumber } from "../../Helpers";
import MySpinner from "../../MySpinner";
import useFetch from "../../useFetch";
import NamedBidder from "../global/NamedBidder";

const VaryBidderBudget = (props:any) => {
  const auction: Auction = props.auction;
  const { data: bidders, isPending: biddersLoading, error: biddersError} = useFetch(`${process.env.REACT_APP_API_URL}/api/bidders/list/${auction.id}`);
  const alreadyConfirmedConfig: RangeBdr = props.paramValue;
  const alreadyConfirmedBidders: string[] = alreadyConfirmedConfig ? alreadyConfirmedConfig.bidder_abbrs : [];
  const alreadyConfirmedMin = alreadyConfirmedConfig ? Math.floor((alreadyConfirmedConfig as RangeBdr).start_pct) : 50;
  const alreadyConfirmedMax = alreadyConfirmedConfig ? Math.floor((alreadyConfirmedConfig as RangeBdr).end_pct) : 150;
  const alreadyConfirmedCount = alreadyConfirmedConfig ? (alreadyConfirmedConfig as RangeBdr).count : 5;
  const [selectedBidders, setSelectedBidders] = useState<string[]>(alreadyConfirmedBidders);
  const [minBudget, setMinBudget] = useState<number>(alreadyConfirmedMin);
  const [maxBudget, setMaxBudget] = useState<number>(alreadyConfirmedMax);
  const [simulationsCount, setSimulationsCount] = useState<number>(alreadyConfirmedCount);

  const isValid = useMemo(()=>{
    return selectedBidders.length > 0 && minBudget < maxBudget
  }, [selectedBidders, minBudget, maxBudget]);

  const handleToggleSelectBidder = (e: ChangeEvent<HTMLInputElement>, b: Bidder): void => {
    const isOn = (e.currentTarget as HTMLInputElement).checked;

    let updatedBidders: string[] = [];

    if (!isOn) { // turn OFF
      selectedBidders.forEach((bidderAbbr: string) => {
        if (b.abbr !== bidderAbbr) {
          updatedBidders.push(bidderAbbr);
        }
      });
    }
    else { // turn ON
      updatedBidders = [...selectedBidders];
      updatedBidders.push(b.abbr);
    }
    setSelectedBidders(updatedBidders);



  }

  const handleSaveValue = () => {
    let paramConfig: RangeBdr;
    paramConfig = {
      'bidder_abbrs': selectedBidders,
      'start_pct': minBudget,
      'end_pct': maxBudget,
      'count': simulationsCount
    }

    props.onValidate(selectedBidders.length > 0 && minBudget < maxBudget, {
      'BidderBudget': paramConfig
    }, simulationsCount, props.currentParam);
  }

  return(
    <>
      <Row className='mb-3'>
        <Col className="align-self-stretch">
          <Card className='h-100'>
            <Card.Header className='text-center'>
              <span className="fw-bold">Parent Auction Values</span>
            </Card.Header>
            <Card.Body className='text-center'>
              {biddersLoading && <MySpinner />}
              {!biddersLoading && !biddersError && bidders.length>0 &&
              <Table responsive>
                <thead>
                  <tr
                    style={{
                      width: '100%',
                      display: 'table',
                      tableLayout: 'fixed'
                    }}
                  >
                    <th>Bidder</th>
                    <th>Original Budget</th>
                    <th>Variant Budget Min.</th>
                    <th>Variant Budget Max.</th>
                  </tr>
                </thead>
                <tbody style={{ display: 'block', overflowY: 'scroll', maxHeight: '300px' }}>
                  {bidders.map((b: Bidder)=>{
                    return <tr
                        key={b.id}
                        style={{
                          width: '100%',
                          display: 'table',
                          tableLayout: 'fixed'
                        }}
                      >
                      <td className={selectedBidders.includes(b.abbr) ? 'fw-bold' : undefined}>{b.is_named && <NamedBidder name={b.name} abbr={b.abbr} />}
                        {!b.is_named && <>{b.name}</>}
                      </td>
                      <td>${formatNumber(b.budget)}</td>
                      <td>{minBudget && selectedBidders.includes(b.abbr) ? `$${formatNumber(minBudget * b.budget/100)}` : <em>--</em>}</td>
                      <td>{maxBudget && selectedBidders.includes(b.abbr) ? `$${formatNumber(maxBudget * b.budget/100)}` : <em>--</em>}</td>
                    </tr>
                  })}
                </tbody>
              </Table>}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header className='text-center'>
              <span className="fw-bold">Parameter Configuration</span>
            </Card.Header>
            <Card.Body>
              <div className='d-flex flex-column mb-4'>
                <div className='mb-2'>Select bidder(s) whose budgets should vary:</div>
                {biddersLoading && <MySpinner />}
                {!biddersLoading && !biddersError && <Form><div key='inline-checkbox'>
                  {bidders.map((b: Bidder)=>{
                    return <Form.Check
                      inline
                      label={b.name}
                      type='checkbox'
                      id={`checked-bidder-${b.id}`}
                      key={b.id}
                      checked={selectedBidders.includes(b.abbr)}
                      onChange={(e)=>handleToggleSelectBidder(e, b)}
                    />
                  })}
                </div></Form>}
              </div>
              {selectedBidders.length > 0 && <>
                <div className='mb-2'>For the bidders, simulation auctions should vary <span className='fw-bold'>total bidder budget</span> values between (% amount of of original budget):</div>
                <div className='d-flex align-items-center justify-content-center my-4'>
                  <InputGroup style={{ width: '180px' }}>
                    <Form.Control
                      value={minBudget}
                      type='number'
                      step={1}
                      min={1}
                      isValid={minBudget < maxBudget}
                      isInvalid={minBudget >= maxBudget}
                      onChange={(e)=>setMinBudget(parseInt(e.target.value))}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                  <div className='p-2'>
                    and
                  </div>
                  <InputGroup style={{ width: '180px' }}>
                    <Form.Control
                      value={maxBudget}
                      type='number'
                      step={1}
                      min={2}
                      isValid={minBudget < maxBudget}
                      isInvalid={minBudget >= maxBudget}
                      onChange={(e)=>setMaxBudget(parseInt(e.target.value))}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                </div>
                <div className='mb-2'>Enter a desired number of variant simulated auctions based on varying this parameter:</div>
                <div className='d-flex align-items-center justify-content-center my-1'>
                  <InputGroup style={{ width: '180px' }}>
                    <Form.Control
                        value={simulationsCount}
                        min={1}
                        step={1}
                        type='number'
                        isValid={simulationsCount<maxBudget-minBudget&&simulationsCount>=1}
                        isInvalid={simulationsCount>=maxBudget-minBudget||simulationsCount<1}
                        onChange={(e)=>setSimulationsCount(parseInt(e.target.value))}
                      />
                    <InputGroup.Text>variants</InputGroup.Text>
                  </InputGroup>
                </div>
              </>
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            variant="primary"
            className='float-end my-3'
            disabled={!isValid}
            onClick={()=>handleSaveValue()}
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default VaryBidderBudget;
