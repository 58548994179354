import * as math from "mathjs";
import { useEffect, useMemo, useState } from "react";
import { Alert, Button, Form, InputGroup, ListGroup, Nav, Tab, Table } from "react-bootstrap";
import { FaSort } from "react-icons/fa";
import { ImTable } from "react-icons/im";
import { AiOutlineLineChart } from "react-icons/ai";
import { HiOutlineCube, HiOutlineSearch } from "react-icons/hi";
import { formatNumber, getParamLabel, getParamSetItems, getParamUnit, getParentValue, isArrayEqual, PARAM_DICTIONARY, parseValueParam } from "../../Helpers";
import MySpinner from "../../MySpinner";
import IconWrapper from "../global/IconWrapper";
import ParamBadge from "../global/ParamBadge";
import VariationResultsGraph from "./VariationResultsGraph";
import Param3DGraph from "./Param3DGraph";
import NewAuctionFromVariantLink from "./NewAuctionFromVariantLink";

const VariantResultsByProduct = (props: any) => {
  const parentAuction: Auction = props.parentAuction;
  const results: VarRes = props.results;
  const variation: Variation = props.variation;
  const variants: Variant[] = props.variants;
  const allProductsDict: { [key: string]: GeneralProduct } = props.allProductsDict;
  const biddersDict: { [key: string]: Bidder } = props.biddersDict;

  // const bidderResultsByVariant = props.bidderResultsByVariant;
  // const bidderResultsByProduct = props.bidderResultsByProduct;

  const [productSearchTerm, setProductSearchTerm] = useState('');
  const [foundProducts, setFoundProducts] = useState<[[string, boolean], number][]>([]);
  const filterProducts: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const keyword: string = e.currentTarget.value;

    setProductSearchTerm(keyword);

    if (stdProductPrice && allProductsDict) {
      let results: [[string, boolean], number][];
      if (keyword !== '') {
          results = stdProductPrice.filter((stdPP: [[string, boolean], number]) => {
            const category: 'sap'|'open' = stdPP[0][1] ? 'sap' : 'open';
            const product = allProductsDict[stdPP[0][0]][category];
            if (product) {
              return product.name.startsWith(keyword.toLowerCase()) || product.name.toLowerCase().includes(keyword.toLowerCase());
            }
            else {
              return false
            }
          });
      }
      else {
        results = stdProductPrice;
      }
      setFoundProducts(results);
    }
  }

  const [stdProductPrice, setStdProductPrice] = useState<[[string, boolean], number][]>([]);
  const [currentProduct, setCurrentProduct] = useState<[string, boolean]|null>(null);
  const currentParentPrice: number|null = useMemo(()=>{
    if (currentProduct) {
      return results.parent_prices.find((parentPrice: [[string, boolean], number])=>isArrayEqual(parentPrice[0], currentProduct))?.at(1) as number
    }
    else {
      return null
    }
  }, [currentProduct]);

  const [currentSort, setCurrentSort] = useState<['variantNum'|'pricePct'|'postedPrice', boolean]>(['variantNum', true]); // [sort_type, is_ascending]

  const [calculationsPending, setCalculationsPending] = useState(true);

  const [filteredSortedPrices, setFilteredSortedPrices] = useState<[[string, boolean], number, number, number, number][]>([]);
  const [selectedProductSortedByVariant, setSelectedProductSortedByVariant] = useState<[[string, boolean], number, number, number, number][]>([]);
  const [foundRows, setFoundRows] = useState<[[string, boolean], number, number, number, number][]>([]);

  const [showNonChanged, setShowNonChanged] = useState(false);

  useEffect(()=>{
    let sorted: [[string, boolean], number, number, number, number][] = [];
    if (currentProduct) {
      if (currentSort[0]==='pricePct') {
        sorted = results.prices.filter(x=>isArrayEqual((x[0] as [string, boolean]), currentProduct)).sort((a,b)=>a[4]-b[4]);
      }
      else if (currentSort[0]==='postedPrice') {
        sorted = results.prices.filter(x=>isArrayEqual((x[0] as [string, boolean]), currentProduct)).sort((a,b)=>a[2]-b[2]);
      }
      else if (currentSort[0]==='variantNum') {
        sorted = results.prices.filter(x=>isArrayEqual((x[0] as [string, boolean]), currentProduct)).sort((a,b)=>a[1]-b[1]);
      }
    }
    if (!currentSort[1]) {
      sorted.reverse();
    }
    setFilteredSortedPrices(sorted);
  }, [currentProduct, currentSort]);

  useEffect(()=>{
    if (currentProduct) {
      setSelectedProductSortedByVariant(results.prices.filter(x=>isArrayEqual((x[0] as [string, boolean]), currentProduct)).sort((a,b)=>a[1]-b[1]));
    }
  }, [currentProduct]);

  useEffect(()=>{
    setFoundRows(filteredSortedPrices);
  }, [filteredSortedPrices]);

  useEffect(()=>{
    let stdPP: [[string, boolean], any][] = []; // should be number but typescript thinks math.std is returning number[] for some reason

    const prList: [string, boolean][] = Object.keys(allProductsDict).map((code: string)=>{
      return [code, !!allProductsDict[code].sap]
    });

    prList.forEach((pr: [string, boolean])=>{
      const prPricesDelta: number[] = results.prices.filter(x=>isArrayEqual((x[0] as [string, boolean]), pr)).map(x=>x[4]);
      if (prPricesDelta.length > 0) { // could be 0 if the product doesn't exist in this variation
        stdPP.push([pr, math.std(prPricesDelta)]);
      }
    });
    stdPP.sort((x, y) => x[1] - y[1]).reverse();
    setStdProductPrice(stdPP);
    setCalculationsPending(false);
  }, [allProductsDict, results]);

  useEffect(()=>{
    setFoundProducts(stdProductPrice);
  }, [stdProductPrice]);

  return (<>
    <div className='d-flex overflow-hidden flex-grow-1 h-100 w-100' style={{ height: 'calc(-180px + 100vh)' }}>
      <div className='left-menu d-flex flex-column overflow-auto py-3' style={{ width: '320px' }}>
        <h6 className="text-center">
          Product (Std. Dev.)
        </h6>
        {calculationsPending && <MySpinner />}
        {!calculationsPending &&
        <>
          <InputGroup>
            <InputGroup.Text id="inputGroup-sizing-sm">
              <IconWrapper>
                <HiOutlineSearch />
              </IconWrapper>
            </InputGroup.Text>
            <Form.Control
              aria-label="Search"
              aria-describedby="inputGroup-sizing-sm"
              value={productSearchTerm}
              onChange={filterProducts}
              type='search'
            />
          </InputGroup>
          <ListGroup className='mt-2' style={{ overflowY: 'scroll' }}>
          {foundProducts.map((stdPP: [[string, boolean], number])=>{
            const category: 'sap'|'open' = stdPP[0][1] ? 'sap' : 'open';
            return <ListGroup.Item key={stdPP[0][0]} onClick={()=>setCurrentProduct(stdPP[0])} active={currentProduct ? isArrayEqual(currentProduct, stdPP[0]) : undefined}>
              <a href="#" className="stretched-link text-reset text-decoration-none"><span className='fw-light'>{allProductsDict[stdPP[0][0]][category]?.name} ({formatNumber(stdPP[1])})</span></a>
            </ListGroup.Item>})}
          </ListGroup>
        </>}
      </div>
      <div className='right-content d-flex flex-column w-100 h-100 p-3'>
        <div style={{ overflowY: 'scroll' }}>
          {!currentProduct && <Alert>Select a product to view final posted prices for each variant.</Alert>}
          {currentProduct && filteredSortedPrices && results && <>
            <Tab.Container defaultActiveKey="results-list">
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link disabled>
                    View:
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="results-list">
                    <IconWrapper>
                      <ImTable />
                      <span>List of Variants</span>
                    </IconWrapper>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="results-2d">
                    <IconWrapper>
                      <AiOutlineLineChart />
                      <span>Posted Prices by Variant</span>
                    </IconWrapper>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="results-3d">
                    <IconWrapper>
                      <HiOutlineCube />
                      <span>Posted Prices by Varied Parameter</span>
                    </IconWrapper>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="results-list">
                  <div className="w-100">
                    <Button
                      size="sm"
                      className="float-end"
                      variant={showNonChanged ? "primary" : "outline-primary"}
                      onClick={()=>setShowNonChanged(!showNonChanged)}
                      >
                      Include variants with no change
                    </Button>
                    <Table>
                      <thead>
                        <tr>
                          <th>
                            <Button variant="link" className="p-0 text-body text-decoration-none" onClick={()=>setCurrentSort(['variantNum', !currentSort[1]])}>
                              <strong>Variant</strong>
                              <FaSort />
                            </Button>
                          </th>
                          <th>Varied Parameters</th>
                          <th>Parent Posted Price</th>
                          <th>
                            <Button variant="link" className="p-0 text-body text-decoration-none" onClick={()=>setCurrentSort(['postedPrice', !currentSort[1]])}>
                              <IconWrapper>
                                <strong>Variant Posted Price</strong>
                                <FaSort />
                              </IconWrapper>
                            </Button>
                          </th>
                          <th>
                            <Button variant="link" className="p-0 text-body text-decoration-none" onClick={()=>setCurrentSort(['pricePct', !currentSort[1]])}>
                              <IconWrapper>
                                <strong>± Price Change (% Change)</strong>
                                <FaSort />
                              </IconWrapper>
                            </Button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {foundRows
                          .filter((pp: [[string, boolean], number,number, number, number])=>{
                            if (showNonChanged) {
                              return true
                            }
                            else {
                              return pp[4]!==0
                            }
                          })
                          .map((pp: [[string, boolean], number,number, number, number])=>{
                            const v = variants.find((v:Variant)=>v.id===pp[1]);
                            return <tr key={`p${pp[0][0]}-v${pp[1]}`}>
                              <td>
                                {v && <NewAuctionFromVariantLink variant={v} parentAuction={parentAuction} /> /* Variant Number */}
                              </td>
                              <td>
                                {/*Varied Parameters*/}
                                {v && v.vp1 && Object.keys(v.vp1) && <ParamBadge parentAuction={parentAuction} title={`Variant ${v.var_number}`} vp={JSON.parse(v.vp1)} vpSet={[JSON.parse(v.vp1), JSON.parse(v.vp2)]} />}
                                {v && v.vp2 && Object.keys(v.vp2) && <ParamBadge parentAuction={parentAuction} className='ms-1' title={`Variant ${v.var_number}`} vpSet={[JSON.parse(v.vp1), JSON.parse(v.vp2)]} vp={JSON.parse(v.vp2)} />}
                              </td>
                              <td>{/*Parent Posted Price*/ currentParentPrice ? formatNumber(currentParentPrice, true) : 'Not found'}</td>
                              <td>{/*Posted Price*/ formatNumber(pp[2], true)}</td>
                              <td>
                                {/*Price Change*/}
                                <span className={pp[4] >= 0 ? 'text-success' : 'text-danger'}>{formatNumber(pp[3], true)} {pp[4]!==Infinity && <>({formatNumber(pp[4])}%)</>}</span>
                              </td>
                            </tr>
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="results-2d">
                  {currentProduct && selectedProductSortedByVariant && allProductsDict[currentProduct[0]] &&
                    <>
                    <VariationResultsGraph
                      title='Posted Price across Variants'
                      xAxis={
                        selectedProductSortedByVariant
                          // .filter((pp: [[string, boolean], number, number, number, number])=>pp[4]!==0)
                          .map((pp: [[string, boolean], number, number, number, number])=>{
                              return variants.find((v:Variant)=>v.id===pp[1])?.var_number
                            }
                          )
                        }
                      yAxis={
                        selectedProductSortedByVariant
                          // .filter((pp: [[string, boolean], number, number, number, number])=>pp[4]!==0)
                          // .sort((a,b)=>a[4]-b[4])
                          .map(pp=>pp[2])}
                      yAxisLabel='Posted Price'
                      variantParamValues={
                        selectedProductSortedByVariant
                          .filter((pp: [[string, boolean], number, number, number, number])=>pp[4]!==0)
                          // .sort((a,b)=>a[4]-b[4])
                          .map((pp: [[string, boolean], number, number, number, number])=>{
                            return [JSON.parse(variants.find((v:Variant)=>v.id===pp[1])?.vp1 as string), JSON.parse(variants.find((v:Variant)=>v.id===pp[1])?.vp2 as string)]
                          })
                      }
                      parentValue={currentParentPrice}
                      label={allProductsDict[currentProduct[0]][currentProduct[1] ? 'sap' : 'open']?.name}
                    />
                    </>
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="results-3d">
                  {currentProduct && selectedProductSortedByVariant && allProductsDict[currentProduct[0]] &&
                    <Param3DGraph
                      title={`Product Price by Varied Parameters for ${allProductsDict[currentProduct[0]][currentProduct[1] ? 'sap' : 'open']?.name}`}
                      annotations={
                        {
                          'A': {
                            'type': PARAM_DICTIONARY[Object.keys(JSON.parse(variation.rp1))[0]].type,
                            'items': getParamSetItems(variation, 0, biddersDict, allProductsDict)
                          },
                          'B': JSON.parse(variation.rp2)!=='NoParam' ? {
                            'type': PARAM_DICTIONARY[Object.keys(JSON.parse(variation.rp2))[0]].type,
                            'items': getParamSetItems(variation, 1, biddersDict, allProductsDict)
                          } : undefined
                        }
                      }
                      xLabel={getParamLabel(variation, 0, biddersDict)}
                      // yLabel={PARAM_DICTIONARY[Object.keys(JSON.parse(variation.rp1))[0]].name}
                      yLabel={JSON.parse(variation.rp2)!=='NoParam' ? getParamLabel(variation, 1, biddersDict) : undefined}
                      zLabel='Posted Price ($)'
                      paramValueUnits={[getParamUnit(PARAM_DICTIONARY[Object.keys(JSON.parse(variation.rp1))[0]].name), JSON.parse(variation.rp2)!=='NoParam' ? getParamUnit(PARAM_DICTIONARY[Object.keys(JSON.parse(variation.rp2))[0]].name) : undefined]}
                      variantNums={
                        selectedProductSortedByVariant
                          .map((pp: [[string, boolean], number,number, number, number])=>{
                            const v = variants.find((v:Variant)=>v.id===pp[1]);
                            return (v as Variant).var_number
                          })
                      }
                      xData={
                        selectedProductSortedByVariant
                          .map((pp: [[string, boolean], number,number, number, number])=>{
                            const v = variants.find((v:Variant)=>v.id===pp[1]);
                            return parseValueParam(JSON.parse((v as Variant).vp1))
                          })
                      }
                      yData={
                        JSON.parse(variation.rp2)!=='NoParam' ?
                        selectedProductSortedByVariant
                          .map((pp: [[string, boolean], number,number, number, number])=>{
                            const v = variants.find((v:Variant)=>v.id===pp[1]);
                            return parseValueParam(JSON.parse((v as Variant).vp2))
                          })
                        :
                        undefined
                      }
                      zData={
                        selectedProductSortedByVariant
                          .map((pp: [[string, boolean], number, number, number, number])=>{
                            return pp[2]
                          })
                      }
                      parentData={[getParentValue(parentAuction, JSON.parse(variation.rp1)), JSON.parse(variation.rp2)!=='NoParam' ? getParentValue(parentAuction,  JSON.parse(variation.rp2)) : undefined, currentParentPrice]}
                    />
                  }
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </>
          }
        </div>
      </div>
    </div>
  </>)
}

export default VariantResultsByProduct;