export class ConfigFileError extends Error {
  statusCode = 400;

  constructor(message: string) {
    super(message);

    Object.setPrototypeOf(this, ConfigFileError.prototype);
  }

  getErrorMessage() {
    return 'Something went wrong: ' + this.message;
  }
}
