import { useMemo, useState } from "react";
import { Accordion, Badge, Button, Table } from "react-bootstrap";
import BidPlacer from "./BidPlacer";
import { formatNumber } from "../../Helpers";
import OffcanvasPane from "../global/OffcanvasPane";
import { DynamicTable } from "../global/DynamicTable";

interface DataTableItem {
  round_num: number;
  agg_demand: number;
  excess_demand: number;
  proc_demand: number;
}

const BidProduct = (props: any) => {
  const auction: Auction = props.auction;
  const product: SpecificProduct = props.product;
  const handlePlaceBid = props.handlePlaceBid;
  const handleRemoveBid = props.handleRemoveBid;
  const eventKey: string = props.eventKey;
  const prices: Price[] = props.prices || [];
  const prevPrices: Price[] = useMemo(()=>{
    return prices.filter((p: Price)=>p.round_num<auction.round_num) || [];
  }, prices);
  const prevPricesReversed: Price[] = useMemo(()=>{
    return prevPrices.reverse()
  }, [prevPrices]);
  const price: Price|null = useMemo(()=>{
    return prices.find((p: Price)=>p.round_num===auction.round_num) || null;
  }, prices);
  const prevPdemands: PDemand[] = props.prevPdemands;
  const [showProductDemandHistory, setShowProductDemandHistory] = useState(false);
  const numPrevDemands = 9;

  const columns = [
    {
      title: 'Round',
      key: 'round_num'
    },
    {
      title: 'Aggregate Demand',
      key: 'agg_demand'
    },
    {
      title: 'Excess Demand',
      key: 'excess_demand'
    },
    {
      title: 'Processed Demand',
      key: 'proc_demand'
    }
  ];
  const demandData: DataTableItem[] = useMemo(()=>{
    return prevPricesReversed.map((p: Price)=>{
      const prevPDemand = prevPdemands.find((pd:PDemand)=>pd.round_num===p.round_num);
      return {
        round_num: p.round_num,
        agg_demand: p.agg_demand,
        excess_demand: p.agg_demand-product.max_supply,
        proc_demand: prevPDemand ? prevPDemand.value : 0
      }
    });
  }, [prevPricesReversed, prevPdemands]);

  // const lastBids: Bid[] = props.lastBids;

  // useEffect(()=>{
  //   console.log(`rendering BidProduct for ${product.code}`);
  // }, []);

    return (
      <>
        <Accordion.Item key={product.code.replaceAll('-', '')} eventKey={eventKey}>
          <Accordion.Header>{product.name} <Badge className='mx-2' pill>{product.set_aside ? 'Set-aside' : 'Open'}</Badge></Accordion.Header>
          <Accordion.Body>
            <Table>
              <thead className='table-light'>
                <tr>
                  <th>Init. Supply x Block Size</th>
                  <th>$/MHz/pop</th>
                  <th>Activity</th>
                  <th>Round Start Price</th>
                  <th>Clock Price</th>
                  <th>Prev. Agg. Demand</th>
                  <th>Prev. Excess Demand</th>
                  <th>Prev. Proc. Demand</th>
                  <th className='current-bids-column'>Current Bids</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {product.init_supply} x {product.size} MHz
                  </td>
                  <td>
                    ${auction.round_num === 1 ? formatNumber(product.dmp) : (((prices.find((p:Price)=>p.round_num===auction.round_num-1) as Price).posted_price/product.size/product.population)).toFixed(4)}
                  </td>
                  <td>
                    {product.ep} EP
                  </td>
                  <td>
                    ${auction.round_num === 1 ? formatNumber(product.initial_price) : formatNumber((prices.find((p:Price)=>p.round_num===auction.round_num) as Price).start_price)}
                  </td>
                  <td>
                    ${auction.round_num === 1 ? formatNumber(product.initial_price) : formatNumber((prices.find((p:Price)=>p.round_num===auction.round_num) as Price).clock_price)}
                  </td>
                  <td className='prevDemands'>
                    {prevPrices.length > 0 ?
                      prevPricesReversed.slice(0,numPrevDemands).map((p: Price, i: number)=>{
                        return <div className='prevDemand' key={i} style={{ opacity: 1-(i/(prevPrices.length+1)) }}>
                          <abbr
                            title={`Round ${p.round_num}`}
                            >
                            {p.agg_demand}
                          </abbr>
                        </div>
                      }) : 'None'}
                    {prevPricesReversed.length>numPrevDemands && <>
                      <div className='prevDemand'>
                        <Button className='text-decoration-none p-0' variant='link' onClick={()=>setShowProductDemandHistory(true)}>[...]</Button>
                      </div>
                    </>}
                  </td>
                  <td className='prevDemands'>
                    {prevPrices.length > 0 ?
                      prevPricesReversed.slice(0,numPrevDemands).map((p: Price, i: number)=>{
                        return <div className='prevDemand' key={i} style={{ opacity: 1-(i/(prevPrices.length+1)) }}>
                          <abbr
                            title={`Round ${p.round_num}`}
                            >
                            {p.agg_demand-product.max_supply}
                          </abbr>
                        </div>
                      }) : 'None'}
                    {prevPricesReversed.length>numPrevDemands && <>
                      <div className='prevDemand'>
                        <Button className='text-decoration-none p-0' variant='link' onClick={()=>setShowProductDemandHistory(true)}>[...]</Button>
                      </div>
                    </>}
                  </td>
                  <td className='prevDemands'>
                    {prevPdemands.length > 0 ?
                      prevPricesReversed.slice(0,numPrevDemands).map((p: Price, i: number)=>{
                        const prevPDemand = prevPdemands.find((pd:PDemand)=>pd.round_num===p.round_num);
                        return <div className='prevDemand' key={i} style={{ opacity: 1-(i/(prevPrices.length+1)) }}>
                          <abbr
                            title={`Round ${p.round_num}`}
                            >
                            {prevPDemand ? prevPDemand.value : 0}
                          </abbr>
                        </div>
                      }) : 'None'}
                    {prevPricesReversed.length>numPrevDemands && prevPdemands.length>0 && <>
                      <div className='prevDemand'>
                        <Button className='text-decoration-none p-0' variant='link' onClick={()=>setShowProductDemandHistory(true)}>[...]</Button>
                      </div>
                    </>}
                  </td>
                  <td>
                    {price &&
                    <BidPlacer
                      product={product}
                      handlePlaceBid={handlePlaceBid}
                      handleRemoveBid={handleRemoveBid}
                      price={price}
                      auction={auction}
                      // lastBids={lastBids}
                      currentBids={props.currentBids}
                      />
                    }
                  </td>
                </tr>
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>
        <OffcanvasPane
          title={`Demand History for ${product.name}`}
          show={showProductDemandHistory}
          placement='bottom'
          onHide={()=>setShowProductDemandHistory(false)}
          className='h-50'
          >
          <div className='pt-2 overflow-auto'>
            <DynamicTable data={demandData} columns={columns} searchable={false} />
          </div>
        </OffcanvasPane>
    </>
    );
};

BidProduct.displayName = 'BidProduct';

export default BidProduct;
