import { forwardRef, ReactNode, useMemo } from "react";
import { Badge, Popover } from "react-bootstrap";
import { formatNumber } from "../../Helpers";
import ParamSummaryText from "./ParamSummaryText";

interface Props {
  children?: ReactNode;
  variation?: Variation;
  variant?: Variant;
  rp?: RangeParam;
  vp?: ValueParam;
}
export type Ref = HTMLDivElement;

const ParamSummaryWindow = forwardRef<Ref,Props>((props, ref) => {
  const variation: Variation|null = props.variation || null;
  const variant: Variant|null = props.variant || null;
  const rangeParam: RangeParam|null = props.rp || null;
  const valueParam: ValueParam|null = props.vp || null;

  const rps: RangeParam[] = useMemo(()=>{
    if (variation) {
      return [JSON.parse(variation.rp1), JSON.parse(variation.rp2)]
    }
    else if (rangeParam && !variation) {
      return [rangeParam]
    }
    else {
      return []
    }
  }, [variation, rangeParam]);

  const vps: ValueParam[] = useMemo(()=>{
    if (variant) {
      return [JSON.parse(variant.vp1), JSON.parse(variant.vp2)]
    }
    else if (valueParam && !variant) {
      return [valueParam]
    }
    else {
      return []
    }
  }, [variant, valueParam]);

  return (
  <Popover ref={ref} {...props}>
    <Popover.Body>
      {rps.length > 0 && // display range params
        <>
          {rps.map((rp: RangeParam, i:number)=>{
            return <div className='mb-2' key={i}>
              <ParamSummaryText rp={rp} />
            </div>
          })}
        </>
      }
      {vps.length > 0 && // display value params
      <>
        {vps.map((vp: ValueParam, i:number)=>{
          return <div className='mb-2' key={i}>
            <ParamSummaryText vp={vp} />
          </div>
        })}
      </>
      }
      <div className='text-end'>
        <small>Click to view details</small>
      </div>
    </Popover.Body>
  </Popover>
  )
})

export default ParamSummaryWindow;