import { useEffect, useMemo, useState } from "react";
import { Badge } from "react-bootstrap";
import { formatNumber } from "../../Helpers";
import MySpinner from "../../MySpinner";

const ParamSummaryText = (props:any) => {
  const rp: RangeParam = props.rp;
  const vp: ValueParam = props.vp;

  const [biddersList, setBiddersList] = useState<[string, string][]>([]);
  const [biddersListLoading, setBiddersListLoading] = useState(false);
  const [bidderesListLoadError, setBiddersListLoadError] = useState('');

  const biddersAbbrDict = useMemo(()=>{
    let dict: { [key: string]: string } = {};
    if (biddersList) {
      biddersList.forEach((b:[string, string])=>{
        dict[b[0]] = b[1];
      });
    }
    console.log(dict);
    return dict
  }, [biddersList]);

  const fetchBiddersList = () => {
    setBiddersListLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/bidders/listnames?include_autobidders=true`)
      .then((res) => {
        if (!res.ok && res.status ===400) {
          return res.text().then(text => { throw new Error(text) })
        }
        else if (!res.ok) {
          throw new Error(`${res.status} error: ${res.statusText}`);
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setBiddersList(data);
        setBiddersListLoading(false)
      })
      .catch((err) => {
        console.log('Bidders Data Load Error: ' + err.message);
        setBiddersListLoadError(err.message);
        setBiddersListLoading(false);
      });
  }

  useEffect(()=>{
    if (!JSON.parse(window.localStorage.getItem('bidders-list') as string)) {
      fetchBiddersList();
    }
    else {
      setBiddersList(JSON.parse(window.localStorage.getItem('bidders-list') as string));
      setBiddersListLoading(false);
    }
  }, []);

  return <>
    {biddersListLoading && <MySpinner />}
    {biddersList && Object.keys(biddersAbbrDict).length>0 && rp && <>
      {rp!=="NoParam" && Object.keys(rp)[0]==='IncPct' && <>
        {((rp as IncPct)['IncPct'] as RangeAuc).values.map((val,i)=><Badge className="p-1 me-1" key={i} bg="secondary">{val}%</Badge>)}
      </>}
      {rp!=="NoParam" && Object.keys(rp)[0]==='SpectrumCap' && <>
        {((rp as SpectrumCap)['SpectrumCap'] as RangeAuc).values.map((val,i)=><Badge className="p-1 me-1" key={i} bg="secondary">{val} blocks</Badge>)}
      </>}
      {rp!=="NoParam" && Object.keys(rp)[0]==='BidderBudget' && <>
        {((rp as BidderBudget)['BidderBudget'] as RangeBdr).count} variants between&nbsp;
        {formatNumber(((rp as BidderBudget)['BidderBudget'] as RangeBdr).start_pct)}% and&nbsp;
        {formatNumber(((rp as BidderBudget)['BidderBudget'] as RangeBdr).end_pct)}% for&nbsp;
        {((rp as BidderBudget)['BidderBudget'] as RangeBdr).bidder_abbrs.map((b:string)=>biddersAbbrDict[b]||b).join(', ')}
      </>}
      {rp!=="NoParam" && Object.keys(rp)[0]==='BidderInitEP' && <>
        {((rp as BidderInitEP)['BidderInitEP'] as RangeBdr).count} variants between&nbsp;
        {formatNumber(((rp as BidderInitEP)['BidderInitEP'] as RangeBdr).start_pct)}% and&nbsp;
        {formatNumber(((rp as BidderInitEP)['BidderInitEP'] as RangeBdr).end_pct)}% for&nbsp;
        {((rp as BidderInitEP)['BidderInitEP'] as RangeBdr).bidder_abbrs.map((b:string)=>biddersAbbrDict[b]||b).join(', ')}
      </>}
      {rp!=="NoParam" && Object.keys(rp)[0]==='Priority' && <>
        {((rp as Priority)['Priority'] as RangeABC).count} variants between&nbsp;
        {formatNumber(((rp as Priority)['Priority'] as RangeABC).start_pct)}% and&nbsp;
        {((rp as Priority)['Priority'] as RangeABC).end_pct}% for&nbsp;
        {((rp as Priority)['Priority'] as RangeABC).rows.length} init. pkg. lots<br/>
      </>}
      {rp!=="NoParam" && Object.keys(rp)[0]==='Budget' && <>
        {((rp as Budget)['Budget'] as RangeABC).count} variants between&nbsp;
        {((rp as Budget)['Budget'] as RangeABC).start_pct}% and&nbsp;
        {((rp as Budget)['Budget'] as RangeABC).end_pct}% for&nbsp;
        {((rp as Budget)['Budget'] as RangeABC).rows.length} init. pkg. lots<br/>
      </>}
      {rp!=="NoParam" && Object.keys(rp)[0]==='Price' && <>
        {((rp as any)['Price'] as RangeABC).count} variants between&nbsp;
        {((rp as any)['Price'] as RangeABC).start_pct}% and&nbsp;
        {((rp as any)['Price'] as RangeABC).end_pct}% for&nbsp;
        {((rp as any)['Price'] as RangeABC).rows.length} init. pkg. lots<br/>
      </>}
      {rp!=="NoParam" && Object.keys(rp)[0]==='DMP' && <>
        {((rp as DMP)['DMP'] as RangeABC).count} variants between&nbsp;
        {((rp as DMP)['DMP'] as RangeABC).start_pct}% and&nbsp;
        {((rp as DMP)['DMP'] as RangeABC).end_pct}% for&nbsp;
        {((rp as DMP)['DMP'] as RangeABC).rows.length} init. pkg. lots<br/>
      </>}
    </>}
    {biddersList && vp && <>
      {vp!=="NoParam" && Object.keys(vp)[0]==='IncPct' &&
        <Badge className="p-1 me-1" bg="secondary">{((vp as IncPct)['IncPct'] as ValueAuc).value}%</Badge>
      }
      {vp!=="NoParam" && Object.keys(vp)[0]==='SpectrumCap' &&
        <Badge className="p-1 me-1" bg="secondary">{((vp as SpectrumCap)['SpectrumCap'] as ValueAuc).value} blocks</Badge>
      }
      {vp!=="NoParam" && Object.keys(vp)[0]==='BidderBudget' && <>
          {formatNumber(((vp as BidderBudget)['BidderBudget'] as ValueBdr).value_pct)}% for&nbsp;
          {((vp as BidderBudget)['BidderBudget'] as ValueBdr).bidder_abbrs.map((b:string)=>biddersAbbrDict[b]||b).join(', ')}
      </>}
      {vp!=="NoParam" && Object.keys(vp)[0]==='BidderInitEP' && <>
          {formatNumber(((vp as BidderInitEP)['BidderInitEP'] as ValueBdr).value_pct)}% for&nbsp;
          {((vp as BidderInitEP)['BidderInitEP'] as ValueBdr).bidder_abbrs.map((b:string)=>biddersAbbrDict[b]||b).join(', ')}
      </>}
      {vp!=="NoParam" && Object.keys(vp)[0]==='Priority' && <>
        {formatNumber(((vp as Priority)['Priority'] as ValueABC).value_pct)}% for&nbsp;
        {((vp as Priority)['Priority'] as ValueABC).rows.length} init. pkg. lots<br/>
      </>}
      {vp!=="NoParam" && Object.keys(vp)[0]==='Budget' && <>
        {formatNumber(((vp as Budget)['Budget'] as ValueABC).value_pct)}% for&nbsp;
        {((vp as Budget)['Budget'] as ValueABC).rows.length} init. pkg. lots<br/>
      </>}
      {vp!=="NoParam" && Object.keys(vp)[0]==='Price' && <>
        {formatNumber(((vp as any)['Price'] as ValueABC).value_pct)}% for&nbsp;
        {((vp as any)['Price'] as ValueABC).rows.length} init. pkg. lots<br/>
      </>}
      {vp!=="NoParam" && Object.keys(vp)[0]==='DMP' && <>
        {formatNumber(((vp as DMP)['DMP'] as ValueABC).value_pct)}% for&nbsp;
        {((vp as DMP)['DMP'] as ValueABC).rows.length} init. pkg. lots<br/>
      </>}
    </>}
  </>
}

export default ParamSummaryText;