import './css/App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
// import { DndProvider } from 'react-dnd'
// import { HTML5Backend } from 'react-dnd-html5-backend'

import Welcome from './components/welcome/Welcome';
import AuctionUserCP from './components/userCP/AuctionUserCP';
import { useEffect, useState } from 'react';
import useFetch from './useFetch';
import AuctionContainer from './components/auction/AuctionContainer';
import StaticPage from './components/staticData/StaticPage';
import VariantCP from './components/variantCP/VariantCP';
import VariantResults from './components/variantResults/VariantResults';
import SimulationsQueue from './components/userCP/SimulationsQueue';
import Sandbox from './components/sandbox/Sandbox';
import NoPath from './NoPath';

function App() {
  const title = '3800 MHz Clock Auction Simulation Tool';
  // const [users, setUsers] = useState(null);
  const [currentUser, setCurrentUser] = useState<[string, string] | null>(window.localStorage.getItem('currentUser') ? JSON.parse(window.localStorage.getItem('currentUser') as string) : null);
  const [isAdmin, setIsAdmin] = useState(window.localStorage.getItem('isAdmin') ? JSON.parse(window.localStorage.getItem('isAdmin') as string) : false);
  // const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  // const [allAuctions, setAllAuctions] = useState([]);

  const userFetchRequest = useFetch(process.env.REACT_APP_API_URL + '/api/bidders/listnames');
  // const regionst2FetchRequest = useFetch(process.env.REACT_APP_API_URL + '/regionst2');
  // const regionst4FetchRequest = useFetch(process.env.REACT_APP_API_URL + '/regionst4');
  // const namedBiddersFetchRequest = useFetch(process.env.REACT_APP_API_URL + '/namedbidders');
  // const productsFetchRequest = useFetch(process.env.REACT_APP_API_URL + '/api/products/list');
  const allBidders = userFetchRequest.data;

  const handleLogin = (user: [string,string], isAdmin:boolean=false) => {
    setCurrentUser(user);
    setIsAdmin(isAdmin);
  }

  useEffect(() => {
    if (window.localStorage.getItem('currentUser')) {
      setCurrentUser(JSON.parse(window.localStorage.getItem('currentUser') as string));
    }
    if (window.localStorage.getItem('isAdmin')) {
      setIsAdmin(JSON.parse(window.localStorage.getItem('isAdmin') as string));
    }
  }, []);
  useEffect(() => {
    window.localStorage.setItem('currentUser', JSON.stringify(currentUser));
    window.localStorage.setItem('isAdmin', JSON.stringify(isAdmin));
  }, [currentUser, isAdmin]);

  return (
    <Router>
     <div className='wrapper'>
        <div className="App">
            <Routes>
              <Route
                path='/web'
                element={ <Welcome
                    title={title}
                    userFetch={userFetchRequest}
                    // users={users}
                    // isLoading={isLoadingUsers}
                    handleLogin={handleLogin} /> }>
              </Route>
              <Route
                path='/web/auction/:id'
                element={currentUser || isAdmin ?
                  <AuctionContainer
                    isAdmin={isAdmin}
                    currentUser={currentUser}
                    handleLogin={handleLogin}
                     /> :
                     <Navigate replace to ='/web' />}
                     >
              </Route>
              <Route
                path='/web/variations/:id'
                element={<VariantCP
                   isAdmin={isAdmin}
                   currentUser={currentUser}
                   handleLogin={handleLogin}
                />}
              >
              </Route>
              <Route
                path='/web/variation-results/:id'
                element={<VariantResults
                   isAdmin={isAdmin}
                   currentUser={currentUser}
                   handleLogin={handleLogin}
                />}
              >
              </Route>
              <Route
                path='/web/auction-cp'
                element=
                    {
                    <AuctionUserCP
                      currentUser={currentUser}
                      isAdmin={isAdmin}
                      handleLogin={handleLogin}
                      allBidders={allBidders} />
                    }
                    >
              </Route>
              <Route
                path='/web/static/regions'
                element=
                    {
                    <StaticPage
                      // namedBiddersFetch={namedBiddersFetchRequest}
                      activeKey='regions'
                      // regionst2Fetch={regionst2FetchRequest}
                      // regionst4Fetch={regionst4FetchRequest}
                      allBidders={allBidders} />
                    }
                    >
              </Route>
              <Route
                path='/web/static/products'
                element=
                    {
                    <StaticPage
                      // namedBiddersFetch={namedBiddersFetchRequest}
                      // productsFetch={productsFetchRequest}
                      // regionst2Fetch={regionst2FetchRequest}
                      // regionst4Fetch={regionst4FetchRequest}
                      activeKey='products'
                      allBidders={allBidders} />
                    }
                    >
              </Route>
              <Route
                path='/web/static/namedbidders'
                element=
                    {
                    <StaticPage
                      // namedBiddersFetch={namedBiddersFetchRequest}
                      // productsFetch={productsFetchRequest}
                      activeKey='namedbidders'
                      // regionst2Fetch={regionst2FetchRequest}
                      // regionst4Fetch={regionst4FetchRequest}
                      allBidders={allBidders} />
                    }
                    >
              </Route>
              <Route
                path='/web/variations/queue'
                element=
                    {
                    <SimulationsQueue />
                    }
                    >
              </Route>
              <Route
                path='/web/static'
                element=
                  {<StaticPage
                    // namedBiddersFetch={namedBiddersFetchRequest}
                    // regionst2Fetch={regionst2FetchRequest}
                    // regionst4Fetch={regionst4FetchRequest}
                    activeKey='regions'
                    />}
                >
              </Route>
              <Route
                path='/web/sandbox'
                element=
                  {<Sandbox />}
                >
              </Route>
              <Route path='*' element={<NoPath />}>
              </Route>
            </Routes>
        </div>
     </div>
      <footer className='mt-auto py-3 bg-light text-center'>
        <small>
          NE&S 3800 MHz Clock Auction Tool
        </small>
      </footer>
    </Router>
  );
}

export default App;
