import { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import MySpinner from "../../MySpinner";
import useFetch from "../../useFetch";
import useInterval from "../../useInterval";
import UserCPNav from "./AdminCPNav";
import AuctionsList from "./AuctionsList";
import AuctionSummary from "./AuctionSummary";

const AuctionUserCP = (props: any) => {
  const currentUser: [string, string] = props.currentUser || JSON.parse(window.localStorage.getItem('currentUser') as string);
  const handleLogin = props.handleLogin;
  const isAdmin = props.isAdmin || JSON.parse(window.localStorage.getItem('isAdmin') as string);

  const [allAuctions, setAllAuctions] = useState<Auction[]|null>(null);
  const [updatedAuctions, setUpdatedAuctions] = useState<Auction[]|null>(null);

  const [loadingAuctions, setLoadingAuctions] = useState(true);
  const [auctionsLoadError, setAuctionsLoadError] = useState('');

  const { data: namedBidders, isPending: namedBiddersLoading, error: namedBiddersError } = useFetch(process.env.REACT_APP_API_URL + '/api/namedbidders/list');
  const [selectedAuction, setSelectedAuction] = useState<Auction|null>(null);

  const fetchAuctions = () => {
    setLoadingAuctions(true);

    const endpoint = props.isAdmin ? process.env.REACT_APP_API_URL + '/api/auctions/listall' : `${process.env.REACT_APP_API_URL}/api/auctions/list/${currentUser[0]}`;

    // setTimeout(()=>{console.log('holding')}, 2000);

    fetch(endpoint)
      .then(res => {
        if(!res.ok) {
          if (res.status ===400) {
            return res.text().then(text => { throw new Error(text) })
          }
          else {
            throw new Error(`${res.status} error: ${res.statusText}`);
          }
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setAllAuctions(data);
        setLoadingAuctions(false);
        // console.log('fetched auctions');
      })
      .catch(err => {
        setLoadingAuctions(false);
        setAuctionsLoadError(err.message);
      });
  }

  useEffect(()=>{
    // load initial fetch of auctions into updatedAuctions (previously empty)
    if (allAuctions && !updatedAuctions) {
      setUpdatedAuctions(allAuctions);
      console.log('initial load');
    }
    // subsequent fetches:
    // only load newly fetched allAuctions into updatedAuctions
    // if a change in auction data is detected
    else if (allAuctions && updatedAuctions) {
      let sortedNewAuctions = [...allAuctions];
      let sortedOldAuctions = [...updatedAuctions];
      sortedNewAuctions.sort((x:Auction,y:Auction)=>x.created_on-y.created_on);
      sortedOldAuctions.sort((x:Auction,y:Auction)=>x.created_on-y.created_on);

      if (JSON.stringify(sortedNewAuctions)!==JSON.stringify(sortedOldAuctions)) {
        setUpdatedAuctions(allAuctions);
        console.log('diff detected');
        setUpdatedAuctions(allAuctions);
      }
    }

    // check that selectedAuction (which may be from localStorage) actually exists for current user's auctions list
    if (!!selectedAuction && !!allAuctions && allAuctions.length>0) {
    // does not execute if selectedAuction is null, to prevent infinite loops
      const found = allAuctions.find((a:Auction)=>a.id===selectedAuction.id);
      if (!found) {
        // if not found, means the cached lastSelectedAuctionId was likely for a different user
        // in any case, if not in current auctions list, should clear
        setSelectedAuction(null);
      }
      else {
        // use the most up-to-date auction from the auctions list, not the localstorage version
        setSelectedAuction(found);
      }
    }
  }, [allAuctions]);
  useEffect(()=>{
    const storedAuctionString = window.localStorage.getItem('lastSelectedAuction');
    if (storedAuctionString) {
      setSelectedAuction(JSON.parse(storedAuctionString));
    }
    // redirect if page is reached without being logged in
    if (!currentUser && !isAdmin) {
      window.location.replace('/web/');
    }
    else {
      // Fetch auctions list on initial load
      // Populate AuctionsList
      fetchAuctions();
    }
  }, []);
  // useEffect(()=>{
  //   // console.log('auctions updated');
  //   if (updatedAuctions) {
  //   }
  // }, [updatedAuctions]);

  useEffect(()=>{
    if (selectedAuction) {
      localStorage.setItem('lastSelectedAuction', JSON.stringify(selectedAuction));
    }
  }, [selectedAuction]);

  useInterval(()=>{
    // poll every 4 secs
    fetchAuctions();
  }, 4000);

  return (
    <>
      {(currentUser || isAdmin) &&
        <>
          <UserCPNav
            handleLogin={handleLogin}
            isAdmin={isAdmin}
            user={currentUser}
          />
          <Container fluid>
            <Card
              className='auctionSummaryCard text-center px-3 py-2 mt-2 mb-2 d-flex flex-column'
              >
              {!!selectedAuction && updatedAuctions && updatedAuctions.length>0 &&
              <AuctionSummary
                user={currentUser}
                auction={selectedAuction}
                namedBidders={namedBidders}
                fetchAuctions={fetchAuctions}
                isAdmin={isAdmin}
                currentUser={currentUser}
              />}
              {updatedAuctions && updatedAuctions.length>0 && !selectedAuction && <>No auction selected</>}
              {updatedAuctions && updatedAuctions.length===0 && <>No auctions available</>}
            </Card>
          </Container>
          <Container className='auctionsList text-center' fluid>
            {updatedAuctions &&
              <AuctionsList
                user={currentUser}
                isAdmin={isAdmin}
                selectedAuction={selectedAuction}
                auctions={updatedAuctions}
                setSelectedAuction={setSelectedAuction}
                namedBidders={namedBidders}
                fetchAuctions={fetchAuctions}
              />
            }
            {!updatedAuctions && <MySpinner />}
          </Container>
        </>
      }
    </>
    )
};

export default AuctionUserCP;