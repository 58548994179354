import { ChangeEvent, useMemo, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { formatNumber } from "../../Helpers";
import MySpinner from "../../MySpinner";
import useFetch from "../../useFetch";
import NamedBidder from "../global/NamedBidder";

const VaryBidderEP = (props:any) => {
  const auction: Auction = props.auction;
  const { data: bidders, isPending: biddersLoading, error: biddersError} = useFetch(`${process.env.REACT_APP_API_URL}/api/bidders/list/${auction.id}`);
  const alreadyConfirmedConfig: RangeBdr = props.paramValue;
  const alreadyConfirmedBidders = alreadyConfirmedConfig ? alreadyConfirmedConfig.bidder_abbrs : [];
  const alreadyConfirmedMinEP = alreadyConfirmedConfig ? (alreadyConfirmedConfig as RangeBdr).start_pct : 50;
  const alreadyConfirmedMaxEP = alreadyConfirmedConfig ? (alreadyConfirmedConfig as RangeBdr).end_pct : 150;
  const alreadyConfirmedCount = alreadyConfirmedConfig ? (alreadyConfirmedConfig as RangeBdr).count : 5;
  const [selectedBidders, setSelectedBidders] = useState<string[]>(alreadyConfirmedBidders);
  const [minEP, setMinEP] = useState<number>(alreadyConfirmedMinEP);
  const [maxEP, setMaxEP] = useState<number>(alreadyConfirmedMaxEP);
  const [simulationsCount, setSimulationsCount] = useState<number>(alreadyConfirmedCount);

  const isValid = useMemo(()=>{
    return selectedBidders.length > 0 && minEP < maxEP
  }, [selectedBidders, minEP, maxEP]);

  const handleToggleSelectBidder = (e: ChangeEvent<HTMLInputElement>, b: Bidder): void => {
    const isOn = (e.currentTarget as HTMLInputElement).checked;

    let updatedBidders: string[] = [];

    if (!isOn) { // OFF to ON
      selectedBidders.forEach((bidderAbbr: string) => {
        if (b.abbr !== bidderAbbr) {
          updatedBidders.push(bidderAbbr);
        }
      });
    }
    else { // ON to OFF
      updatedBidders = [...selectedBidders];
      updatedBidders.push(b.abbr);
    }
    setSelectedBidders(updatedBidders);
}

  const handleSaveValue = () => {
    let paramConfig: RangeBdr;
    paramConfig = {
      'bidder_abbrs': selectedBidders,
      'start_pct': minEP,
      'end_pct': maxEP,
      'count': simulationsCount
    }

    props.onValidate(selectedBidders.length > 0 && minEP < maxEP, {
      'BidderInitEP': paramConfig
    }, simulationsCount, props.currentParam);
  }

  return(
    <>
      <Row className='mb-3'>
        <Col className="align-self-stretch">
          <Card className='h-100'>
            <Card.Header className='text-center'>
              <span className="fw-bold">Parent Auction Values</span>
            </Card.Header>
            <Card.Body className='text-center'>
              {biddersLoading && <MySpinner />}
              {!biddersLoading && !biddersError && bidders.length>0 &&
              <Table responsive>
                <thead>
                  <tr
                    style={{
                      width: '100%',
                      display: 'table',
                      tableLayout: 'fixed'
                    }}
                  >
                    <th>Bidder</th>
                    <th>Original EP</th>
                    <th>Variant EP Min.</th>
                    <th>Variant EP Max.</th>
                  </tr>
                </thead>
                <tbody style={{ display: 'block', overflowY: 'scroll', maxHeight: '300px' }}>
                  {bidders.map((b: Bidder)=>{
                    return <tr
                        key={b.id}
                        style={{
                          width: '100%',
                          display: 'table',
                          tableLayout: 'fixed'
                        }}
                      >
                      <td>
                        {b.is_named && <NamedBidder name={b.name} abbr={b.abbr} />}
                        {!b.is_named && <>{b.name}</>}
                      </td>
                      <td>{formatNumber(b.init_ep)} EP</td>
                      <td>{minEP && selectedBidders.includes(b.abbr) ? `${formatNumber(Math.round(minEP * b.init_ep/100))} EP` : <em>--</em>}</td>
                      <td>{maxEP && selectedBidders.includes(b.abbr) ? `${formatNumber(Math.round(maxEP * b.init_ep/100))} EP` : <em>--</em>}</td>
                    </tr>
                  })}
                </tbody>
              </Table>}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header className='text-center'>
              <span className="fw-bold">Parameter Configuration</span>
            </Card.Header>
            <Card.Body>
              <div className='d-flex flex-column mb-4'>
                <div className='mb-2'>Select bidder(s) whose initial EP values should vary:</div>
                {biddersLoading && <MySpinner />}
                {!biddersLoading && !biddersError && <Form><div key='inline-checkbox'>
                  {bidders.map((b: Bidder)=>{
                    return <Form.Check
                      inline
                      label={b.name}
                      name='selectedBidders'
                      type='checkbox'
                      id={`checked-bidder-${b.id}`}
                      key={b.id}
                      checked={selectedBidders.includes(b.abbr)}
                      onChange={(e)=>handleToggleSelectBidder(e, b)}
                    />
                  })}
                </div></Form>}
              </div>
              {selectedBidders.length > 0 && <>
                <div className='mb-2'>For the bidders, simulation auctions should vary <span className='fw-bold'>initial EP</span> values between (% amount of of original initial EP amount):</div>
                <div className='d-flex align-items-center justify-content-center my-4'>
                  <InputGroup style={{ width: '180px' }}>
                    <Form.Control
                      value={minEP}
                      type='number'
                      step={1}
                      min={1}
                      isValid={minEP < maxEP}
                      isInvalid={minEP >= maxEP}
                      onChange={(e)=>setMinEP(parseInt(e.target.value))}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                  <div className='p-2'>
                    and
                  </div>
                  <InputGroup style={{ width: '180px' }}>
                    <Form.Control
                      value={maxEP}
                      type='number'
                      step={1}
                      min={2}
                      isValid={minEP < maxEP}
                      isInvalid={minEP >= maxEP}
                      onChange={(e)=>setMaxEP(parseInt(e.target.value))}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                </div>
                <div className='mb-2'>Enter a desired number of variant simulated auctions based on varying this parameter:</div>
                <div className='d-flex align-items-center justify-content-center my-1'>
                  <InputGroup style={{ width: '180px' }}>
                    <Form.Control
                        value={simulationsCount}
                        min={1}
                        step={1}
                        type='number'
                        isValid={simulationsCount<maxEP-minEP&&simulationsCount>=1}
                        isInvalid={simulationsCount>=maxEP-minEP||simulationsCount<1}
                        onChange={(e)=>setSimulationsCount(parseInt(e.target.value))}
                      />
                    <InputGroup.Text>variants</InputGroup.Text>
                  </InputGroup>
                </div>
              </>
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            variant="primary"
            className='float-end my-3'
            disabled={!isValid}
            onClick={()=>handleSaveValue()}
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default VaryBidderEP;
