import { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { MDBDataTable } from 'mdbreact';
import { formatNumber, formatPrice } from "../../Helpers";
import { DynamicTable } from "../global/DynamicTable";

interface DataTableItem {
  name: string;
  size: number;
  population: number;
  set_aside: boolean;
  dmp: number;
  ep: number;
  init_supply: number;
  initial_price: number;
}

const ProductsView = (props:any) => {
  const columns = [
    {
      title: 'Service Area',
      key: 'name',
      sort: (a:DataTableItem, b:DataTableItem) => a.name<b.name?1:(a.name>b.name?-1:0),
      width: 150,
      searchable: true
    },
    {
      title: 'Block Size',
      key: 'size',
      sort: (a:DataTableItem, b:DataTableItem) => a.size-b.size,
      width: 80
    },
    {
      title: 'Product',
      key: 'set_aside',
      render: (_: any, item:DataTableItem)=>item.set_aside ? 'Set-aside' : 'Open',
      width: 80
    },
    {
      title: 'Population',
      key: 'population',
      width: 100,
      sort: (a:DataTableItem, b:DataTableItem) => a.population-b.population,
      render: (_: any, item:DataTableItem)=>formatNumber(item.population)
    },
    {
      title: '$/MHz/pop',
      key: 'dmp',
      sort: (a:DataTableItem, b:DataTableItem) => a.dmp-b.dmp,
      render: (_: any, item:DataTableItem)=>'$' + item.dmp,
      width: 100
    },
    {
      title: 'EP',
      key: 'ep',
      width: 100,
      sort: (a:DataTableItem, b:DataTableItem) => a.ep-b.ep,
      render: (_: any, item:DataTableItem)=>item.ep + ' EP',
    },
    {
      title: 'Init. Supply',
      key: 'init_supply',
      width: 100
    },
    {
      title: 'Init. Price',
      key: 'initial_price',
      width: 100,
      sort: (a:DataTableItem, b:DataTableItem) => a.initial_price-b.initial_price,
      render: (_: any, item:DataTableItem)=>formatNumber(item.initial_price, true),
    }
  ];

  const [productsData, setProductsData] = useState<any[]>([]);
  const [productsLoading, setProductsLoading] = useState(true);
  const [productsErrorMsg, setProductsErrorMsg] = useState<null|string>(null);

  const [productsArray, setProductsArray] = useState<DataTableItem[]>([]);
  const [productsDatatable, setProductsDatatable] = useState<any>({});

  const fetchProductsData = () => {
    fetch(process.env.REACT_APP_API_URL + '/api/products/list')
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setProductsData(data);
        setProductsLoading(false);
        console.log('fetched products data');
      })
      .catch(err => {
        setProductsLoading(false);
        setProductsErrorMsg(err.message);
      });
  }

  useEffect(() => {
    if (!JSON.parse(window.localStorage.getItem('products-list') as string)) {
      fetchProductsData();
    }
    else {
      setProductsData(JSON.parse(window.localStorage.getItem('products-list') as string));
      setProductsLoading(false);
    }
  }, []);

  useEffect(() => {
    let productsValues:DataTableItem[] = [];
    Object.values(productsData).map((product:GeneralProduct) => {
      if (product.open) {
        let datatableProduct:DataTableItem = {
            name: product.open.name,
            size: product.open.size,
            set_aside: product.open['set_aside'],
            population: product.open.population,
            dmp: product.open.dmp,
            ep: product.open.ep,
            init_supply: product.open.init_supply,
            initial_price: product.open.initial_price
        };
        productsValues.push(datatableProduct);
      }
      if (product.sap) {
        let datatableProduct:DataTableItem = {
          name: product.sap.name,
          size: product.sap.size,
          set_aside: product.sap['set_aside'],
          population: product.sap.population,
          dmp: product.sap.dmp,
          ep: product.sap.ep,
          init_supply: product.sap.init_supply,
          initial_price: product.sap.initial_price
        };
        productsValues.push(datatableProduct);
      }
    });
    productsValues.sort((a:DataTableItem,b:DataTableItem)=>a.name<b.name?-1:(a.name>b.name?1:0));
    setProductsArray(productsValues);
  }, [productsData]);

    return (
        <div className='mt-3'>
        {!productsLoading && productsArray &&
          <DynamicTable
            columns={columns}
            data={productsArray}
          />
        }
        </div>
    );
};

ProductsView.displayName = 'ProductsView';

export default ProductsView;
