import { useEffect, useState } from "react";
import { AUCTION_STATUS } from "../../Helpers";
import MySpinner from "../../MySpinner";
import useFetch from "../../useFetch";
import useInterval from "../../useInterval";
import AuctionAdminCP from "./AuctionAdminCP";
// import { Container } from "react-bootstrap";
// import { useParams } from "react-router-dom";
import AuctionCP from "./AuctionCP";
import AuctionNavBar from "./AuctionNavBar";

const Auction = (props: any) => {
  const currentUser: [string, string] = props.currentUser;
  const isAdmin = props.isAdmin;
  const auctionId = props.auctionId;
  const bidder: Bidder = props.currentBidder; // only for Bidder User (not Admin)
  // const auction: Auction = props.auction;
  // const loading = props.loading;
  // const auctionStatus: string = props.auctionStatus;
  const handleLogin = props.handleLogin;

  const initialAuctionFetch = useFetch(process.env.REACT_APP_API_URL + '/api/auctions/get/' + auctionId);

  const [currentAuction, setCurrentAuction] = useState<Auction|null>(initialAuctionFetch.data);
  const [updatedAuctionData, setUpdatedAuctionData] = useState<Auction|null>(null);
  const [updateAuctionPending, setUpdateAuctionPending] = useState(false);
  const [auctionStatus, setAuctionStatus] = useState('Ready');

  const [loading, setLoading] = useState(true);

  useEffect(()=> {
    if (currentAuction) {
      console.log('updating status');
      if (currentAuction.ended) {
        setAuctionStatus(AUCTION_STATUS.ended);
      }
      else if (currentAuction.status === 'Paused') {
        setAuctionStatus(AUCTION_STATUS.paused);
      }
      else {
        if (currentAuction.pending_bidder_count > 0) {
          if (isAdmin) {
            setAuctionStatus(AUCTION_STATUS.waiting_general);
          }
          else if (bidder) {
            if (process.env.REACT_APP_DEV_ENV === 'local') {
              setAuctionStatus(AUCTION_STATUS.ready);
            }
            else {
              // check if current bidder is a pending bidder
              fetch(`${process.env.REACT_APP_API_URL}/api/bidders/has_submitted/${bidder.id}/${auctionId}`)
                .then((res) => {
                  if(!res.ok) {
                    return res.text().then(text => { throw new Error(text) })
                  }
                  else {
                    return res.json();
                  }
                })
                .then((data) => {
                  const hasSubmitted: boolean = data;
                  if (hasSubmitted) {
                    setAuctionStatus(AUCTION_STATUS.waiting);
                  }
                  else {
                    setAuctionStatus(AUCTION_STATUS.ready);
                  }
                })
                .catch((err) => {
                  setAuctionStatus(AUCTION_STATUS.error);
                  console.log(err);
                });
            }
          }
        }
      }
    }
  }, [currentAuction]);

  useEffect(()=>{
    if (!initialAuctionFetch.error) {
      setCurrentAuction(initialAuctionFetch.data);
      setLoading(false);
    }
  }, [initialAuctionFetch.data]);

  useInterval(() => {
    if (currentAuction && !currentAuction.ended) {
      console.log('Checking auction status');
      setUpdateAuctionPending(true);
      fetch(`${process.env.REACT_APP_API_URL}/api/auctions/get/${auctionId}`)
        .then((res) => {
          if(!res.ok) {
            return res.text().then(text => { throw new Error(text) })
          }
          else {
            return res.json();
          }
        })
        .then((data) => {
          setTimeout(()=>{console.log('storing updated auction data'); setUpdatedAuctionData(data); setUpdateAuctionPending(false);}, 1500);
        })
        .catch((err) => {
          setUpdateAuctionPending(false);
          console.log(err);
        });
    }
  }, 15000);

  const reloadCurrentAuction = () => {
    setAuctionStatus('Reloading with updated auction data');
    setLoading(true);
    setTimeout(()=>{setUpdatedAuctionData(null); setCurrentAuction(updatedAuctionData); setLoading(false);}, 1400)
  }

  useEffect(()=>{console.log('rendering Auction')}, []);
    return (
      <>
        {initialAuctionFetch.isPending && !initialAuctionFetch.error && <MySpinner />}
        {bidder && !initialAuctionFetch.isPending && currentAuction && 
        <>
          <AuctionNavBar 
            currentUser={currentUser} 
            auction={currentAuction} 
            isAdmin={isAdmin}
            handleLogin={handleLogin} 
            bidder={bidder} 
            updatedAuction={updatedAuctionData}
            handleReload={reloadCurrentAuction}
            // pendingBidders={pendingBidders}
            updatePending={updateAuctionPending}
            auctionStatus={auctionStatus}
          />
          {loading && <MySpinner />}
          {!loading && 
          <AuctionCP 
            currentUser={currentUser} 
            auction={currentAuction} 
            isAdmin={isAdmin}
            bidder={bidder}
            // auctionStatus={auctionStatus}
          />
          }
        </>
        }
        {isAdmin && !initialAuctionFetch.isPending && currentAuction && 
        <>
          <AuctionNavBar 
            currentUser={currentUser} 
            auction={currentAuction} 
            handleLogin={handleLogin} 
            bidder={bidder} 
            isAdmin={isAdmin}
            // pendingBidders={pendingBidders}
            updatedAuction={updatedAuctionData}
            handleReload={reloadCurrentAuction}
            updatePending={updateAuctionPending}
            auctionStatus={auctionStatus}
          />
          {loading && <MySpinner />}
          {!loading && 
          <AuctionAdminCP
            auction={currentAuction} 
            isAdmin={isAdmin}
            bidder={bidder}
            // auctionStatus={auctionStatus}
          />
          }
        </>
        }
      </>
    );
};

Auction.displayName = 'Auction';


export default Auction;
