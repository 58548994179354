import { SyntheticEvent, useEffect, useState } from 'react';
import { Alert, Button, ButtonGroup, Form, ProgressBar, Table } from 'react-bootstrap';
import OffcanvasPane from '../global/OffcanvasPane';
import PendingButton from '../global/PendingButton';
import * as xlsx from 'xlsx';
import { formatNumber, simpleCleanDecode } from '../../Helpers';

const UploadExcelBids = (props: any) => {
  const bidder: Bidder = props.bidder;
  const [mainErrorMsg, setMainErrorMsg] = useState<null|string>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [warnings, setWarnings] = useState<string[]>([]);
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [validationProgressVal, setValidationProgressVal] = useState(0);
  const [currentlySubmitting, setCurrentlySubmitting] = useState(false);
  const [validatedBidsData, setValidatedBidsData] = useState<NewBid[]|null>(null);
  const [isUpdatedDisplayRegions, setIsUpdatedDisplayRegions] = useState(false);

  const [showFileUpload, setShowFileUpload] = useState(true);
  const [showConfirmedBids, setShowConfirmedBids] = useState(false);

  const resetFileUpload = () => {
    setValidationInProgress(false);
    setValidatedBidsData(null);
    setWarnings([]);
    setErrors([]);
    setMainErrorMsg(null);
    setShowConfirmedBids(false);
    setShowFileUpload(true);
  }

  const handleFileUpload = (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    const file = target.files ? target.files[0] : null;
    const filename = file?.name;
    const filetype = filename?.split('.').at(-1);

    resetFileUpload();

    let gatheredErrors:string[] = [];
    let gatheredWarnings:string[] = [];

    try {
      if (file) {
        console.log('validating file ' + filename);
        if (filetype && ['xlsx', 'xls', 'csv'].includes(filetype)) {
          const reader = new FileReader();
          reader.onload = (e) => {
            try {
              if (e.target) {
                setValidationInProgress(true);
                setValidationProgressVal(0);
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: 'array' });
                const sheet = workbook.Sheets[workbook.SheetNames[0]];
                const REQUIRED_COLUMNS = [
                  'Round',
                  'Company ID',
                  'Company Name',
                  'Product Number',
                  'Category',
                  'Service Area Name',
                  'Eligibility Points',
                  'Round Start Price',
                  'Clock Price',
                  // 'Bid Quantity',
                  'Bid Price ($)'
                ];
                const COLUMNS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];
                let missingColumns:string[] = [...REQUIRED_COLUMNS];
                // REQUIRED_COLUMNS.forEach((column) => {
                //   if (!Object.keys(xlsx.utils.sheet_to_json(sheet)).includes(column)) {
                //     missingColumns.push(column);
                //   }
                // });
                COLUMNS.forEach((column: string, i: number) => {
                  if (sheet[column + '1'] && REQUIRED_COLUMNS.includes(simpleCleanDecode(sheet[column + '1'].v))) {
                    missingColumns = missingColumns.filter((s: string)=>s!==simpleCleanDecode(sheet[column+'1'].v));
                  }
                  else if (sheet[column + '2'] && REQUIRED_COLUMNS.includes(simpleCleanDecode(sheet[column + '2'].v))) {
                    missingColumns = missingColumns.filter((s: string)=>s!==simpleCleanDecode(sheet[column+'2'].v));
                  }
                });
                if (missingColumns.length > 0) {
                  gatheredErrors.push('Missing required column(s) in config file (ensure spelling is correct): ' + missingColumns.join(', '))
                  throw Error('Missing required column(s)');
                }

                setValidationProgressVal(10);

                let newBids: NewBid[] = [];
                let currentNewBid: NewBid|null;
                let currentBidsArray: [number, number][] = [];

                xlsx.utils.sheet_to_json(sheet)
                .map((row: any, r: number) => {
                  const i = r+1; // count in human way for error msging
                  let isOK = true;
                  let isValidProduct = false;
                  let category = 'open';
                  if (!row['Round']) {
                    gatheredWarnings.push(`Row ${i} ignored: No round specified`);
                    isOK = false;
                  }
                  else if (row['Round'] !== props.auction.round_num) {
                    gatheredWarnings.push(`Row ${i} ignored: Round specified must be current round [${props.auction.round_num}]`);
                    isOK = false;
                  }
                  if (!row['Company Name']) {
                    gatheredWarnings.push(`Row ${i} ignored: No company (bidder) specified`);
                    isOK = false;
                  }
                  else if (simpleCleanDecode(row['Company Name']) !== props.bidder.name) {
                    gatheredWarnings.push(`Row ${i} ignored: Company specified ${simpleCleanDecode(row['Company Name'])} does not match current bidder ${props.bidder.name}`);
                    isOK = false;
                  }
                  if (!row['Product Number']) {
                    gatheredWarnings.push(`Row ${i} ignored: No product specified`);
                    isOK = false;
                  }
                  else if (!Object.keys(props.productsDict).includes(row['Product Number'])) {
                    gatheredWarnings.push(`Row ${i} ignored: ${row['Product Number']} is not a valid product code`);
                    isOK = false;
                  }
                  else {
                    if (!row['Category']) {
                      category = 'sap';
                    }
                    else {
                      if (row['Category'] === 'Open') {
                        category = 'open';
                      }
                      else if (row['Category'].replace('-', '').toLowerCase() === 'Set-aside'.replace('-', '').toLowerCase()) {
                        category = 'sap';
                      }
                      else {
                        gatheredWarnings.push(`Row ${i} ignored: ${row['Category']} is not a valid category for product ${row['Product Number']}`);
                        isOK = false;
                      }
                    }
                    if (category) {
                      if (props.productsDict[row['Product Number']][category]) {
                        isValidProduct = true;
                      }
                    }
                    if (isValidProduct) {
                      if (!row['Service Area Name']) {
                        gatheredWarnings.push(`Row ${i} ignored: No service area name specified`);
                        isOK = false;
                      }
                      else {
                        if (simpleCleanDecode(row['Service Area Name']) !== (props.productsDict[row['Product Number']][category] as SpecificProduct).name.slice(8)) {
                          gatheredWarnings.push(`Row ${i} ignored: Service area ${simpleCleanDecode(row['Service Area Name'])} does not match product ${row['Product Number']}. (Should be ${(props.productsDict[row['Product Number']].open as SpecificProduct).name.slice(8)}.)`);
                          isOK = false;
                        }
                      }
                      if (!row['Eligibility Points']) {
                        gatheredWarnings.push(`Row ${i} ignored: No EP value specified`);
                        isOK = false;
                      }
                      else {
                        if (row['Eligibility Points'] !== (props.productsDict[row['Product Number']][category] as SpecificProduct).ep) {
                          gatheredWarnings.push(`Row ${i} ignored: EP value ${row['Eligibility Points']} does not match product ${row['Product Number']} EP. (Should be ${(props.productsDict[row['Product Number']].open as SpecificProduct).ep}.)`);
                          isOK = false;
                        }
                      }
                      if (!row['Bid Quantity'] && row['Bid Quantity'] !== 0) {
                        gatheredWarnings.push(`Row ${i} (${(props.productsDict[row['Product Number']].open as SpecificProduct).name}) ignored: Empty bid quantity`);
                        isOK = false;
                      }
                      else if (!row['Bid Price ($)']) {
                        gatheredErrors.push(`Row ${i} (${(props.productsDict[row['Product Number']].open as SpecificProduct).name}): Missing bid price`);
                        isOK = false;
                      }
                      else {
                        const product = props.productsDict[row['Product Number']][category] as SpecificProduct;
                        const startPrice = props.auction.round_num === 1 ? product.initial_price : (props.allPrices.find((p:Price)=>p.round_num===props.auction.round_num&&p.product_code===product.code) as Price).start_price;
                        const clockPrice = props.auction.round_num === 1 ? product.initial_price : (props.allPrices.find((p:Price)=>p.round_num===props.auction.round_num&&p.product_code===product.code) as Price).clock_price;
                        if (row['Bid Price ($)'] < startPrice || row['Bid Price ($)'] > clockPrice) {
                          gatheredErrors.push(`Row ${i} error: $${formatNumber(row['Bid Price ($)'])} is not a valid price for this lot. Must be between start-of-round price $${formatNumber(startPrice)} and clock price $${formatNumber(clockPrice)} (inclusive).`);
                          isOK = false;
                        }
                      }
                    }
                  }

                  setValidationProgressVal(10 + (i/sheet.length) * 80);

                  return {'row': row, 'isOK': isOK }
                })
                .sort((r1: any, r2: any) => {
                  if (r1.row['Product Number'] && r2.row['Product Number']) {
                    return r1.row['Product Number'] - r2.row['Product Number']
                  }
                  else if (r1.row['Product Number']) {
                    return 1
                  }
                  else {
                    return -1
                  }
                })
                .forEach((validatedRow: {row: any, isOK: boolean}) => {
                  if (validatedRow.isOK) {
                    const currentBid: [number, number] = [
                        validatedRow.row['Bid Price ($)'],
                        validatedRow.row['Bid Quantity'] || 0
                      ];
                    if (!currentNewBid) {
                      // first row being looked at
                      currentBidsArray = [currentBid];
                      currentNewBid = {
                        product_code: validatedRow.row['Product Number'],
                        set_aside: validatedRow.row['Category'].toLowerCase() === 'open' ? false : true,
                        bids: currentBidsArray
                      };
                    }
                    else if (currentNewBid.product_code === validatedRow.row['Product Number']) {
                      // same product as last row
                      currentBidsArray.push(currentBid);
                      currentNewBid = {
                        product_code: validatedRow.row['Product Number'],
                        set_aside: validatedRow.row['Category'].toLowerCase() === 'open' ? false : true,
                        bids: currentBidsArray
                      };
                    }
                    else {
                      // new product being looked at (non-first)
                      newBids.push(currentNewBid); // push existing previous NewBid (now complete)
                      currentBidsArray = [currentBid];
                      currentNewBid = {
                        product_code: validatedRow.row['Product Number'],
                        set_aside: validatedRow.row['Category'].toLowerCase() === 'open' ? false : true,
                        bids: currentBidsArray
                      };
                    }
                  }
                  else if (currentNewBid) {
                    // make sure last NewBid is pushed even if current row doesn't contain a valid bid
                    newBids.push(currentNewBid);
                    currentBidsArray = [];
                    currentNewBid = null;
                  }
                });

                setValidationProgressVal(100);

                setWarnings(gatheredWarnings);

                if (gatheredErrors.length > 0) {
                  setErrors(gatheredErrors);
                  throw Error('Errors found within file: See below');
                }

                setValidatedBidsData(newBids);

                console.log(newBids);
              }
            }
            catch (err) {
              throw new Error(`Bids File Error: ${(err as Error).message}`)
            }
          }
          reader.readAsArrayBuffer(file);
        }
        else {
          throw new Error('Invalid file type: .' + filetype);
        }
      }
    }
    catch (err) {
      setValidatedBidsData(null);
      setValidationInProgress(false);
      setErrors(gatheredErrors);
      setWarnings(gatheredWarnings);
      if (err instanceof Error) {
        setMainErrorMsg(`${err.message}`);
        console.log(err);
      }
    }
  }

  const handleSubmitBids = () => {
    if (validatedBidsData) {
      setCurrentlySubmitting(true);
      // check if new Products added and update Display Regions
      const existingDisplayRegions: string[] = [...bidder.display_regions];
      const currentBidRegions: string[] = validatedBidsData.map((newBid: NewBid)=>newBid.product_code);
      const difference = currentBidRegions.filter(x => !existingDisplayRegions.includes(x));
      const newDisplayRegions: string[] = existingDisplayRegions.concat(difference);
      console.log('submitting POST');
      fetch(`${process.env.REACT_APP_API_URL}/api/bidders/edit/${bidder.id}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ display_regions: newDisplayRegions })
      })
        .then(res => {
          if(!res.ok) {
            return res.text().then(text => { throw new Error(text) })
          }
          // else {
          //   return res.json();
          // }
        })
        .then(data => {
          console.log('successfully sent as POST');
          setIsUpdatedDisplayRegions(true);
        })
        .catch(err => {
          console.log(err);
          setCurrentlySubmitting(false);
          setErrors([err.message]);
        });
    }
  }

  const handleConfirmFile = () => {
    setShowFileUpload(false);
    setShowConfirmedBids(true);
    console.log('submit');
  }

  useEffect(()=>{
    if (validatedBidsData) {
      const bidsSubmitObj: BidSubmission = {
        auction_id: props.auction.id,
        bidder_id: props.bidder.id,
        round_num: props.auction.round_num,
        new_bids: validatedBidsData
      };
      // submit Bids
      fetch(`${process.env.REACT_APP_API_URL}/api/bids/submit`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bidsSubmitObj)
      })
      .then(res => {
        if (!res.ok) {
          if (res.status !== 404) {
            setWarnings([]);
            return res.text().then(text => { throw new Error(text) })
          }
          else {
            throw new Error(`${res.status}: ${res.statusText}`)
          }
        }
      })
      .then(data => {console.log('successfully sent as POST'); window.location.reload();})
      .catch(err => {
        setCurrentlySubmitting(false);
        setErrors([err.message]);
      });
    }
  }, [isUpdatedDisplayRegions]);

  useEffect(()=>{
    resetFileUpload();
  }, [props.show]);

  return (
    <OffcanvasPane
      show={props.show}
      onHide={props.onHide}
      className='h-auto'
      title='Upload bids from Excel template'
    >
      {showFileUpload && !showConfirmedBids &&
        <>
          <Form.Group controlId="formFileLg" className="mb-3">
            <Form.Label>Upload bids from Excel template (.xls or .xlsx)</Form.Label>
            <Form.Control
              type="file"
              size="lg"
              onChange={ (e) => handleFileUpload(e) }
              isValid={!mainErrorMsg}
              isInvalid={mainErrorMsg ? !!mainErrorMsg : undefined}
              disabled={validationInProgress}
              />
            <Form.Control.Feedback type='invalid'>
              {mainErrorMsg}
            </Form.Control.Feedback>
          </Form.Group>
          {validationInProgress && <ProgressBar className='my-3' animated now={validationProgressVal} />}
          {warnings.map((warningMsg:string, i:number) => (
            <Alert key={i} variant='warning'>
              {warningMsg}
            </Alert>
          ))}
          {errors && errors.map((errorMsg:string) => (
            <Alert key={errorMsg} variant='danger'>
              <span dangerouslySetInnerHTML={{__html: errorMsg}} />
            </Alert>
          ))}
          <div className='d-flex justify-content-center'>
            <ButtonGroup>
              <PendingButton
                variant='primary'
                disabled={(validatedBidsData ? undefined : !validatedBidsData) || currentlySubmitting}
                onClick={()=>handleConfirmFile()}
                pending={currentlySubmitting}
              >
                Confirm
              </PendingButton>
              <Button
                variant='outline-primary'
                onClick={props.onHide}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </div>
        </>
      }
      {!showFileUpload && showConfirmedBids && validatedBidsData &&
        <>
          {validatedBidsData.length === 0 &&
            <p>No valid bids found. Please try again.</p>
          }
          {validatedBidsData.length > 0 &&
            <>
              <p>The following bids have been processed. Confirm and submit bids?</p>
              <div className='d-flex justify-content-center'>
                <ButtonGroup>
                  <PendingButton
                    disabled={validatedBidsData.length === 0 || currentlySubmitting || errors.length > 0}
                    pending={currentlySubmitting}
                    onClick={()=>handleSubmitBids()}
                  >
                    Submit
                  </PendingButton>
                  <Button variant='outline-primary' onClick={()=>resetFileUpload()}>Back</Button>
                </ButtonGroup>
              </div>
              {errors.length > 0 &&
                <>
                  <h6 className='my-3'>Error(s) detected by server. Please address and resubmit bids.</h6>
                  {errors.map((e: string, i: number)=>{
                    return <Alert key={i} variant='danger'>
                      <span dangerouslySetInnerHTML={{__html: e}} />
                    </Alert>})
                  }
                </>
              }
              {errors.length === 0 &&
              <Table>
                <thead>
                  <tr>
                    <th>Lot</th>
                    <th>Bid Package</th>
                  </tr>
                </thead>
                <tbody>
                {validatedBidsData.map((newBid: NewBid) => {
                  return <tr key={newBid.product_code}>
                    <td>{(props.productsDict[newBid.product_code][newBid.set_aside ? 'sap' : 'open'] as SpecificProduct).name}</td>
                    <td>
                      <ul>
                        {newBid.bids.map((b: [number, number]) => {
                          return <li key={b[1]}><strong>{b[1]} blocks at ${formatNumber(b[0])}</strong></li>
                        })}
                      </ul>
                    </td>
                  </tr>
                })}
                </tbody>
              </Table>
              }
            </>
          }
        </>
      }
    </OffcanvasPane>
  );
};

UploadExcelBids.displayName = 'UploadExcelBids';


export default UploadExcelBids;
