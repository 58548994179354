import { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import ErrorsModal from "../global/ErrorsModal";
import { doNotExceed, formatNumber } from "../../Helpers";

const BidPlacer = (props: any) => {
  const auction: Auction = props.auction;
  const product: SpecificProduct = props.product;
  const handlePlaceBid = props.handlePlaceBid;
  const handleRemoveBid = props.handleRemoveBid;
  const price = props.price;
  // const lastBids: Bid[] = props.lastBids;
  const currentBids = props.currentBids; // bids dict

  const [thisBids, setThisBids] = useState<[number, number][]>([]);

  const [showMonotonicityErrorModal, setShowMonotonicityErrorModal] = useState(false);

  let step = 10;
  if (product.initial_price > 10000) {
    step = 100;
  } 
  else if (product.initial_price > 100000) {
    step = 1000;
  }

  const validatePlaceBid = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const bidFormElements: ProductBidHTMLFormElement = e.currentTarget.elements as ProductBidHTMLFormElement;
    const bidPrice: number = bidFormElements.price.value;
    const bidBlocks: number = bidFormElements.blocks.value;

    let newBids: [number, number][] = [...thisBids, [bidPrice, bidBlocks]];

    // check monotonicity
    // first sort by blocks
    newBids.sort((a, b) => {
      if (a[1] < b[1]) {
        return -1
      }
      else if (a[1] > b[1]) {
        return 1
      }
      else {
        return 0
      }
    });
    // check monotonically incr.
    const isMonotonicUp = newBids.every((e, i, a) => {
      if (i) {
        return e[0] > a[i-1][0]
      }
      else {
        return true
      }
    });
    // check monotonically decr.
    const isMonotonicDown = newBids.every((e, i, a) => {
      if (i) {
        return e[0] < a[i-1][0]
      }
      else {
        return true
      }
    });

    if (isMonotonicDown || isMonotonicUp) {
      handlePlaceBid(e);
      setThisBids(newBids);
    }
    else {
      setShowMonotonicityErrorModal(true);
    }
  }

  const handleDeleteBid = (productCode: string, ep: number, setAside: boolean, bidPrice: number, bidBlocks: number) => {
    // const bidFormElements: ProductBidHTMLFormElement = e.currentTarget.elements as ProductBidHTMLFormElement;

    handleRemoveBid(productCode, ep, setAside, bidPrice, bidBlocks);
    let currentBids = [...thisBids];
    setThisBids(currentBids.filter((bid: [number, number]) => {
      return bid[0] !== bidPrice || bid[1] !== bidBlocks;
    }));
  }

  // useEffect(()=>{
  //   if (lastBids) {
  //     setThisBids(lastBids.filter(b=>b.product_code===product.code).map((bid: Bid) => {
  //       return [price.clock_price, bid.num_blocks]
  //     }));
  //   }
  // }, [lastBids]);

  useEffect(()=>{
  if (currentBids[product.code]) {
    setThisBids(currentBids[product.code][product.set_aside ? 'sap' : 'open'].map(((bid:[number,number])=>bid)));
  }
  else if (Object.keys(currentBids).length === 0) {
    setThisBids([]);
  }
}, [currentBids]);

    return (
      <>
        {thisBids && thisBids.map((bid: [number, number], index: number) => {
          return <p key={index} className='text-end'><strong>{bid[1]} block(s)</strong> at <strong>${formatNumber(bid[0])}</strong> <Button size='sm' variant='secondary' onClick={() => handleDeleteBid(product.code, product.ep, product.set_aside, bid[0], bid[1])}>[-]</Button></p>
        })}
        {thisBids.length < 5 && 
        <Form onSubmit={validatePlaceBid}>
          <InputGroup size='sm'>
            <Form.Control 
              name='code'
              type='hidden' 
              value={product.code} 
              />
            <Form.Control 
              name='ep'
              type='hidden' 
              value={product.ep} 
              />
            {product.set_aside && <Form.Control 
              name='setAside'
              type='hidden' 
              value='true'
              />}
            <Form.Control 
              placeholder='No. of block(s)' 
              aria-label='No. of block(s)' 
              type='number'
              name='blocks'
              step='1'
              min={0}
              max={product.init_supply}
              defaultValue={auction.round_num === 1 ? doNotExceed(product.init_supply, auction.spectrum_cap) : undefined}
              required
              />
            <InputGroup.Text>$</InputGroup.Text>
            <Form.Control 
              placeholder='Bid price' 
              aria-label='Bid price' 
              type='number'
              name='price'
              step={step}
              min={auction.round_num === 1 ? product.initial_price : price.start_price}
              max={auction.round_num === 1 ? product.initial_price : price.clock_price}
              defaultValue={auction.round_num === 1 ? price.clock_price : undefined}
              required
              />
            <Button 
              variant='primary' 
              id={'place-bid-button--' + product.code}
              type='submit'
              >
              [+]
            </Button>
          </InputGroup>
        </Form>
        }
        <ErrorsModal 
          errorsList={['Monotonicity rule violation']}
          onHide={() => setShowMonotonicityErrorModal(false)}
          show={showMonotonicityErrorModal}
        />
      </>
    );
};

BidPlacer.displayName = 'BidPlacer';

export default BidPlacer;
