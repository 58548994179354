import { Alert, Button } from "react-bootstrap";
import VaryABC from "./VaryABC";
import VaryBidderBudget from "./VaryBidderBudget";
import VaryBidderEP from "./VaryBidderEP";
import VaryIncPercent from "./VaryIncPercent";
import VarySpectrumCap from "./VarySpectrumCap";

const ParameterConfiguration = (props:any) => {
  const auction: Auction = props.auction;
  const paramViews: [string|null, string|null] = props.paramViews; // the currently selected parameter (not necessarily confirmed; for viewing only)
  const paramValues: [RangeParam, RangeParam] = props.paramValues;
  const whichParam: number = props.whichParam;
  // const isParamsReady: [boolean, boolean] = props.isParamsReady;

  const handleSaveAndContinue = props.handleSaveAndContinue;
  // const handleSelectParameter = props.handleSelectParameter;
  // const handleClickParamContinue = props.handleClickParamContinue;

  const PARAMETERS = props.parameters;

  return (<>
    <div className='mx-3 my-1 d-flex flex-column h-100'>
    {paramViews[whichParam]===`IncPct` && <VaryIncPercent auction={auction} currentParam={whichParam} paramValue={paramValues[whichParam] && Object.keys(paramValues[whichParam])[0]==='IncPct' ? ((paramValues[whichParam] as IncPct)['IncPct'] as RangeAuc) : undefined} onValidate={handleSaveAndContinue} />}
    {paramViews[whichParam]===`SpectrumCap` && <VarySpectrumCap auction={auction} currentParam={whichParam} paramValue={paramValues[whichParam] && Object.keys(paramValues[whichParam])[0]==='SpectrumCap' ? ((paramValues[whichParam] as SpectrumCap)['SpectrumCap'] as RangeAuc) : undefined} onValidate={handleSaveAndContinue} />}
    {paramViews[whichParam]===`BidderBudget` && <VaryBidderBudget auction={auction} currentParam={whichParam} paramValue={paramValues[whichParam] && Object.keys(paramValues[whichParam])[0]==='BidderBudget' ? ((paramValues[whichParam] as BidderBudget)['BidderBudget'] as RangeBdr) : undefined} onValidate={handleSaveAndContinue} />}
    {paramViews[whichParam]===`BidderInitEP` && <VaryBidderEP auction={auction} currentParam={whichParam} paramValue={paramValues[whichParam] && Object.keys(paramValues[whichParam])[0]==='BidderInitEP' ? (paramValues[whichParam] as BidderInitEP)['BidderInitEP'] as RangeBdr : undefined} onValidate={handleSaveAndContinue} />}
    {paramViews[whichParam]===`Priority` && <VaryABC parameters={PARAMETERS} paramName='Priority' auction={auction} currentParam={whichParam} paramValue={paramValues[whichParam] && Object.keys(paramValues[whichParam])[0]==='Priority' ? (paramValues[whichParam] as Priority)['Priority'] : undefined} onValidate={handleSaveAndContinue} />}
    {paramViews[whichParam]===`Budget` && <VaryABC parameters={PARAMETERS} paramName='Budget' auction={auction} currentParam={whichParam} paramValue={paramValues[whichParam] && Object.keys(paramValues[whichParam])[0]==='Budget' ? (paramValues[whichParam] as Budget)['Budget'] : undefined} onValidate={handleSaveAndContinue} />}
    {paramViews[whichParam]===`Price` && <VaryABC parameters={PARAMETERS} paramName='Price' auction={auction} currentParam={whichParam} paramValue={paramValues[whichParam] && Object.keys(paramValues[whichParam])[0]==='Price' ? (paramValues[whichParam] as any)['Price'] : undefined} onValidate={handleSaveAndContinue} />}
    {paramViews[whichParam]===`DMP` && <VaryABC parameters={PARAMETERS} paramName='DMP' auction={auction} currentParam={whichParam} paramValue={paramValues[whichParam] && Object.keys(paramValues[whichParam])[0]==='DMP' ? (paramValues[whichParam] as DMP)['DMP'] : undefined} onValidate={handleSaveAndContinue} />}
    {!paramViews[whichParam] &&
      <div
        className='justify-content-center d-flex w-100'
        style={{ maxWidth: '600px' }}
      >
        <Alert variant='danger'>No parameter has been selected.</Alert>
      </div>
    }
    {paramViews[whichParam]==='None'&&
      <div
        className='align-self-center d-flex flex-column w-100'
        style={{ maxWidth: '600px' }}
      >
        <Alert variant='primary'>No additional parameter will be varied in this set of variant auctions.</Alert>
        <div className='justify-content-center d-flex w-100'>
          <Button
            variant="primary"
            className='float-end my-3'
            onClick={()=>handleSaveAndContinue(true, 'NoParam', 1, whichParam)}
          >
            {paramViews[whichParam]==='None' ? 'Confirm' : 'Save'}
          </Button>
        </div>
      </div>
    }
    </div>
  </>)
}

export default ParameterConfiguration;