import { useEffect, useState } from 'react';
import { Alert, Button, Card, Form, InputGroup } from 'react-bootstrap';
import OffcanvasPane from '../global/OffcanvasPane';
import PendingButton from '../global/PendingButton';

const AutoSubmitModal = (props: any) => {
  const auction = props.auction;
  // const handleSubmit = props.handleAutoSubmit;
  // const currentBids = props.currentBids;
  // const formatBidObj = props.formatBidObj;
  const [roundNum, setRoundNum] = useState<number|undefined>(undefined);

  const [autoSubmitPending, setAutoSubmitPending] = useState(false);
  // const [autoSubmitError, setAutoSubmitError] = useState<string|null>(null);
  const [autoSubmitComplete, setAutoSubmitComplete] = useState(false);
  const [autoSubmitResponse, setAutoSubmitResponse] = useState<AutobidSubmissionResult|null>(null);
  const [allErrors, setAllErrors] = useState<string[]>([]);
  // const [miscMsgs, setMiscMsgs] = useState<string[]>([]);

  const [reloadCountdown, setReloadCountdown] = useState<number|null>(null);

  const handleChangeRound = (e: any) => {
    setRoundNum(e.target.value);
  }

  const autoSubmitBids = (r: number) => {
    // const bidsSubmitObj = formatBidObj(currentBids);

    console.log('submitting POST');
    setAutoSubmitPending(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/bids/fast-forward/${auction.id}/${props.bidder.id}/${roundNum}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
        if(!res.ok) {
          if (res.status ===400) {
            return res.text().then(text => { throw new Error(text) })
          }
          else {
            throw new Error(`${res.status}: ${res.statusText}`);
          }
        }
        else {
          return res.json();
        }
    })
    .then(data => {
        console.log('successfully sent as POST');
        console.log(data);
        setAutoSubmitResponse(data);
        setAutoSubmitPending(false);
        setAutoSubmitComplete(true);
    })
    .catch(err => {
        setAutoSubmitPending(false);
        setAllErrors([`${err.message}`]);
    });
  }

  const countdownOne = () => {
    if (reloadCountdown || reloadCountdown === 0) {
      setTimeout(()=>{
        console.log('counting down');
        if (reloadCountdown > 0) {
          setReloadCountdown(reloadCountdown - 1);
        }
        else {
          window.location.reload();
        }
      },　1000);
    }
  }

  useEffect(()=>{
    countdownOne();
  }, [reloadCountdown]);

  useEffect(()=>{
    if (autoSubmitResponse) {
      if (autoSubmitResponse.status !== 'TransientContinuing') {
        setReloadCountdown(15);
      }
    }
  }, [autoSubmitResponse]);

    return (
      <OffcanvasPane
        title='Auto-submit bids to maintain demand'
        show={props.show}
        onHide={props.onHide}
        animation='true'
        className='h-auto'
      >
      <div className='d-flex w-100 justify-content-center'>
        <div className='w-50' style={{ minWidth: '600px', maxWidth: '800px' }}>
          <Form.Label htmlFor='autosubmit-round'>Enter a round number (inclusive) up to which bids are automatically placed to maintain demand:</Form.Label>
          <InputGroup className='mb-3'>
            <InputGroup.Text id='autosubmit-round-input'>
              Round
            </InputGroup.Text>
            <Form.Control
              id='autosubmit-round'
              aria-describedby='autosubmit-round-input'
              aria-label='Round number'
              type='number'
              min={auction.round_num+2}
              value={roundNum}
              onChange={handleChangeRound}
            />
          </InputGroup>
          <Alert variant='info'>
            <p>Pressing submit will cause this bidder to place bids at the previous Processed Demand at Clock Price until the specified round is completed. Auto-submission will stop when any of these three conditions is met:</p>
            <ol>
              <li>Auction pauses</li>
              <li>Auction ends</li>
              <li>The specified round is reached and processed</li>
              <li>The bidder budget is about to be exceeded</li>
            </ol>
          </Alert>
          <div className='d-flex justify-content-center'>
            <PendingButton
              onClick={roundNum ? () => autoSubmitBids(roundNum) : undefined}
              pending={autoSubmitPending}
              disabled={!roundNum || (autoSubmitComplete || autoSubmitPending)}
              error={allErrors.length > 0}
              errorMsg='Submit'
            >
              {!autoSubmitPending && !autoSubmitComplete && 'Submit'}
              {autoSubmitComplete && !autoSubmitPending && 'Submitted'}
            </PendingButton>
          </div>
          {allErrors && allErrors.map((errorMsg:string) => (
            <Alert key={errorMsg} variant='danger'>
              {errorMsg}
            </Alert>
          ))}
          {autoSubmitResponse &&
            <Card className='mt-3'>
              <Card.Body>
                <Card.Title>
                  {autoSubmitResponse.status !== 'TransientContinuing'
                  && 'Autosubmission of bids has stopped'}
                  {autoSubmitResponse.status === 'TransientContinuing'
                  && 'Autosubmission of bids still in progress'}
                </Card.Title>
                <Card.Text>
                  {autoSubmitResponse.msg}
                </Card.Text>
                {autoSubmitResponse.status !== 'TransientContinuing' &&
                <div className='d-flex justify-content-center'>
                  <Button onClick={() => window.location.reload()}>
                    Reloading auction in {reloadCountdown} s...
                  </Button>
                </div>
                }
              </Card.Body>
            </Card>
          }
        </div>
      </div>
      </OffcanvasPane>
    );
};

AutoSubmitModal.displayName = 'AutoSubmitModal';

export default AutoSubmitModal;
