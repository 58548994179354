import { useEffect, useMemo, useState } from "react";
import { Accordion, Alert, Button, ButtonGroup, Card, Col, ListGroup, ProgressBar, Row } from "react-bootstrap"
import { HiOutlineChevronDoubleRight, HiChevronDoubleUp, HiChevronUp } from "react-icons/hi";
import { IoIosPause, IoIosPlay } from "react-icons/io";
import { IoTrashBinSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { formatNumber, getSensibleDuration, isDeepEqual } from "../../Helpers";
import MySpinner from "../../MySpinner";
import useFetch from "../../useFetch";
import useInterval from "../../useInterval";
import IconWrapper from "../global/IconWrapper";
import ParamBadge from "../global/ParamBadge";
import PendingButton from "../global/PendingButton";
import QueueNav from "./QueueNav";

interface StatusDictItem {
  auction?: Auction;
  pending: boolean;
  errors: string[];
  exec_dur_secs: number;
  live_lapse: number;
  last_timer: number;
}

const SimulationsQueue = (props: any) => {
  const debugMode = false;

  const { data: variations, isPending: loading, error: loadError } = useFetch(`${process.env.REACT_APP_API_URL}/api/variations/list`);
  const [prevVariations, setPrevVariations] = useState<Variation[]>([]);
  const [updatedVariations, setUpdatedVariations] = useState<Variation[]>([]);
  // const [showAllVariations, setShowAllVariations] = useState(true);
  const [variationStatusDict, setVariationStatusDict] = useState<{ [key: number]: StatusDictItem }>({}); // to keep track of statuses of incomplete variations -- does NOT include completed variations

  const variationsChanged = useMemo(() => {
    if (updatedVariations) {
      return !isDeepEqual(prevVariations, updatedVariations)
    }
    else {
      return false
    }
  }, [prevVariations, updatedVariations]);

  const handleDeleteVariation = (v: Variation) => {
    let updatedVarDict = {...variationStatusDict};

    updatedVarDict[v.id].pending = true;
    updatedVarDict[v.id].errors = [];

    setVariationStatusDict(updatedVarDict);

    console.log('submitting POST');
    fetch(`${process.env.REACT_APP_API_URL}/api/variations/delete/${v.id}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => {
        if (!res.ok && res.status ===400) {
          return res.text().then(text => { throw new Error(text) })
        }
        else if (!res.ok) {
          throw new Error(`${res.status} error: ${res.statusText}`);
        }
        // else {
        //   return res.json();
        // }
      })
      .then(data => {
        console.log('successfully deleted variation ' + v.id);
        updatedVarDict[v.id].pending = false;
        updatedVarDict[v.id].errors = [];
        setVariationStatusDict(updatedVarDict);
        window.location.reload();
      })
      .catch(err => {
        console.log(err);
        updatedVarDict[v.id].pending = false;
        updatedVarDict[v.id].errors = [];
        setVariationStatusDict(updatedVarDict);
      });
  }

  const handlePauseVariation = (v: Variation) => {
    let updatedVarDict = {...variationStatusDict};

    updatedVarDict[v.id].pending = true;
    updatedVarDict[v.id].errors = [];

    setVariationStatusDict(updatedVarDict);

    console.log('submitting POST');
    fetch(`${process.env.REACT_APP_API_URL}/api/variations/pause/${v.id}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => {
        if (!res.ok && res.status ===400) {
          return res.text().then(text => { throw new Error(text) })
        }
        else if (!res.ok) {
          throw new Error(`${res.status} error: ${res.statusText}`);
        }
        // else {
        //   return res.json();
        // }
      })
      .then(data => {
        console.log('successfully paused variation ' + v.id);
        updatedVarDict[v.id].pending = false;
        updatedVarDict[v.id].errors = [];
        setVariationStatusDict(updatedVarDict);
        fetchVariations();
      })
      .catch(err => {
        console.log(err);
        updatedVarDict[v.id].pending = false;
        updatedVarDict[v.id].errors = [];
        setVariationStatusDict(updatedVarDict);
      });
  }
  const handleNewQueuePosition = (v: Variation, q1: number, q2: number) => {
    let updatedVarDict = {...variationStatusDict};

    updatedVarDict[v.id].pending = true;
    updatedVarDict[v.id].errors = [];

    setVariationStatusDict(updatedVarDict);

    console.log('submitting POST');
    fetch(`${process.env.REACT_APP_API_URL}/api/variations/edit/${v.id}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        queue: Math.floor((q2+q1)/2)
      })
    })
      .then(res => {
        if (!res.ok && res.status ===400) {
          return res.text().then(text => { throw new Error(text) })
        }
        else if (!res.ok) {
          throw new Error(`${res.status} error: ${res.statusText}`);
        }
        // else {
        //   return res.json();
        // }
      })
      .then(data => {
        console.log(`successfully requeued variation ${v.id}`);
        updatedVarDict[v.id].pending = false;
        updatedVarDict[v.id].errors = [];
        setVariationStatusDict(updatedVarDict);
        fetchVariations();
      })
      .catch(err => {
        console.log(err);
        updatedVarDict[v.id].pending = false;
        updatedVarDict[v.id].errors = [];
        setVariationStatusDict(updatedVarDict);
      });
  }
  const handleResumeVariation = (v: Variation) => {
    let updatedVarDict = {...variationStatusDict};

    updatedVarDict[v.id].pending = true;
    updatedVarDict[v.id].errors = [];

    setVariationStatusDict(updatedVarDict);

    console.log('submitting POST');
    fetch(`${process.env.REACT_APP_API_URL}/api/variations/resume/${v.id}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => {
        if (!res.ok && res.status ===400) {
          return res.text().then(text => { throw new Error(text) })
        }
        else if (!res.ok) {
          throw new Error(`${res.status} error: ${res.statusText}`);
        }
        // else {
        //   return res.json();
        // }
      })
      .then(data => {
        console.log('successfully resumed variation ' + v.id);
        updatedVarDict[v.id].pending = false;
        updatedVarDict[v.id].errors = [];
        setVariationStatusDict(updatedVarDict);
        fetchVariations();
      })
      .catch(err => {
        console.log(err);
        updatedVarDict[v.id].pending = false;
        updatedVarDict[v.id].errors = [];
        setVariationStatusDict(updatedVarDict);
      });
  }

  useEffect(()=>{
    if (variations) {
      setPrevVariations(variations);
    }
  }, [variations]);

  useEffect(()=>{
    let updatedVariationStatusDict = {...variationStatusDict};
    setPrevVariations(updatedVariations);
    updatedVariations.forEach((v: Variation)=>{
      if (!updatedVariationStatusDict[v.id]) {
        updatedVariationStatusDict[v.id] = {
          'pending': false,
          'errors': [],
          'exec_dur_secs': v.exec_dur_secs,
          'live_lapse': 0, // in sec
          'last_timer': Math.floor(Date.now()/1000) + 1, // allow for 1 sec latency between front and backend
        };
      }
      else {
        updatedVariationStatusDict[v.id].pending = false;

        if (variationsChanged && v.exec_dur_secs!==updatedVariationStatusDict[v.id].exec_dur_secs && v.status==='Running') {
          updatedVariationStatusDict[v.id].exec_dur_secs = v.exec_dur_secs;
          updatedVariationStatusDict[v.id].live_lapse = 0;
          updatedVariationStatusDict[v.id].last_timer = Math.floor(Date.now()/1000) + 1 // allow for 1 sec latency between front and backend
        }
        else if (v.exec_dur_secs===updatedVariationStatusDict[v.id].exec_dur_secs && v.status==='Running') {
          updatedVariationStatusDict[v.id].live_lapse = (Math.floor(Date.now()/1000)-updatedVariationStatusDict[v.id].last_timer);
        }
        if (v.status==='Running') {
          console.log(`${v.name} lapsed time: ${v.exec_dur_secs+updatedVariationStatusDict[v.id].live_lapse}`);
        }
      }
      if (!updatedVariationStatusDict[v.id].auction) {
        fetchAuction(v.auction_id, v.id);
      }
    });
    setVariationStatusDict(updatedVariationStatusDict);
  }, [updatedVariations]);

  const fetchVariations = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/variations/list`)
      .then((res) => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data)=>{
        setUpdatedVariations(data);
      })
      .catch((err) => {;
        console.log(err);
      });
  }

  const fetchAuction = (aId: number, vId: number): Auction|void => {
    let updatedVariationStatusDict = {...variationStatusDict};
    updatedVariations.forEach((v: Variation)=>{
      if (!updatedVariationStatusDict[v.id]) {
        updatedVariationStatusDict[v.id] = {
          'pending': false,
          'errors': [],
          'exec_dur_secs': v.exec_dur_secs,
          'last_timer': Math.floor(Date.now()/1000) + 1, // allow for 1 sec latency between front and backend
          'live_lapse': 0
        };
      }
    });

    fetch(`${process.env.REACT_APP_API_URL}/api/auctions/get/${aId}`)
      .then((res) => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data)=>{
        updatedVariationStatusDict[vId].auction = data;
        setVariationStatusDict(updatedVariationStatusDict);
      })
      .catch((err) => {;
        updatedVariationStatusDict[vId].errors = [err.message];
        setVariationStatusDict(updatedVariationStatusDict);
        console.log(err);
      });
  }

  useInterval(()=>{
    if (variations) {
      // console.log('Checking variation queue status');
      fetchVariations();
    }
  }, 1000);

  return (
    <>
      <QueueNav />
      <div className='d-flex flex-column overflow-hidden'>
        <Card className='h-100 m-2'>
          <Card.Header>
            <Row>
              <Col sm={3} className='d-flex'>
                <span className='fw-bold'>
                  Variation
                </span>
              </Col>
              <Col sm={3} className='d-flex'>
                <span className='fw-bold'>Varied Parameters</span>
              </Col>
              <Col sm={4} className='d-flex justify-content-center'>
                <span className='fw-bold'>Status</span>
              </Col>
              <Col sm={2} className='d-flex justify-content-center'>
                <span className='fw-bold'>Actions</span>
              </Col>
            </Row>
          </Card.Header>
          {(loading || (Object.keys(variationStatusDict).length===0&&variations.length>0)) && <MySpinner />}
          {!loading && loadError && <Alert variant='danger'>{loadError}</Alert>}
          {!!variations && !loading && !loadError && <>
          <div style={{ overflowY: 'auto', height: 'calc(100vh - 56px - 150px)' }}>
            {!(Object.keys(variationStatusDict).length===0&&variations.length>0) && updatedVariations.filter((v: Variation)=>v.status==='Running').length===0 && <>
              <div className='w-100 p-4 text-center'>None</div>
            </>}
            <ListGroup className='overflow-scroll' variant='flush'>
              {Object.keys(variationStatusDict).length>0 && updatedVariations.filter((v: Variation)=>v.status==='Running').map((v: Variation, i: number)=>{
                return <ListGroup.Item key={`${v.id}`}>
                  <Row>
                    <Col sm={3}>
                      <div className='w-100'>{v.name}</div>
                      <div className='w-100'>
                        <IconWrapper>
                          <Link to={`/web/variations/${v.auction_id}`} className='text-decoration-none'>
                            <HiOutlineChevronDoubleRight />
                            <span className='ms-1 pt-1 mb-0'>{variationStatusDict[v.id]['auction'] ? (variationStatusDict[v.id]['auction'] as Auction)?.name : ''}</span>
                          </Link>
                        </IconWrapper>
                      </div>
                    </Col>
                    <Col sm={3}>
                      {!!variationStatusDict[v.id]['auction'] && <>
                      <ParamBadge className='me-1' title={v.name} rpSet={[JSON.parse(v.rp1), JSON.parse(v.rp2)]} rp={JSON.parse(v.rp1)} parentAuction={variationStatusDict[v.id]['auction'] as Auction} />
                      <ParamBadge title={v.name} rpSet={[JSON.parse(v.rp1), JSON.parse(v.rp2)]} rp={JSON.parse(v.rp2)} parentAuction={variationStatusDict[v.id]['auction'] as Auction} />
                      </>}
                    </Col>
                    <Col sm={4} className='text-center'>
                      <small>
                        {v.status} ({getSensibleDuration(v.exec_dur_secs+variationStatusDict[v.id].live_lapse)})
                      </small><br/>
                      {v.pending_variants > 0 &&
                        <ProgressBar
                          className="my-2"
                          variant={v.status==='Paused' ? 'warning' : undefined}
                          now={Math.floor(100*(v.num_variants-v.pending_variants)/v.num_variants)}
                          label={`${v.num_variants-v.pending_variants}/${v.num_variants}`}
                          animated
                        />
                      }
                      {v.pending_variants === 0 &&
                        <ProgressBar
                          variant="success"
                          now={100}
                          label={`${v.num_variants} variants`}
                        />
                      }
                    </Col>
                    <Col sm={2} className='text-center'>
                      {v.pending_variants > 0 && v.status==='Running' &&
                      <>
                        <ButtonGroup>
                          <PendingButton
                            variant="outline-primary"
                            onClick={()=>handlePauseVariation(v)}
                            pending={variationStatusDict[v.id].pending}
                          >
                            <IconWrapper>
                              <IoIosPause />
                              <small>Pause</small>
                            </IconWrapper>
                          </PendingButton>
                        </ButtonGroup><br/>
                        {debugMode && <small>Queue Position: {formatNumber(v.queue)}</small>}
                      </>
                      }
                      {v.status==='Paused' &&
                        <>
                          <ButtonGroup>
                            <PendingButton
                              variant="outline-primary"
                              onClick={()=>handleResumeVariation(v)}
                              pending={variationStatusDict[v.id].pending}
                            >
                              <IconWrapper>
                                <IoIosPlay />
                                <small>Resume</small>
                              </IconWrapper>
                            </PendingButton>
                          </ButtonGroup><br/>
                          {debugMode && <small>Queue Position: {formatNumber(v.queue)}</small>}
                        </>
                        }
                      {v.pending_variants === 0 &&
                        <>
                          <Link to={`/web/variation-results/${v.id}`}>
                            <Button variant="outline-primary" className='mb-2'>View Results</Button>
                          </Link><br/>
                          <PendingButton
                            variant='outline-danger'
                            onClick={()=>handleDeleteVariation(v)}
                            disabled={(variationStatusDict[v.id]&&variationStatusDict[v.id].pending)}
                            >
                            <IconWrapper>
                              <IoTrashBinSharp />
                            </IconWrapper>
                          </PendingButton>
                        </>
                      }
                    </Col>
                  </Row>
                </ListGroup.Item>
              })}
            </ListGroup>
          <Accordion defaultActiveKey={['1', '2']} alwaysOpen>
            <Accordion.Item eventKey='1'>
              <Accordion.Header>Queued Variations</Accordion.Header>
              <Accordion.Body className='p-0'>
                {!(Object.keys(variationStatusDict).length===0&&variations.length>0) && !loading && !loadError && updatedVariations.filter((v: Variation)=>v.status==='Paused'||v.status==='Pausing'||v.status==='Queued').length===0 && <>
                  <div className='w-100 p-4 text-center'>None</div>
                </>}
                <ListGroup className='overflow-scroll' variant='flush'>
                  {!!variations && !loading && !loadError && Object.keys(variationStatusDict).length>0 &&
                    updatedVariations
                      .filter((v: Variation)=>v.status==='Paused'||v.status==='Pausing'||v.status==='Queued')
                      .sort((v1: Variation, v2: Variation)=>v1.queue-v2.queue)
                      .map((v: Variation, i: number)=>{
                        const oneUpQueuePosition = i===0 ? 0 :
                          updatedVariations
                            .filter((v: Variation)=>v.status==='Paused'||v.status==='Pausing'||v.status==='Queued')
                            .sort((v1: Variation, v2: Variation)=>v1.queue-v2.queue)
                            [i-1].queue;
                        const twoUpQueuePosition = i<=1 ? 0 :
                          updatedVariations
                            .filter((v: Variation)=>v.status==='Paused'||v.status==='Pausing'||v.status==='Queued')
                            .sort((v1: Variation, v2: Variation)=>v1.queue-v2.queue)
                            [i-2].queue;
                        return <ListGroup.Item key={`${v.id}`}>
                          <Row>
                            <Col sm={3}>
                              <div className='w-100'>{v.name}</div>
                              <div className='w-100'>
                                <IconWrapper>
                                  <Link to={`/web/variations/${v.auction_id}`} className='text-decoration-none'>
                                    <HiOutlineChevronDoubleRight />
                                    <span className='ms-1 pt-1 mb-0'>{variationStatusDict[v.id]['auction'] ? (variationStatusDict[v.id]['auction'] as Auction)?.name : ''}</span>
                                  </Link>
                                </IconWrapper>
                              </div>
                            </Col>
                            <Col sm={3}>
                              {!!variationStatusDict[v.id]['auction'] && <>
                              <ParamBadge className='me-1' title={v.name} rpSet={[JSON.parse(v.rp1), JSON.parse(v.rp2)]} rp={JSON.parse(v.rp1)} parentAuction={variationStatusDict[v.id]['auction'] as Auction} />
                              <ParamBadge title={v.name} rpSet={[JSON.parse(v.rp1), JSON.parse(v.rp2)]} rp={JSON.parse(v.rp2)} parentAuction={variationStatusDict[v.id]['auction'] as Auction} />
                              </>}
                            </Col>
                            <Col sm={4} className='text-center'>
                              <small>
                                {v.status} ({getSensibleDuration(v.exec_dur_secs)})
                              </small><br/>
                              {v.pending_variants > 0 &&
                                <ProgressBar
                                  className="my-2"
                                  variant={v.status==='Paused' ? 'warning' : undefined}
                                  now={Math.floor(100*(v.num_variants-v.pending_variants)/v.num_variants)}
                                  label={`${v.num_variants-v.pending_variants}/${v.num_variants}`}
                                  animated
                                />
                              }
                              {v.pending_variants === 0 &&
                                <ProgressBar
                                  variant="success"
                                  now={100}
                                  label={`${v.num_variants} variants`}
                                />
                              }
                            </Col>
                            <Col sm={2} className='text-center'>
                              {v.pending_variants>0 && v.status==='Paused' &&
                                <>
                                <ButtonGroup>
                                  <ButtonGroup>
                                    <PendingButton
                                      variant="outline-primary"
                                      onClick={()=>handleResumeVariation(v)}
                                      pending={variationStatusDict[v.id].pending}
                                      >
                                      <IconWrapper>
                                        <IoIosPlay />
                                        <small>Resume</small>
                                      </IconWrapper>
                                    </PendingButton>
                                  <PendingButton
                                    variant="outline-primary"
                                    onClick={
                                      ()=>handleNewQueuePosition(
                                          v,
                                          twoUpQueuePosition,
                                          oneUpQueuePosition
                                          )
                                    }
                                    disabled={i===0||variationStatusDict[v.id].pending}
                                    >
                                    <IconWrapper>
                                      <HiChevronUp />
                                    </IconWrapper>
                                  </PendingButton>
                                  <PendingButton
                                    variant="outline-primary"
                                    onClick={
                                      ()=>handleNewQueuePosition(
                                        v,
                                        0,
                                        updatedVariations
                                          .filter((v: Variation)=>v.status==='Paused'||v.status==='Pausing'||v.status==='Queued')
                                          .sort((v1: Variation, v2: Variation)=>v1.queue-v2.queue)
                                          [0].queue
                                      )
                                    }
                                    disabled={i===0||(variationStatusDict[v.id]&&variationStatusDict[v.id].pending)}
                                    >
                                    <IconWrapper>
                                      <HiChevronDoubleUp />
                                    </IconWrapper>
                                  </PendingButton>
                                  <PendingButton
                                    variant='outline-danger'
                                    onClick={()=>handleDeleteVariation(v)}
                                    disabled={(variationStatusDict[v.id]&&variationStatusDict[v.id].pending)}
                                    >
                                    <IconWrapper>
                                      <IoTrashBinSharp />
                                    </IconWrapper>
                                  </PendingButton>
                                  </ButtonGroup>
                                </ButtonGroup><br/>
                                {debugMode && <small>Queue Position: {formatNumber(v.queue)}</small>}
                                </>
                              }
                              {v.pending_variants>0 && (v.status==='Queued'||v.status==='Pausing') &&
                                <>
                                <ButtonGroup>
                                  <PendingButton
                                    variant="outline-primary"
                                    onClick={()=>handlePauseVariation(v)}
                                    pending={variationStatusDict[v.id].pending}
                                    disabled={variationStatusDict[v.id].pending||v.status==='Pausing'}
                                    >
                                    <IconWrapper>
                                      <IoIosPause />
                                      <small>Pause</small>
                                    </IconWrapper>
                                  </PendingButton>
                                  <PendingButton
                                    variant="outline-primary"
                                    onClick={
                                      ()=>handleNewQueuePosition(
                                          v,
                                          twoUpQueuePosition,
                                          oneUpQueuePosition
                                          )
                                    }
                                    disabled={i===0||(variationStatusDict[v.id]&&variationStatusDict[v.id].pending)}
                                    >
                                    <IconWrapper>
                                      <HiChevronUp />
                                    </IconWrapper>
                                  </PendingButton>
                                  <PendingButton
                                    variant="outline-primary"
                                    onClick={
                                      ()=>handleNewQueuePosition(
                                        v,
                                        0,
                                        updatedVariations
                                          .filter((v: Variation)=>v.status==='Paused'||v.status==='Pausing'||v.status==='Queued')
                                          .sort((v1: Variation, v2: Variation)=>v1.queue-v2.queue)
                                          [0].queue
                                      )
                                    }
                                    disabled={i===0||(variationStatusDict[v.id]&&variationStatusDict[v.id].pending)}
                                    >
                                    <IconWrapper>
                                      <HiChevronDoubleUp />
                                    </IconWrapper>
                                  </PendingButton>
                                  <PendingButton
                                    variant='outline-danger'
                                    onClick={()=>handleDeleteVariation(v)}
                                    disabled={true}
                                    >
                                    <IconWrapper>
                                      <IoTrashBinSharp />
                                    </IconWrapper>
                                  </PendingButton>
                                </ButtonGroup><br/>
                                {debugMode && <small>Queue Position: {formatNumber(v.queue)}</small>}
                                </>
                              }
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      })
                  }
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='2'>
              <Accordion.Header>Completed Variations</Accordion.Header>
              <Accordion.Body className='p-0'>
                {!(Object.keys(variationStatusDict).length===0&&variations.length>0) && !loading && !loadError && updatedVariations.filter((v: Variation)=>v.status==='Completed').length===0 && <>
                  <div className='w-100 p-4 text-center'>None</div>
                </>}
                <ListGroup className='overflow-scroll' variant='flush'>
                  {Object.keys(variationStatusDict).length>0 && !loading && !loadError && updatedVariations.filter((v: Variation)=>v.status==='Completed').map((v: Variation, i: number)=>{
                    return <ListGroup.Item key={`${v.id}`}>
                      <Row>
                        <Col sm={3}>
                          <div className='w-100'>{v.name}</div>
                          <div className='w-100'>
                            <IconWrapper>
                              <Link to={`/web/variations/${v.auction_id}`} className='text-decoration-none'>
                                <HiOutlineChevronDoubleRight />
                                <span className='ms-1 pt-1 mb-0'>{variationStatusDict[v.id]['auction'] ? (variationStatusDict[v.id]['auction'] as Auction)?.name : ''}</span>
                              </Link>
                            </IconWrapper>
                          </div>
                        </Col>
                        <Col sm={3}>
                          {!!variationStatusDict[v.id]['auction'] && <>
                          <ParamBadge className='me-1' title={v.name} rpSet={[JSON.parse(v.rp1), JSON.parse(v.rp2)]} rp={JSON.parse(v.rp1)} parentAuction={variationStatusDict[v.id]['auction'] as Auction} />
                          <ParamBadge title={v.name} rpSet={[JSON.parse(v.rp1), JSON.parse(v.rp2)]} rp={JSON.parse(v.rp2)} parentAuction={variationStatusDict[v.id]['auction'] as Auction} />
                          </>}
                        </Col>
                        <Col sm={4} className='text-center'>
                          <small>
                            {v.status} ({getSensibleDuration(v.exec_dur_secs)})
                          </small><br/>
                          {v.pending_variants > 0 &&
                            <ProgressBar
                              className="my-2"
                              variant={v.status==='Paused' ? 'warning' : undefined}
                              now={Math.floor(100*(v.num_variants-v.pending_variants)/v.num_variants)}
                              label={`${v.num_variants-v.pending_variants}/${v.num_variants}`}
                              animated
                            />
                          }
                          {v.pending_variants === 0 &&
                            <ProgressBar
                              variant="success"
                              now={100}
                              label={`${v.num_variants} variants`}
                            />
                          }
                        </Col>
                        <Col sm={2} className='text-center'>
                          {v.pending_variants > 0 && v.status==='Queued' &&
                            <ButtonGroup>
                              <Button disabled variant='outline-secondary'>
                                <small>Queued</small>
                              </Button>
                              <PendingButton
                                variant="outline-primary"
                              >
                                <IconWrapper>
                                  <HiChevronUp />
                                </IconWrapper>
                              </PendingButton>
                              <PendingButton
                                variant="outline-primary"
                              >
                                <IconWrapper>
                                  <HiChevronDoubleUp />
                                </IconWrapper>
                              </PendingButton>
                              <PendingButton
                                variant='outline-danger'
                                onClick={()=>handleDeleteVariation(v)}
                                disabled={(variationStatusDict[v.id]&&variationStatusDict[v.id].pending)}
                                >
                                <IconWrapper>
                                  <IoTrashBinSharp />
                                </IconWrapper>
                              </PendingButton>
                            </ButtonGroup>
                          }
                          {v.pending_variants > 0 && v.status==='Running' &&
                            <ButtonGroup>
                              <PendingButton
                                variant="outline-primary"
                                onClick={()=>handlePauseVariation(v)}
                                pending={variationStatusDict[v.id].pending}
                              >
                                <IconWrapper>
                                  <IoIosPause />
                                  <small>Pause</small>
                                </IconWrapper>
                              </PendingButton>
                              <PendingButton
                                variant="outline-primary"
                              >
                                <IconWrapper>
                                  <HiChevronUp />
                                </IconWrapper>
                              </PendingButton>
                              <PendingButton
                                variant="outline-primary"
                              >
                                <IconWrapper>
                                  <HiChevronDoubleUp />
                                </IconWrapper>
                              </PendingButton>
                              <PendingButton
                                variant='outline-danger'
                                onClick={()=>handleDeleteVariation(v)}
                                disabled={(variationStatusDict[v.id]&&variationStatusDict[v.id].pending)}
                                >
                                <IconWrapper>
                                  <IoTrashBinSharp />
                                </IconWrapper>
                              </PendingButton>
                            </ButtonGroup>
                          }
                          {v.status==='Paused' &&
                            <ButtonGroup>
                              <PendingButton
                                variant="outline-primary"
                                onClick={()=>handleResumeVariation(v)}
                                pending={variationStatusDict[v.id].pending}
                              >
                                <IconWrapper>
                                  <IoIosPlay />
                                  <small>Resume</small>
                                </IconWrapper>
                              </PendingButton>
                              <PendingButton
                                variant="outline-primary"
                              >
                                <IconWrapper>
                                  <HiChevronUp />
                                </IconWrapper>
                              </PendingButton>
                              <PendingButton
                                variant="outline-primary"
                              >
                                <IconWrapper>
                                  <HiChevronDoubleUp />
                                </IconWrapper>
                              </PendingButton>
                              <PendingButton
                                variant='outline-danger'
                                onClick={()=>handleDeleteVariation(v)}
                                disabled={(variationStatusDict[v.id]&&variationStatusDict[v.id].pending)}
                                >
                                <IconWrapper>
                                  <IoTrashBinSharp />
                                </IconWrapper>
                              </PendingButton>
                            </ButtonGroup>
                            }
                          {v.pending_variants === 0 &&
                          <>
                            <Link to={`/web/variation-results/${v.id}`}>
                              <Button variant="outline-primary" className='mb-2'>
                                View Results
                              </Button>
                            </Link><br/>
                            <PendingButton
                              variant='outline-danger'
                              onClick={()=>handleDeleteVariation(v)}
                              disabled={(variationStatusDict[v.id]&&variationStatusDict[v.id].pending)}
                              >
                              <IconWrapper>
                                <IoTrashBinSharp />
                              </IconWrapper>
                            </PendingButton>
                            {debugMode && <small>Queue Position: {formatNumber(v.queue)}</small>}
                          </>
                          }
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  })}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          </div>
          </>}
        </Card>
      </div>
    </>
  )
}

export default SimulationsQueue;