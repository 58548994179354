import { Card } from "react-bootstrap";

const ParameterGrid = (props:any) => {
  const PARAMETERS = props.parameters;
  const whichParam: number = props.whichParam;
  const handleToggleParameter = props.handleToggleParameter;
  // const handleConfirm = props.handleConfirm;
  const paramViews: [string|null, string|null] = props.paramViews; // the currently selected parameter (not necessarily confirmed; for viewing only)
  // const selectedParams: [string, string] = props.selectedParams;
  const confirmedParams: [RangeParam, RangeParam] = props.paramValues;

  const SINGLE_SELECT_PARAMS = ['IncPct', 'SpectrumCap'];

  const checkIfDisabled = (paramAbbr: string) => {
    const otherParam = Math.abs(whichParam-1);
    return confirmedParams[otherParam]!=='NoParam' && SINGLE_SELECT_PARAMS.includes(paramAbbr) && Object.keys(confirmedParams[otherParam])[0]===paramAbbr;
  }

  const getBgColor = (param: { name: string, abbr: string, explanation: string }) => {
    if (checkIfDisabled(param.abbr)) {
      return 'light'
    }
    else if (paramViews[whichParam]===param.abbr) {
      return 'primary'
    }
    else {
      return undefined
    }
  }

  return (<>
    <Card.Body>
      <h6>Choose Parameter {whichParam+1} on which to base variant auctions:</h6>
      <div className="d-flex flex-wrap">
      {whichParam===1 &&
        <Card
          style={{ minWidth: '18rem', width: '31%' }}
          className='noneParamSelect variantSelectableParam m-1'
          bg={paramViews.includes('None') ? 'primary' : undefined}
          text={paramViews.includes('None') ? 'light' : 'danger'}
          border={'danger'}
          onClick={()=>handleToggleParameter('None', whichParam)}
          >
          <Card.Body>
            <span className='fw-bold'>None</span><br/>
            Do not vary a second parameter.
          </Card.Body>
        </Card>
      }
      {PARAMETERS.map((param: { name: string, abbr: string, explanation: string }, i: number)=>
        <Card
          style={{ minWidth: '18rem', width: '31%' }}
          className={checkIfDisabled(param.abbr) ? 'm-1' : 'variantSelectableParam m-1'}
          key={i}
          bg={getBgColor(param)}
          text={paramViews[whichParam]===param.abbr ? 'light' : undefined}
          onClick={!checkIfDisabled(param.abbr) ? ()=>handleToggleParameter(param.abbr, whichParam) : undefined}
          >
          <Card.Body>
            <span className='fw-bold'><small>{param.name}</small></span><br/>
            <small>{param.explanation}</small>
          </Card.Body>
        </Card>
      )}
      </div>
    </Card.Body>
  </>)
}

export default ParameterGrid;