import type { ChartOptions } from 'chart.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useMemo } from 'react';
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface DMPPoint {
  label: string;
  value: number;
}

const DemandCurve = (props:any) => {
  const demandCurveData: DMPPoint[] = props.demandCurveData;
  const minConstant: number|null = props.minConstant;
  const maxConstant: number|null = props.maxConstant
  const minDemandCurveData: number[] = useMemo(()=>{
    if (minConstant) {
      return demandCurveData.filter((dp:DMPPoint)=>!!dp.value).map((dp:DMPPoint)=>dp.value*minConstant)
    }
    else {
      return []
    }
  }, [minConstant]);
  const maxDemandCurveData: number[] = useMemo(()=>{
    if (maxConstant) {
      return demandCurveData.filter((dp:DMPPoint)=>!!dp.value).map((dp:DMPPoint)=>dp.value*maxConstant)
    }
    else {
      return []
    }
  }, [maxConstant]);

  const options: ChartOptions<'line'> = {
    scales: {
      x: {
        display: false
      },
      y: {
        display: false
      }
    },
    plugins: {
      legend: {
        display: false
      }
    },
    animation: props.disableAnimation ? false : undefined
  };

  return (<Line
      data={{
        labels: demandCurveData.filter((dp:DMPPoint)=>!!dp.value).map((dp:DMPPoint)=>dp.label),
        datasets: [
          {
            label: '$/MHz/pop.',
            data: demandCurveData.filter((dp:DMPPoint)=>!!dp.value).map((dp:DMPPoint)=>dp.value),
            fill: false,
            borderColor: '#2d4f6f',
            tension: 0.1
          },
          {
            label: 'Min. $/MHz/pop.',
            data: minDemandCurveData,
            fill: false,
            borderColor: '#8D2C2F',
            tension: 0.1
          },
          {
            label: 'Max. $/MHz/pop.',
            data: maxDemandCurveData,
            fill: false,
            borderColor: '#367F4D',
            tension: 0.1
          },
        ]
      }}
      options={options}
      height={50}
    />)
}

export default DemandCurve;