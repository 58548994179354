import { useEffect, useRef, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { HiOutlineChevronDoubleRight } from "react-icons/hi";
import ErrorsModal from "../global/ErrorsModal";
import IconWrapper from "../global/IconWrapper";
import ParameterConfiguration from "./ParameterConfiguration";
import ParameterGrid from "./ParameterGrid";

const ParameterSelection = (props:any) => {
  const auction: Auction = props.auction;
  // const selectedParams: [string, string] = props.selectedParams;
  const paramValues: [RangeParam, RangeParam] = props.paramValues;
  const isParamsReady: [boolean, boolean] = props. isParamsReady;
  const whichParam: number = props.whichParam; // current parameter index (0 or 1)

  // const handleSelectParameter = props.handleSelectParameter;
  const handleConfirm = props.handleConfirm;
  const handleSaveAndContinue = props.handleSaveAndContinue;
  const handleClickParamContinue = props.handleClickParamContinue;

  const [paramViews, setParamViews] = useState<[string|null, string|null]>([null, null]);
  const [showOverrideAlert, setShowOverrideAlert] = useState<boolean>(false);
  const [confirmOverride, setConfirmOverride] = useState<boolean>(false);
  const [newOverrideParam, setNewOverrideParam] = useState<string|null>(null);

  const configRef = useRef<HTMLAnchorElement>(null);
  const selectRef = useRef<HTMLAnchorElement>(null);

  const PARAMETERS = props.parameters;

  const handleSelectParameter = (param: string) => {
    const newParamViews: [string|null, string|null] = [...paramViews];
    if (paramViews[whichParam]!==param) {
      newParamViews[whichParam] = param;
      setParamViews(newParamViews);
    }
  }

  const handleToggleParameter = (param: string) => {
    if (paramValues[whichParam]!=='NoParam'&&!confirmOverride) {
      setShowOverrideAlert(false);
      setShowOverrideAlert(true);
      setNewOverrideParam(param);
    }
    else { // confirmOverride is true
      const newParamViews: [string|null, string|null] = [...paramViews];
      newParamViews[whichParam] = param;
      setParamViews(newParamViews);
      handleSelectParameter(param);
      configRef.current?.click();
    }
  }

  useEffect(()=>{
    if (newOverrideParam && !!confirmOverride) {
      handleToggleParameter(newOverrideParam);
    }
  }, [confirmOverride]);

  useEffect(()=>{
    const newParamViews: [string|null, string|null] = [...paramViews];
    if (paramValues[whichParam]!=='NoParam') {
      newParamViews[whichParam] = (Object.keys(paramValues[whichParam])[0]);
    }
    else if (paramValues[whichParam]==='NoParam' && isParamsReady[whichParam]) {
      newParamViews[whichParam] = 'None';
    }
    else {
      newParamViews[whichParam] = null;
      selectRef.current?.click();
    }
    setParamViews(newParamViews);
  }, [whichParam]);

  return (<>
    <Tab.Container defaultActiveKey='select'>
      <Nav variant='pills' className='p-3 m-2 justify-content-center'>
        <Nav.Item>
          <Nav.Link
            eventKey='select'
            onClick={()=>setConfirmOverride(false)}
            ref={selectRef}
            >
            Selection
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link disabled>
            <IconWrapper>
              <span>&nbsp;</span>
              <HiOutlineChevronDoubleRight />
              <span>&nbsp;</span>
            </IconWrapper>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='config'
            ref={configRef}
            >
            Configuration
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content className='d-flex'>
        <Tab.Pane eventKey='select' className='flex-fill'>
          <ParameterGrid
            parameters={PARAMETERS}
            paramValues={paramValues}
            paramViews={paramViews}
            whichParam={whichParam}
            handleToggleParameter={handleToggleParameter}
            handleConfirm={handleConfirm}
          />
        </Tab.Pane>
        <Tab.Pane eventKey='config' className='flex-fill'>
          <ParameterConfiguration
            auction={auction}
            parameters={PARAMETERS}
            paramViews={paramViews}
            paramValues={paramValues}
            whichParam={whichParam}
            isParamsReady={isParamsReady}
            handleSaveAndContinue={handleSaveAndContinue}
            handleSelectParameter={handleSelectParameter}
            handleClickParamContinue={handleClickParamContinue}
          />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
    <ErrorsModal
      show={showOverrideAlert}
      onHide={()=>setShowOverrideAlert(false)}
      warningsList={['You have already confirmed a parameter at this screen. Proceeding will override any previous configuration you may have saved.']}
      buttonAction={()=>{setConfirmOverride(true); setShowOverrideAlert(false);}}
      buttonText='Continue'
    />
  </>);
}

export default ParameterSelection;