import { useEffect, useState } from "react";
import { Accordion, Badge, Col, Container, Row, Spinner } from "react-bootstrap";
import { namedBidderSort } from "../../Helpers";
import NamedBidder from "../global/NamedBidder";

const NamedBiddersView = () => {
  const [namedBiddersData, setNamedBiddersData] = useState<null|{[key: string]: NamedBidder}>(null);
  const [namedBiddersLoading, setNamedBiddersLoading] = useState(true);
  const [namedBiddersErrorMsg, setNamedBiddersErrorMsg] = useState<null|string>(null);

  const [regionsT4Data, setRegionsT4Data] = useState<null|{[key: string]: Region}>(null);
  const [loadingT4Data, setLoadingT4Data] = useState(true);
  const [regionsT4ErrorMsg, setRegionsT4ErrorMsg] = useState<null|string>(null);

  const fetchNamedBiddersData = () => {
    fetch(process.env.REACT_APP_API_URL + '/api/namedbidders/list')
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setNamedBiddersData(data);
        setNamedBiddersLoading(false);
        console.log('fetched named bidders data');
      })
      .catch(err => {
        setNamedBiddersLoading(false);
        setNamedBiddersErrorMsg(err.message);
      });
  }
  const fetchRegionsT4Data = () => {
    fetch(process.env.REACT_APP_API_URL + '/api/regionst4/list')
      .then(res => {
        if(!res.ok) {
          return res.text().then(text => { throw new Error(text) })
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setRegionsT4Data(data);
        setLoadingT4Data(false);
        console.log('fetched T4 region data');
      })
      .catch(err => {
        setLoadingT4Data(false);
        setRegionsT4ErrorMsg(err.message);
      });
  }

  useEffect(() => {
    fetchNamedBiddersData();
    fetchRegionsT4Data();
  }, []);

    return (
      <>
        {namedBiddersLoading || loadingT4Data && <><Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="visually-hidden">Loading...</span></>
        }
        {namedBiddersErrorMsg &&
          <div>3500 MHz holding bidders error: {namedBiddersErrorMsg}</div>
        }
        {regionsT4ErrorMsg &&
          <div>Tier 4 Regions Error: {regionsT4ErrorMsg}</div>
        }
        {namedBiddersData && regionsT4Data &&
        <>
        <div className='mt-3'>View bidders with 3500 MHz holdings and their allocated products below.</div>
        <Accordion className='my-3' alwaysOpen>
          {Object.keys(namedBiddersData).sort().map((key:string) => {
            const bidderName: string = namedBiddersData[key].display_name || namedBiddersData[key].name;
            return (<Accordion.Item eventKey={key} key={namedBiddersData[key].abbr}>
              <Accordion.Header>
                <div className='me-3'>
                  <NamedBidder abbr={namedBiddersData[key].abbr} name={bidderName} />
                </div>
                <Badge bg='secondary' pill>{Object.keys(namedBiddersData[key].alloc_count).length} service areas</Badge>
              </Accordion.Header>
              <Accordion.Body className='text-start'>
                {Object.keys(namedBiddersData[key].alloc_count).length > 0 ?
                  <Container>
                    {Object.keys(namedBiddersData[key].alloc_count).sort().map((code:string) => (
                      <Row key={namedBiddersData[key].abbr + '-' + code}>
                        <Col sm={8}><strong>{regionsT4Data[code].name}</strong></Col>
                        <Col sm={4}>{namedBiddersData[key].alloc_count[code]} blocks</Col>
                      </Row>
                    ))}
                  </Container>
                  :
                  'No products allocated'
                }
              </Accordion.Body>
            </Accordion.Item>)
          })}
        </Accordion>
        </>
        }
      </>
    );
};

NamedBiddersView.displayName = 'NamedBiddersView';

export default NamedBiddersView;
