import { Offcanvas } from "react-bootstrap";

const OffcanvasPane = (props: any) => {
  const children = props.children;
  const title:string = props.title;
  const show = props.show;
  const handleClose = props.onHide;
  const headerDescription = props.headerDescription;
  const placement = props.placement || 'top';

    return (
        <Offcanvas show={show} onHide={handleClose} placement={placement} {...props}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{title}</Offcanvas.Title>
            {headerDescription}
          </Offcanvas.Header>
          <Offcanvas.Body>
            {children}
          </Offcanvas.Body>
        </Offcanvas>
    );
};

OffcanvasPane.displayName = 'OffcanvasPane';

export default OffcanvasPane;
