import { Breadcrumb, Button, Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import nesglobe from "../../nesglobe.png";

const VariantCPNav = (props:any) => {
  const auction: Auction = props.auction;

  return (
    <Navbar expand='lg' bg='light'>
      <Container fluid>
        <Navbar.Brand className='fs-6'>
            <img className='logo' src={nesglobe} alt="NE&S 3800 MHz Clock Auction Simulation Tool" />
        </Navbar.Brand>
        <Navbar.Text>
          <Breadcrumb>
            <Breadcrumb.Item href={`/web/auction-cp`}>
              Auctions List
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/web/variations/${auction.id}`}>
              Variation Manager
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <span>Auction: <span className='fw-bold'>{auction.name}</span></span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Navbar.Text>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          {props.isAdmin &&
          <Navbar.Text className="ms-1">
            <Link to='/web/variations/queue'>
              <Button variant="purple" size="sm">Variations Queue</Button>
            </Link>
          </Navbar.Text>
          }
          {props.isAdmin &&
          <Navbar.Text className="ms-1">
            <Link to='/web/auction-cp'>
              <Button variant="secondary" size="sm">Back</Button>
            </Link>
          </Navbar.Text>
          }
          <Navbar.Text className='ms-1'>
            <Link to='/web' onClick={() => props.handleLogin(null, false)}>
              <Button variant='danger' size='sm'>Logout</Button>
            </Link>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    );
}

export default VariantCPNav;