import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import NamedBiddersView from "./NamedBiddersView";
import ProductsView from "./ProductsView";
import RegionsView from "./RegionsView";

const StaticPage = (props: any) => {
  const activeKey = props.activeKey || 'regions';

    return (
        <>
        <Navbar expand='lg' bg='light' sticky='top'>
          <Container fluid>
            <Navbar.Brand>Static Data</Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text className='px-1'>
                <Link to='/web'>
                  <Button variant='secondary' size='sm'>Home</Button>
                </Link>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container className='mt-4'>
          <Nav variant="tabs" defaultActiveKey={activeKey}>
            <Nav.Item>
              <Nav.Link href="/web/static/regions" eventKey='regions'>Regions</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/web/static/products" eventKey='products'>Products</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/web/static/namedbidders" eventKey='namedbidders'>
                Bidders with 3500 MHz Holdings
              </Nav.Link>
            </Nav.Item>
          </Nav>
          {activeKey === 'regions' && 
          <RegionsView />
          }
          {activeKey === 'products' &&
          <ProductsView />
          }
          {activeKey === 'namedbidders' &&
          <NamedBiddersView />
          }
        </Container>
        </>
    );
};

StaticPage.displayName = 'StaticPage';

export default StaticPage;
