import { useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, ButtonToolbar, Form, InputGroup, ListGroup, Table } from "react-bootstrap";
import { FaSort } from "react-icons/fa";
import { HiChevronDoubleLeft, HiChevronDoubleRight, HiChevronLeft, HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { getSensibleDuration, range } from "../../Helpers";
import IconWrapper from "./IconWrapper";

export interface IColumnType<T> {
  key: string;
  title: string;
  sort?: (a: T, b: T) => number;
  width?: number;
  render?: (column: IColumnType<T>, item: T) => void;
  searchable?: boolean;
}

interface Props<T> {
  data: T[];
  columns: IColumnType<T>[];
  pagination?: number;
  searchable?: boolean;
}

export function DynamicTable<T>({ data, columns, pagination=10, searchable=true }: Props<T>): JSX.Element {
  const renderTime = Date.now();

  const [sortedDataRows, setSortedDataRows] = useState<T[]>([]);
  const [sortAsc, setSortAsc] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [foundDataRows, setFoundDataRows] = useState<T[]>([]);
  // const [perPage, setPerPage] = useState<number>(pagination);
  const isPaginated = useMemo(()=>{
    if (data.length<pagination) {
      return false
    }
    else {
      return true
    }
  }, [data, pagination]);
  const [slices, setSlices] = useState<T[][]>([]);

  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const handleSort = (sortFn: ((a: any,b: any)=>number)|undefined, isAsc: boolean) => {
    setSortAsc(isAsc);
    const allSorted: T[] = data;
    const foundSorted: T[] = foundDataRows;
    [allSorted, foundSorted].forEach((arr:T[])=>{
      arr.sort(sortFn);
      if (!isAsc) {
        arr.reverse();
      }
    });
    setSortedDataRows([...allSorted]);
    setFoundDataRows([...foundSorted]);
  }

  const filterBySearchTerm = () => {
    let results: T[];
    if (searchTerm !== '') {
        results = sortedDataRows.filter((item: T) => {
          let columnsToSearch: string[] = [];
          columns.forEach((column: IColumnType<T>)=>{
            if (column.searchable) {
              const contents: string = (item as any)[column.key];
              columnsToSearch.push(contents);
            }
          });

          const toInclude = (element: string) => element.toLowerCase().includes(searchTerm.toLowerCase());

          return columnsToSearch.some(toInclude)
        });
    }
    else {
      results = sortedDataRows;
    }
    setCurrentPageIndex(0);
    setFoundDataRows([...results]);
  }

  const handleFilter: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const keyword: string = e.currentTarget.value;

    setSearchTerm(keyword);
  }

  useEffect(()=>{
    filterBySearchTerm();
  }, [searchTerm]);;
  useEffect(()=>{
    console.log('updating foundDataRows');
    if (foundDataRows.length>pagination) {
      console.log(`DynamicTable: computing slices of ${foundDataRows.length} rows @ ${getSensibleDuration((Date.now()-renderTime)/1000)}`);
      const numPages = Math.floor(foundDataRows.length/pagination) + (foundDataRows.length%pagination===0 ? 0 : 1);
      let pages: T[][] = [];

      range(numPages).forEach((n:number)=>{
        if (n+1<numPages) {
          pages.push(foundDataRows.slice(n*pagination,n*pagination+pagination));
        }
        else {
          pages.push(foundDataRows.slice(n*pagination));
        }
      });
      console.log(`DynamicTable: computing slices of ${foundDataRows.length} rows complete @ ${getSensibleDuration((Date.now()-renderTime)/1000)}`);
      setSlices(pages);
    }
    else {
      setSlices([foundDataRows]);
    }
  }, [foundDataRows]);
  useEffect(()=>{
    if (searchTerm==='') {
      setFoundDataRows([...sortedDataRows]);
    }
    else {
      filterBySearchTerm();
    }
  }, [sortedDataRows]);
  useEffect(()=>{
    console.log(`DynamicTable: setting ${data.length} sortedDataRows @ ${getSensibleDuration((Date.now()-renderTime)/1000)}`);
    setCurrentPageIndex(0);
    setSortedDataRows([...data]);
  }, [data]);
  useEffect(()=>{
    // console.log('DynamicTable: began render at ' + renderTime);
  }, []);

  return <>
    {searchable &&
    <InputGroup>
      <InputGroup.Text id="inputGroup-sizing-sm">
        <IconWrapper>
          <HiOutlineSearch />
        </IconWrapper>
      </InputGroup.Text>
      <Form.Control
        aria-label="Search"
        aria-describedby="inputGroup-sizing-sm"
        value={searchTerm}
        onChange={handleFilter}
        type='search'
      />
    </InputGroup>
    }
    <Table>
      <thead>
        <tr>
          {columns.map((column, columnIndex)=>{
            return <th key={`col-heading-${columnIndex}`} style={{ width: column.width ? `${column.width}px` : undefined }}>
              {!!column.sort &&
                <Button
                  variant="link"
                  className="p-0 text-body text-decoration-none"
                  onClick={()=>handleSort(column.sort, !sortAsc)}
                  >
                  <strong>
                    <small>{column.title}</small>
                  </strong>
                  <FaSort />
                </Button>
              }
              {!column.sort && <small>{column.title}</small>}
            </th>
          })}
        </tr>
      </thead>
      <tbody>
        {slices.length>0 && slices[currentPageIndex] && slices[currentPageIndex].map((item, itemIndex)=>(
          <tr key={`data-row-${itemIndex}`}>
            {columns.map((column, columnIndex)=>(
              <td key={`data-cell-${columnIndex}`} style={{ width: column.width ? `${column.width}px` : undefined }}>
                {column.render ? column.render(column, item) : (item as any)[column.key]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
    {isPaginated && foundDataRows.length>pagination && slices.length>1 &&
    <div className='d-flex justify-content-center'>
      <ButtonToolbar>
        <ButtonGroup className='mx-1'>
          <Button
            size='sm'
            variant='outline-secondary'
            onClick={()=>setCurrentPageIndex(0)}
            disabled={currentPageIndex===0}
            >
            <IconWrapper>
              <HiChevronDoubleLeft />
            </IconWrapper>
          </Button>
          <Button
            size='sm'
            variant='outline-secondary'
            onClick={()=>setCurrentPageIndex(currentPageIndex-1)}
            disabled={currentPageIndex===0}
            >
            <IconWrapper>
              <HiChevronLeft />
            </IconWrapper>
          </Button>
        </ButtonGroup>
        <ButtonGroup className='mx-1'>
          <Button
            size='sm'
            variant='link'
            disabled
            className='text-decoration-none'
            >
            Page {currentPageIndex+1} / {slices.length}
          </Button>
        </ButtonGroup>
        <ButtonGroup className='mx-1'>
          <Button
            size='sm'
            variant='outline-secondary'
            onClick={()=>setCurrentPageIndex(currentPageIndex+1)}
            disabled={currentPageIndex===slices.length-1}
            >
            <IconWrapper>
              <HiChevronRight />
            </IconWrapper>
          </Button>
          <Button
            size='sm'
            variant='outline-secondary'
            onClick={()=>setCurrentPageIndex(slices.length-1)}
            disabled={currentPageIndex===slices.length-1}
            >
            <IconWrapper>
              <HiChevronDoubleRight />
            </IconWrapper>
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    </div>
    }
  </>
}

